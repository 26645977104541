// checkUserByMailID

export const checkUserByMailID = async (mail_id,LRAapp) => {
    console.log("mail_id", mail_id);
    console.log("LRAapp", LRAapp);
  
     try {
       const loggedin_by = LRAapp.id;
       const data = await LRAapp.functions.checkUserByMailID(
        mail_id,loggedin_by
       );
       console.log("data", data);
       if (data !== undefined) {
         return JSON.stringify(data);
       }
     } catch (error) {
       return "error:" + error;
     }
   };
   