/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useMemo, useEffect, useRef } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getAllHoliday } from "../../../services/company_holidaysService";
import GlobalListTable from "../../../components/globalTable/gListTable";
import GlobalListPrintTable from "../../../components/globalTable/gListPrintTable";
import { useReactToPrint } from "react-to-print";
//import SweetAlert from 'react-bootstrap-sweetalert';

const holiday = (props) => {
  //context variables

  const context = useContext(AppSettings);
  const jackapp = useRealmApp();

  //state variables
  const [holiday, setholiday] = useState([]);
  const [tabHolidayinfo, setHolidayinfo] = useState(true);
  //print variables & functions
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //table functions
  const data = useMemo(() => [...holiday], [holiday]);
  const HolidayColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Holiday Date",
        accessor: "holiday_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Holiday Name",
        accessor: "holiday_names",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Holiday Description",
        accessor: "holiday_description",
        sortable: true,
        width: "30%",
      },

      // {
      //   Header: "Created On",
      //   accessor: "activity_on",
      //   sortable: true,
      //   width: "10%",
      //   Cell: ({ value }) => Moment(value).format(context.appMomentDateFormat),
      // },
      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    [context.appMomentDateFormat]
  );

  const hiddenHolidayColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
  ];
  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setHolidayinfo(true);
        return;
      default:
        break;
    }
  };

  useEffect(() => {   
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadHoliday(jackapp.currentUser);
  }, []);

   //internal functions
   async function loadHoliday(userInfo) {
    if (holiday.length === 0)
      getAllHoliday(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setholiday(JSON.parse(response));
          // console.log("Response : ", response);
        }
      });
  }
  
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listholiday">Holiday</Link>
          </li>
          <li className="breadcrumb-item active bold">List Holiday</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/settings/holiday"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabHolidayinfo ? "active " : "")
                      }
                    >
                      All
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Link
                  to="/lms/addholiday"
                  href="#"
                  className="btn btn-success btn-rounded px-4 rounded-pill me-2"
                >
                  <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
                  Add Holiday
                </Link>

                <Button
                  onClick={handlePrint}
                  className="btn btn-success btn-rounded px-4 rounded-pill "
                >
                  <i className="fa fa-print fa-lg me-2 ms-n2 text-success-900"></i>{" "}
                  Print
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <PanelBody>
            <GlobalListTable
              gcolumns={HolidayColumns}
              gdata={data !== undefined ? JSON.stringify(data) : ""}
              ghiddencolumns={hiddenHolidayColumns}
              gkey={"holiday_id"}
              glink="/lms/detailholiday"
              gplaceholder={"Search holiday.."}
            />           
          </PanelBody>
         <PanelBody className="invisible">
            <GlobalListPrintTable
              gcolumns={HolidayColumns}
              gdata={data !== undefined ? JSON.stringify(data) : ""}
              ghiddencolumns={hiddenHolidayColumns}
              gkey={"holiday_id"}
              glink="/lms/detailholiday"
              gplaceholder={"Search holiday.."}
              ref={componentRef}
            />
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default holiday;
