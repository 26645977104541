import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { createLeaveRequest } from "../../../services/leaverequestService";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "../../../components/customstyles/react-select-styles";
import { getLeaveTypeLookup } from "../../../services/leavetypesService";
import { getPending } from "../../../services/leave_statusService";
import { getSettingsIDByOrganisationID } from "../../../services/settingsService";
import {
  getAllUsersForLookup,
  getRequestToForLookup,
} from "../../../services/usersService";
import { emailsend } from "../../../services/emailservice";
import { getUserNameByRealmID } from "../../../services/usersService";

const AddLeaveRequest = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();

  //state variavles
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabLeaveRequestnfo, setLeaverRequestnfo] = useState(true);
  const [leaverequest, setLeaveRequest] = useState({
    leavetype_id: "",
    requestto_id: "",
    from_date: "",
    to_date: "",
    total_days: "",
    leave_description: "",
    requestby_id: "",
  });
  const [errors, setErrors] = useState({
    leavetype_id: "",
    requestto_id: "",
    from_date: "",
    to_date: "",
    total_days: "",
    leave_description: "",
    requestby_id: "",
  });
  const [tabLeaveRequestinfo, setLeaveRequestsnfo] = useState(true);
  const [leavetypeList, setleavetypeList] = useState([]);
  const [requesttoList, setrequesttoList] = useState([]);
  const [settings_id, SetSettingsID] = useState();
  const [leavestatus_id, SetLeaveStatusID] = useState();
  const [validatemsg, SetValidatemsg] = useState("");

  //save functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setLeaveRequest({ ...leaverequest, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };

  const findtotaldays = () => {
    errors.total_days = "";
    var date1, date2;
    //  console.log( "from_date", leaverequest.from_date,"to_date", leaverequest.to_date);
    date1 = new Date(leaverequest.from_date);
    date2 = new Date(leaverequest.to_date);
    //calculate time difference
    var time_difference = date2.getTime() - date1.getTime();
    var totaldays = 1 + time_difference / (1000 * 60 * 60 * 24);        
    if (Number.isNaN(totaldays)) {
      totaldays = 0;
    }
    var total_days = String(totaldays)
    setLeaveRequest({ ...leaverequest, ["total_days"]: total_days });
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setLeaveRequest({ ...leaverequest, [name]: value });
    handleFieldValidation(event);
    // console.log(leaverequest);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        if (settings_id !== undefined) {
          saveLeaveRequest(jackapp.currentUser);
        } else {
          SetValidatemsg("Setting not configured, Please add setting. ");
        }
      //console.log("settings_id",settings_id);
    }

    async function saveLeaveRequest(userInfo) {
      createLeaveRequest(
        leaverequest.leavetype_id,
        leaverequest.requestto_id,
        leaverequest.from_date,
        leaverequest.to_date,
        leaverequest.total_days,
        leaverequest.leave_description,
        settings_id,
        leavestatus_id,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setLeaveRequest(JSON.parse(response));
          setisSaved(true);
          emailsend(
            userInfo.customData.first_name +
              " " +
              userInfo.customData.last_name,
            userInfo.customData.realm_user_id,
            leaverequest.requestto_id,
            response._id,
            leavestatus_id,
            window.location.hostname,
            userInfo
          );
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      leavetype_id:
        leaverequest.leavetype_id === ""
          ? (iserror = "Leave Type is required")
          : errors.leavetype_id,
      from_date:
        leaverequest.from_date === ""
          ? (iserror = "From Date is required")
          : errors.from_date,
      to_date:
        leaverequest.to_date === ""
          ? (iserror = "To Date is required")
          : errors.to_date,

      leave_description:
        leaverequest.leave_description === ""
          ? (iserror = "Description is required")
          : errors.leave_description,

      requestto_id:
        leaverequest.requestto_id === ""
          ? (iserror = "Request to is required")
          : errors.requestto_id,
      total_days:
        leaverequest.total_days <= 0
          ? (iserror =
              "Please select a valid From date and To date/To date should be after a From date")
          : errors.total_days,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "leavetype_id":
        localerror = value.length < 3 ? "Leave Type is missing" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/listleaverequest",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setLeaveRequestsnfo(true);
        return;
      default:
        break;
    }
  };
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadrequestto(jackapp.currentUser);
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavetype(jackapp.currentUser);
    }
    var org_id =
      jackapp.currentUser.customData.user_group_roles[0]["organization_id"];
    GetSettingsIDByOrganisationID(org_id);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavestatus(jackapp.currentUser);
    }
  }, []);

  //internal function
  async function loadleavetype(userInfo) {
    getLeaveTypeLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavetypedata = JSON.parse(response);
        let options = leavetypedata.map(function (leavetype) {
          return { value: leavetype._id, label: leavetype.leave_name };
        });
        setleavetypeList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadrequestto(userInfo) {
    getRequestToForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let requesttodata = JSON.parse(response);
        let options = requesttodata.map(function (requestto) {
          return {
            value: requestto.realm_user_id,
            label: requestto.fullname,
          };
        });
        setrequesttoList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function GetSettingsIDByOrganisationID(org_id) {
    //  console.log("keyid : ", keyid);
    getSettingsIDByOrganisationID(org_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let SettingsData = JSON.parse(response);
          SettingsData.map((Settingsdata) => {
            SetSettingsID(Settingsdata._id);
            // console.log("Settingsdata._id : ", Settingsdata._id);
          });
        }
      }
    );
  }

  async function loadleavestatus(leavestatus_id) {
    //  console.log("keyid : ", keyid);
    getPending(leavestatus_id, jackapp.currentUser).then((response) => {
      if (response !== undefined) {
        //console.log(JSON.parse(response));
        let LeaveStatusData = JSON.parse(response);
        LeaveStatusData.map((LeaveStatusData) => {
          SetLeaveStatusID(LeaveStatusData._id);
          //console.log("LeaveStatusData._id : ", LeaveStatusData._id);
        });
      }
    });
  }

  useEffect(() => {
    findtotaldays();
  }, [leaverequest.from_date, leaverequest.to_date]);
  
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listleaverequest">Leave Request</Link>
          </li>
          <li className="breadcrumb-item active bold">Add Leave Request</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/lms/addLeaveRequest"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabLeaveRequestinfo ? "active " : "")
                      }
                    >
                      LeaveRequest Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label ">
                    Leave Type {""}
                    <label className="text-danger">*</label>
                  </label>
                  <Select
                    name="leavetype_id"
                    id="leavetype_id"
                    options={leavetypeList}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "leavetype_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.leavetype_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="leavetype_id"
                    >
                      {errors.leavetype_id}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    From Date <label className="text-danger">*</label>{" "}
                  </label>
                  <input
                    type="date"
                    name="from_date"
                    id="from_date"
                    className="form-control"
                    onChange={handleChange}
                    value={leaverequest.from_date}
                  />
                  {errors.from_date.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="from_date"
                    >
                      {errors.from_date}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    To Date <label className="text-danger">*</label>{" "}
                  </label>
                  <input
                    type="date"
                    name="to_date"
                    id="to_date"
                    className="form-control"
                    onChange={handleChange}
                    value={leaverequest.to_date}
                  />
                  {errors.to_date.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="to_date"
                    >
                      {errors.to_date}
                    </small>
                  )}
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <label className="form-label form-label">
                    Leave Description <label className="text-danger">*</label>
                  </label>
                  <input
                    type="text"
                    name="leave_description"
                    id="leave_description"
                    className="form-control"
                    onChange={handleChange}
                    value={leaverequest.leave_description}
                  />
                  {errors.leave_description.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="leave_description"
                    >
                      {errors.leave_description}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Request To  <label className="text-danger">*</label></label>

                  <Select
                    name="requestto_id"
                    id="requestto_id"
                    options={requesttoList}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "requestto_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.requestto_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="requestto_id"
                    >
                      {errors.requestto_id}
                    </small>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <div
                    className={
                      "tab-pane fade " +
                      (tabLeaveRequestnfo ? "show active " : "")
                    }
                  >
                    <label className="form-label form-label">Total Days </label>
                    <input
                      type="differenceInDays"
                      name="total_days"
                      id="total_days"
                      className="form-control"
                      onChange={handleChange}
                      value={leaverequest.total_days}
                    />
                    {errors.total_days.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="total_days"
                      >
                        {errors.total_days}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {validatemsg.length > 0 && (
                <big className="form-check-label text-red" htmlFor="validation">
                  {validatemsg}
                </big>
              )}
            </PanelBody>
          </form>
        </Panel>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="LeaveRequest is added successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default AddLeaveRequest;
