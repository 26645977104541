import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody} from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { insertHoliday } from "../../../services/company_holidaysService";
import { getSettingsIDByOrganisationID } from "../../../services/settingsService";
import SweetAlert from "react-bootstrap-sweetalert";
const AddHoliday = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
 // console.log("jackapp",jackapp);
  //state variavles
  const [isvaliddata, setisvaliddata] = useState(false);
  const [settings_id, SetSettingsID] = useState();
  const [validatemsg, SetValidatemsg] = useState("");
  const [isSaved, setisSaved] = useState(false);
  const [holiday, setHoliday] = useState({
    holiday_names: "",
    holiday_description: "",
    holiday_date: "",
    is_active: "1",
  });
  const [errors, setErrors] = useState({
    holiday_names: "",
    holiday_description: "",
    holiday_date: "",
    is_active: "",
  });
  const [tabHolidayinfo, setHolidaysnfo] = useState(true);
  //save functions

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setHoliday({ ...holiday, [name]: value });
    handleFieldValidation(event);
    // console.log(holiday);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      if(settings_id !== undefined){
        saveHoliday(jackapp.currentUser);
      }
      else {
        SetValidatemsg(
          "Setting not configured, Please add setting. "
        );
      }   
          //console.log("settings_id",settings_id);

    }
   
    async function saveHoliday(userInfo) {
      insertHoliday(
        holiday.holiday_names,
        holiday.holiday_description,
        holiday.holiday_date,
        holiday.is_active,
        settings_id,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setHoliday(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      holiday_names: holiday.holiday_names === ""  ? (iserror = "Holiday Name is required")  : errors.holiday_names,
      holiday_date: holiday.holiday_date === ""  ? (iserror = "Holiday Date is required")  : errors.holiday_date,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "holiday_names":
        localerror =
          value.length < 3 ? "Holiday Name must be at least 3 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/listholiday",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
    setHolidaysnfo(true);
        return; 
        default:
          break;
  }
  };
  useEffect(() => {
    var org_id =
    jackapp.currentUser.customData.user_group_roles[0]["organization_id"];
  GetSettingsIDByOrganisationID(org_id);
  }, []);
  async function GetSettingsIDByOrganisationID(org_id) {
    //  console.log("keyid : ", keyid);
    getSettingsIDByOrganisationID(org_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let SettingsData = JSON.parse(response);
          SettingsData.map((Settingsdata) => {
            SetSettingsID(Settingsdata._id);
           // console.log("Settingsdata._id : ", Settingsdata._id);
          });
        }
      }
    );
  }
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/lms/listholiday">Holiday</Link>
        </li>
        <li className="breadcrumb-item active bold">Add Holiday</li>
      </ol>
    </div>

    <div className="col-xl-12">
      <Panel>
        <PanelHeader noButton={true}>
          <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
                <li className="nav-item" key="info">
                  <Link
                    
                    onClick={(e) => showTab(e, "info")}
                    className={"nav-link " + (tabHolidayinfo ? "active " : "")}
                  >
                    Holiday Information
                  </Link>
                </li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
              <Button
                onClick={handleSubmit}
                type="submit"
                color="primary"
                disabled={!isvaliddata}
                size="md"
                className="me-1"
              >
                <i className="fa fa-save"></i>&nbsp; Save
              </Button>
              <Button color="default" size="md" onClick={handleSweetAlert}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </PanelHeader>
        <form noValidate>
          <PanelBody>
          <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4 mb-2">
                  <label className="form-label form-label">Holiday Date{" "} <label className="text-danger">*</label> </label>
                  <input
                    type="date"
                    name="holiday_date"
                    id="holiday_date"
                    className="form-control"
                    onChange={handleChange}
                    value={holiday.holiday_date}
                  />
                  {errors.holiday_date.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="holiday_date"
                    >
                      {errors.holiday_date}
                    </small>
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-8 mb-2">
                  <label className="form-label form-label ">
                    Holiday Name{" "}<label  className="text-danger">*</label>
                  </label>

                  <input
                    type="text"
                    name="holiday_names"
                    id="holiday_names"
                    className="form-control"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.holiday_names.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="holiday_names"
                    >
                      {errors.holiday_names}
                    </small>
                  )}
                </div>
              </div> 
              <div className="row">                
                <div className="col-sm-6 col-md-6 col-lg-12 mb-2">
                  <label className="form-label form-label">Holiday Description</label>

                  <textarea
                    type="text"
                    name="holiday_description"
                    id="holiday_description"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {errors.holiday_description.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="holiday_description"
                    >
                      {errors.holiday_description}
                    </small>
                  )}
                </div>
              </div>

              {validatemsg.length > 0 && (
                <big className="form-check-label text-red" htmlFor="validation">
                  {validatemsg}
                </big>
              )}
          </PanelBody>
        </form>
      </Panel>
    </div>
                <div>
                      {isSaved && (
                        <SweetAlert
                          success
                          style={{ color: "black" }}
                          title="Holiday is added successfully.."
                          onConfirm={handleSweetAlert}
                        ></SweetAlert>
                      )}
           </div>
  </div>
  );
};
export default AddHoliday;
