const initialState = null;
const appInfoReducer = (
    state = initialState,
     action
) => {
    switch (action.type) {
        case "setappnfo":
            return action.payload;
        default:
            return state
    }
}

export default appInfoReducer;