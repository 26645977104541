const initialState = null;
const userInfoReducer = (
    state = initialState,
     action
) => {
    switch (action.type) {
        case "setuserinfo":
            return action.payload;
        default:
            return state
    }
}

export default userInfoReducer;