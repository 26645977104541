/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useMemo, useEffect } from "react";
//import { useContext } from "react";
//import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";

//import Moment from "moment";
//import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getLeaveRequest} from "../../../services/leaverequestService";
import {getPendingLeaveRequest, getApprovedLeaveRequest,
  getRejectedLeaveRequest,
  getOnHoldLeaveRequest} from "../../../services/leave_statusService";

import GlobalListTable2 from "../../../components/globalTable/gListTable2";

//import SweetAlert from 'react-bootstrap-sweetalert';

const ListLeaveTransaction = (props) => {
  //context variables
  //let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [leavetransaction, setleavetransaction] = useState([]);
  const [pendingleavetransaction, setpendingleavetransaction] = useState([]);
  const [approvedleavetransaction, setapprovedleavetransaction] = useState([]);
  const [rejectedleavetransaction, setrejectedleavetransaction] = useState([]);
  const [onholdleavetransaction, setonholdleavetransaction] = useState([]);

  const [tabAllinfo, setAllinfo] = useState(true);
  const [tabPendinginfo, setPendinginfo] = useState(false);
  const [tabApprovedinfo, setApprovedinfo] = useState(false);
  const [tabRejectedinfo, setRejectedinfo] = useState(false);
  const [tabOnHoldinfo, setOnHoldinfo] = useState(false);

  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "allinfo":
        setAllinfo(true);
        setPendinginfo(false);
        setApprovedinfo(false);
        setRejectedinfo(false);
        setOnHoldinfo(false);
        return;
      case "pendinginfo":
        setAllinfo(false);
        setPendinginfo(true);
        setApprovedinfo(false);
        setRejectedinfo(false);
        setOnHoldinfo(false);
        return;
        case "approvedinfo":
        setAllinfo(false);
        setPendinginfo(false);
        setApprovedinfo(true);
        setRejectedinfo(false);
        setOnHoldinfo(false);
        return;
        case "rejectedinfo":
          setAllinfo(false);
          setPendinginfo(false);
          setApprovedinfo(false);
          setRejectedinfo(true);
          setOnHoldinfo(false);
          return;
          case "onholdinfo":
            setAllinfo(false);
            setPendinginfo(false);
            setApprovedinfo(false);
            setRejectedinfo(false);
            setOnHoldinfo(true);
            return;
      default:
        break;
    }
  };
 
  //table functions
  const data1 = useMemo(() => [...leavetransaction], [leavetransaction]);
  const leavetransactionColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request To",
        accessor: "first_name",
        sortable: true,
        width: "30%",
      },
      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    
      {
        Header: "Leave Status Comment",
        accessor: "leave_reason",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Request By",
        accessor: "email",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenleavetransactionColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];
  const data2 = useMemo(() => [...pendingleavetransaction], [pendingleavetransaction]);
  const pendingleavetransactionColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request To",
        accessor: "first_name",
        sortable: true,
        width: "30%",
      },
      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    
      {
        Header: "Leave Status Comment",
        accessor: "leave_reason",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Request By",
        accessor: "email",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenpendingleavetransactionColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];

  const data3 = useMemo(() => [...approvedleavetransaction], [approvedleavetransaction]);
  const approvedleavetransactionColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request To",
        accessor: "first_name",
        sortable: true,
        width: "30%",
      },
      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    
      {
        Header: "Leave Status Comment",
        accessor: "leave_reason",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Request By",
        accessor: "email",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenapprovedleavetransactionColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];

  const data4 = useMemo(() => [...rejectedleavetransaction], [rejectedleavetransaction]);
  const rejectedleavetransactionColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request To",
        accessor: "first_name",
        sortable: true,
        width: "30%",
      },
      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    
      {
        Header: "Leave Status Comment",
        accessor: "leave_reason",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Request By",
        accessor: "email",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenrejectedleavetransactionColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];

  const data5 = useMemo(() => [...onholdleavetransaction], [onholdleavetransaction]);
  const onholdleavetransactionColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request To",
        accessor: "first_name",
        sortable: true,
        width: "30%",
      },
      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    
      {
        Header: "Leave Status Comment",
        accessor: "leave_reason",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Request By",
        accessor: "email",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenonholdleavetransactionColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];
  //Ops Functions
  // const showTab = (e, tab) => {
  //   e.preventDefault();
  //   switch (tab) {
  //     case "allinfo":
  //       setleavetransactioninfo(true);
  //       return;
  //   }
  // };
  useEffect(() => {
    //internal functions  
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadleavetransaction(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetPendingLeaveRequest(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetApprovedLeaveRequest(jackapp.currentUser);
      
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetRejectedLeaveRequest(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetOnHoldLeaveRequest(jackapp.currentUser);     

  }, []);

  ///internal functions
  async function loadleavetransaction(userInfo) {
    if (leavetransaction.length === 0)
    // console.log("userInfo : ", userInfo);
    getLeaveRequest(1, userInfo).then((response) => {
      if (response !== undefined) {         
        let transactionDate = (JSON.parse(response));
        setleavetransaction(transactionDate);        
      }      
    });
  }

  async function GetPendingLeaveRequest(userInfo) {
    if (pendingleavetransaction.length === 0)
    // console.log("userInfo : ", userInfo);
    getPendingLeaveRequest(1, userInfo).then((response) => {
      if (response !== undefined) {         
        let pendingdata = (JSON.parse(response));
        setpendingleavetransaction(pendingdata);        
      }      
    });
  }

  async function GetApprovedLeaveRequest(userInfo) {
    if (approvedleavetransaction.length === 0)
    // console.log("userInfo : ", userInfo);
    getApprovedLeaveRequest(1, userInfo).then((response) => {
      if (response !== undefined) {         
        let approveddata = (JSON.parse(response));
        setapprovedleavetransaction(approveddata);        
      }      
    });
  }

  async function GetRejectedLeaveRequest(userInfo) {
    if (rejectedleavetransaction.length === 0)
    // console.log("userInfo : ", userInfo);
    getRejectedLeaveRequest(1, userInfo).then((response) => {
      if (response !== undefined) {         
        let rejecteddata = (JSON.parse(response));
        setrejectedleavetransaction(rejecteddata);
        
      }
      
    });
  }

  async function GetOnHoldLeaveRequest(userInfo) {
    if (rejectedleavetransaction.length === 0)
    // console.log("userInfo : ", userInfo);
    getOnHoldLeaveRequest(1, userInfo).then((response) => {
      if (response !== undefined) {         
        let onholddata = (JSON.parse(response));
        setonholdleavetransaction(onholddata);        
      }      
    });
  }

  return (     
    <div className="row">  
    <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listtransaction"> Leave Transaction</Link>
          </li>
          <li className="breadcrumb-item active bold">List Leave Transactions</li>
        </ol>
      </div> 
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabAllinfo ? "show active " : "")}
        >   
      <div className="col-xl-12">
        <Panel>  
        <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          All
                        </Link>
                      </li>
                      <li className="nav-item" key="pendinginfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "pendinginfo")}
                          className={
                            "nav-link " + (tabPendinginfo ? "active " : "")
                          }
                        >
                          Pending
                        </Link>
                      </li>
                      <li className="nav-item" key="approvedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "approvedinfo")}
                          className={
                            "nav-link " + (tabApprovedinfo ? "active " : "")
                          }
                        >
                          Approved
                        </Link>
                      </li>
                      <li className="nav-item" key="rejectedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "rejectedinfo")}
                          className={
                            "nav-link " + (tabRejectedinfo ? "active " : "")
                          }
                        >
                          Rejected
                        </Link>
                      </li>

                      <li className="nav-item" key="onholdinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "onholdinfo")}
                          className={
                            "nav-link " + (tabOnHoldinfo ? "active " : "")
                          }
                        >
                          On Hold
                        </Link>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </PanelHeader>       
          <PanelBody>
            <GlobalListTable2
              gcolumns={leavetransactionColumns}
              gdata={data1 !== undefined ? JSON.stringify(data1) : ""}
              ghiddencolumns={hiddenleavetransactionColumns}
              gkey={"user_id"}
            // glink="/lms/detailleaverequest"
              gplaceholder={"Search All Leave Request..."}
              
            />
          </PanelBody>
        </Panel>
      </div>
      </div>
    
      </div> 
      <div className="tab-content p-0">
      <div
          className={"tab-pane fade " + (tabPendinginfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          All
                        </Link>
                      </li>
                      <li className="nav-item" key="pendinginfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "pendinginfo")}
                          className={
                            "nav-link " + (tabPendinginfo ? "active " : "")
                          }
                        >
                          Pending
                        </Link>
                      </li>
                      <li className="nav-item" key="approvedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "approvedinfo")}
                          className={
                            "nav-link " + (tabApprovedinfo ? "active " : "")
                          }
                        >
                          Approved
                        </Link>
                      </li>
                      <li className="nav-item" key="rejectedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "rejectedinfo")}
                          className={
                            "nav-link " + (tabRejectedinfo ? "active " : "")
                          }
                        >
                          Rejected
                        </Link>
                      </li>
                      <li className="nav-item" key="onholdinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "onholdinfo")}
                          className={
                            "nav-link " + (tabOnHoldinfo ? "active " : "")
                          }
                        >
                          On Hold
                        </Link>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </PanelHeader>

              <PanelBody>
              <GlobalListTable2
              gcolumns={pendingleavetransactionColumns}
              gdata={data2 !== undefined ? JSON.stringify(data2) : ""}
              ghiddencolumns={hiddenpendingleavetransactionColumns}
              gkey={"user_id"}
               // glink="/lms/detailleaverequest"
              gplaceholder={"Search Pending Leave Request..."}
              
             
            />
            
                
              </PanelBody>
            </Panel>
          </div>
        </div>
        </div>
        <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabApprovedinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          All
                        </Link>
                      </li>
                      <li className="nav-item" key="pendinginfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "pendinginfo")}
                          className={
                            "nav-link " + (tabPendinginfo ? "active " : "")
                          }
                        >
                          Pending
                        </Link>
                      </li>
                      <li className="nav-item" key="approvedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "approvedinfo")}
                          className={
                            "nav-link " + (tabApprovedinfo ? "active " : "")
                          }
                        >
                          Approved
                        </Link>
                      </li>
                      <li className="nav-item" key="rejectedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "rejectedinfo")}
                          className={
                            "nav-link " + (tabRejectedinfo ? "active " : "")
                          }
                        >
                          Rejected
                        </Link>
                      </li>
                      <li className="nav-item" key="onholdinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "onholdinfo")}
                          className={
                            "nav-link " + (tabOnHoldinfo ? "active " : "")
                          }
                        >
                          On Hold
                        </Link>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </PanelHeader>

              <PanelBody>
              <GlobalListTable2
              gcolumns={approvedleavetransactionColumns}
              gdata={data3 !== undefined ? JSON.stringify(data3) : ""}
              ghiddencolumns={hiddenapprovedleavetransactionColumns}
              gkey={"user_id"}
               // glink="/lms/detailleaverequest"
              gplaceholder={"Search Approved Leave Request..."}
             
            />
            
                
              </PanelBody>
            </Panel>
          </div>
        </div>
        </div>
        <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabRejectedinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          All
                        </Link>
                      </li>
                      <li className="nav-item" key="pendinginfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "pendinginfo")}
                          className={
                            "nav-link " + (tabPendinginfo ? "active " : "")
                          }
                        >
                          Pending
                        </Link>
                      </li>
                      <li className="nav-item" key="approvedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "approvedinfo")}
                          className={
                            "nav-link " + (tabApprovedinfo ? "active " : "")
                          }
                        >
                          Approved
                        </Link>
                      </li>
                      <li className="nav-item" key="rejectedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "rejectedinfo")}
                          className={
                            "nav-link " + (tabRejectedinfo ? "active " : "")
                          }
                        >
                          Rejected
                        </Link>
                      </li>
                      <li className="nav-item" key="onholdinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "onholdinfo")}
                          className={
                            "nav-link " + (tabOnHoldinfo ? "active " : "")
                          }
                        >
                          On Hold
                        </Link>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </PanelHeader>

              <PanelBody>
              <GlobalListTable2
              gcolumns={rejectedleavetransactionColumns}
              gdata={data4 !== undefined ? JSON.stringify(data4) : ""}
              ghiddencolumns={hiddenrejectedleavetransactionColumns}
              gkey={"user_id"}  
               // glink="/lms/detailleaverequest"          
              gplaceholder={"Search Rejected Leave Request.."}
            />
            
                
              </PanelBody>
            </Panel>
          </div>
        </div>
        </div>
        <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabOnHoldinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          All
                        </Link>
                      </li>
                      <li className="nav-item" key="pendinginfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "pendinginfo")}
                          className={
                            "nav-link " + (tabPendinginfo ? "active " : "")
                          }
                        >
                          Pending
                        </Link>
                      </li>
                      <li className="nav-item" key="approvedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "approvedinfo")}
                          className={
                            "nav-link " + (tabApprovedinfo ? "active " : "")
                          }
                        >
                          Approved
                        </Link>
                      </li>
                      <li className="nav-item" key="rejectedinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "rejectedinfo")}
                          className={
                            "nav-link " + (tabRejectedinfo ? "active " : "")
                          }
                        >
                          Rejected
                        </Link>
                      </li>
                      <li className="nav-item" key="onholdinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "onholdinfo")}
                          className={
                            "nav-link " + (tabOnHoldinfo ? "active " : "")
                          }
                        >
                          On Hold
                        </Link>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </PanelHeader>

              <PanelBody>
              <GlobalListTable2
              gcolumns={onholdleavetransactionColumns}
              gdata={data5 !== undefined ? JSON.stringify(data5) : ""}
              ghiddencolumns={hiddenonholdleavetransactionColumns}
              gkey={"user_id"}  
               // glink="/lms/detailleaverequest"          
              gplaceholder={"Search On-Hold Leave Request.."}
            />
            
                
              </PanelBody>
            </Panel>
          </div>
        </div>
        </div>
    </div>
    
  );
};

export default ListLeaveTransaction;
