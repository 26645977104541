/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useMemo, useEffect, useRef } from "react";
//import { useContext } from "react";
//import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup } from "reactstrap";
//import Moment from "moment";
//import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";
import { Button } from "reactstrap";
import GlobalListPrintTable from "../../../components/globalTable/gListPrintTable";
import { useReactToPrint } from "react-to-print";
import {
  getYearlyReports,
  getDailyReports,
  getWeeklyReports,
  getMonthlyReports,
  getDateWiseReport,
} from "../../../services/reportService";

import GlobalListTable from "../../../components/globalTable/gListTable";
import GlobalListTable1 from "../../../components/globalTable/gListTable1";
import GlobalListTable2 from "../../../components/globalTable/gListTable2";
import { getAllUsersForLookup } from "../../../services/usersService";

import { getLeaveTypes } from "../../../services/leavetypesService";
import SweetAlert from "react-bootstrap-sweetalert";

const Reports = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  const componentRef = useRef();
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const componentRef4 = useRef();
 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });
  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
  });
  const handlePrint3 = useReactToPrint({
    content: () => componentRef3.current,
  });
  const handlePrint4 = useReactToPrint({
    content: () => componentRef4.current,
  });

  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setReport({ ...report, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };

  const findtotaldays = () => {
    errors.total_days = "";
    var date1, date2;
    //  console.log( "from_date", leaverequest.from_date,"to_date", leaverequest.to_date);
    date1 = new Date(report.from_date);
    date2 = new Date(report.to_date);
    //calculate time difference
    var time_difference = date2.getTime() - date1.getTime();
    var totaldays = 1 + time_difference / (1000 * 60 * 60 * 24);        
    if (Number.isNaN(totaldays)) {
      totaldays = 0;
    }
    var total_days = String(totaldays)
   // console.log("total_days",total_days);
    if(total_days < 0 ){
     // console.log("false")
      SetValidatemsg(
        "Please select a valid From date and To date/To date should be after a From date"
      );
    }
    else{
     // console.log("true")
      SetValidatemsg("");

    }
    setReport({ ...report, ["total_days"]: total_days });
  };


  //state variables
  const [leaveapproval, setleaveapproval] = useState([]);
  const [dailyleaveapproval, setdailyleaveapproval] = useState([]);
  const [weeklyleaveapproval, setweeklyleaveapproval] = useState([]);
  const [monthlyleaveapproval, setmonthlyleaveapproval] = useState([]);
  const [datewiseleaveapproval, setdatewiseleaveapproval] = useState([]);
  const [alluserlist, setAllUserList] = useState([]);
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);

  const [tabAllinfo, setAllinfo] = useState(true);
  const [tabdailyinfo, setDailyinfo] = useState(false);
  const [tabweeklyinfo, setWeeklyinfo] = useState(false);
  const [tabmonthlyinfo, setMonthlyinfo] = useState(false);
  const [tabdatewiseinfo, setDatewiseinfo] = useState(false);


  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      from_date:
      report.from_date === ""
          ? (iserror = "From Date is required")
          : errors.from_date,
      to_date:
        report.to_date === ""
          ? (iserror = "To Date is required")
          : errors.to_date,
          userby_id:
          report.userby_id === ""
            ? (iserror = "User Name is required")
            : errors.userby_id,
  
          total_days:
          report.total_days <= 0
            ? (iserror =
                "Please select a valid From date and To date/To date should be after a From date")
            : errors.total_days,
   
    });
    return iserror.length === 0;
  };

 
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "allinfo":
        setAllinfo(true);
        setDailyinfo(false);
        setWeeklyinfo(false);
        setMonthlyinfo(false);
        setDatewiseinfo(false);
        return;
      case "dailyinfo":
        setAllinfo(false);
        setDailyinfo(true);
        setWeeklyinfo(false);
        setMonthlyinfo(false);
        setDatewiseinfo(false);
        return;
      case "weeklyinfo":
        setAllinfo(false);
        setDailyinfo(false);
        setWeeklyinfo(true);
        setMonthlyinfo(false);
        setDatewiseinfo(false);
        return;
      case "monthlyinfo":
        setAllinfo(false);
        setDailyinfo(false);
        setWeeklyinfo(false);
        setMonthlyinfo(true);
        setDatewiseinfo(false);
        return;
      case "datewiseinfo":
        setAllinfo(false);
        setDailyinfo(false);
        setWeeklyinfo(false);
        setMonthlyinfo(false);
        setDatewiseinfo(true);
        return;
      default:
        break;
    }
  };
  
  const [isSaved, setisSaved] = useState(false);
  const [report, setReport] = useState({
    from_date: "",
    to_date: "",
    userby_id:"",
    total_days: "",
  });
  const [errors, setErrors] = useState({
    from_date: "",
    to_date: "",
    userby_id:"",
    total_days: "",
  });

 
  //save functions

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setReport({ ...report, [name]: value });
    handleFieldValidation(event);
    // console.log(report);
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
    
       
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
   
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    if (validateForm()) {
      errors.SetValidatemsg="";
   
      //  console.log("report.from_date", report.from_date);
    //console.log("report.to_date", report.to_date);
    //console.log("report.userby_id", report.userby_id);
    loadDateWiseReports(String(report.from_date), String(report.to_date),String(report.userby_id));
   
  }};
  // async function saveReport(userInfo) {
  //   createReport(
  //     report.from_date,
  //     report.to_date,

  //     userInfo
  //   ).then((response) => {
  //     if (response !== undefined) {
  //       setisSaved(true);
  //     }
  //   });
  // }

  //error handling functions
  

  
  
  //table functions
  const data1 = useMemo(() => [...leaveapproval], [leaveapproval]);
  const LeaveApprovalColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "5%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "20",
      },

      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "10%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "10%",
      },
      {
        Header: "Total  Days",
        accessor: "total_days",
        sortable: true,
        width: "10%",
      },
      {
        Header: "Request By",
        accessor: "fullname",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Status",
        accessor: "status_name",
        sortable: true,
        width: "20%",
      },
    ],
    []
  );

  const hiddenLeaveApprovalColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
    "leave_description",
    "is_active",
  ];
  const data2 = useMemo(() => [...dailyleaveapproval], [dailyleaveapproval]);
  const dailyleaveapprovalColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "5%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "20",
      },

      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "10%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "10%",
      },
      {
        Header: "Total  Days",
        accessor: "total_days",
        sortable: true,
        width: "10%",
      },
      {
        Header: "Request By",
        accessor: "fullname",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Status",
        accessor: "status_name",
        sortable: true,
        width: "20%",
      },
    ],
    []
  );

  const hiddendailyleaveapprovalColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];

  const data3 = useMemo(() => [...weeklyleaveapproval], [weeklyleaveapproval]);
  const weeklyleaveapprovalColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },

      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request By",
        accessor: "fullname",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    ],
    []
  );

  const hiddenweeklyleaveapprovalColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];

  const data4 = useMemo(
    () => [...monthlyleaveapproval],
    [monthlyleaveapproval]
  );
  const monthlyleaveapprovalColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },

      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request By",
        accessor: "fullname",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    ],
    []
  );

  const hiddenmonthlyleaveapprovalColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];

  const data5 = useMemo(
    () => [...datewiseleaveapproval],
    [datewiseleaveapproval]
  );
  const datewiseleaveapprovalColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },

      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request By",
        accessor: "fullname",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
    ],
    []
  );

  const hiddendatewiseleaveapprovalColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];
  //Ops Functions
  // const showTab = (e, tab) => {
  //   e.preventDefault();
  //   switch (tab) {
  //     case "allinfo":
  //       setleavetransactioninfo(true);
  //       return;
  //   }
  // };
  useEffect(() => {
    //internal functions
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadLeaveApproval(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadDailyReports(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadweeklyReports(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadMonthlyReports(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadAllUser(jackapp.currentUser);

  },[]);
  useEffect(() => {
    findtotaldays();
  }, [report.from_date, report.to_date]);
  async function loadLeaveApproval(userInfo) {
    if (leaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getYearlyReports(1, userInfo).then((response) => {
        if (response !== undefined) {
          let yearlyData = JSON.parse(response);
         // setleaveapproval(yearlyData);
          if(yearlyData.length > "0")
          {
           // console.log("true")
            SetValidatemsg('');
            setleaveapproval(yearlyData);
          }
          else{
            //console.log("false")
            setleaveapproval("");
            SetValidatemsg(
              "No Data Found."
            );
          }

        }
      });
  }
  async function loadDailyReports(userInfo) {
    if (dailyleaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getDailyReports(1, userInfo).then((response) => {
        if (response !== undefined) {
          let dailydata = JSON.parse(response);
          //setdailyleaveapproval(dailydata);
          if(dailydata.length > "0")
          {
           // console.log("true")
            SetValidatemsg('');
            setdailyleaveapproval(dailydata);
          }
          else{
           // console.log("false")
            setdailyleaveapproval("");
            SetValidatemsg(
              "No Data Found."
            );
          }

          
        }
      });
  }

  async function loadweeklyReports(userInfo) {
    if (weeklyleaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getWeeklyReports(1, userInfo).then((response) => {
        if (response !== undefined) {
          let weeklydata = JSON.parse(response);
         // setweeklyleaveapproval(weeklydata);
          if(weeklydata.length > "0")
          {
           // console.log("true")
            SetValidatemsg('');
            setweeklyleaveapproval(weeklydata);
          }
          else{
            //console.log("false")
            setweeklyleaveapproval("");
            SetValidatemsg(
              "No Data Found."
            );
          }

        }
      });
  }

  async function loadMonthlyReports(userInfo) {
    if (monthlyleaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getMonthlyReports(1, userInfo).then((response) => {
        if (response !== undefined) {
          let monthlydata = JSON.parse(response);
         // setmonthlyleaveapproval(monthlydata);
          if(monthlydata.length > "0")
          {
           // console.log("true")
            SetValidatemsg('');
            setmonthlyleaveapproval(monthlydata);
          }
          else{
            //console.log("false")
            setmonthlyleaveapproval("");
            SetValidatemsg(
              "No Data Found."
            );
          }
        }
      });
  }

  async function loadDateWiseReports(from_date, to_date, userby) {
   // console.log("from_date", from_date);
   // console.log("to_date", to_date);
    getDateWiseReport(from_date, to_date, userby, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let datewisedata = JSON.parse(response);
          //setdatewiseleaveapproval(datewisedata);
          //console.log("datewisedata.length",datewisedata.length)
          if(datewisedata.length > "0")
          {
            //console.log("true")
            SetValidatemsg('');
            setdatewiseleaveapproval(datewisedata);
          }
          else{
           // console.log("false")
            setdatewiseleaveapproval("");
            SetValidatemsg(
              "No Data Found. "
            );
          }
        }
      }
    );
  }

  async function loadAllUser(userInfo) {
    getAllUsersForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let requesttodata = JSON.parse(response);
        let options = requesttodata.map(function (requestto) {
          return {
            value: requestto.realm_user_id,
            label: requestto.fullname,
          };
        });
        setAllUserList(options);
        //console.log("Organization1" + response);
      }
    });
  }
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/report"> Reports</Link>
          </li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div className={"tab-pane fade " + (tabAllinfo ? "show active " : "")}>
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          Yearly
                        </Link>
                      </li>
                      <li className="nav-item" key="dailyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "dailyinfo")}
                          className={
                            "nav-link " + (tabdailyinfo ? "active " : "")
                          }
                        >
                          Daily
                        </Link>
                      </li>
                      <li className="nav-item" key="weeklyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "weeklyinfo")}
                          className={
                            "nav-link " + (tabweeklyinfo ? "active " : "")
                          }
                        >
                          Weekly
                        </Link>
                      </li>
                      <li className="nav-item" key="monthlyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "monthlyinfo")}
                          className={
                            "nav-link " + (tabmonthlyinfo ? "active " : "")
                          }
                        >
                          Monthly
                        </Link>
                      </li>

                      <li className="nav-item" key="datewiseinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "datewiseinfo")}
                          className={
                            "nav-link " + (tabdatewiseinfo ? "active " : "")
                          }
                        >
                          Date Wise
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      onClick={handlePrint}
                      className="btn btn-success   btn-rounded px-4 rounded-pill  "
                    >
                      <i className="fa fa-print fa-lg  me-2 ms-n2 text-success-900"></i>{" "}
                      Print
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>
              <PanelBody>
                <GlobalListTable2
                  gcolumns={LeaveApprovalColumns}
                  gdata={data1 !== undefined ? JSON.stringify(data1) : ""}
                  ghiddencolumns={hiddenLeaveApprovalColumns}
                  gkey={"user_id"}
                  //glink="/lms/detailleaveapproval"
                  gplaceholder={"Search All Leave Request..."}
                />
              </PanelBody>

              <PanelBody className="invisible">
                <GlobalListPrintTable
                  gcolumns={LeaveApprovalColumns}
                  gdata={data1 !== undefined ? JSON.stringify(data1) : ""}
                  ghiddencolumns={hiddenLeaveApprovalColumns}
                  gkey={"user_id"}
                  //glink="/lms/detailleaveapproval"
                  gplaceholder={"Search All Leave Request..."}
                  ref={componentRef}
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabdailyinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          Yearly
                        </Link>
                      </li>
                      <li className="nav-item" key="dailyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "dailyinfo")}
                          className={
                            "nav-link " + (tabdailyinfo ? "active " : "")
                          }
                        >
                          Daily
                        </Link>
                      </li>
                      <li className="nav-item" key="weeklyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "weeklyinfo")}
                          className={
                            "nav-link " + (tabweeklyinfo ? "active " : "")
                          }
                        >
                          Weekly
                        </Link>
                      </li>
                      <li className="nav-item" key="monthlyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "monthlyinfo")}
                          className={
                            "nav-link " + (tabmonthlyinfo ? "active " : "")
                          }
                        >
                          Monthly
                        </Link>
                      </li>
                      <li className="nav-item" key="datewiseinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "datewiseinfo")}
                          className={
                            "nav-link " + (tabdatewiseinfo ? "active " : "")
                          }
                        >
                          Date Wise
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      onClick={handlePrint1}
                      className="btn btn-success   btn-rounded px-4 rounded-pill  "
                    >
                      <i className="fa fa-print fa-lg  me-2 ms-n2 text-success-900"></i>{" "}
                      Print
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <GlobalListTable2
                  gcolumns={dailyleaveapprovalColumns}
                  gdata={data2 !== undefined ? JSON.stringify(data2) : ""}
                  ghiddencolumns={hiddendailyleaveapprovalColumns}
                  gkey={"user_id"}
                  // glink="/lms/detailleaverequest"
                  gplaceholder={"Search Daily Leave Request..."}
                />
              </PanelBody>
              <PanelBody className="invisible">
                <GlobalListPrintTable
                  gcolumns={dailyleaveapprovalColumns}
                  gdata={data2 !== undefined ? JSON.stringify(data2) : ""}
                  ghiddencolumns={hiddendailyleaveapprovalColumns}
                  gkey={"user_id"}
                  // glink="/lms/detailleaverequest"
                  gplaceholder={"Search Daily Leave Request..."}
                  ref={componentRef1}
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabweeklyinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          Yearly
                        </Link>
                      </li>
                      <li className="nav-item" key="dailyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "dailyinfo")}
                          className={
                            "nav-link " + (tabdailyinfo ? "active " : "")
                          }
                        >
                          Daily
                        </Link>
                      </li>
                      <li className="nav-item" key="weeklyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "weeklyinfo")}
                          className={
                            "nav-link " + (tabweeklyinfo ? "active " : "")
                          }
                        >
                          Weekly
                        </Link>
                      </li>
                      <li className="nav-item" key="monthlyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "monthlyinfo")}
                          className={
                            "nav-link " + (tabmonthlyinfo ? "active " : "")
                          }
                        >
                          Monthly
                        </Link>
                      </li>
                      <li className="nav-item" key="datewiseinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "datewiseinfo")}
                          className={
                            "nav-link " + (tabdatewiseinfo ? "active " : "")
                          }
                        >
                          Date Wise
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      onClick={handlePrint2}
                      className="btn btn-success   btn-rounded px-4 rounded-pill  "
                    >
                      <i className="fa fa-print fa-lg  me-2 ms-n2 text-success-900"></i>{" "}
                      Print
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <GlobalListTable2
                  gcolumns={weeklyleaveapprovalColumns}
                  gdata={data3 !== undefined ? JSON.stringify(data3) : ""}
                  ghiddencolumns={hiddenweeklyleaveapprovalColumns}
                  gkey={"user_id"}
                  // glink="/lms/detailleaverequest"
                  gplaceholder={"Search Weekly Leave Request..."}
                />
              </PanelBody>
              <PanelBody className="invisible">
                <GlobalListPrintTable
                  gcolumns={weeklyleaveapprovalColumns}
                  gdata={data3 !== undefined ? JSON.stringify(data3) : ""}
                  ghiddencolumns={hiddenweeklyleaveapprovalColumns}
                  gkey={"user_id"}
                  // glink="/lms/detailleaverequest"
                  gplaceholder={"Search Weekly Leave Request..."}
                  ref={componentRef2}
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabmonthlyinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          Yearly
                        </Link>
                      </li>
                      <li className="nav-item" key="dailyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "dailyinfo")}
                          className={
                            "nav-link " + (tabdailyinfo ? "active " : "")
                          }
                        >
                          Daily
                        </Link>
                      </li>
                      <li className="nav-item" key="weeklyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "weeklyinfo")}
                          className={
                            "nav-link " + (tabweeklyinfo ? "active " : "")
                          }
                        >
                          Weekly
                        </Link>
                      </li>
                      <li className="nav-item" key="monthlyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "monthlyinfo")}
                          className={
                            "nav-link " + (tabmonthlyinfo ? "active " : "")
                          }
                        >
                          Monthly
                        </Link>
                      </li>
                      <li className="nav-item" key="datewiseinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "datewiseinfo")}
                          className={
                            "nav-link " + (tabdatewiseinfo ? "active " : "")
                          }
                        >
                          Date Wise
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      onClick={handlePrint3}
                      className="btn btn-success   btn-rounded px-4 rounded-pill  "
                    >
                      <i className="fa fa-print fa-lg  me-2 ms-n2 text-success-900"></i>{" "}
                      Print
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <GlobalListTable2
                  gcolumns={monthlyleaveapprovalColumns}
                  gdata={data4 !== undefined ? JSON.stringify(data4) : ""}
                  ghiddencolumns={hiddenmonthlyleaveapprovalColumns}
                  gkey={"user_id"}
                  // glink="/lms/detailleaverequest"
                  gplaceholder={"Search Monthly Leave Request.."}
                />
              </PanelBody>
              <PanelBody className="invisible">
                <GlobalListPrintTable
                  gcolumns={monthlyleaveapprovalColumns}
                  gdata={data4 !== undefined ? JSON.stringify(data4) : ""}
                  ghiddencolumns={hiddenmonthlyleaveapprovalColumns}
                  gkey={"user_id"}
                  //glink="/lms/detailleaveapproval"
                  gplaceholder={"Search All Leave Request..."}
                  ref={componentRef3}
                />
              </PanelBody>
             
            </Panel>
          </div>
        </div>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabdatewiseinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="allinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "allinfo")}
                          className={
                            "nav-link " + (tabAllinfo ? "active " : "")
                          }
                        >
                          Yearly
                        </Link>
                      </li>
                      <li className="nav-item" key="dailyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "dailyinfo")}
                          className={
                            "nav-link " + (tabdailyinfo ? "active " : "")
                          }
                        >
                          Daily
                        </Link>
                      </li>
                      <li className="nav-item" key="weeklyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "weeklyinfo")}
                          className={
                            "nav-link " + (tabweeklyinfo ? "active " : "")
                          }
                        >
                          Weekly
                        </Link>
                      </li>
                      <li className="nav-item" key="monthlyinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "monthlyinfo")}
                          className={
                            "nav-link " + (tabmonthlyinfo ? "active " : "")
                          }
                        >
                          Monthly
                        </Link>
                      </li>
                      <li className="nav-item" key="datewiseinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "datewiseinfo")}
                          className={
                            "nav-link " + (tabdatewiseinfo ? "active " : "")
                          }
                        >
                          Date Wise
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      onClick={handlePrint4}
                      className="btn btn-success   btn-rounded px-4 rounded-pill  "
                    >
                      <i className="fa fa-print fa-lg  me-2 ms-n2 text-success-900"></i>{" "}
                      Print
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <div className="col-xl-12">
                <Panel>
                  <form noValidate>
                    <PanelBody>
                      <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-2">
                          <label className="form-label form-label">
                            From Date<label className="text-danger">*</label>
                          </label>

                          <input
                            type="date"
                            name="from_date"
                            id="from_date"
                            className="form-control"
                            value={report.from_date}
                            onChange={handleChange}
                            noValidate
                          />
                          {errors.from_date.length > 0 && (
                            <small
                              className="form-check-label text-red"
                              htmlFor="from_date"
                            >
                              {errors.from_date}
                            </small>
                          )}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2">
                          <label className="form-label form-label">
                            To Date<label className="text-danger">*</label>{" "}
                          </label>
                          <input
                            type="date"
                            name="to_date"
                            id="to_date"
                            className="form-control"
                            value={report.to_date}
                            onChange={handleChange}
                            noValidate
                          />
                          {errors.to_date.length > 0 && (
                            <small
                              className="form-check-label text-red"
                              htmlFor="to_date"
                            >
                              {errors.to_date}
                            </small>
                          )}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2">
                  <label className="form-label form-label">User  <label className="text-danger">*</label></label>

                  <Select
                    name="userby_id"
                    id="userby_id"
                    options={alluserlist}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "userby_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.userby_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="userby_id"
                    >
                      {errors.userby_id}
                    </small>
                  )}
                </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 p-4">
                          <Button
                            type="submit"
                            onClick={handleSubmit}
                            color="primary"
                            size="md"
                            disabled={!isvaliddata}
                           
                          >
                            <i className=""></i>&nbsp; Submit
                          </Button>
                        </div>
                      </div>
                      
                    </PanelBody>
                    <hr></hr>
                    <h3>Date Wise Leave Report</h3>
                    
                    <PanelBody>
                      <GlobalListTable2
                        gcolumns={datewiseleaveapprovalColumns}
                        gdata={data5 !== undefined ? JSON.stringify(data5) : ""}
                        ghiddencolumns={hiddendatewiseleaveapprovalColumns}
                        gkey={"user_id"}
                        // glink="/lms/detailleaverequest"
                        gplaceholder={"Search Date Wise Leave Request..."}
                      />
                    </PanelBody>
                    <PanelBody className="invisible">
                      <GlobalListPrintTable
                        gcolumns={datewiseleaveapprovalColumns}
                        gdata={data5 !== undefined ? JSON.stringify(data5) : ""}
                        ghiddencolumns={hiddendatewiseleaveapprovalColumns}
                        gkey={"user_id"}
                        //glink="/lms/detailleaveapproval"
                        gplaceholder={"Search Date Wise Leave Request..."}
                        ref={componentRef4}
                      />

                    </PanelBody>
                    
                  </form>
                  {validatemsg.length > 0 &&(
                <big className="form-check-label text-red" htmlFor="validation">
                  {validatemsg}
                </big>
              )}
                </Panel>
              </div>
              <div>
                
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
