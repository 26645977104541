import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {Panel,PanelHeader,PanelBody} from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { createCustomer } from "../../../services/customerService";
import SweetAlert from "react-bootstrap-sweetalert";
import FileBase from "react-file-base64";
import { getAllStateForLookupByCountry } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from "../../../components/customstyles/react-select-styles";

const AddCustomer = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const statuschecked = true;
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isstatuschecked, setisstatuschecked] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabCustomerinfo, setcustomernfo] = useState(true);
  const [customer, setCustomers] = useState({
    customer_account_number: "",
    customer_name: "",
    logo: "",
    organization_id: "",
    notes: "",
    is_same_address: "",
    is_active: "",
    address: [{
      id: "0",
      type: "",
      title: "",
      street: "",
      suite: "",
      country: "",
      states: "",
      city: "",
      post_code: "",
      phone: "",
      email: "",
      fax: "",
    },
    {
      id: "1",
      type: "",
      title: "",
      street: "",
      suite: "",
      country: "",
      states: "",
      city: "",
      post_code: "",
      phone: "",
      email: "",
      fax: "",
    }
  ],
  website: "",
  linkedin: "",
  facebook: "",
  twitter: "",
  instagram: "",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
  const [stateList1, setstateList1] = useState([]);
  const [stateList2, setstateList2] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [errors, setErrors] = useState({
    client_account_number: "",
    customer_account_number:"",
    customer_name:"",
    client_name: "",
    website: "",
    logo: "",
    organization_id: "",
    notes: "",
    is_same_address: "",
  });

  async function loadStates(itemid,country_id) {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      
      getAllStateForLookupByCountry(1, jackapp.currentUser,country_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let statedata = JSON.parse(response);
        let options = statedata.map(function (state) {
          return { value: state.id, label: state.name };
        });
        if(itemid==="0")
        setstateList1(options);
        if(itemid==="1")
        setstateList2(options);
        //console.log("state1" + response);
      }
    });
  
    }
  }
  useEffect(() => {

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {
            return { value: country.id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }
    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, [jackapp.currentUser]);

  //save functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    // setFacility({ ...facility, [name]: value });
    const path = name.split('.');
    const finalProp = path.pop();
    const newcustomer = { ...customer };
    let pointer = newcustomer;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =value;
    setCustomers(newcustomer);
    setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };

  const handleChange = (event) => {
    // let target = event.target;
    // setFacility({ ...facility, [target.name]: target.value });
    const path = event.target.name.split('.');
    const finalProp = path.pop();
    const newcustomer = { ...customer };
    let pointer = newcustomer;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      setCustomers(newcustomer);
    handleFieldValidation(event);
    setisvaliddata(true);
    // console.log(facility);
  };

  function handleAddressArrayObjectChange(e, itemId, property,islist) {
    const value = (islist===true)?e.value:e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...customer.address];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex(item => item.id === itemId);
    // -1 check to see if we found that object in social_media
    if(index !== -1){
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value  //here property can be "link" 
      }
    }
    setCustomers({ ...customer, address: temparrayprops })
    if(property==="country")
    loadStates(itemId,value);

    if(!islist)  handleFieldValidation(e);
    setisvaliddata(true);
  }

  const handleIsStatusChanged = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (event.target.checked) {
      setisstatuschecked(true);
      
    } else {
      setisstatuschecked(false);
      
    }
    setCustomers({ ...customer, [name]: event.target.checked });
    setisvaliddata(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      saveCustomer(jackapp.currentUser);
    }
    async function saveCustomer(userInfo) {
      createCustomer(
        customer.customer_account_number,customer.customer_name,customer.logo,
        customer.organization_id,
        customer.address[0].id,customer.address[0].title,customer.address[0].street,customer.address[0].suite,
        customer.address[0].country,customer.address[0].states,customer.address[0].city,customer.address[0].post_code,
        customer.address[0].phone,customer.address[0].email,customer.address[0].fax,
        customer.address[1].id,customer.address[1].title,customer.address[1].street,customer.address[1].suite,customer.address[1].country,
        customer.address[1].states,customer.address[1].city,customer.address[1].post_code,customer.address[1].phone,
        customer.address[1].email,customer.address[1].fax,
        customer.website,
        customer.linkedin,
        customer.facebook,
        customer.twitter,
        customer.instagram,
        customer.notes,customer.is_same_address,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setCustomers(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      customer_account_number:
        customer.customer_account_number === ""
          ? (iserror = "customer account number is required")
          : errors.customer_account_number,
      customer_name:
        customer.customer_name === ""
          ? (iserror = "customer name is required")
          : errors.customer_name,
      organization_id:
        customer.organization_id === 0 || customer.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "customer_name":
        localerror =
          value.length < 3 ? "customer Name must be at least 3 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };

  //Message functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setcustomernfo(true);
        return;
        default :
        return;
    }
  };

  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/settings/customers",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/settings/customers">Customers</Link>
        </li>
        <li className="breadcrumb-item active bold">Add Customer</li>
      </ol>
    </div>

    <div className="col-xl-12">
      <Panel>
       
      <PanelHeader noButton={true}>
        <div className="d-flex align-items-center">
          <div>
            <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item" key="info">
                <Link
                  to="/settings/addcustomer"
                  onClick={(e) => showTab(e, "info")}
                  className={"nav-link " + (tabCustomerinfo ? "active " : "")}
                >
                  Customer Information
                </Link>
              </li>
            </ul>
          </div>
          <ButtonGroup className="ms-auto my-n1">
            <Button
              onClick={handleSubmit}
              type="submit"
              color="primary"
              disabled={!isvaliddata}
              size="md"
              className="me-1"
            >
              <i className="fa fa-save"></i>&nbsp; Save
            </Button>
            <Button color="default" size="md" onClick={handleSweetAlert}>
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </PanelHeader>
          <form  noValidate>
          <PanelBody className="p-1 m-1">
            <div className="tab-content p-0">
              <div
                className={
                  "tab-pane fade " + (tabCustomerinfo ? "show active " : "")
                }
              >
                <div className="row">
                <div className="row p-0 m-0">
                  <div className="col-xl-8 colcol-xl-8 col ms-0 ">
                    <Panel>
                      <PanelHeader noButton={true} minButton={true}>
                        Basic Info
                      </PanelHeader>
                      <PanelBody className="p-0 m-0">
                        <div className="row p-1">
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label">
                                Account Number <label className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="customer_account_number"
                                  id="customer_account_number"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                               {errors.customer_account_number.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="customer_account_number"
                                  >
                                    {errors.customer_account_number}
                                  </small>
                                )} 
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                                    <label className="form-label form-label">
                              Customer Name <label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="customer_name"
                              id="customer_name"
                              className="form-control"
                              onChange={handleChange}
                            />
                           {errors.customer_name.length > 0 && (
                              <small
                                className="form-check-label text-red"
                                htmlFor="customer_name"
                              >
                                {errors.customer_name}
                              </small>
                            )} 
                           </div>
                           <div className="col-sm-12 col-md-3 col-lg-3">
                                      <label className="form-label">Organization <label className="text-danger">*</label></label>
                                      <Select
                                        name="organization_id"
                                        options={OrganizationList}
                                        isSearchable={true}
                                        styles={styles}
                                        onChange={(val) => {
                                          handleDropDownChange({
                                            target: {
                                              name: "organization_id",
                                              value: val.value,
                                            },
                                          });
                                        }}
                                      ></Select>
                                       {errors.organization_id.length > 0 && (
                                        <small
                                          className="form-check-label text-red"
                                          htmlFor="organization_id"
                                        >
                                          {errors.organization_id}
                                        </small>
                                      )} 
                           </div>
                        </div>  
                        <div className="row mb-1">
                        <div className="col-sm-12 col-md-7 col-lg-7 ">
                              <label className="form-label">Website</label>
                              <input
                                type="text"
                                name="website"
                                id="website"
                                className="form-control"
                                onChange={handleChange}
                              />
                              {/* {errors.website.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="website"
                                >
                                  {errors.website}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-2">
                              <label className="form-label">Active</label>
                              <div className="form-check form-switch ms-auto mb-0">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  disabled="disabled"
                                  name="isactive"
                                  checked={statuschecked}
                                  id="isactive"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isactive"
                                >
                                  &nbsp;
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3  mw-25 rounded">
                                <label className="form-label ">Logo</label>
                               
                                  <FileBase
                                    type="file"
                                    multiple={false}
                                    onDone={({ base64 }) => {
                                      setCustomers({ ...customer, ["logo"]: base64 });
                                      setisvaliddata(true);
                                    }}
                                  /></div>
                          </div>
                                
                        <div className="row mb-1">
                        <div className="col-sm-12 col-md-9 col-lg-9">
                            <label className="form-label">Notes</label>

                            <input
                              type="textarea"
                              name="notes"
                              id="notes"
                              className="form-control"
                              onChange={handleChange}
                            />
                            {/* {errors.notes.length > 0 && (
                              <small
                                className="form-check-label text-red"
                                htmlFor="notes"
                              >
                                {errors.notes}
                              </small>
                            )} */}
                          </div>
                          <div className="col-xl-2  col  ms-0 ">
                                  <div className="text-center">
                                  <img src={customer.logo} className="rounded-circle mx-auto d-block mw-100" width="100"
                                height="100" />
                                </div>
                                </div>
                              </div>
    
                      </PanelBody>
                    </Panel>
                  </div>
                  <div className="col-xl-4  col  ms-0">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                          Social Media handles{" "}
                        </PanelHeader>
                        <PanelBody className="p-0 m-0">
                          <div className="row p-1 ">
                            <div className="col-12">
                              <label className="form-label">LinkedIn</label>
                              <input
                                type="text"
                                name="linkedin"
                                id="linkedin"
                                className="form-control"
                                onChange={handleChange}
                              />
                              {/* {errors.linkedin.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="linkedin"
                                >
                                  {errors.linkedin}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-12">
                              <label className="form-label">Facebook</label>
                              <input
                                type="text"
                                name="facebook"
                                id="facebook"
                                className="form-control"
                                onChange={handleChange}
                              />
                              {/* {errors.facebook.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="facebook"
                                >
                                  {errors.facebook}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-6">
                              <label className="form-label">Twitter</label>

                              <input
                                type="text"
                                name="twitter"
                                id="twitter"
                                className="form-control"
                                onChange={handleChange}
                              />
                              {/* {errors.twitter.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="twitter"
                                >
                                  {errors.twitter}
                                </small>
                              )} */}
                            </div>
                            <div className="col-6">
                              <label className="form-label">
                                Instagram
                              </label>

                              <input
                                type="text"
                                name="instagram"
                                id="instagram"
                                className="form-control"
                                onChange={handleChange}
                              />
                              {/* {errors.instagram.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="instagram"
                                >
                                  {errors.instagram}
                                </small>
                              )} */}
                            </div>
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className=" col-xl-6 col ms-0">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                          Physical Contact Info{" "}
                        </PanelHeader>
                        <PanelBody className="p-0 m-0">
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                              <label className="form-label">Street</label>

                              <input
                                type="text"
                                name="address_0_street"
                                id="address_0_street"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "street")}
                              />
                              {/* {errors.street.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="street"
                                >
                                  {errors.street}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                              <label className="form-label">Suite</label>

                              <input
                                type="text"
                                name="suite"
                                id="suite"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "suite")}
                              />
                              {/* {errors.suite.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="suite"
                                >
                                  {errors.suite}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label form-label">
                                Country{" "}
                              </label>
                              <Select
                                name="country_id"
                                options={countryList}
                                isSearchable={true}
                                styles={styles}
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "country",true)}

                              ></Select>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label form-label">
                                State{" "}
                              </label>
                              <Select
                                name="state_id"
                                options={stateList1}
                                isSearchable={true}
                                styles={styles}
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "states",true)}
                              ></Select>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">City</label>

                              <input
                                type="text"
                                name="city"
                                id="city"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "city")}
                              />
                              {/* {errors.city.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="city"
                                >
                                  {errors.city}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">
                                Postal Code
                              </label>

                              <input
                                type="text"
                                name="post_code"
                                id="post_code"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "post_code")}
                              />
                              {/* {errors.post_code.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="post_code"
                                >
                                  {errors.post_code}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Phone</label>

                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "phone")}
                              />
                              {/* {errors.phone.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="phone"
                                >
                                  {errors.phone}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Fax</label>

                              <input
                                type="text"
                                name="fax"
                                id="fax"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "fax")}
                              />
                              {/* {errors.fax.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="fax"
                                >
                                  {errors.fax}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label">Email</label>

                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "0", "email")}
                              />
                              {/* {errors.email.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="customer_name"
                                >
                                  {errors.email}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                          
                            
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                    <div className=" col-xl-6 col ms-0">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                        <div className="d-flex align-items-center">
                              <div>
                               Billing Contact Info {""}
                              </div>
                              <ButtonGroup className="ms-auto my-n1">
                              <div className="form-check ms-auto mb-0">
                              <input type="checkbox" className="form-check-input" 
                              name="is_same_address" checked={isstatuschecked}  onChange={handleIsStatusChanged} id="is_same_address" value="1" />
                                &nbsp;
                                 <label className="form-label me-3">
                                Is Same as Physical Contact Info
                              </label>
                                 </div>
                              </ButtonGroup>
                            </div>
                        </PanelHeader>
                        <PanelBody className="p-0 m-0">
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                              <label className="form-label">Street</label>

                              <input
                                type="text"
                                name="street"
                                id="street"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "street")}
                              />
                              {/* {errors.street.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="street"
                                >
                                  {errors.street}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                              <label className="form-label">Suite</label>

                              <input
                                type="text"
                                name="suite"
                                id="suite"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "suite")}
                              />
                              {/* {errors.suite.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="suite"
                                >
                                  {errors.suite}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label form-label">
                                Country{" "}
                              </label>
                              <Select
                                name="country_id"
                                options={countryList}
                                isSearchable={true}
                                styles={styles}
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "country",true)}
                              ></Select>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label form-label">
                                State{" "}
                              </label>
                              <Select
                                name="state_id"
                                options={stateList2}
                                isSearchable={true}
                                styles={styles}
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "states",true)}
                              ></Select>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">City</label>

                              <input
                                type="text"
                                name="city"
                                id="city"
                                className="form-control"
                                onChange={handleChange}
                              />
                              {/* {errors.city.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="city"
                                >
                                  {errors.city}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">
                                Postal Code
                              </label>

                              <input
                                type="text"
                                name="post_code"
                                id="post_code"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "post_code")}
                              />
                              {/* {errors.post_code.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="post_code"
                                >
                                  {errors.post_code}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Phone</label>

                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "phone")}
                              />
                              {/* {errors.phone.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="phone"
                                >
                                  {errors.phone}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Fax</label>

                              <input
                                type="text"
                                name="fax"
                                id="fax"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "fax")}
                              />
                              {/* {errors.fax.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="fax"
                                >
                                  {errors.fax}
                                </small>
                              )} */}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label">Email</label>

                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="form-control"
                                onChange={(e) => handleAddressArrayObjectChange(e, "1", "email")}
                              />
                              {/* {errors.email.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="customer_name"
                                >
                                  {errors.email}
                                </small>
                              )} */}
                            </div>
                          </div>
                          <div className="row pb-1">
                          
                            
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {isSaved && (
                <SweetAlert
                  success
                  style={{ color: "black" }}
                  title="Customer is added successfully.."
                  onConfirm={handleSweetAlert}
                ></SweetAlert>
              )}
            </div>
          </PanelBody>
        </form>
      </Panel>
    </div>
  </div>
  );
};

export default AddCustomer;
