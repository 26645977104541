export const getAllCountries = async (loggedinby, jackapp) => {
  try {
    const data = await jackapp.functions.getAllCountries();
    if (data !== undefined) {
      //console.log("countries"+data );
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCountryForLookup = async (loggedinby, jackapp) => {
  try {
   
    const data = await jackapp.functions.getCountryForLookup();
    if (data !== undefined) {
      //console.log("countries" + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllCountriesByID = async (countryId, jackapp) => {
  try {
    const { data } = null;
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createCountry = async (
  alpha_code,
  code,
  country_name,
  description,
  flag_path,
  internet_code,
  numeric_code,
  official_state_name,
  sovereignty,
  jackapp
) => {
  try {
    const { data } = null;
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateCountryByID = async (
  id,
  alpha_code,
  code,
  country_name,
  description,
  flag_path,
  internet_code,
  numeric_code,
  official_state_name,
  sovereignty,
  loggedinby,
  jackapp
) => {
  try {
    const { data } = null;
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteCountryByID = async (id, loggedinby, jackapp) => {
  try {
    const { data } = null;
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
