import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
import { Link} from 'react-router-dom';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getOrganizationByID,updateOrganizationByID,} from "../../../services/organizationService";
import SweetAlert from "react-bootstrap-sweetalert";
import ActivityHistory from '../../../components/activityHistory/activityHistory';
//import {useContext} from 'react';
const EditOrganization = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variable
  const [tabOrganizationinfo, setOrganizationinfo] = useState(true);
const [tabActivityinfo, setActivityinfo] = useState(false);
  const [organization, setOrganization] = useState({
    _id: "",
    organization_name: "",
    root_url: "",
    login_url: "",
    is_active: "1",
      activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      }],
  });
  const [errors, setErrors] = useState({
    _id: "",
    organization_name: "",
    login_url: 0,
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);

  const keyid = useLocation().state.organization_id;

  //savefunctions

  const handleChange = (event) => {
    let target = event.target;
    setOrganization({ ...organization, [target.name]: target.value });
    handleFieldValidation(event);
    // console.log(organization);
  };
  const handleSubmit = async (e) => {
    console.log("called handlesubmit");
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        updateOrganization(jackapp.currentUser);
    }
    async function updateOrganization(userInfo) {
      updateOrganizationByID(
        organization._id,
        organization.organization_name,
        organization.root_url,
        organization.login_url,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setOrganizations(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      organization_name:
        organization.organization_name === ""
          ? (iserror = "Organization Name is required")
          : errors.organization_name,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "organization_name":
        localerror =
          value.length < 3 ? "Organization Name must be at least 3 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/settings/organization",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setOrganizationinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setOrganizationinfo(false);
            setActivityinfo(true);
          return;
          default:
            break;
  }
  };
  useEffect(() => {
    //internal functions
    async function loadOrganizationByID(userInfo) {
      getOrganizationByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let Organization = JSON.parse(response);
          Organization.map((organization) =>
            //console.log(organization._id)
            setOrganization({ ...organization, _id: organization._id })
          );
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganizationByID(jackapp.currentUser);
    }
  }, [keyid, jackapp.currentUser]);
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/settings/organization">Organization</Link>
        </li>
        <li className="breadcrumb-item active bold">Edit Organization</li>
      </ol>
    </div>
    <div className="tab-content p-0">
      <div className={'tab-pane fade ' + (tabOrganizationinfo ? 'show active ': '')}>
        <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabOrganizationinfo ? "active " : "")}
                >
                Organization Information
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Information</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
                          <Button
                          type="submit"
                          onClick={handleSubmit}
                          color="primary"
                          disabled={!isvaliddata}
                          size="md"
                          className="me-1"
                          >
                          <i className="fa fa-save"></i>&nbsp; Save
                          </Button>
                          <Button color="default" size="md" onClick={handleSweetAlert}>
                          Cancel
                          </Button>
                      </ButtonGroup>
            </div>
          </PanelHeader>
            <form noValidate>
              <PanelBody>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label ">
                    Organization Name{" "}<label  className="text-danger">*</label>
                  </label>

                  <input
                    type="text"
                    name="organization_name"
                    id="organization_name"
                    className="form-control"
                    value={organization.organization_name}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.organization_name.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="organization_name"
                    >
                      {errors.organization_name}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Root Url</label>

                  <input
                    type="text"
                    name="root_url"
                    id="root_url"
                    className="form-control"
                    value={organization.root_url}
                    onChange={handleChange}
                    noValidate
                  />
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Login Url </label>
                  <input
                    type="text"
                    name="login_url"
                    id="login_url"
                    className="form-control"
                    value={organization.login_url}
                    onChange={handleChange}
                    noValidate
                  />
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Active</label>
                  <div className="form-check form-switch ms-auto mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      disabled="disabled"
                      name="isactive"
                      id="isactive"
                      checked={organization.is_active === "1" ? true : false}
                    />
                    <label className="form-check-label" htmlFor="isactive">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>
              </PanelBody>
            </form>
          </Panel>
        </div>
      </div>
      <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
      <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabOrganizationinfo ? "active " : "")}
                >
                Organization Information
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Information</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
                          <Button
                          type="submit"
                          onClick={handleSubmit}
                          color="primary"
                          disabled={!isvaliddata}
                          size="md"
                          className="me-1"
                          >
                          <i className="fa fa-save"></i>&nbsp; Save
                          </Button>
                          <Button color="default" size="md" onClick={handleSweetAlert}>
                          Cancel
                          </Button>
                      </ButtonGroup>
            </div>
          </PanelHeader>
          <PanelBody>
              <ActivityHistory activityhistory={organization!==undefined?JSON.stringify(organization.activity_history):""}/>
          </PanelBody>
          </Panel>
        </div>

      
      
      </div>
    </div>
              <div>
                {isSaved && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="Organization is updated successfully.."
                    onConfirm={handleSweetAlert}
                  ></SweetAlert>
                )}
              </div>
  </div>
  );
};

export default EditOrganization;
