//services for getCompanyHolidays
export const getAllHoliday = async (loggedin_by, LRAapp) => {
    try {
      const loggedin_by = LRAapp.id;
  
      const data = await LRAapp.functions.getCompanyHolidays(loggedin_by);
  
      if (data !== undefined) {
        // console.log("getLeaveStatusPermission" + JSON.stringify(data));
        return JSON.stringify(data);
      }
    } catch (error) {
      return error.response.data.message;
    }
  };

//services for getCompanyHolidayID
export const getHolidayByID = async (holiday_id, LRAapp) => {
    try {
        
        const loggedin_by = LRAapp.id;
        console.log("loggedin_by ",loggedin_by,"holiday_id :",holiday_id);
        const data = await LRAapp.functions.getCompanyHolidayByID(holiday_id, loggedin_by);
        if (data !== undefined) {
            // console.log("users" + JSON.stringify(data));
            return JSON.stringify(data);
        }
    } catch (error) {
        return error.response.data.message;
    }
};

//services for insertCompanyHoliday
export const insertHoliday = async (
    holiday_names,
    holiday_description,
    holiday_date,
    is_active,
    settings_id,
    jackapp
) => {
    try {
        var loggedinby = jackapp.id;
        const userdata = {
            holiday_names:holiday_names,
            holiday_description:holiday_description,
            holiday_date:holiday_date,
            settings_id,
            is_active:is_active,           
            };
           
        const data = await jackapp.functions.insertCompanyHoliday(
            JSON.stringify(userdata),
            loggedinby
        );
        console.log("status: success",userdata);
        return data;
    } catch (error) {
        console.log("error : ", error);
        throw Error(error);
    }
};

//services for updateCompanyHoliday
export const updateCompanyHoliday = async (
    holiday_id,
    holiday_names,
    holiday_description,
    holiday_date,
    jackapp
  ) => {
    try {
      // var loggedinby = jackapp.id;
      // console.log("holiday_name : ", holiday_name);
      // const data = await jackapp.functions.updateHolidayByID(
      //   holiday_id,
      //   holiday_name,
      //   root_url,
      //   login_url,
      //   loggedinby
      // );
      var loggedinby = jackapp.id;
      const Holidaydata = {
     _id: holiday_id,
        holiday_names: holiday_names,
        holiday_description: holiday_description,
        holiday_date: holiday_date
      };
  
      const data = await jackapp.functions.updateCompanyHoliday(
        JSON.stringify(Holidaydata),
        loggedinby
      );
      //console.log("status: success",Holidaydata,loggedinby);
      return { status: "success", data };
    } catch (error) {
      return error.response.data.message;
    }
  };


// Services for deleteCompanyHolidayID
export const deleteHolidayID = async (user_id, LRAapp) => {
    try {
       // console.log("deleteHolidayID ",deleteHolidayID)
        var loggedinby = LRAapp.id;
        const data = await LRAapp.functions.deleteCompanyHolidayByID(user_id, loggedinby);
        if (data !== undefined) {
            return { status: "success", data };
        }
    } catch (error) {
        return error.response.data.message;
    }
};

//getUpcomingHolidays

export const getUpcomingHolidays = async (loggedin_by, LRAapp) => {
    try {
      const loggedin_by = LRAapp.id;
  
      const data = await LRAapp.functions.getUpcomingHolidays(loggedin_by);
  
      if (data !== undefined) {
        // console.log("getLeaveStatusPermission" + JSON.stringify(data));
        return JSON.stringify(data);
      }
    } catch (error) {
      return error.response.data.message;
    }
  };