import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { useRealmApp } from "../../providers/RealmApp";
import { getRolePermissionByUserID } from "../../services/employeeroleService.jsx";
import EmployeeHome from "./employeehome.jsx";
import ManagerHome from "./managerhome";

const Home = () => {
  const jackapp = useRealmApp();
const [visible,setVisible] = useState();

  useEffect(() =>{
    var emprole_id = jackapp.currentUser.customData.employeerole_id;
    GetRolePermissionByUserID(emprole_id);
  })
  async function GetRolePermissionByUserID(emprole_id) {    
    getRolePermissionByUserID(emprole_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {          
          let EmpRoleData = JSON.parse(response);
//console.log("EmpRoleData :",EmpRoleData);
          setVisible(EmpRoleData);
        }
      }
    );
  }

  return (
    <div>
      
      <Panel>       
        <PanelBody>
          
          <div  hidden= {visible == true}>  
          <ManagerHome/>
            </div>

            <div  hidden= {visible == false}>            
            <EmployeeHome/>  
              </div>

            </PanelBody>
      </Panel>
    </div>
  );
};

export default Home;
