export const getUsers = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getUsers(loggedin_by, "");
    if (data !== undefined) {
      //   console.log("Users" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getUserByID = async (user_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getUserByID(user_id, loggedin_by);
    if (data !== undefined) {
      // console.log("users" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getUserNameByRealmID = async (realm_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getUserNameByRealmID(
      realm_id,
      loggedin_by
    );
    if (data !== undefined) {
      console.log("Realm user" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createUser = async (
  first_name,
  last_name,
  email,
  gender_id,
  dob,
  doj,
  employee_number,
  emergency_contact,
  employeerole_id,
  pan_number,
  aadhar_number,
  photo,
  designation,
  department,
  physical_id,
  physical_title,
  physical_street,
  physical_suite,
  physical_country,
  physical_states,
  physical_city,
  physical_post_code,
  physical_m_phone,
  physical_o_phone,
  physical_email,
  physical_fax,
  grouplist,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;

    const address = [
      {
        id: physical_id,
        type: "physical",
        title: physical_title,
        street: physical_street,
        suite: physical_suite,
        country: physical_country,
        states: physical_states,
        city: physical_city,
        post_code: physical_post_code,
        m_phone: physical_m_phone,
        o_phone: physical_o_phone,
        alternateemail: physical_email,
        fax: physical_fax,
      },
    ];

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const userdata = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      gender_id: gender_id,
      dob: dob,
      doj: doj,
      employee_number: employee_number,
      emergency_contact: emergency_contact,
      employeerole_id: employeerole_id,
      pan_number: pan_number,
      aadhar_number: aadhar_number,
      photo: photo,
      designation: designation,
      department: department,
      address: address,
      user_group_roles: grouplist,
      social_media: social_media_handle,

      //  organization_id: organization_id,
    };

    const data = await jackapp.functions.insertUser(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const updateUserByID = async (
  user_id,
  first_name,
  last_name,
  email,
  gender_id,
  dob,
  doj,
  employee_number,
  emergency_contact,
  employeerole_id,
  pan_number,
  aadhar_number,
  photo,
  designation,
  department,
  physical_id,
  physical_title,
  physical_street,
  physical_suite,
  physical_country,
  physical_states,
  physical_city,
  physical_post_code,
  physical_m_phone,
  physical_o_phone,
  physical_email,
  physical_fax,
  grouplist,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const address = [
      {
        id: physical_id,
        type: "physical",
        title: physical_title,
        street: physical_street,
        suite: physical_suite,
        country: physical_country,
        states: physical_states,
        city: physical_city,
        post_code: physical_post_code,
        m_phone: physical_m_phone,
        o_phone: physical_o_phone,
        alternateemail: physical_email,
        fax: physical_fax,
      },
    ];

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const userdata = {
      user_id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      gender_id: gender_id,
      dob: dob,
      doj: doj,
      employee_number: employee_number,
      emergency_contact: emergency_contact,
      employeerole_id: employeerole_id,
      pan_number: pan_number,
      aadhar_number: aadhar_number,
      photo: photo,
      designation: designation,
      department: department,
      address: address,
      user_group_roles: grouplist,
      social_media: social_media_handle,
    };

    const data = await jackapp.functions.updateUserByID(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateUserRealmUserID = async (
  user_id,
  email,
  realm_user_id,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;

    const userdata = {
      user_id: user_id,
      email: email,
      realm_user_id: realm_user_id,
    };

    const data = await jackapp.functions.updateUserRealmUserID(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteUserByID = async (user_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteUserByID(user_id, loggedinby);
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllGenderForLookup = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAllGenderForLookup(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllUsersForLookup = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAllUsersForLookup(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
    // console.log("getAllUsersForLookup", getAllUsersForLookup);
  } catch (error) {
    return error.response.data.message;
  }
};
export const getRequestToForLookup = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getRequestToForLookup(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
    // console.log("getAllUsersForLookup", getAllUsersForLookup);
  } catch (error) {
    return error.response.data.message;
  }
};

export const getEmployeeRoleLookup = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getEmployeeRoleLookup(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
