import React,{useState,useMemo,useEffect} from 'react';
import {useContext} from 'react';

import { ButtonGroup} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';

import Moment from 'moment';
import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getAllRoles} from "../../../services/roleService";
import GlobalListTable from '../../../components/globalTable/gListTable';
//import SweetAlert from 'react-bootstrap-sweetalert';

const Roles = (props) => {

//context variables
const context = useContext(AppSettings);
const jackapp = useRealmApp();
//state variables
const [roles, setRoles] = useState([]);
const [tabRolesnfo, setRolesnfo] = useState(true);
//table functions 
  const data = useMemo(() => [...roles], [roles]);
  const rolesColumns = useMemo(
    () => [
		{
			Header: "ID",accessor: '_id',sortable:true,width: '20%'
		   },
          {
           Header: "Role ShortName",accessor: 'role_short_name',sortable:true,width: '20%'
          },
		  {
           Header: "Description",accessor: 'role_description',sortable:true,width: '30%'
          },
		  {
           Header: "Weight",accessor: 'weight',sortable:true,width: '30%'
      },
	  {
		Header: "Organization",accessor: 'organization_name',sortable:true,width: '30%'
   },
		  {
            Header: "Created On",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          },
          {
            Header: "Active",
							  accessor: 'is_active',
							  sortable: true,width:  '10%',
                Cell: ({ value }) => {
                  return (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                      {value === "1" ? "Yes" : "No"}
                    </span>
                  );
                },
          }
    ],
    []
  ); 

const hiddenRolesColumns =['_id','deleted_on','organization_id','deleted_by','created_by','modified_on','modified_by','last_modified_on','last_modified_by']

//Ops Functions
const showTab = (e, tab) => {
	e.preventDefault();
	switch(tab){
		case 'info':
	setRolesnfo(true);
			return; 
}
};

  useEffect(() => {
//internal functions
async function loadRoles(userInfo) {
	if(roles.length===0)
	getAllRoles(1,userInfo)
	.then((response)=>{
		if(response!==undefined)
		{	//let data[] = response;
		//	console.log("12" +response.map());
			setRoles(JSON.parse(response));
			
			}

	});
	
	
  }
	if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
	loadRoles(jackapp.currentUser);
  }, [jackapp.currentUser]);

	return (
		<div>
			<div className="row">
				<div className="fs-3 mb-1">
				<ol className="breadcrumb float-xl-start">
					<li className="breadcrumb-item"><Link to="/user/roles">Roles</Link></li>
					<li className="breadcrumb-item active bold">List Roles</li>
				</ol>
				</div>
        
        	<div className="col-xl-12">
     		 <Panel>
				<PanelHeader noButton={true}>
								<div className="d-flex align-items-center">
								<div >
								<ul className="nav nav-tabs nav-tabs-inverse">
									<li className="nav-item" key="info">
									<Link to="/user/roles" onClick={(e) => showTab(e, 'info')} 
									className={'nav-link ' + (tabRolesnfo ? 'active ': '')}>All</Link></li>
								
								</ul>
								</div>
								<ButtonGroup className="ms-auto my-n1">
								<Link to="/user/addrole" href="#" className="btn btn-success btn-rounded px-4 rounded-pill">
									<i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Role</Link>
									</ButtonGroup>
								</div>
				</PanelHeader>
				<PanelBody>
				<GlobalListTable gcolumns={rolesColumns}  gdata={data!==undefined?JSON.stringify(data):""} 
						ghiddencolumns={hiddenRolesColumns}
						gkey={"role_id"} glink="/user/detailrole"
						gplaceholder={"Search Roles.."}
						/>
				</PanelBody>
			</Panel>
            </div>
    </div>
		</div>
	)
}

export default Roles;