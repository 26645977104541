import React, { useState, useEffect } from "react";
//import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody} from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getOrganizationByID,
  deleteOrganizationByID,
} from "../../../services/organizationService";
//import { AppSettings } from "../../../config/app-settings";
import SweetAlert from "react-bootstrap-sweetalert";
import ActivityHistory from '../../../components/activityHistory/activityHistory';
const DetailOrganization = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  //state variabley
  const [organization, setOrganization] = useState({
    _id: "",
    organization_name: "",
    root_url: "",
    login_url: "",
    is_active: "1",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      }],
   
  });

  const jackapp = useRealmApp();
  //const context = useContext(AppSettings);
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);

  //const data = useMemo(() => [...Organization], [Organization]);
  const keyid = useLocation().state.organization_id;
  const [tabOrganizationinfo, setOrganizationinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //functions

  const handleDelete = async (Organizationid) => {
  //  console.log(Organizationid);
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteOrganization(keyid, jackapp.currentUser);
    }
  };
  async function deleteOrganization(Organizationid, userInfo) {
    deleteOrganizationByID(Organizationid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/settings/organization",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setOrganizationinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setOrganizationinfo(false);
            setActivityinfo(true);
          return;
          default:
            break;
  }
  };
  
  useEffect(() => {
    //internal functions
    async function loadOrganizationByID(userInfo) {
      getOrganizationByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let Organization1 = JSON.parse(response);
          Organization1.map((organization) =>
            //console.log(organization._id)
            setOrganization({ ...organization, _id: organization._id })
          );
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadOrganizationByID(jackapp.currentUser);
  }, [keyid, jackapp.currentUser]);
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/settings/organization">Organization</Link>
        </li>
        <li className="breadcrumb-item active bold">Detail Organization</li>
      </ol>
    </div>
  <div className="tab-content p-0">
      <div className={'tab-pane fade ' + (tabOrganizationinfo ? 'show active ': '')}>
        <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabOrganizationinfo ? "active " : "")}
                >
                Basic Info
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
              <Link
              to={{pathname: "/settings/editOrganization",}}
              state={{ "organization_id": keyid }}
              className="btn btn-primary  me-2 "
              >
              <i className="fa fa-fw  fa-square"></i> Edit
              </Link>

              <Button
              onClick={() => handleDelete(keyid)}
              className="btn btn-danger me-2  "
              >
              <i className="fa fa-trash"></i>&nbsp; Delete
              </Button>
              <Button
              onClick={handleCancel}
              className="btn btn-default me-2 "
              >
              Cancel
              </Button>
            </ButtonGroup>
            </div>
          </PanelHeader>

          <PanelBody>
          <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <label className="form-label form-label ">Organization Name </label>

                <input
                  type="text"
                  name="organization_name"
                  id="organization_name"
                  className="form-control"
                  disabled="disabled"
                  value={organization.organization_name}
                />
                <label className="form-check-label" htmlFor="organization_name">
                  &nbsp;
                </label>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <label className="form-label form-label">Root Url</label>

                <input
                  type="text"
                  name="root_url"
                  id="root_url"
                  className="form-control"
                  disabled="disabled"
                  value={organization.root_url}
                />
                <label className="form-check-label" htmlFor="root_url">
                  &nbsp;
                </label>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <label className="form-label form-label">Login Url </label>
                <input
                  type="text"
                  name="login_url"
                  id="login_url"
                  className="form-control"
                  disabled="disabled"
                  value={organization.login_url}
                />
                <label className="form-check-label" htmlFor="login_url">
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <label className="form-label form-label">Active</label>
                <div className="form-check form-switch ms-auto mb-0">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled="disabled"
                    name="isactive"
                    id="isactive"
                    checked={organization.is_active === "1" ? true : false}
                  />
                  <label className="form-check-label" htmlFor="isactive">
                    &nbsp;
                  </label>
                </div>
              </div>
            </div>
            </PanelBody>
          
          </Panel>
        </div>
      

      </div>
      <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
      <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabOrganizationinfo ? "active " : "")}
                >
                Basic Info
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
              <Link
              to={{
                pathname: "/settings/editOrganization",
                state: { organization_id: keyid },
              }}
              className="btn btn-primary  me-2 "
              >
              <i className="fa fa-fw  fa-square"></i> Edit
              </Link>

              <Button
              onClick={() => handleDelete(keyid)}
              className="btn btn-danger me-2  "
              >
              <i className="fa fa-trash"></i>&nbsp; Delete
              </Button>
              <Button
              onClick={handleCancel}
              className="btn btn-default me-2 "
              >
              Cancel
              </Button>
            </ButtonGroup>
            </div>
          </PanelHeader>

          <PanelBody>
              <ActivityHistory activityhistory={organization!==undefined?JSON.stringify(organization.activity_history):""}/>
          </PanelBody>
          </Panel>
        </div>

      
      
      </div>
      
  </div>
  {isDeleted && (
            <SweetAlert
            style={{ color: "black" }}
            warning
            title=""
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnText="No"
            cancelBtnBsStyle="info"
            showCancel
            focusCancelBtn
            onConfirm={handleDeleteSweetAlert}
            onCancel={handleDeleteCancel}
            >
            <h5> Do you wish to delete Organization[{organization.organization_name}]?.</h5>
            </SweetAlert>
          )}
          {isDeletedConfirm && (
            <SweetAlert
            success
            style={{ color: "black" }}
            title="Organization is deleted successfully.."
            onConfirm={handleConfirmDeleteSweetAlert}
            ></SweetAlert>
          )}
</div>
  );
};

export default DetailOrganization;
