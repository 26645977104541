/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, } from "react";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody, 
} from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { createGroup } from "../../../services/groupService";
import SweetAlert from "react-bootstrap-sweetalert";
//import { isNumeric } from "validator";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
//import { getAllFacilityForLookup } from "../../../services/facilityService";
import { getAllCustomerForLookup } from "../../../services/customerService";
import styles from "../../../components/customstyles/react-select-styles";
//import FormInput from "../../../components/common/FormInput";
//import OrganizationListSelect from "../../../components/common/OrganizationListSelect";
const AddGroup = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const statuschecked = true;
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabGroupinfo, setGroupInfo] = useState(true);
  const [groups, setGroups] = useState({
    group_short_name: "",
    group_description: "",
    //  facility_id: "0",
    customer_id: "0",
    organization_id: "0",
  });
  const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  // const [facilityList, setFacilityList] = useState([]);

  const [errors, setErrors] = useState({
    group_short_name: "",
    group_description: "",
    //  facility_id: "",
    customer_id: "",
    organization_id: "",
  });

  const [state, setState] = useState({
    text: "",
    number: "",
    email: "",
    country: "",
    message: "",
    acceptance: false,
  });

  const handleDropdown = (country) => {
    this.setState({ country });
  };

  //save functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setGroups({ ...groups, [name]: value });
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setGroups({ ...groups, [name]: value });
    handleFieldValidation(event);
    // console.log(groups);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        saveGroup(jackapp.currentUser);
    }
    async function saveGroup(userInfo) {
      createGroup(
        groups.group_short_name,
        groups.group_description,
        //        groups.facility_id,
        groups.customer_id,
        groups.organization_id,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setGroups(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,

      group_short_name:
        groups.group_short_name === ""
          ? (iserror = "Group Short Name is required")
          : errors.group_short_name,
      // facility_id:
      //   groups.facility_id === "0" || groups.facility_id === ""
      //     ? (iserror = "Facility is required")
      //     : errors.facility_id,
      organization_id:
        groups.organization_id === "0" || groups.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
          customer_id:
        groups.customer_id === "0" || groups.customer_id === ""
          ? (iserror = "Customer is required")
          : errors.customer_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "group_short_name":
        localerror =
          value.length < 3
            ? "Group Short Name must be at least 3 characters"
            : "";
        break;
      case "group_description":
        localerror =
          value.length > 255
            ? "Group Description must be less than 255 characters"
            : "";
        break;
      // case "facility_id":
      //   localerror = value.length === 0 ? "Facility is missing." : "";
      //   break;
      case "organization_id":
        localerror = value.length === 0 ? "Organization is missing." : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/user/groups",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setGroupInfo(true);
        return;
    }
  };
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization1" + response);
        }
      });
    }

    // if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //   loadFacility(jackapp.currentUser);
    // }

    // async function loadFacility(userInfo) {
    //   getAllFacilityForLookup(1, userInfo).then((response) => {
    //     if (response !== undefined) {
    //       //let data[] = response;
    //       //console.log("Facility" + JSON.parse(response));
    //       let facilitydata = JSON.parse(response);
    //       let options = facilitydata.map(function (facility) {
    //         return { value: facility._id, label: facility.facility_name };
    //       })
    //       setFacilityList(options);
    //     }
    //   });
    // }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, []);
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/groups">Groups</Link>
          </li>
          <li className="breadcrumb-item active bold">Add Group</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/user/addrole"
                      onClick={(e) => showTab(e, "info")}
                      className={"nav-link " + (tabGroupinfo ? "active " : "")}
                    >
                      Group Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <label className="form-label form-label ">
                    Group Short Name <label  className="text-danger">*</label>
                  </label>

                  <input
                    type="text"
                    name="group_short_name"
                    id="group_short_name"
                    className="form-control"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.group_short_name.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="group_short_name"
                    >
                      {errors.group_short_name}
                    </small>
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <label className="form-label form-label">Description</label>

                  <input
                    type="text"
                    name="group_description"
                    id="group_description"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {errors.group_description.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="group_description"
                    >
                      {errors.group_description}
                    </small>
                  )}
                </div>
              </div>

              <div className="row mb-2">
                {/* <FormInput type={"text"} onChange={handleChange} /> */}
                {/* <div>
                  <OrganizationListSelect
                    value=""
                    data={OrganizationList}
                    placeholder="select Organization"
                    className="col-sm-12 col-md-6 col-lg-6"
                  />
                </div> */}
                <div className="col-sm-12 col-md-6 col-lg-6 ">
                  <label className="form-label form-label">Organization <label  className="text-danger">*</label></label>
                  <Select
                    name="organization_id"
                    options={OrganizationList}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: { name: "organization_id", value: val.value },
                      });
                    }}
                  ></Select>
                  {errors.organization_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="organization_id"
                    >
                      {errors.organization_id}
                    </small>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 ">
                  <label className="form-label">Customer <label  className="text-danger">*</label> </label>
                  <Select
                    name="customer_id"
                    id="customer_id"
                    options={customerList}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "customer_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.customer_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="customer_id"
                    >
                      {errors.customer_id}
                    </small>
                  )}
                </div>
                {/* <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <label className="form-label form-label">Facility *</label>
                  <Select name="facility_id" 
                  options={facilityList}
                  isSearchable={true}
                  styles={styles} 
                  onChange={(val)=> {handleDropDownChange({target: { name:'facility_id', value: val.value }})}}  ></Select>
                  {errors.facility_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="facility_id"
                    >
                      {errors.facility_id}
                    </small>
                  )}
                </div> */}
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <label className="form-label form-label">Active</label>
                  <div className="form-check form-switch ms-auto mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      disabled="disabled"
                      name="is_active"
                      checked={statuschecked}
                      id="is_active"
                    />
                    <label className="form-check-label" htmlFor="is_active">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>
            </PanelBody>
          </form>
        </Panel>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Group is added successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default AddGroup;
