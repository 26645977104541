import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import styles from "../../../components/customstyles/react-select-styles";
import Select from "react-select";
import { useRealmApp } from "../../../providers/RealmApp";
import { createLeaveTypes } from "../../../services/leavetypesService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getSettingsIDByOrganisationID } from "../../../services/settingsService";

const AddLeaveType = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [settingyearlist, setSettingsYearlist]= useState([]);
  const [leavetype, setLeaveType] = useState({
    setting_year_id:"",
    leave_name: "",
    leavetype_description: "",
    no_of_days: "",
    is_active: "",
  });
  const [errors, setErrors] = useState({
    setting_year_id:"",
    leave_name: "",
    leavetype_description: "",
    no_of_days: "",
    is_active: "",
  });
  const [tabLeaveTypeinfo, setLeaveTypeinfo] = useState(true);

  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setLeaveType({ ...leavetype, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };
  //save functions
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setLeaveType({ ...leavetype, [name]: value });
    handleFieldValidation(event);
    // console.log(leavetype);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)     
        saveLeaveType(jackapp.currentUser);    
          //console.log("settings_id",settings_id);
    }
   
    async function saveLeaveType(userInfo) {
      createLeaveTypes(
        leavetype.setting_year_id,
        leavetype.leave_name,
      leavetype.leavetype_description,
      leavetype.no_of_days, 
      leavetype.is_active,          
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setHoliday(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
 

  

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      leave_name:
        leavetype.leave_name === ""
          ? (iserror = "Leave Type Name is required")
          : errors.leave_name,
      leavetype_description:
        leavetype.leavetype_description === ""
          ? (iserror = "Leave Type Description is required")
          : errors.leavetype_description,
      no_of_days:
        leavetype.no_of_days === ""
          ? (iserror = "No Of Days is required")
          : errors.no_of_days,

          setting_year_id:
          leavetype.setting_year_id === ""
            ? (iserror = "Setting Year is required")
            : errors.setting_year_id,
    });
    return iserror.length === 0;
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
    
       
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/listleavetype",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setLeaveTypeinfo(true);
        return;
      default:
        break;
    }
  };
  useEffect(() => {
    var org_id =
    jackapp.currentUser.customData.user_group_roles[0]["organization_id"];
  GetSettingsIDByOrganisationID(org_id);
  }, []);

  async function GetSettingsIDByOrganisationID(org_id) {
    //  console.log("keyid : ", keyid);
    getSettingsIDByOrganisationID(org_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let SettingsData = JSON.parse(response);
          let options = SettingsData.map(function (setting) {
            return { value: setting._id, label: setting.starting_year+' To '+setting.ending_year };
          });        
            setSettingsYearlist(options)
            console.log("options",options);     
        }
      }
    );
  }

  
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listleavetype">Leave Type </Link>
          </li>
          <li className="breadcrumb-item active bold">Add Leave Type</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/settings/addSetting"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabLeaveTypeinfo ? "active " : "")
                      }
                    >
                    Leave Type Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody>
              <div className="row">

                 <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    Settings Year<label className="text-danger">*</label>
                  </label>

                  <Select
                    name="setting_year_id"
                    id="setting_year_id"
                    options={settingyearlist}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "setting_year_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.setting_year_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="setting_year_id"
                    >
                      {errors.setting_year_id}
                    </small>
                  )}
                </div>
               

                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    Leave Type Name<label className="text-danger">*</label>
                  </label>

                  <input
                    type="text"
                    name="leave_name"
                    id="leave_name"
                    className="form-control"
                    value={leavetype.leave_name}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.leave_name.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="leave_name"
                    >
                      {errors.leave_name}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    No Of Days<label className="text-danger">*</label>{" "}
                  </label>
                  <input
                    type="text"
                    name="no_of_days"
                    id="no_of_days"
                    className="form-control"
                    value={leavetype.no_of_days}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.no_of_days.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="no_of_days"
                    >
                      {errors.no_of_days}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-12">
                  <label className="form-label form-label">
                    Leave Type Description<label className="text-danger">*</label>{" "}
                  </label>
                  <textarea
                    type="text"
                    name="leavetype_description"
                    id="leavetype_description"
                    className="form-control"
                    value={leavetype.leavetype_description}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.leavetype_description.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="leavetype_description"
                    >
                      {errors.leavetype_description}
                    </small>
                  )}
                </div>
               

                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Active</label>
                  <div className="form-check form-switch ms-auto mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="is_active"
                      id="is_active"
                      defaultChecked={true}
                      onChange={(e) => {
                        setisvaliddata(true);
                        setLeaveType({
                          ...leavetype,
                          [e.target.name]: e.target.checked ? "1" : "0",
                        });
                      }}
                    />

                    <label className="form-check-label" htmlFor="is_active">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>
             
            </PanelBody>
          </form>
        </Panel>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Leave Type is added successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default AddLeaveType;
