export const getAvailableSickLeave = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAvailableSickLeave(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};
export const getAvailableCasualLeave = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAvailableCasualLeave(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};
