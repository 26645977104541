import React, { useMemo,useContext } from "react";
import Moment from 'moment';
import { AppSettings } from '../../config/app-settings';
import GlobalActivityTable from '../globalTable/gActivityTable';
export default function ActivityHistory(activitydata) 
  {
	//context variables
const context = useContext(AppSettings);
//state variables
  //table functions 

 const activityhistoryColumns = useMemo(
    () => [

          {
           Header: "Activity",accessor: 'activity_type',sortable:false,width: '20%'
          },
		  {
           Header: "Username",accessor: 'activity_by_name',sortable:false,width: '30%'
          },
		  {
           Header: "Email",accessor: 'activity_by_email',sortable:false,width: '30%'
          },
		  {
            Header: "Date",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          }
    ],
    [context.appMomentDateFormat]
  ); 

const hiddenactivityhistoryColumns =['_id','activity_by_id','activity_by_clientee']

  return (
    <div>
		<GlobalActivityTable gcolumns={activityhistoryColumns}  gdata={activitydata.activityhistory} ghiddencolumns={hiddenactivityhistoryColumns}/>
  
    </div>
  );
}
