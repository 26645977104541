import React from 'react';
import { Link,useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { useRealmApp } from "../../../providers/RealmApp";
import {getUserByID} from "../../../services/usersService"
const DropdownProfile = ({props}) => {
	let navigate = useNavigate();
	//const navigate = useNavigate();
	//const goToLoginoutPage = () => navigate('/user/logout');
    const [dropdownOpen, setdropdownOpen] = useState(false);
	const [useremail, setuseremail] = useState();
	const [userphoto, setuserphoto] = useState();
	const [usersbyid, setUserByID] = useState([]);
	const [isdefaultuserphoto, setisdefaultuserphoto] = useState(true);
	const app = useRealmApp();
	//const keyid = useLocation().state.app.currentUser.customData._id;

	//console.log("keyid",keyid);
	const toggle = () => {
		setdropdownOpen(!dropdownOpen);
	  };
	const onClickLogoutHandler =   (event) => {
		navigate("/authenticate/logout");
	  }
	  const onClickChangePasswordHandler =   (event) => {
		navigate("/authenticate/changepassword");
	  }

	  useEffect(() => {
		if (app.currentUser !== null && app.currentUser !== undefined) 
		{
			setuseremail(app.currentUser._profile.data.email);
			if (app.currentUser.customData !== null && app.currentUser .customData!== undefined) 
			{
				setuserphoto(app.currentUser.customData.photo);
				if(app.currentUser.customData.photo!=="")
				setisdefaultuserphoto(false);
			//console.log(app.currentUser.customData._id);
			}
		}
		
	}, [app.currentUser])
	
		
		return (
			<div className="navbar-item navbar-user dropdown">
				<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
					<div className="menu-profile-image menu-profile-image-icon bg-white-50 text-gray-600">
					<img src={isdefaultuserphoto?"../assets/img/user/user-13.jpg":userphoto }alt="" /> 
					</div>
					
					<span>
						<span className="d-none d-md-inline">{useremail}</span>
						<b className="caret"></b>
					</span>
				</a>
				<div className="dropdown-menu dropdown-menu-end me-1">
					<Link href=""
					 className="dropdown-item" 
					 to={{pathname: "/user/edituser",}}
					 	state={{ "user_id":app.currentUser.customData._id} } 
									>Edit Profile</Link>	
					
					
					<a href="" onClick={onClickChangePasswordHandler} className="dropdown-item">Change Password</a>
					<div className="dropdown-divider"></div>
					<a href="" onClick={onClickLogoutHandler} className="dropdown-item">Log Out</a>
				</div>
			</div>
		);
};

export default DropdownProfile;
