import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { ButtonGroup, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";
//import { Button } from 'reactstrap';
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getFacilityByID,deleteFacilityByID,} from "../../../services/facilityService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllStateForLookupByCountry } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
//import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllCustomerForLookup } from "../../../services/customerService";
import styles from "../../../components/customstyles/react-select-styles";
import ActivityHistory from '../../../components/activityHistory/activityHistory';


const DetailFacility = (props) => {
  //context variables
  let navigate = useNavigate();
  const statuschecked = true;
  //const context = useContext(AppSettings);
  //state variabley
  const [facility, setFacility] = useState({
    facility_code: "",
    facility_name: "",
    address: {
      _id: "",
      type: "",
      title: "",
      street: "",
      suite: "",
      country: "",
      states: "",
      city: "",
      post_code: "",
      phone: "",
      email: "",
      fax: "",
    },
    social_media: [
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
    ],
    notes: "",
    customer_id: "",
    organization_id: "",
    is_active: "1",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });

  const jackapp = useRealmApp();
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  //const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [tabFacilityinfo, setFacilityinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //const data = useMemo(() => [...facility], [facility]);
  const keyid = useLocation().state.facility_id;

  //functions

  const handleDelete = async (facilityid) => {
    
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteFacility(keyid, jackapp.currentUser);
    }
  };
  async function deleteFacility(facilityid, userInfo) {
    deleteFacilityByID(facilityid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/settings/facility",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setFacilityinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setFacilityinfo(false);
            setActivityinfo(true);
          return;
          default:
            return;
  }
  };
  async function loadStates(itemid,country_id) {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      
      getAllStateForLookupByCountry(1, jackapp.currentUser,country_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let statedata = JSON.parse(response);
        let options = statedata.map(function (state) {
          return { value: state.id, label: state.name };
        });
        setStateList(options);
        //console.log("state1" + response);
      }
    });
  
    }
  }
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {

            return { value: country.id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }
    // if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //   loadOrganization(jackapp.currentUser);
    // }

    // async function loadOrganization(userInfo) {
    //   getAllOrganizationForLookup(1, userInfo).then((response) => {
    //     if (response !== undefined) {
    //       //let data[] = response;
    //       let Organizationdata = JSON.parse(response);
    //       let options = Organizationdata.map(function (organization) {
    //         return { value: organization._id, label: organization.organization_name };
    //       });
    //       setOrganizationList(options);
    //       //console.log("Organization1" + response);
    //     }
    //   });
    // }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, [jackapp.currentUser]);

  useEffect(() => {
    //internal functions
    async function loadFacilityByID(userInfo) {
      getFacilityByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let facilitys = JSON.parse(response);
          facilitys.map((facility) => {
            if (facility.address.country !== null && facility.address.country !== "") {
              loadStates("0",facility.address.country);
            }
            //console.log("facility._id", facility._id);
            setFacility({ ...facility, _id: facility._id });
          });
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadFacilityByID(jackapp.currentUser);
     // console.log("facility.facility_name ", facility.facilityele_name);
    }
  }, [keyid, jackapp.currentUser]);
  return (

      <div className="row">
        <div className="fs-3 mb-1">
          <ol className="breadcrumb float-xl-start">
            <li className="breadcrumb-item">
              <Link to="/settings/facility">Facility</Link>
            </li>
            <li className="breadcrumb-item active bold">Detail Facility</li>
          </ol>
        </div>

		    	<div className="tab-content p-0">
              <div className={'tab-pane fade ' + (tabFacilityinfo ? 'show active ': '')}>
                <div className="col-xl-12">
                  <Panel>
                    <PanelHeader noButton={true}>
                        <div className="d-flex align-items-center">
                        <div>
                          <ul className="nav nav-tabs nav-tabs-inverse">
                          <li className="nav-item me-2" key="info">
                            <Link
                            to="/extra/profile"
                            onClick={(e) => showTab(e, "info")}
                            className={"nav-link " + (tabFacilityinfo ? "active " : "")}
                            >
                            Basic Info
                            </Link>
                          </li>
                          <li className="nav-item" key="activityinfo">
                            <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                            className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
                          </ul>
                        </div>
                        <ButtonGroup className="ms-auto my-n1">
                        <Link
              to={{pathname: "/settings/editfacility",}}
              state={{ "facility_id": keyid }}
              className="btn btn-primary  me-2 "
              >
                          <i className="fa fa-fw  fa-square"></i> Edit
                          </Link>

                          <Button
                          onClick={() => handleDelete(keyid)}
                          className="btn btn-danger me-2  "
                          >
                          <i className="fa fa-trash"></i>&nbsp; Delete
                          </Button>
                          <Button
                          onClick={handleCancel}
                          className="btn btn-default me-2 "
                          >
                          Cancel
                          </Button>
                        </ButtonGroup>
                        </div>
                    </PanelHeader>

                    <form noValidate>
                      <PanelBody className="p-0 m-2">
                        
                            <div className="row ">
                              <div className="col-xl-12 col ms-0 ">
                                <Panel>
                                  <PanelHeader noButton={true} minButton={true}>
                                    Basic Info
                                  </PanelHeader>
                                  <PanelBody className="p-0 m-1">
                                    <div className="row mt-1 mb-1">
                                      <div className="col-sm-12 col-md-3 col-lg-3">
                                        <label className="form-label ">
                                          Facility Code 
                                        </label>

                                        <input
                                          type="text"
                                          name="facility_code"
                                          id="facility_code"
                                          className="form-control"
                                          disabled="disabled"
                                          value={facility.facility_code}
                                        />
                                      </div>
                                      <div className="col-sm-12 col-md-3 col-lg-3">
                                        <label className="form-label">
                                          Facility Name
                                        </label>

                                        <input
                                          type="text"
                                          name="facility_name"
                                          id="facility_name"
                                          className="form-control"
                                          disabled="disabled"
                                          value={facility.facility_name}
                                        />
                                      </div>
                                      {/* <div className="col-sm-12 col-md-3 col-lg-3">
                                        <label className="form-label">
                                          Organization *
                                        </label>
                                        <Select
                                          name="organization_id"
                                          options={OrganizationList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={OrganizationList.filter(
                                            (organization) =>
                                              organization.value === facility.organization_id
                                          )}
                                          isDisabled={true}
                                        ></Select>
                                      </div> */}

                                      <div className="col-sm-12 col-md-3 col-lg-3">
                                        <label className="form-label">
                                          Customer
                                        </label>
                                        <Select
                                          name="customer_id"
                                          options={customerList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={customerList.filter(
                                            (customer) =>
                                              customer.value === facility.customer_id
                                          )}
                                          isDisabled={true}
                                        ></Select>
                                      </div>
                                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          <label className="form-label">Website</label>
                                          <input
                                            type="text"
                                            name="website"
                                            id="website"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.social_media[0].link}
                                          />

                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                      <div className="col-sm-12 col-md-9 col-lg-9 ">
                                        <label className="form-label">Notes</label>

                                        <input
                                          type="text"
                                          name="notes"
                                          id="notes"
                                          className="form-control"
                                          disabled="disabled"
                                          value={facility.notes}
                                        />
                                        
                                      </div>
                                     
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                        <label className="form-label">Active</label>
                                          <div className="form-check form-switch ms-auto mb-0">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              disabled="disabled"
                                              name="isactive"
                                              checked={statuschecked}
                                              id="isactive"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="isactive"
                                            >
                                              &nbsp;
                                            </label>
                                          </div>
                                        </div>
                                    </div>
                                  </PanelBody>
                                </Panel>
                              </div>
                              </div>
                              <div className="row">
                                <div className=" col-xl-8 col ms-0">
                                  <Panel>
                                    <PanelHeader noButton={true} minButton={true}>
                                      Contact Info{" "}
                                    </PanelHeader>
                                    <PanelBody className="p-0 m-0">
                                    <div className="row p-1">
                                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                                          <label className="form-label">Street</label>

                                          <input
                                            type="text"
                                            name="street"
                                            id="street"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.street}
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                                          <label className="form-label">Suite</label>

                                          <input
                                            type="text"
                                            name="suite"
                                            id="suite"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.suite}
                                          />
                                        </div>
                                      </div>
                                      <div className="row pb-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                                          <label className="form-label">
                                            Country{" "}
                                          </label>
                                          <Select
                                            name="country_id"
                                            options={countryList}
                                            isSearchable={true}
                                            styles={styles}
                                            value={countryList.filter(
                                              (country) =>
                                                country.value ===
                                                facility.address.country
                                            )}
                                            isDisabled={true}
                                          ></Select>
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          <label className="form-label">
                                            State{" "}
                                          </label>
                                          <Select
                                            name="state_id"
                                            options={stateList}
                                            isSearchable={true}
                                            styles={styles}
                                            value={stateList.filter(
                                              (state) =>
                                                state.value === facility.address.states
                                            )}
                                            isDisabled={true}
                                          ></Select>
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          <label className="form-label">City</label>

                                          <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.city}
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          <label className="form-label">
                                            Postal Code
                                          </label>

                                          <input
                                            type="text"
                                            name="post_code"
                                            id="post_code"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.post_code}
                                          />
                                        </div>
                                      </div>
                                      <div className="row pb-1">
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          <label className="form-label">Phone</label>

                                          <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.phone}
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                          <label className="form-label">Fax</label>

                                          <input
                                            type="text"
                                            name="fax"
                                            id="fax"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.fax}
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                          <label className="form-label">Email</label>

                                          <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.address.email}
                                          />
                                        </div>
                                      </div>
                                    </PanelBody>
                                  </Panel>
                                </div>
                                <div className="col-xl-4  col  ms-0">
                                  <Panel>
                                    <PanelHeader noButton={true} minButton={true}>
                                      Social Media handles{" "}
                                    </PanelHeader>
                                    <PanelBody className="p-0 m-1">
                                    
                                      <div className="row  p-1">
                                        <div className="col-12">
                                          <label className="form-label">LinkedIn</label>
                                          <input
                                            type="text"
                                            name="linkedin"
                                            id="linkedin"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.social_media[1].link}
                                          />
                                        </div>
                                      </div>
                                      <div className="row pb-1">
                                        <div className="col-12">
                                          <label className="form-label">Facebook</label>
                                          <input
                                            type="text"
                                            name="facebook"
                                            id="facebook"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.social_media[2].link}
                                          />
                                        </div>
                                      </div>
                                      <div className="row pb-1">
                                        <div className="col-6">
                                          <label className="form-label">Twitter</label>

                                          <input
                                            type="text"
                                            name="twitter"
                                            id="twitter"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.social_media[3].link}
                                          />
                                        </div>
                                        <div className="col-6">
                                          <label className="form-label">
                                            Instagram
                                          </label>

                                          <input
                                            type="text"
                                            name="instagram"
                                            id="instagram"
                                            className="form-control"
                                            disabled="disabled"
                                            value={facility.social_media[4].link}
                                          />
                                        </div>
                                      </div>
                                    </PanelBody>
                                  </Panel>
                                  
                                </div>
                              
                            </div>
                      </PanelBody>
                    </form>
                  </Panel>

                </div>
              </div>
              <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
                <div className="col-xl-12">
                    <Panel>
                    <PanelHeader noButton={true}>
                        <div className="d-flex align-items-center">
                        <div>
                          <ul className="nav nav-tabs nav-tabs-inverse">
                          <li className="nav-item me-2" key="info">
                            <Link
                            to="/extra/profile"
                            onClick={(e) => showTab(e, "info")}
                            className={"nav-link " + (tabFacilityinfo ? "active " : "")}
                            >
                            Basic Info
                            </Link>
                          </li>
                          <li className="nav-item" key="activityinfo">
                            <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                            className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
                          </ul>
                        </div>
                        <ButtonGroup className="ms-auto my-n1">
                        <Link
              to={{pathname: "/settings/editfacility",}}
              state={{ "facility_id": keyid }}
              className="btn btn-primary  me-2 "
              >
                          <i className="fa fa-fw  fa-square"></i> Edit
                          </Link>

                          <Button
                          onClick={() => handleDelete(keyid)}
                          className="btn btn-danger me-2  "
                          >
                          <i className="fa fa-trash"></i>&nbsp; Delete
                          </Button>
                          <Button
                          onClick={handleCancel}
                          className="btn btn-default me-2 "
                          >
                          Cancel
                          </Button>
                        </ButtonGroup>
                        </div>
                    </PanelHeader>

                    <PanelBody>
                        <ActivityHistory activityhistory={facility!==undefined?JSON.stringify(facility.activity_history):""}/>
                    </PanelBody>
                    </Panel>
                  </div>
				    </div>
          </div>
        {isDeleted && (
                            <SweetAlert
                              style={{ color: "black" }}
                              warning
                              title=""
                              confirmBtnText="Yes"
                              confirmBtnBsStyle="danger"
                              cancelBtnText="No"
                              cancelBtnBsStyle="info"
                              showCancel
                              focusCancelBtn
                              onConfirm={handleDeleteSweetAlert}
                              onCancel={handleDeleteCancel}
                            >
                              <h5>
                                {" "}
                                Do you wish to delete Facility[
                                {facility.facility_name}]?.
                              </h5>
                            </SweetAlert>
                          )}
                          {isDeletedConfirm && (
                            <SweetAlert
                              success
                              style={{ color: "black" }}
                              title="Facility is deleted successfully.."
                              onConfirm={handleConfirmDeleteSweetAlert}
                            ></SweetAlert>
                          )}
      </div>

  );
};

export default DetailFacility;
