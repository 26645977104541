import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";

import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup} from 'reactstrap';
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getAllCustomers } from "../../../services/customerService";
import GlobalListTable from '../../../components/globalTable/gListTable';
//import SweetAlert from 'react-bootstrap-sweetalert';

const Customers = (props) => {
  //context variables

  const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [customers, setCustomers] = useState([]);
  const [tabCustomerinfo, setCustomerinfo] = useState(true);
  //table functions
  const data = useMemo(() => [...customers], [customers]);
  const customersColumns = useMemo(
    () => [
      {
        Header: "ID",accessor: '_id',sortable:true,width: '20%'
       },

          {
           Header: "Account Number",accessor: 'customer_account_number',sortable:true,width: '20%'
          },
		  {
           Header: "Customer Name",accessor: 'customer_name',sortable:true,width: '30%'
          },
		  {
           Header: "Organization Name",accessor: 'organization_name',sortable:true,width: '30%'
      },
		  {
            Header: "Created On",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          },
          {
            Header: "Active",
							  accessor: 'is_active',
							  sortable: true,width:  '10%',
                Cell: ({ value }) => {
                  return (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                      {value === "1" ? "Yes" : "No"}
                    </span>
                  );
                },
          }
    ],
    []
  ); 
  
  const hiddencustomersColumns = [
    "_id",
    "logo",
    
    "organization_id",
    "address",
    "social_media",
    "notes",
    "is_same_address",
  ];
 
  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setCustomerinfo(true);
        return; 
  }
  };
  useEffect(() => {
    //internal functions
    async function loadcustomers(userInfo) {
      if (customers.length === 0)
      getAllCustomers(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;
            //	console.log("12" +response.map());
            setCustomers(JSON.parse(response));
          }
        });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadcustomers(jackapp.currentUser);
  }, [jackapp.currentUser]);

  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item"><Link to="/settings/customers">Customers</Link></li>
        <li className="breadcrumb-item active bold">List Customer</li>
      </ol>
      </div>
    <div className="col-xl-12">
      <Panel>
      <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
              <div >
              <ul className="nav nav-tabs nav-tabs-inverse">
                <li className="nav-item" key="info">
                <Link to="/settings/customers" onClick={(e) => showTab(e, 'info')} 
                className={'nav-link ' + (tabCustomerinfo ? 'active ': '')}>All</Link></li>
              
              </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
              <Link to="/settings/addcustomer" href="#" className="btn btn-success btn-rounded px-4 rounded-pill">
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Customer</Link>
                </ButtonGroup>
              </div>
      </PanelHeader>
        <PanelBody>
        <GlobalListTable gcolumns={customersColumns}  gdata={data!==undefined?JSON.stringify(data):""} 
        ghiddencolumns={hiddencustomersColumns}
        gkey={"customer_id"} glink="/settings/detailcustomer"
        gplaceholder={"Search Customer.."}
        />
  
        </PanelBody>
      </Panel>
    </div>
  </div>
  );
};

export default Customers;
