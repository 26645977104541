import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import SweetAlert from "react-bootstrap-sweetalert";
import { isNumeric } from "validator";
import { createRoleGroup } from "../../../services/rolegroupService";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllCustomerForLookup } from "../../../services/customerService";
import { getGroupsForLookup } from "../../../services/groupService";
import {  getRolesForLookupByOrganization } from "../../../services/roleService";
import styles from "../../../components/customstyles/react-select-styles";
const AddRoleGroup = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const statuschecked = true;
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabRoleGroupsInfo, setRoleGroupsInfo] = useState(true);
  const [rolegroups, setRoleGroups] = useState({
    organization_id: "",
    customer_id: "",
    group_id: "",
    activity_history: {
      activity_type: "",
      activity_by_id: "",
      activity_by_name: "",
      activity_by_email: "",
      activity_by_clientee: "",
      activity_on: "",
    },
  });
  const [errors, setErrors] = useState({
    organization_id: "",
    customer_id: "",
    group_id: "",
  });
  const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [checked, setChecked] = useState([]);

  //save functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setRoleGroups({ ...rolegroups, [name]: value });
    setErrors({ ...errors, [name]: "" });
    setisvaliddata(true);
    if (name === "organization_id") {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadGroups(jackapp.currentUser, value);
      }
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadRoles(jackapp.currentUser,value);
      }
    
    }
    //console.log(`Option selected:`, selectedOption);
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    setisvaliddata(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        saveRoleGroup(jackapp.currentUser);
    }
    async function saveRoleGroup(userInfo) {
      createRoleGroup(
        rolegroups.organization_id,
        rolegroups.customer_id,
        rolegroups.group_id,
        checked,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setRoleGroups(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      // organization_id:
      //   rolegroups.organization_id === "" || rolegroups.organization_id === ""
      //     ? (iserror = "Organization is required")
      //     : errors.organization_id,
      group_id:
        rolegroups.group_id === "" || rolegroups.group_id === ""
          ? (iserror = "Group is required")
          : errors.group_id,
      // customer_id:
      //   rolegroups.customer_id === "" || rolegroups.customer_id === ""
      //     ? (iserror = "Customer is required")
      //     : errors.customer_id,
      organization_id:
        rolegroups.organization_id === "" || rolegroups.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "group_id":
        localerror = value.length === "" ? " is missing." : "";
        break;
      case "organization_id":
        localerror = value.length === "" ? "Organization is missing." : "";
        break;
      case "inlineCheckbox1":
        localerror = value.length === "" ? "Roles is missing." : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };

  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/user/userpolicies",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setRoleGroupsInfo(true);
        return;
    }
  };

  async function loadRoles(userInfo,organization_id) {
    getRolesForLookupByOrganization(1, organization_id,userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let rolesdata = JSON.parse(response);
        let options = rolesdata.map(function (role) {
          return { value: role._id, label: role.role_short_name };
        });
        setRolesList(options);
        //console.log("role" + response);
      }
    });
  }
  useEffect(() => {
  

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, []);

  async function loadGroups(userInfo, organization_id) {
    getGroupsForLookup(1, userInfo, organization_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let groupsdata = JSON.parse(response);
        let options = groupsdata.map(function (group) {
          return { value: group._id, label: group.group_short_name };
        });
        setGroupsList(options);
        //console.log("group" + response);
      }
    });
  }

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/userpolicies">User Policies</Link>
          </li>
          <li className="breadcrumb-item active bold">Add User Policy</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/user/adduserpolicy"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabRoleGroupsInfo ? "active " : "")
                      }
                    >
                      RoleGroup Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody>
              <fieldset>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4">
                    <label className="form-label form-label">
                      Organization <label className="text-danger">*</label>
                    </label>
                    <Select
                      name="organization_id"
                      options={OrganizationList}
                      isSearchable={true}
                      styles={styles}
                      onChange={(val) => {
                        handleDropDownChange({
                          target: { name: "organization_id", value: val.value },
                        });
                      }}
                    ></Select>
                    {errors.organization_id.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="organization_id"
                      >
                        {errors.organization_id}
                      </small>
                    )}
                  </div>
                  {/* <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="form-label">
                      Customer <label className="text-danger">*</label>
                    </label>
                    <Select
                      name="customer_id"
                      id="customer_id"
                      options={customerList}
                      isSearchable={true}
                      styles={styles}
                      onChange={(val) => {
                        handleDropDownChange({
                          target: {
                            name: "customer_id",
                            value: val.value,
                          },
                        });
                      }}
                    ></Select>
                    {errors.customer_id.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="customer_id"
                      >
                        {errors.customer_id}
                      </small>
                    )}
                  </div> */}
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="form-label form-label">
                      Group <label className="text-danger">*</label>
                    </label>
                    <Select
                      name="group_id"
                      options={groupsList}
                      isSearchable={true}
                      styles={styles}
                      onChange={(val) => {
                        handleDropDownChange({
                          target: { name: "group_id", value: val.value },
                        });
                      }}
                    ></Select>
                    {errors.group_id.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="group_id"
                      >
                        {errors.group_id}
                      </small>
                    )}
                  </div>
                  <Panel>
                    <PanelHeader noButton={true} minButton={true}>
                      Roles List{" "}
                    </PanelHeader>
                    <PanelBody className="p-2 m-2">
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                          {rolesList.map((role, i) => (
                            <div
                              key={i}
                               className="form-check form-check-inline p-3 "
                            >
                              <input
                                className="form-check-input "
                                type="checkbox"
                                id="inlineCheckbox1"
                                value={role.value}
                                onChange={handleCheck}
                              ></input>
                              <label
                                 className="form-check-label align-middle"
                                for="inlineCheckbox1"
                              >
                                <span>{role.label}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </PanelBody>
                  </Panel>
                </div>
              </fieldset>
              <div>
                {isSaved && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="RoleGroup is added successfully.."
                    onConfirm={handleSweetAlert}
                  ></SweetAlert>
                )}
              </div>
            </PanelBody>
          </form>
        </Panel>
      </div>
    </div>
  );
};

export default AddRoleGroup;
