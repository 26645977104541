import React, { useState, useEffect } from "react";
//import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { getLeaveTypesByID, deleteLeaveTypesByID } from "../../../services/leavetypesService";

import FileBase from "react-file-base64";
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";
//import { AppSettings } from "../../../config/app-settings";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getSettingsIDByOrganisationID } from "../../../services/settingsService";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
const DetailLeaveType = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  //state variabley
  const [isvaliddata, setisvaliddata] = useState(false);
  const [leavetype, setLeaveType] = useState({
    _id: "",
    organization_id: "",
    starting_year: "",
    ending_year: "",
    is_active: "",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });

  const jackapp = useRealmApp();
  //const context = useContext(AppSettings);
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [OrganizationList, setOrganizationList] = useState([]);
  //const data = useMemo(() => [...Setting], [Setting]);
  const keyid = useLocation().state.leavetype_id;
  const [tabLeaveTypeinfo, setLeaveTypeinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  const [settingyearlist, setSettingsYearlist]= useState([]);
  const[organization_name,setOrganizationName] = useState("");
  //functions

  const handleDelete = async () => {
    
    //  console.log(Settingid);
   
      setisDeleted(true);

  
   
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteSetting(keyid, jackapp.currentUser);
    }
  };
  async function deleteSetting(Settingid, userInfo) {
    deleteLeaveTypesByID(Settingid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }

  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/lms/listleavetype",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setLeaveTypeinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setLeaveTypeinfo(false);
        setActivityinfo(true);
        return;
      default:
        break;
    }
  };


  useEffect(() => { 
    //internal functions
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
    loadLeaveTypesByID(jackapp.currentUser);

    var org_id =
    jackapp.currentUser.customData.user_group_roles[0]["organization_id"];
  GetSettingsIDByOrganisationID(org_id);
      
  }, []);


  async function loadLeaveTypesByID(userInfo) {    
    //console.log("keyid : ", keyid);
   getLeaveTypesByID(keyid, userInfo).then((response) => {
      if (response !== undefined) {
        console.log(JSON.parse(response));
        let Setting = JSON.parse(response);
        Setting.map((leavetype) =>{
          //console.log(leavetype._id)
          setLeaveType({ ...leavetype, _id: leavetype._id })      
       } );
      }  
    })
    
  }
  async function GetSettingsIDByOrganisationID(org_id) {
    //  console.log("keyid : ", keyid);
    getSettingsIDByOrganisationID(org_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let SettingsData = JSON.parse(response);
          let options = SettingsData.map(function (setting) {
            return { value: setting._id, label: setting.starting_year+' To '+setting.ending_year };
          });        
            setSettingsYearlist(options)
            console.log("options",options);     
        }
      }
    );
  }


  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listleavetype">Leave Type</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail Leave Type</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabLeaveTypeinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveTypeinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Link
                      to={{ pathname: "/lms/editleavetype" }}
                      state={{ leavetype_id: keyid }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label ">Setting Year </label>
              <Select
                      name="setting_year_id"
                      options={settingyearlist}
                      isSearchable={true}
                      styles={styles}
                      value={settingyearlist.filter(
                        (customer) =>
                          customer.value === leavetype.setting_year_id
                      )}
                      isDisabled={true}
                    ></Select> 

                    
                    <label className="form-check-label" htmlFor="setting_year_id">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-3 col-lg-4">
                    <label className="form-label">
                      Leave Type Name
                    </label>
                    <input
                      type="text"
                      name="leave_name"
                      id="leave_name"
                      className="form-control"
                      disabled="disabled"
                      value={leavetype.leave_name}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="leave_name"
                    >
                      &nbsp;
                    </label>
                  </div>

                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">
                      No Of Days{""}
                    </label>

                    <input
                      type="text"
                      name="no_of_days"
                      id="no_of_days"
                      className="form-control"
                      disabled="disabled"
                      value={leavetype.no_of_days}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="no_of_days"
                    >
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-12">
                    <label className="form-label form-label">
                      Leave Type Description{" "}
                    </label>
                    <textarea
                      type="text"
                      name="leavetype_description"
                      id="leavetype_description"
                      className="form-control"
                      disabled="disabled"
                      value={leavetype.leavetype_description}
                    />
                    <label className="form-check-label" htmlFor="leavetype_description">
                      &nbsp;
                    </label>
                  </div>
                </div>

                <div className="row pb-2">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">Active</label>
                    <div className="form-check form-switch ms-auto mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled="disabled"
                        name="is_active"
                        id="is_active"
                        checked={leavetype.is_active === "1" ? true : false}
                      />
                      <label className="form-check-label" htmlFor="is_active">
                        &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveTypeinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Link
                      to={{
                        pathname: "/lms/editleavetype",
                        state: { leavetype_id: keyid },
                      }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    leavetype !== undefined
                      ? JSON.stringify(leavetype.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      {isDeleted && (
        <SweetAlert
          style={{ color: "black" }}
          warning
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnText="No"
          cancelBtnBsStyle="info"
          showCancel
          focusCancelBtn
          onConfirm={handleDeleteSweetAlert}
          onCancel={handleDeleteCancel}
        >
          <h5> Do you wish to delete Leave Type[{leavetype.leave_name}]?.</h5>
        </SweetAlert>
      )}
      {isDeletedConfirm && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title="Leave Type is deleted successfully..."
          onConfirm={handleConfirmDeleteSweetAlert}
        ></SweetAlert>
      )}
    </div>
  );
};

export default DetailLeaveType;
