// Services for getLeaveStatusLookup
export const getLeaveStatusLookup = async (loggedinby, LRAapp) => {
  try {
    // console.log('getLeaveStatusLookup',getLeaveStatusLookup)
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveStatusLookup(loggedin_by);

    if (data !== undefined) {
      // console.log("Business" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for getLeaveStatusLookup
export const getLeaveStatusPermission = async (leavestatus_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;

    const data = await LRAapp.functions.getLeaveStatusPermission(
      leavestatus_id,
      loggedin_by
    );

    if (data !== undefined) {
      //console.log("getLeaveStatusPermission" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getPending = async (loggedin_by, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;

    const data = await LRAapp.functions.getLeaveStatusByPending(loggedin_by );

    if (data !== undefined) {
     // console.log("getLeaveStatusPermission" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getPendingLeaveRequest = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getPendingLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getApprovedLeaveRequest = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getApprovedLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getRejectedLeaveRequest = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getRejectedLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getOnHoldLeaveRequest = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getOnHoldLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getAllPendingLeaveInfo = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAllPendingLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getAllApprovedLeaveInfo = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAllApprovedLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

// export const getAllRejectedLeaveInfo = async (loggedinby, LRAapp) => {
//   try {
//     const loggedin_by = LRAapp.id;
//     const data = await LRAapp.functions.getAllRejectedLeaveInfo(loggedin_by);
//     if (data !== undefined) {
//       //console.log("LeaveInfo" + JSON.stringify(data));
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     console.log("LeaveInfo error" + error);
//     return error.response.data.message;
//   }
// };

export const getAllOnHoldLeaveInfo = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAllOnHoldLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};