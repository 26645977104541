import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { getLeaveTypesByID,updateLeaveTypesByID } from "../../../services/leavetypesService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getSettingsIDByOrganisationID } from "../../../services/settingsService";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
//import {useContext} from 'react';
const EditLeaveType = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variable
  const [tabLeaveTypeinfo, setLeaveTypeinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  const [leavetype, setLeaveType] = useState({
    _id: "",
    leave_name: "",
    leavetype_description: "",
    no_of_days: "",
    setting_year_id:"",
    is_active: "",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    _id: "",
    leave_name: "",
    leavetype_description: "",
    no_of_days: "",
    setting_year_id:"",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [settingyearlist, setSettingsYearlist]= useState([]);
  const keyid = useLocation().state.leavetype_id;
 


  //savefunctions

  
  const handleSubmit = async (e) => {
    console.log("called handlesubmit");
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)      
          updateleaveType(jackapp.currentUser);
     
    }
    async function updateleaveType(userInfo) {
      updateLeaveTypesByID(
        leavetype._id,
        leavetype.leave_name,
        leavetype.leavetype_description,
        leavetype.no_of_days,
        leavetype.setting_year_id,  
        leavetype.is_active,
         userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setSettings(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    // setFacility({ ...facility, [name]: value });
    const path = name.split(".");
    const finalProp = path.pop();
    const newfaciity = { ...leavetype };
    let pointer = newfaciity;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] = value;
    setLeaveType(newfaciity);
    setisvaliddata(true);
  };
  const handleChange = (event) => {
    // let target = event.target;
    // setFacility({ ...facility, [target.name]: target.value });
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newfaciity = { ...leavetype };
    let pointer = newfaciity;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setLeaveType(newfaciity);
    handleFieldValidation(event);
    // console.log(facility);
  };
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      leave_name:
        leavetype.leave_name === ""
          ? (iserror = "Leave Name is required")
          : errors.leave_name,
      leavetype_description:
        leavetype.leavetype_description === ""
          ? (iserror = "Leave Type Description is required")
          : errors.leavetype_description,
      no_of_days:
        leavetype.no_of_days === ""
          ? (iserror = "No Of Days is required")
          : errors.no_of_days,
          setting_year_id:
          leavetype.setting_year_id === ""
            ? (iserror = "Setting Year is required")
            : errors.setting_year_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
     
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/listleavetype",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setLeaveTypeinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setLeaveTypeinfo(false);
        setActivityinfo(true);
        return;
      default:
        break;
    }
  };
  useEffect(() => {
    //internal functions
    
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadLeaveTypeByID(jackapp.currentUser);
    }

    var org_id =
    jackapp.currentUser.customData.user_group_roles[0]["organization_id"];
  GetSettingsIDByOrganisationID(org_id);
  }, []);

  async function loadLeaveTypeByID(userInfo) {
    getLeaveTypesByID(keyid, userInfo).then((response) => {
      if (response !== undefined) {
        //console.log(JSON.parse(response));
        let leavetypeData = JSON.parse(response);
        leavetypeData.map((leavetype) =>
          //console.log(leavetype._id)
          setLeaveType({ ...leavetype, _id: leavetype._id })
        );
      }
    });
  }
  
  async function GetSettingsIDByOrganisationID(org_id) {
    //  console.log("keyid : ", keyid);
    getSettingsIDByOrganisationID(org_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let SettingsData = JSON.parse(response);
          let options = SettingsData.map(function (setting) {
            return { value: setting._id, label: setting.starting_year+' To '+setting.ending_year };
          });        
            setSettingsYearlist(options)
            console.log("options",options);     
        }
      }
    );
  }
 

 
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listleavetype">Leave Type</Link>
          </li>
          <li className="breadcrumb-item active bold">Edit Leave Type</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabLeaveTypeinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveTypeinfo ? "active " : "")
                          }
                        >
                          leavetypeData Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>
              <form noValidate>
                <PanelBody>
                  <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    Settings Year<label className="text-danger">*</label>
                  </label>

                  <Select
                     name="setting_year_id"
                     options={settingyearlist}
                     isSearchable={true}
                     styles={styles}
                     value={settingyearlist.filter(
                       (customer) =>
                         customer.value === leavetype.setting_year_id
                     )}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "setting_year_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.setting_year_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="setting_year_id"
                    >
                      {errors.setting_year_id}
                    </small>
                  )}
                </div>
                    <div className="col-sm-12 col-md-3 col-lg-4">
                      <label className="form-label">
                        Leave Type Name <label className="text-danger">*</label>
                      </label>
                      <input
                        type="text"
                        name="leave_name"
                        id="leave_name"
                        className="form-control"
                        value={leavetype.leave_name}
                        onChange={handleChange}
                        noValidate
                      />
                      {errors.leave_name.length > 0 && (
                        <small
                          className="form-check-label text-red"
                          htmlFor="leave_name"
                        >
                          {errors.leave_name}
                        </small>
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <label className="form-label form-label">
                        No Of Days{""}<label className="text-danger">*</label>
                      </label>

                      <input
                        type="text"
                        name="no_of_days"
                        id="no_of_days"
                        className="form-control"
                        value={leavetype.no_of_days}
                        onChange={handleChange}
                        noValidate
                      />
                      {errors.no_of_days.length > 0 && (
                        <small
                          className="form-check-label text-red"
                          htmlFor="no_of_days"
                        >
                          {errors.no_of_days}
                        </small>
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-12">
                      <label className="form-label form-label">
                        Leave Type Description{" "}<label className="text-danger">*</label>
                      </label>
                      <textarea 
                        type="text"
                        name="leavetype_description"
                        id="leavetype_description"
                        className="form-control"
                        value={leavetype.leavetype_description}
                        onChange={handleChange}
                        noValidate
                      />{" "}
                      {errors.leavetype_description.length > 0 && (
                        <small
                          className="form-check-label text-red"
                          htmlFor="leavetype_description"
                        >
                          {errors.leavetype_description}
                        </small>
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <label className="form-label form-label">Active</label>
                      <div className="form-check form-switch ms-auto mb-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="is_active"
                          id="is_active"
                          checked={leavetype.is_active === "1" ? true : false}
                          onChange={(e) => {
                            setisvaliddata(true);
                            setLeaveType({
                              ...leavetype,
                              [e.target.name]: e.target.checked ? "1" : "0",
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="is_active">
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </div>                  
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveTypeinfo ? "active " : "")
                          }
                        >
                          Leave Type Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>
              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    leavetype !== undefined
                      ? JSON.stringify(leavetype.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Leave Type is updated successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default EditLeaveType;
