import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup} from 'reactstrap';
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getAllFacility } from "../../../services/facilityService";
import GlobalListTable from '../../../components/globalTable/gListTable';
//import SweetAlert from 'react-bootstrap-sweetalert';

const Facility = (props) => {
  //context variables
  
  const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [facility, setFacility] = useState([]);
  const [tabFacilityinfo, setFacilityinfo] = useState(true);
  //table functions
  const data = useMemo(() => [...facility], [facility]);
  const facilityColumns = useMemo(
    () => [
          {
            Header: "ID",accessor: '_id',sortable:true,width: '20%'
          },

          {
           Header: "Facility Code",accessor: 'facility_code',sortable:true,width: '20%'
          },
          {
            Header: "Facility Name",accessor: 'facility_name',sortable:true,width: '30%'
            },
          
          {
                Header: "Customer Name",accessor: 'customer_name',sortable:true,width: '30%'
          }, 
          {
            Header: "Organization Name",accessor: 'organization_name',sortable:true,width: '30%'
          },
		      {
            Header: "Created On",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          },
          {
            Header: "Active",
							  accessor: 'is_active',
							  sortable: true,width:  '10%',
                Cell: ({ value }) => {
                  return (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                      {value === "1" ? "Yes" : "No"}
                    </span>
                  );
                },
          }
    ],
    []
  ); 
  
  const hiddenFacilityColumns = [
    "_id",
    "activity_history",
    "address",
    "notes",
    "organization_id",
    "clientele_id",
  ];
  
  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setFacilityinfo(true);
        return; 
  }
  };
  useEffect(() => {
    //internal functions
    async function loadFacility(userInfo) {
      if (facility.length === 0)
        getAllFacility(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;
            //	console.log("12" +response.map());
            setFacility(JSON.parse(response));
          }
        });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadFacility(jackapp.currentUser);
  }, [jackapp.currentUser]);

  return (
    <div className="row">
      <div className="fs-3 mb-1">
				<ol className="breadcrumb float-xl-start">
					<li className="breadcrumb-item"><Link to="/settings/facility">Facilities</Link></li>
					<li className="breadcrumb-item active bold">List Facility</li>
				</ol>
				</div>
      <div className="col-xl-12">
        <Panel>
        <PanelHeader noButton={true}>
								<div className="d-flex align-items-center">
								<div >
								<ul className="nav nav-tabs nav-tabs-inverse">
									<li className="nav-item" key="info">
									<Link to="/settings/roles" onClick={(e) => showTab(e, 'info')} 
									className={'nav-link ' + (tabFacilityinfo ? 'active ': '')}>All</Link></li>
								
								</ul>
								</div>
								<ButtonGroup className="ms-auto my-n1">
								<Link to="/settings/addfacility" href="#" className="btn btn-success btn-rounded px-4 rounded-pill">
									<i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Facility</Link>
									</ButtonGroup>
								</div>
				</PanelHeader>
          <PanelBody>
          <GlobalListTable gcolumns={facilityColumns}  gdata={data!==undefined?JSON.stringify(data):""} 
        ghiddencolumns={hiddenFacilityColumns}
        gkey={"facility_id"} glink="/settings/detailfacility"
        gplaceholder={"Search Facilities.."}
        />
  
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default Facility;
