import React from 'react';
import TopMenuNav from './top-menu-nav.jsx';


const TopMenu = () => {
	
		return (
			<div id="top-menu" className="app-top-menu">
				<TopMenuNav />
			</div>
		)

}

export default TopMenu;