import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import { useRealmApp } from "../../providers/RealmApp";
import { getLeaveInfoByHome } from "../../services/leaverequestService";
import GlobalList1Table from "../../components/globalTable/gListTable1";
import { getUpcomingHolidays } from "../../services/company_holidaysService";
import { getAvailableSickLeave,getAvailableCasualLeave } from "../../services/homeService";

import { getLeaveTypeByCasualLeave, getLeaveTypeBySickLeave } from "../../services/leave_approvalService";

const EmployeeHome = () => {
  const jackapp = useRealmApp();  
  const [casualleaveList, setcasualleaveList] = useState();
  const [sickleaveList, setsickleaveList] = useState();
  const [upcomingholidayList, setupcomingholidayList] = useState();
  const [upcomingholidaydateList, setupcomingholidaydateList] = useState();
  //state variables
  const [leaverequest, setleaverequest] = useState([]);
  //table functions
  const data = useMemo(() => [...leaverequest], [leaverequest]);
  const leaverequestColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Request To",
        accessor: "first_name",
        sortable: true,
        width: "30%",
      },
      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Total Days",
        accessor: "total_days",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Leave Status",
        accessor: "status_name",
        sortable: true,
        width: "30%",
      },
      //      {
      //       Header: "Customer Name",accessor: 'customer_name',sortable:true,width: '30%'
      //  },
      // {
      //      Header: "Facility",accessor: 'facility_name',sortable:true,width: '30%'
      // },
      {
        Header: "Leave Status Comment",
        accessor: "leave_reason",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Request By",
        accessor: "email",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenleaverequestColumns = [
    "_id",
    "deleted_on",
    "setting_id",
    "email",
    "leave_description",
    "first_name",
  ];


  useEffect(() => {
    //internal functions
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadupcomingholiday(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadleaverequest(jackapp.currentUser);

      
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetLeaveTypeBySickLeave(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        GetLeaveTypeByCasualLeave(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadsickleave(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadcasualleave(jackapp.currentUser);
  }, []);

  async function loadupcomingholiday(userInfo) {
    getUpcomingHolidays(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let holidaydata = JSON.parse(response);
        setupcomingholidayList(holidaydata[0].holiday_names);
        setupcomingholidaydateList(holidaydata[0].holiday_date);      
      }
    });
  }

  async function loadleaverequest(userInfo) {
    if (leaverequest.length === 0)
      // console.log("userInfo : ", userInfo);
      getLeaveInfoByHome(1, userInfo).then((response) => {
        if (response !== undefined) {
          setleaverequest(JSON.parse(response));
        }
      });
  }

  async function GetLeaveTypeBySickLeave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getLeaveTypeBySickLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let sickleavedata = JSON.parse(response);
       // console.log(sickleavedata[0].no_of_days)
        setsickleaveList(sickleavedata[0].no_of_days);
        
      }
    });
  }

  async function GetLeaveTypeByCasualLeave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getLeaveTypeByCasualLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let casualleavedata = JSON.parse(response);
       // console.log(casualleavedata[0].no_of_days)
        setcasualleaveList(casualleavedata[0].no_of_days);
        
      }
    });
  }

  async function loadsickleave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getAvailableSickLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let sickleavedata = JSON.parse(response);
        setsickleaveList(sickleavedata[0].count);
             }
    });
  }
  async function loadcasualleave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getAvailableCasualLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let casualleavedata = JSON.parse(response);
        setcasualleaveList(casualleavedata[0].count);
        
      }
    });
  }

  return (
    <div>
     
      <h1 className="page-header">Dashboard</h1>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-blue">
            <div className="stats-icon">
              <i className="fa fa-desktop"></i>
            </div>
            <div className="stats-info">
              <h4>UPCOMING HOLIDAYS</h4>

              <h5>{upcomingholidayList}</h5>
              <h4>{upcomingholidaydateList}</h4>
            </div>
            <div className="stats-link">
              <Link to="lms/userlistholiday">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-info">
            <div className="stats-icon">
              <i className="fa fa-link"></i>
            </div>
            <div className="stats-info">
              <h4>AVAILABLE SICK LEAVE</h4>
              <p>{sickleaveList}</p>
              <p></p>
            </div>
            <div className="stats-link">
              <Link to="">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-orange">
            <div className="stats-icon">
              <i className="fa fa-users"></i>
            </div>
            <div className="stats-info">
              <h4>AVAILABLE CASUAL LEAVE</h4>
              <p>{casualleaveList}</p>
            </div>
            <div className="stats-link">
              <Link to="">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="stats-link">
          <Panel>
            <PanelBody>
              <div>
                <h4>
                  <p>Requested Leave</p>
                </h4>
              </div>
              <GlobalList1Table
                gcolumns={leaverequestColumns}
                gdata={data !== undefined ? JSON.stringify(data) : ""}
                ghiddencolumns={hiddenleaverequestColumns}
                gkey={"user_id"}
                glink="/lms/detailleaverequest"
                gplaceholder={"Search LeaveRequest.."}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default EmployeeHome;
