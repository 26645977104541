import React,{useState,useEffect} from 'react';
//import {useContext} from 'react';
import Select from "react-select";
import { Link,useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';
import { ButtonGroup,Button} from 'reactstrap';
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getRoleByID,deleteRoleByID} from "../../../services/roleService";
import SweetAlert from 'react-bootstrap-sweetalert';
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from '../../../components/customstyles/react-select-styles';
import ActivityHistory from '../../../components/activityHistory/activityHistory';

const DetailRole = (props) => {
//context variables
let navigate = useNavigate();
//const context = useContext(AppSettings);
//state variabley
const [role, setRole] = useState({
	_id:'',
	role_short_name: "",
    role_description: "",
    weight: 0,
    organization_id: "",
    is_active: "1",
	activity_history: {
      activity_type: "",
      activity_by_id: "",
      activity_by_name: "",
      activity_by_email: "",
      activity_by_clientee: "",
      activity_on: "",
    }

  });

const jackapp = useRealmApp();
const [isDeleted, setisDeleted] = useState(false);
const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
const [OrganizationList, setOrganizationList] = useState([]);
//const data = useMemo(() => [...role], [role]);
const keyid = useLocation().state.role_id;
const [tabRolesinfo, setRolesinfo] = useState(true);
const [tabActivityinfo, setActivityinfo] = useState(false);
//functions

  const handleDelete= async (roleid) => {
	
   setisDeleted(true);
  
 
  }
   const handleDeleteSweetAlert =  () => 
   {
	   
	   setisDeleted(false);
	   if(keyid!=null){
		   if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
		   deleteRole(keyid,jackapp.currentUser);
	   }
   
}
async function deleteRole(roleid,userInfo) {
   
   deleteRoleByID(roleid,userInfo)
   .then((response)=>{
	   if(response!==undefined)
	   {	
		   setisDeleted(false);
		   setisDeletedConfirm(true);
	   }

   });
   
   
 }
 const handleConfirmDeleteSweetAlert =  () => {
   
   setisDeletedConfirm(false);
   handleCancel();
   
}
const handleDeleteCancel =()=>{
   setisDeleted(false);
		   setisDeletedConfirm(false);
   return true;}
  const handleCancel =  () => {
      navigate({
        pathname:  '/user/roles',
        // state: {
        //   response: messageFromServer 
        // } 
     });
  }
  const showTab = (e, tab) => {
	e.preventDefault();
	switch(tab){
		case 'info':
			setRolesinfo(true);
			setActivityinfo(false);
			return; 
		case 'activityinfo':
        	setRolesinfo(false);
        	setActivityinfo(true);
				return;
}
};

  useEffect(() => {
	//internal functions
	async function loadRoleByID(userInfo) {

		getRoleByID(keyid,userInfo)
		.then((response)=>{
			if(response!==undefined)
			{	
				//console.log(JSON.parse(response));
				let roles = JSON.parse(response);
				roles.map((role) =>
						//console.log(role._id)
						setRole({ ...role,_id:role._id})
					);
			}
	
		});
		
	  }
	
    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          })
          setOrganizationList(options);
          //console.log("Organization1" + response);
        
        }
		
      }

	  );
    }
	if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
		loadRoleByID(jackapp.currentUser);
   	if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
		loadOrganization(jackapp.currentUser);
	  }
	}, [keyid,jackapp.currentUser]);
	return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/Roles">Roles</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail Role</li>
        </ol>
      </div>
		<div className="tab-content p-0">
				<div className={'tab-pane fade ' + (tabRolesinfo ? 'show active ': '')}>
					<div className="col-xl-12">
						<Panel >
						<PanelHeader noButton={true}>
							<div className="d-flex align-items-center">
							<div>
								<ul className="nav nav-tabs nav-tabs-inverse">
								<li className="nav-item me-2" key="info">
									<Link
									to="/extra/profile"
									onClick={(e) => showTab(e, "info")}
									className={"nav-link " + (tabRolesinfo ? "active " : "")}
									>
									Role Information
									</Link>
								</li>
								<li className="nav-item" key="activityinfo">
									<Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
									className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Information</Link></li>
								</ul>
							</div>
							<ButtonGroup className="ms-auto my-n1">
							<Link
										to={{pathname: "/user/editrole",}}
										state={{ "role_id": keyid }}
										className="btn btn-primary  me-2 "
										>
								<i className="fa fa-fw  fa-square"></i> Edit
								</Link>

								<Button
								onClick={() => handleDelete(keyid)}
								className="btn btn-danger me-2  "
								>
								<i className="fa fa-trash"></i>&nbsp; Delete
								</Button>
								<Button
								onClick={handleCancel}
								className="btn btn-default me-2 "
								>
								Cancel
								</Button>
							</ButtonGroup>
							</div>
						</PanelHeader>

						<PanelBody>

							<div className="row">
								<div className="col-sm-4 col-md-4 col-lg-4">
								<label className="form-label form-label ">
									Role Short Name{" "}
								</label>

								<input
									type="text"
									name="role_short_name"
									id="role_short_name"
									className="form-control"
									disabled="disabled"
									value={role.role_short_name}
								/>
								<label className="form-check-label" htmlFor="role_short_name">
									&nbsp;
								</label>
								</div>
								<div className="col-sm-4 col-md-4 col-lg-4">
								<label className="form-label form-label">Description</label>

								<input
									type="text"
									name="role_description"
									id="role_description"
									className="form-control"
									disabled="disabled"
									value={role.role_description}
								/>
								<label
									className="form-check-label"
									htmlFor="role_description"
								>
									&nbsp;
								</label>
								</div>
								<div className="col-sm-4 col-md-4 col-lg-4">
								<label className="form-label form-label">Weight </label>
								<input
									type="text"
									name="weight"
									id="weight"
									className="form-control"
									disabled="disabled"
									value={role.weight}
								/>
								<label className="form-check-label" htmlFor="weight">
									&nbsp;
								</label>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-sm-4 col-md-4 col-lg-4">
								<label className="form-label form-label">Organization *</label>
								<div className="col-md-6">
									<Select
									name="organization_id"
									options={OrganizationList}
									isSearchable={true}
									styles={styles}
									value={OrganizationList.filter(
										(organization) => organization.value === role.organization_id
									)}
									isDisabled={true}
									></Select>
								</div>
								</div>
								<div className="col-sm-4 col-md-4 col-lg-4">
								<label className="form-label form-label">Active</label>
								<div className="form-check form-switch ms-auto mb-0">
									<input
									type="checkbox"
									className="form-check-input"
									disabled="disabled"
									name="isactive"
									id="isactive"
									checked={role.is_active === "1" ? true : false}
									/>
									<label className="form-check-label" htmlFor="isactive">
									&nbsp;
									</label>
								</div>
								</div>
							</div>
							</PanelBody>
						{isDeleted && (
							<SweetAlert
							style={{ color: "black" }}
							warning
							title=""
							confirmBtnText="Yes"
							confirmBtnBsStyle="danger"
							cancelBtnText="No"
							cancelBtnBsStyle="info"
							showCancel
							focusCancelBtn
							onConfirm={handleDeleteSweetAlert}
							onCancel={handleDeleteCancel}
							>
							<h5> Do you wish to delete role[{role.role_short_name}]?.</h5>
							</SweetAlert>
						)}
						{isDeletedConfirm && (
							<SweetAlert
							success
							style={{ color: "black" }}
							title="Role is deleted successfully.."
							onConfirm={handleConfirmDeleteSweetAlert}
							></SweetAlert>
						)}
						</Panel>
					</div>
				

				</div>
				<div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
				<div className="col-xl-12">
						<Panel>
						<PanelHeader noButton={true}>
							<div className="d-flex align-items-center">
							<div>
								<ul className="nav nav-tabs nav-tabs-inverse">
								<li className="nav-item me-2" key="info">
									<Link
									to="/extra/profile"
									onClick={(e) => showTab(e, "info")}
									className={"nav-link " + (tabRolesinfo ? "active " : "")}
									>
									Role Information
									</Link>
								</li>
								<li className="nav-item" key="activityinfo">
									<Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
									className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Information</Link></li>
								</ul>
							</div>
							<ButtonGroup className="ms-auto my-n1">
							<Link
								to={{pathname: "/user/editrole",}}
								state={{ "role_id": keyid }}
								className="btn btn-primary  me-2 "
								>
								<i className="fa fa-fw  fa-square"></i> Edit
								</Link>

								<Button
								onClick={() => handleDelete(keyid)}
								className="btn btn-danger me-2  "
								>
								<i className="fa fa-trash"></i>&nbsp; Delete
								</Button>
								<Button
								onClick={handleCancel}
								className="btn btn-default me-2 "
								>
								Cancel
								</Button>
							</ButtonGroup>
							</div>
						</PanelHeader>

						<PanelBody>
								<ActivityHistory activityhistory={role!==undefined?JSON.stringify(role.activity_history):""}/>
						</PanelBody>
						</Panel>
					</div>

				
				
				</div>
				
		</div>
	</div>
  );
}

export default DetailRole;