// Services for getSettings
export const getSetting = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getSettings();
    // console.log("data : "+data);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for getSettingsById
export const getSettingsByID = async (setting_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getSettingByID(setting_id, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for createSettings

export const createSettings = async (
  organization_id,
  starting_year,
  ending_year,
  is_active,
  LRAapp
) => {
  try {
    /*    const user = JSON.parse(userpayload);*/
    //temp

    var loggedinby = LRAapp.id;
    const Settingdata = {
      organization_id: organization_id,
      starting_year: starting_year,
      ending_year: ending_year,
      is_active: is_active,
    };
    // console.log("Settingdata", Settingdata);
    const data = await LRAapp.functions.insertSetting(
      JSON.stringify(Settingdata),
      loggedinby
    );
    // console.log("data", data);

    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("our error" + error);
    return error;
  }
};

// Services for updateSettingsByID

export const updateSettingsByID = async (
  setting_id,
  organization_id,
  starting_year,
  ending_year,
  is_active,
  LRAapp
) => {
  try {
    var loggedinby = LRAapp.id;
    const Settingdata = {
      setting_id: setting_id,
      organization_id: organization_id,
      starting_year: starting_year,
      ending_year: ending_year,
      is_active: is_active,
    };
    console.log(loggedinby);
    const data = await LRAapp.functions.updateSettingByID(
      JSON.stringify(Settingdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for deleteSettingsById

export const deleteSettingsByID = async (setting_id, LRAapp) => {
  try {
    var loggedinby = LRAapp.id;
    const data = await LRAapp.functions.deleteSettingByID(
      setting_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    console.log("error:" + error);
    return error;
  }
};

// Services for getSettingsYearidLookup

export const getSettingsYearidLookup = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getSettingsYearidLookup(loggedin_by);

    if (data !== undefined) {
      // console.log("Business" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for getSettingsById
export const getSettingsIDByOrganisationID = async (
  organization_id,
  LRAapp
) => {
  try {
    //  console.log("organization_id: ", organization_id);
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getSettingsIDByOrganisationID(
      organization_id,
      loggedin_by,
      ""
    );
    if (data !== undefined) {
      // console.log("data", data)
      return JSON.stringify(data);
    }
  } catch (error) {
    // console.log("error:" + error);
    console.log("error:" + error);
    return error.response.data.message;
  }
};

export const getOrganizationListByOrgID = async (organization_id, LRAapp) => {
  try {
    //  console.log("organization_id: ", organization_id);
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getOrganizationListByOrgID(
      organization_id,
      loggedin_by,
      ""
    );
    if (data !== undefined) {
      //  console.log("data", data)
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error:" + error);
    return error.response.data.message;
  }
};

export const getSettingsStatusByOrgID = async (
  organization_id,
  statusid,
  LRAapp
) => {
  try {
    //  console.log("organization_id: ", organization_id);
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getSettingsStatusByOrgID(
      organization_id,
      statusid,
      loggedin_by,
      ""
    );
    if (data !== undefined) {
      // console.log("Setting Status", data);
      return JSON.stringify(data);
    }
  } catch (error) {
    // console.log("error:" + error);
    console.log("error:" + error);
    return error.response.data.message;
  }
};
