export const getAllRoleGroups = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getRoleGroup(loggedin_by,'');

    if (data !== undefined) {
      
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRoleGroupByID = async (rolegroup_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getRoleGroupByID(
      rolegroup_id,
      loggedin_by
    );
    if (data !== undefined) {
       
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createRoleGroup = async (
  organization_id,
  customer_id,
  group_id,
  checked_items,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    //console.log("rolegroup_short_name : ", rolegroup_short_name);
    const rolegroupdata = {
      organization_id: organization_id,
      customer_id:customer_id,
      group_id: group_id,
      role_ids: checked_items,
      sort_order:"0",
      is_system_record:"0"

    };
    // console.log(JSON.stringify(groupdata));
    //console.log(loggedinby);
    const data = await jackapp.functions.insertRoleGroup(
      JSON.stringify(rolegroupdata),
      loggedinby
    );
    // const data = await jackapp.functions.insertRoleGroup(
    //   rolegroup_short_name,
    //   rolegroup_description,
    //   weight,
    //   organization_id,
    //   loggedinby
    // );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const updateRoleGroupByID = async (
  rolegroup_id,
  organization_id,
  customer_id,
  group_id,
  checked_items,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const rolegroupdata = {
      rolegroup_id: rolegroup_id,
      organization_id: organization_id,
      customer_id:customer_id,
      group_id: group_id,
      role_ids: checked_items,
      sort_order:"0",
      is_system_record:"0"
    };
    
    const data = await jackapp.functions.updateRoleGroupByID(
      JSON.stringify(rolegroupdata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteRoleGroupByID = async (rolegroup_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteRoleGroupByID(
      rolegroup_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
