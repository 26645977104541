import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel";
import { getUpcomingHolidays } from "../../services/company_holidaysService";
import { useRealmApp } from "../../providers/RealmApp";
import { getLeaveInfoByHome } from "../../services/leaverequestService";
import { getPendingLeaveInfoByLeaveStatus } from "../../services/leave_approvalService";
import {
  getAllPendingLeaveInfo,
  getAllApprovedLeaveInfo,
  getAllOnHoldLeaveInfo,
} from "../../services/leave_statusService";
import { getUsers } from "../../services/usersService";
import GlobalList1Table from "../../components/globalTable/gListTable1";
import { getAvailableSickLeave,getAvailableCasualLeave } from "../../services/homeService";
import { getLeaveTypeByCasualLeave, getLeaveTypeBySickLeave } from "../../services/leave_approvalService";
const ManagerHome = () => {
  const jackapp = useRealmApp();

  //state variables
  const [casualleave, setcasualleave] = useState();
  const [sickleave, setsickleave] = useState();
  const [leaverequest, setleaverequest] = useState([]);
  const [onholdleaveapproval, setonholdleaveapproval] = useState([]);
  const [pendingleaveapproval, setpendingleaveapproval] = useState([]);
  const [approvedleaveapproval, setapprovedleaveapproval] = useState([]);
  const [upcomingholidayname, setupcomingholidayname] = useState([]);
  const [upcomingholidaydate, setupcomingholidaydate] = useState([]);
  const [casualleaveList, setcasualleaveList] = useState();
  const [sickleaveList, setsickleaveList] = useState();
  const [users, setusers] = useState([]);
  const [Pending, setPending] = useState([]);
  
  //table functions
  const data1 = useMemo(() => [...onholdleaveapproval], [onholdleaveapproval]);
  const OnHoldLeaveApprovalColumns = useMemo(() => [
    {
      Header: "ID",
      accessor: "_id",
      sortable: true,
      width: "20%",
    },

    {
      Header: "Leave Type",
      accessor: "leave_name",
      sortable: true,
      width: "5%",
    },
    {
      Header: "Leave Description",
      accessor: "leave_description",
      sortable: true,
      width: "20",
    },

    {
      Header: "From Date",
      accessor: "from_date",
      sortable: true,
      width: "10%",
    },
    {
      Header: "To Date",
      accessor: "to_date",
      sortable: true,
      width: "10%",
    },
    {
      Header: "Total  Days",
      accessor: "total_days",
      sortable: true,
      width: "10%",
    },
    {
      Header: "Request By",
      accessor: "fullname",
      sortable: true,
      width: "20%",
    },

    {
      Header: "Status",
      accessor: "status_name",
      sortable: true,
      width: "20%",
    },
    {
      Header: "Leave Status Comments",
      accessor: "leave_reason",
      sortable: true,
      width: "20%",
    },
    // {
    //   Header: "Created On",
    //   accessor: "activity_on",
    //   sortable: true,
    //   width: "10%",
    //   Cell: ({ value }) => Moment(value).format(context.appMomentDateFormat),
    // },
    {
      Header: "Active",
      accessor: "is_active",
      sortable: true,
      width: "10%",
      Cell: ({ value }) => {
        return (
          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
            {value === "1" ? "Yes" : "No"}
          </span>
        );
      },
    },
  ]);

  const hiddenOnHoldLeaveApprovalColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
    "leave_description",
    "is_active",
    "leave_reason",
  ];

  const data2 = useMemo(
    () => [...pendingleaveapproval],
    [pendingleaveapproval]
  );
  const PendingLeaveApprovalColumns = useMemo(() => [
    {
      Header: "ID",
      accessor: "_id",
      sortable: true,
      width: "20%",
    },

    {
      Header: "Leave Type",
      accessor: "leave_name",
      sortable: true,
      width: "5%",
    },
    {
      Header: "Leave Description",
      accessor: "leave_description",
      sortable: true,
      width: "20",
    },

    {
      Header: "From Date",
      accessor: "from_date",
      sortable: true,
      width: "10%",
    },
    {
      Header: "To Date",
      accessor: "to_date",
      sortable: true,
      width: "10%",
    },
    {
      Header: "Total  Days",
      accessor: "total_days",
      sortable: true,
      width: "10%",
    },
    {
      Header: "Request By",
      accessor: "fullname",
      sortable: true,
      width: "20%",
    },

    {
      Header: "Status",
      accessor: "status_name",
      sortable: true,
      width: "20%",
    },
    {
      Header: "Leave Status Comments",
      accessor: "leave_reason",
      sortable: true,
      width: "20%",
    },
    // {
    //   Header: "Created On",
    //   accessor: "activity_on",
    //   sortable: true,
    //   width: "10%",
    //   Cell: ({ value }) => Moment(value).format(context.appMomentDateFormat),
    // },
    {
      Header: "Active",
      accessor: "is_active",
      sortable: true,
      width: "10%",
      Cell: ({ value }) => {
        return (
          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
            {value === "1" ? "Yes" : "No"}
          </span>
        );
      },
    },
  ]);

  const hiddenPendingLeaveApprovalColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
    "leave_description",
    "is_active",
    "leave_reason",
  ];

  const data3 = useMemo(
    () => [...approvedleaveapproval],
    [approvedleaveapproval]
  );
  const ApprovedLeaveApprovalColumns = useMemo(() => [
    {
      Header: "ID",
      accessor: "_id",
      sortable: true,
      width: "20%",
    },

    {
      Header: "Leave Type",
      accessor: "leave_name",
      sortable: true,
      width: "5%",
    },
    {
      Header: "Leave Description",
      accessor: "leave_description",
      sortable: true,
      width: "20",
    },

    {
      Header: "From Date",
      accessor: "from_date",
      sortable: true,
      width: "10%",
    },
    {
      Header: "To Date",
      accessor: "to_date",
      sortable: true,
      width: "10%",
    },
    {
      Header: "Total  Days",
      accessor: "total_days",
      sortable: true,
      width: "10%",
    },
    {
      Header: "Request By",
      accessor: "fullname",
      sortable: true,
      width: "20%",
    },

    {
      Header: "Status",
      accessor: "status_name",
      sortable: true,
      width: "20%",
    },
    {
      Header: "Leave Status Comments",
      accessor: "leave_reason",
      sortable: true,
      width: "20%",
    },
    // {
    //   Header: "Created On",
    //   accessor: "activity_on",
    //   sortable: true,
    //   width: "10%",
    //   Cell: ({ value }) => Moment(value).format(context.appMomentDateFormat),
    // },
    {
      Header: "Active",
      accessor: "is_active",
      sortable: true,
      width: "10%",
      Cell: ({ value }) => {
        return (
          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
            {value === "1" ? "Yes" : "No"}
          </span>
        );
      },
    },
  ]);

  const hiddenApprovedLeaveApprovalColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
    "leave_description",
    "is_active",
    "leave_reason",
  ];

  //Ops Functions

  useEffect(() => {
    //internal functions

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetUpcomingHolidays(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadleaverequest(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadonholdleaveapproval(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadpendingleaveapproval(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadapprovedleaveapproval(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadusers(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadPending(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetLeaveTypeBySickLeave(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        GetLeaveTypeByCasualLeave(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadsickleave(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadcasualleave(jackapp.currentUser);

     
  },[]);

  async function loadleaverequest(userInfo) {
    if (leaverequest.length === 0)
      // console.log("userInfo : ", userInfo);
      getLeaveInfoByHome(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setleaverequest(JSON.parse(response));
        }
      });
  }

  async function loadpendingleaveapproval(userInfo) {
    if (pendingleaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getAllPendingLeaveInfo(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setpendingleaveapproval(JSON.parse(response));
        }
      });
  }

  async function loadapprovedleaveapproval(userInfo) {
    if (pendingleaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getAllApprovedLeaveInfo(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setapprovedleaveapproval(JSON.parse(response));
        }
      });
  }

  async function loadonholdleaveapproval(userInfo) {
    if (pendingleaveapproval.length === 0)
      // console.log("userInfo : ", userInfo);
      getAllOnHoldLeaveInfo(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setonholdleaveapproval(JSON.parse(response));
        }
      });
  }
  async function loadusers(userInfo) {
    if (users.length === 0)
      getUsers(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setusers(JSON.parse(response).length);
          // console.log("users",JSON.parse(response).length)
        }
      });
  }

  async function GetUpcomingHolidays(userInfo) {
    if (users.length === 0)
      getUpcomingHolidays(1, userInfo).then((response) => {
        if (response !== undefined) {
          setupcomingholidayname(JSON.parse(response)[0].holiday_names);
          setupcomingholidaydate(JSON.parse(response)[0].holiday_date);
          // console.log("users",JSON.parse(response).length)
        }
      });
  }

  async function loadPending(userInfo) {
    if (users.length === 0)
      getPendingLeaveInfoByLeaveStatus(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setPending(JSON.parse(response).length);
        }
      });
  }

  async function GetLeaveTypeBySickLeave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getLeaveTypeBySickLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let sickleavedata = JSON.parse(response);
      //  console.log(sickleavedata[0].no_of_days)
        setsickleaveList(sickleavedata[0].no_of_days);
        
      }
    });
  }

  async function GetLeaveTypeByCasualLeave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getLeaveTypeByCasualLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let casualleavedata = JSON.parse(response);
      //  console.log(casualleavedata[0].no_of_days)
        setcasualleaveList(casualleavedata[0].no_of_days);
        
      }
    });
  }


  async function loadsickleave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getAvailableSickLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let sickleavedata = JSON.parse(response);   
        setsickleaveList(sickleavedata[0].count);
       // console.log("sickleavedata[0].count", sickleavedata[0].count);
      }
    });
  }
  async function loadcasualleave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getAvailableCasualLeave(1, userInfo).then((response) => {
      if (response !== undefined) {     
        let casualleavedata = JSON.parse(response);
               setcasualleaveList(casualleavedata[0].count);      
      }
    });
  }

  

  return (
    <div>
      <h1 className="page-header">Dashboard</h1>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-blue">
            <div className="stats-icon">
              <i className="fa fa-desktop"></i>
            </div>
            <div className="stats-info">
              <h4>UPCOMING HOLIDAYS</h4>
              <h5>{upcomingholidayname}</h5>
              <h4>{upcomingholidaydate}</h4>
            </div>
            <div className="stats-link">
              <Link to="/lms/listholiday">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-red">
            <div className="stats-icon">
              <i className="fa fa-thermometer-full"></i>
            </div>
            <div className="stats-info">
              <h4>AVAILABLE SICK LEAVE</h4>
              <p>{sickleaveList}</p>
            </div>
            <div className="stats-link">
              <Link>
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-info">
            <div className="stats-icon">
              <i className="fa fa-cubes"></i>
            </div>
            <div className="stats-info">
              <h4>AVAILABLE CASUAL LEAVE</h4>
              <p>{casualleaveList}</p>
            </div>
            <div className="stats-link">
              <Link>
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-green">
            <div className="stats-icon">
              <i className="fa fa-users"></i>
            </div>
            <div className="stats-info">
              <h4>TOTAL EMPLOYEES</h4>
              <p>{users}</p>
            </div>
            <div className="stats-link">
              <Link to="/user/organizationusers">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-orange">
            <div className="stats-icon">
              <i className="fa fa-envelope"></i>
            </div>
            <div className="stats-info">
              <h4>PENDING LEAVE APPROVALS</h4>
              <p>{Pending}</p>
            </div>
            <div className="stats-link">
              <Link to="/lms/listapproval">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="stats-link">
          <Panel>
            <PanelBody>
              <div>
                <h4>
                  <p>Pending Leave Record</p>
                </h4>
              </div>
              <GlobalList1Table
                gcolumns={PendingLeaveApprovalColumns}
                gdata={data2 !== undefined ? JSON.stringify(data2) : ""}
                ghiddencolumns={hiddenPendingLeaveApprovalColumns}
                gkey={"holiday_id"}
                glink="/lms/detailapproval"
              />
            </PanelBody>
          </Panel>

          <Panel>
            <PanelBody>
              <div>
                <h4>
                  <p>On Hold Leave Record</p>
                </h4>
              </div>
              <GlobalList1Table
                gcolumns={OnHoldLeaveApprovalColumns}
                gdata={data1 !== undefined ? JSON.stringify(data1) : ""}
                ghiddencolumns={hiddenOnHoldLeaveApprovalColumns}
                gkey={"holiday_id"}
                glink="/lms/detailapproval"
              />
            </PanelBody>
          </Panel>

          {/* <Panel>
            <PanelBody>
              <div>
                <h4>
                  <p>Upcoming Employee Leave</p>
                </h4>
              </div>
              <GlobalList1Table
                gcolumns={ApprovedLeaveApprovalColumns}
                gdata={data3 !== undefined ? JSON.stringify(data3) : ""}
                ghiddencolumns={hiddenApprovedLeaveApprovalColumns}
                gkey={"holiday_id"}
                glink="/lms/detailapproval"
              />
            </PanelBody>
          </Panel> */}
        </div>
      </div>
    </div>
  );
};

export default ManagerHome;
