//const Menu = getMenus();
function GetMenus(props) {
  switch (props) {
    case "Employee":
      return [
        { path: "/", icon: "fa fa-sitemap", title: "Home" },

        {
          path: "/lms/*",
          icon: "fa fa-calendar",
          title: "Leave Management",
          children: [
            { path: "/lms/listleaverequest", title: "Leave Request" },
            { path: "/lms/listtransaction", title: "Leave Transaction" },
            { path: "/lms/userlistholiday", title: "Company Holiday" },
            { path: "/lms/about", title: "About" },
          ],
        },
        /*
  { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
    children: [
      { path: '/menu/menu-1-1', title: 'Menu 1.1',
        children: [
          { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
            children: [
              { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
              { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
            ]
          },
          { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
          { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
        ]
      },
      { path: '/menu/menu-1-2', title: 'Menu 1.2' },
      { path: '/menu/menu-1-3', title: 'Menu 1.3' },
    ]
  }
  */
      ];
    default:
      return [
        { path: "/", icon: "fa fa-sitemap", title: "Home" },
        // {
        //   path: "/references",
        //   icon: "fa fa-cogs",
        //   title: "Reference Data",
        //   children: [
        //     { path: "/references/countries", title: "Countries" },
        //   ],
        // },
        {
          path: "/settings",
          icon: "fa fa-cogs",
          title: "Settings Data",
          children: [
            { path: "/settings/organization", title: "OrganiZation" },
            { path: "/settings/customers", title: "Customers" },
            { path: "/settings/facility", title: "Facilities" },
          ],
        },
        {
          path: "/user/*",
          icon: "fa fa-key",
          title: "User Management",
          children: [
            { path: "/user/organizationusers", title: "Organisation Users" },
            { path: "/user/customerusers", title: "Customer Users" },
            { path: "/user/userpolicies", title: "User Policies" },
            { path: "/user/roles", title: "Roles" },
            { path: "/user/groups", title: "Groups" },
          ],
        },
        {
          path: "/lms/*",
          icon: "fa fa-calendar",
          title: "Leave Management",
          children: [
            { path: "/lms/listleaverequest", title: "Leave Request" },
            { path: "/lms/listtransaction", title: "Leave Transaction" },
            { path: "/lms/listholiday", title: "Company Holiday" },
            { path: "/lms/listapproval", title: "Leave Request Approval" },
            { path: "/lms/setting", title: "Settings" },
            { path: "/lms/report", title: "Report" },
            { path: "/lms/listleavetype", title: "Leave Type" },

            { path: "/lms/about", title: "About" },
          ],
        },
        /*
    { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
      children: [
        { path: '/menu/menu-1-1', title: 'Menu 1.1',
          children: [
            { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
              children: [
                { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
                { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
              ]
            },
            { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
            { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
          ]
        },
        { path: '/menu/menu-1-2', title: 'Menu 1.2' },
        { path: '/menu/menu-1-3', title: 'Menu 1.3' },
      ]
    }
    */
      ];
  }
}

export default GetMenus;
