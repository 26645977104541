export const getAllFacility = async (loggedinby, jackapp) => {
  try {
    const data = await jackapp.functions.getFacility();

    if (data !== undefined) {
      // console.log("Facility" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllFacilityForLookup = async (loggedinby, jackapp) => {
  try {
    const data = await jackapp.functions.getFacilityForLookup();

    if (data !== undefined) {
      // console.log("Facility" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getFacilityByID = async (facility_id, jackapp) => {
  try {
    console.log("Facility " + facility_id, jackapp.id);
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getFacilityByID(
      facility_id,
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("Facility" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createFacility = async (
  facility_code,
  facility_name,
  notes,
  customer_id,
  organization_id,
  street,
  suite,
  country,
  states,
  city,
  post_code,
  phone,
  email,
  fax,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;    

    const address = {
      type: "",
      title: "",
      street: street,
      suite: suite,
      country: country,
      states: states,
      city: city,
      post_code: post_code,
      phone: phone,
      email: email,
      fax: fax,
    };

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const facilitydata = {
      facility_code: facility_code,
      facility_name: facility_name,
      address: address,
      social_media: social_media_handle,
      notes: notes,
      customer_id: customer_id,
      organization_id: organization_id,
      sort_order:"0",
      is_system_record:"0",
      is_active:"0"
      
    };
    //console.log(JSON.stringify(facilitydata));
    //console.log(loggedinby);
    const data = await jackapp.functions.insertFacility(
      JSON.stringify(facilitydata),
      loggedinby
    );
    console.log("status: success ", data);
    return { status: "success", data };
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const updateFacilityByID = async (
  facility_id,
  facility_code,
  facility_name,
  notes,
  customer_id,
  organization_id,
  street,
  suite,
  country,
  states,
  city,
  post_code,
  phone,
  email,
  fax,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const address = {
      type: "",
      title: "",
      street: street,
      suite: suite,
      country: country,
      states: states,
      city: city,
      post_code: post_code,
      phone: phone,
      email: email,
      fax: fax,
    };

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const facilitydata = {
      facility_id: facility_id,
      facility_code: facility_code,
      facility_name: facility_name,
      address: address,
      social_media: social_media_handle,
      notes: notes,
      customer_id: customer_id,
      organization_id: organization_id,
      sort_order:"0",
      is_system_record:"0",
      is_active:"0"
    };

    const data = await jackapp.functions.updateFacilityByID(
      JSON.stringify(facilitydata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteFacilityByID = async (facility_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteFacilityByID(
      facility_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
