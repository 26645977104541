export const getGroupsForLookup = async (loggedinby, jackapp, organization_id) => {
  try {
    const loggedin_by = jackapp.id;
    const filter = "";
    const data = await jackapp.functions.getAllGroupsForLookup(
      loggedin_by,
      filter,
      organization_id
    );

    if (data !== undefined) {
      // console.log("Organization" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllGroupsForLookupbyCustomerID = async (
  loggedinby,
  jackapp,
  organization_id,
  customer_id
) => {
  try {
    const loggedin_by = jackapp.id;
    const filter = "";
    const data = await jackapp.functions.getAllGroupsForLookupbyCustomerID(
      loggedin_by,
      filter,
      organization_id,
      customer_id
    );

    if (data !== undefined) {
      // console.log("Organization" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllGroups = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getGroups(loggedin_by);

    if (data !== undefined) {
      // console.log("Groups" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getGroupByID = async (group_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getGroupByID(group_id, loggedin_by);
    if (data !== undefined) {
      
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createGroup = async (
  group_short_name,
  group_description,
  //facility_id,
  customer_id,
  organization_id,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    //console.log("group_short_name : ", group_short_name);
    const groupdata = {
      group_short_name: group_short_name,
      group_description: group_description,
      group_key: "",
      //facility_id: facility_id,
      customer_id: customer_id,
      organization_id: organization_id,
      sort_order: "0",
      is_system_record: "0",
    };
    // console.log(JSON.stringify(groupdata));
    //console.log(loggedinby);
    const data = await jackapp.functions.insertGroup(
      JSON.stringify(groupdata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const updateGroupByID = async (
  group_id,
  group_short_name,
  group_description,
  //facility_id,
  customer_id,
  organization_id,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;

    const groupdata = {
      group_id: group_id,
      group_short_name: group_short_name,
      group_description: group_description,
      group_key: "",
      //facility_id: facility_id,
      customer_id: customer_id,
      organization_id: organization_id,
      sort_order: "0",
      is_system_record: "0",
    };

    const data = await jackapp.functions.updateGroupByID(
      JSON.stringify(groupdata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteGroupByID = async (group_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteGroupByID(group_id, loggedinby);
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
