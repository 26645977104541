import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { ButtonGroup, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getCustomerByID,deleteCustomerByID,} from "../../../services/customerService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllStateForLookupByCountry } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from "../../../components/customstyles/react-select-styles";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
const DetailCustomer = (props) => {
  //context variables
  let navigate = useNavigate();
  const statuschecked = true;
  //const context = useContext(AppSettings);
  //state variable
  const [customer, setCustomer] = useState({
    _id: "",
    customer_account_number: "",
    customer_name: "",
    logo: "",
    organization_id: "",
    notes: "",
    is_same_address: "",
    is_active: "",
    address: [
      {
        id: "0",
        _id: "",
        type: "",
        title: "",
        street: "",
        suite: "",
        country: "",
        states: "",
        city: "",
        post_code: "",
        phone: "",
        email: "",
        fax: "",
      },
      {
        id: "1",
        _id: "",
        type: "",
        title: "",
        street: "",
        suite: "",
        country: "",
        states: "",
        city: "",
        post_code: "",
        phone: "",
        email: "",
        fax: "",
      },
    ],
    social_media: [
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
    ],
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });

  const jackapp = useRealmApp();
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [isstatuschecked, setisstatuschecked] = useState(false);
  const [stateList1, setstateList1] = useState([]);
  const [stateList2, setstateList2] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [tabCustomerinfo, setCustomerinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //const data = useMemo(() => [...customer], [customer]);
  const keyid = useLocation().state.customer_id;

  //functions
  const handleIsStatusChanged = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (event.target.checked) {
      setisstatuschecked(true);
    } else {
      setisstatuschecked(false);
    }
    setCustomer({ ...customer, [name]: event.target.checked });
  };
  const handleDelete = async (customerid) => {
    
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deletecustomer(keyid, jackapp.currentUser);
    }
  };
  async function deletecustomer(customerid, userInfo) {
    deleteCustomerByID(customerid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/settings/customers",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setCustomerinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setCustomerinfo(false);
        setActivityinfo(true);
        return;
    }
  };
  async function loadStates(itemid,country_id) {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      
      getAllStateForLookupByCountry(1, jackapp.currentUser,country_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let statedata = JSON.parse(response);
        let options = statedata.map(function (state) {
          return { value: state.id, label: state.name };
        });
        if(itemid==="0")
        setstateList1(options);
        if(itemid==="1")
        setstateList2(options);
        //console.log("state1" + response);
      }
    });
  
    }
  }
  useEffect(() => {
  
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {
            return { value: country.id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, []);

  useEffect(() => {
    //internal functions
    async function loadcustomerByID(userInfo) {
      getCustomerByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let customers = JSON.parse(response);
          customers.map((customer) => {
            //console.log("customer._id", customer._id);
            setCustomer({ ...customer, _id: customer._id });
            if (customer.address[0].country !== null && customer.address[0].country !== "") {
              loadStates("0",customer.address[0].country);
            }
            if (customer.address[1].country !== null && customer.address[1].country !== "") {
              loadStates("1",customer.address[1].country);
            }
          });
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadcustomerByID(jackapp.currentUser);
   // console.log("customer.customer_name ", customer.customer_name);
  }, [keyid, jackapp.currentUser]);
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/settings/customers">Customers</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail Customer</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabCustomerinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabCustomerinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
              to={{pathname: "/settings/editcustomer",}}
              state={{ "customer_id": keyid }}
              className="btn btn-primary  me-2 "
              >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-8 colcol-xl-8 col ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Basic Info
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Account Number <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="customer_account_number"
                                  id="customer_account_number"
                                  className="form-control"
                                  value={customer.customer_account_number}
                                  disabled="disabled"
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label">
                                  Customer Name <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="customer_name"
                                  id="customer_name"
                                  className="form-control"
                                  value={customer.customer_name}
                                  disabled="disabled"
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Organization <label  className="text-danger">*</label> </label>
                                <Select
                                  name="organization_id"
                                  options={OrganizationList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={OrganizationList.filter(
                                    (organization) =>
                                      organization.value === customer.organization_id
                                  )}
                                  isDisabled={true}
                                ></Select>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-sm-12 col-md-7 col-lg-7 ">
                                <label className="form-label">Website</label>
                                <input
                                  type="text"
                                  name="website"
                                  id="website"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.social_media[0].link}
                                />
                              </div>
                              <div className="col-sm-12 col-md-2 col-lg-2">
                                <label className="form-label">Active</label>
                                <div className="form-check form-switch ms-auto mb-0">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    disabled="disabled"
                                    name="isactive"
                                    checked={statuschecked}
                                    id="isactive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="isactive"
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </div>

                              <div className="col-sm-12 col-md-3 col-lg-3  mw-25 rounded">
                                <label className="form-label">Logo</label>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-sm-12 col-md-9 col-lg-9">
                                <label className="form-label">Notes</label>

                                <input
                                  type="textarea"
                                  name="notes"
                                  id="notes"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.notes}
                                />
                              </div>

                              <div className="col-xl-2  col  ms-0 ">
                                <div className="text-center">
                                  <img
                                    src={customer.logo}
                                    alt="No Image"
                                    className="rounded-circle mx-auto d-block mw-100"
                                    width="100"
                                    height="100"
                                  />
                                </div>
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-4  col  ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Social Media handles{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1 ">
                              <div className="col-12">
                                <label className="form-label">LinkedIn</label>
                                <input
                                  type="text"
                                  name="linkedin"
                                  id="linkedin"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.social_media[1].link}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-12">
                                <label className="form-label">Facebook</label>
                                <input
                                  type="text"
                                  name="facebook"
                                  id="facebook"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.social_media[2].link}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-6">
                                <label className="form-label">Twitter</label>

                                <input
                                  type="text"
                                  name="twitter"
                                  id="twitter"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.social_media[3].link}
                                />
                              </div>
                              <div className="col-6">
                                <label className="form-label">Instagram</label>

                                <input
                                  type="text"
                                  name="instagram"
                                  id="instagram"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.social_media[4].link}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className=" col-xl-6 col ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Physical Contact Info{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Street</label>

                                <input
                                  type="text"
                                  name="address_0_street"
                                  id="address_0_street"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[0].street}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Suite</label>

                                <input
                                  type="text"
                                  name="suite"
                                  id="suite"
                                  className="form-control"
                                  value={customer.address[0].suite}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  Country{" "}
                                </label>
                                <Select
                                  name="country_id"
                                  options={countryList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={countryList.filter(
                                    (country) =>
                                      country.value ===
                                      customer.address[0].country
                                  )}
                                  isDisabled={true}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  State{" "}
                                </label>
                                <Select
                                  name="state_id"
                                  options={stateList1}
                                  isSearchable={true}
                                  styles={styles}
                                  value={stateList1.filter(
                                    (state) =>
                                      state.value === customer.address[0].states
                                  )}
                                  isDisabled={true}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">City</label>

                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[0].city}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Postal Code
                                </label>

                                <input
                                  type="text"
                                  name="post_code"
                                  id="post_code"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[0].post_code}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Phone</label>

                                <input
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[0].phone}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Fax</label>

                                <input
                                  type="text"
                                  name="fax"
                                  id="fax"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[0].fax}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">Email</label>

                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[0].email}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className=" col-xl-6 col ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            <div className="d-flex align-items-center">
                              <div>Billing Contact Info {""}</div>
                              <ButtonGroup className="ms-auto my-n1">
                                <div className="form-check ms-auto mb-0">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="is_same_address"
                                    checked={isstatuschecked}
                                    onChange={handleIsStatusChanged}
                                    id="is_same_address"
                                    value="1"
                                  />
                                  &nbsp;
                                  <label className="form-label me-3">
                                    Is Same as Physical Contact Info
                                  </label>
                                </div>
                              </ButtonGroup>
                            </div>
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Street</label>

                                <input
                                  type="text"
                                  name="address_0_street"
                                  id="address_0_street"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[1].street}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Suite</label>

                                <input
                                  type="text"
                                  name="suite"
                                  id="suite"
                                  className="form-control"
                                  value={customer.address[1].suite}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  Country{" "}
                                </label>
                                <Select
                                  name="country_id"
                                  options={countryList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={countryList.filter(
                                    (country) =>
                                      country.value ===
                                      customer.address[1].country
                                  )}
                                  isDisabled={true}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  State{" "}
                                </label>
                                <Select
                                  name="state_id"
                                  options={stateList2}
                                  isSearchable={true}
                                  styles={styles}
                                  value={stateList2.filter(
                                    (state) =>
                                      state.value === customer.address[1].states
                                  )}
                                  isDisabled={true}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">City</label>

                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[1].city}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Postal Code
                                </label>

                                <input
                                  type="text"
                                  name="post_code"
                                  id="post_code"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[1].post_code}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Phone</label>

                                <input
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[1].phone}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Fax</label>

                                <input
                                  type="text"
                                  name="fax"
                                  id="fax"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[1].fax}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">Email</label>

                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  disabled="disabled"
                                  value={customer.address[1].email}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabCustomerinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Link
                      to={{pathname: "/settings/editcustomer",}}
                      state={{ "customer_id": keyid }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    customer !== undefined
                      ? JSON.stringify(customer.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      {isDeleted && (
        <SweetAlert
          style={{ color: "black" }}
          warning
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnText="No"
          cancelBtnBsStyle="info"
          showCancel
          focusCancelBtn
          onConfirm={handleDeleteSweetAlert}
          onCancel={handleDeleteCancel}
        >
          <h5>
            {" "}
            Do you wish to delete Customer[
            {customer.customer_name}]?.
          </h5>
        </SweetAlert>
      )}
      {isDeletedConfirm && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title="Customer is deleted successfully.."
          onConfirm={handleConfirmDeleteSweetAlert}
        ></SweetAlert>
      )}
    </div>
  );
};

export default DetailCustomer;
