export const emailsend = async (
  submittedname,
  realmuserid,
  requestto_id,
  leaverequestid,
  statusid,
  leaverequesturl,
  LRAapp
) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.emailsend(
      submittedname,
      realmuserid,
      requestto_id,
      leaverequestid,
      statusid,
      leaverequesturl,
      loggedin_by
    );

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return "error:" + error;
  }
};
