/* eslint-disable default-case */
import React,{useState,useEffect} from 'react';
//import {useContext} from 'react';
import Select from "react-select";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody, } from '../../../components/panel/panel';
import { ButtonGroup,Button} from 'reactstrap';
import { Link} from 'react-router-dom';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getRoleByID,updateRoleByID} from "../../../services/roleService";
import SweetAlert from 'react-bootstrap-sweetalert';
import {isNumeric} from 'validator';
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from '../../../components/customstyles/react-select-styles';
import ActivityHistory from '../../../components/activityHistory/activityHistory';
//import {useContext} from 'react';
const EditRole = (props) => {
//context variables
let navigate = useNavigate();
//const context = useContext(AppSettings);
const jackapp = useRealmApp();
//state variable
const [tabRolesinfo, setRolesinfo] = useState(true);
const [tabActivityinfo, setActivityinfo] = useState(false);
const [role, setRole] = useState({
	_id:'',
    role_short_name: "",
    role_description: "",
    weight: 0,
    organization_id: "",
    is_active: "1",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      }],
  });
  const [errors, setErrors] = useState({
	_id:'',
    role_short_name: '',
    role_description: '',
    weight:0,
    organization_id: '',
	is_active:'',
	activity_history: {
      activity_type: "",
      activity_by_id: "",
      activity_by_name: "",
      activity_by_email: "",
      activity_by_clientee: "",
      activity_on: "",
    }
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);

const keyid = useLocation().state.role_id;
const [OrganizationList, setOrganizationList] = useState([]);

//savefunctions
const handleDropDownChange = selectedOption => {
    const { name, value } = selectedOption.target;
    setRole({ ...role, [name]: value });
    setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    let target =event.target;
    setRole({ ...role, [target.name]: target.value });
	handleFieldValidation(event);
   // console.log(role);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(validateForm()){
        if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
        updateRole(jackapp.currentUser);
    }
    async function updateRole(userInfo) {
	
		updateRoleByID(role._id,role.role_short_name,role.role_description,role.weight,role.organization_id,userInfo)
		.then((response)=>{
			if(response!==undefined)
			{	//let data[] = response;
			//	console.log("12" +response.map());
				//setRoles(JSON.parse(response));
                setisSaved(true);
				}

		});
		
		
	  }
	 
  }
//validation functions
//error handling functions
const validateForm = () => {
    try
    {
    let valid = false;
    valid= handleFieldRequiredValidation();
    Object.values(errors).forEach((val) => {
        if(val.length > 0) valid = false;
      })
   // console.log(valid);
    return valid;
}catch(error)
{return false;}
};
const handleFieldRequiredValidation=() =>
    {
        let iserror = '';
        
        setErrors({ ...errors, 
            weight:  (role.weight === 0? iserror='weight is required': errors.weight) ,
           role_short_name:  (role.role_short_name === ''? iserror='Role Short Name is required': errors.role_short_name) ,
           organization_id:role.organization_id === '' || role.organization_id === ""? (iserror = "Organization is required"): errors.organization_id,
                 }
            );
           // console.log(iserror);
            return (iserror.length===0)
}
const handleFieldValidation=(event) =>
    {
        event.preventDefault();
        const { name, value } = event.target;
        let localerror ='';  
        switch (name) {
          case 'role_short_name': 
          localerror = (value.length < 3? 'Role Short Name must be at least 3 characters': '');
            break;
            case 'role_description': 
            localerror = (value.length > 255? 'Role Description must be less than 255 characters': '');
            break;
          case 'weight': 
            localerror = (value.length <= 0? 'weight information is missing.': '');
            localerror = (!isNumeric(value)? 'weight must contain only numbers.': '');
            break;
          case "organization_id":
            localerror = value.length <= '' ? "Organization is missing." : "";
            break;
          default:
            break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
}
  //message functions
  const handleSweetAlert =  () => {
    setisSaved(false);

    navigate({
        pathname:  '/user/roles',
        // state: {
        //   response: messageFromServer 
        // } 
     });
    
}
const showTab = (e, tab) => {
	e.preventDefault();
	switch(tab){
		case 'info':
			setRolesinfo(true);
			setActivityinfo(false);
			return; 
		case 'activityinfo':
        	setRolesinfo(false);
        	setActivityinfo(true);
				return;
}
};

  useEffect(() => {
    //internal functions
    async function loadRoleByID(userInfo) {

        getRoleByID(keyid,userInfo)
        .then((response)=>{
            if(response!==undefined)
            {	
                //console.log(JSON.parse(response));
                let roles = JSON.parse(response);
                roles.map((role) =>
                        //console.log(role._id)
                        setRole({ ...role,_id:role._id})
                    );
            }
    
        });
        
      }
      async function loadOrganization(userInfo) {
        getAllOrganizationForLookup(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;
  
            let Organizationdata = JSON.parse(response);
            let options = Organizationdata.map(function (organization) {
              return { value: organization._id, label: organization.organization_name };
            })
            setOrganizationList(options);
            //console.log("Organization1" + response);
          }
        });
      }
	  if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
	  {loadRoleByID(jackapp.currentUser);}
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadOrganization(jackapp.currentUser);
      }
  
     
	}, [keyid,jackapp.currentUser]);
	return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/Roles">Roles</Link>
          </li>
          <li className="breadcrumb-item active bold">Edi Role</li>
        </ol>
      </div>
      <div className="tab-content p-0">
				<div className={'tab-pane fade ' + (tabRolesinfo ? 'show active ': '')}>
          <div className="col-xl-12">
            <Panel>
            <PanelHeader noButton={true}>
							<div className="d-flex align-items-center">
							<div>
								<ul className="nav nav-tabs nav-tabs-inverse">
								<li className="nav-item me-2" key="info">
									<Link
									to="/extra/profile"
									onClick={(e) => showTab(e, "info")}
									className={"nav-link " + (tabRolesinfo ? "active " : "")}
									>
									Role Information
									</Link>
								</li>
								<li className="nav-item" key="activityinfo">
									<Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
									className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Information</Link></li>
								</ul>
							</div>
              <ButtonGroup className="ms-auto my-n1">
                            <Button
                            type="submit"
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!isvaliddata}
                            size="md"
                            className="me-1"
                            >
                            <i className="fa fa-save"></i>&nbsp; Save
                            </Button>
                            <Button color="default" size="md" onClick={handleSweetAlert}>
                            Cancel
                            </Button>
						            </ButtonGroup>
							</div>
						</PanelHeader>
              <form noValidate>
                <PanelBody>
                    <div className="row pb-2">
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label form-label ">
                          Role Short Name <label className="text-danger">*</label>
                        </label>

                        <input
                          type="text"
                          name="role_short_name"
                          id="role_short_name"
                          className="form-control"
                          value={role.role_short_name}
                          onChange={handleChange}
                          noValidate
                        />
                        {errors.role_short_name.length > 0 && (
                          <small
                            className="form-check-label text-red"
                            htmlFor="role_short_name"
                          >
                            {errors.role_short_name}
                          </small>
                        )}
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label form-label">Description</label>

                        <input
                          type="text"
                          name="role_description"
                          id="role_description"
                          className="form-control"
                          value={role.role_description}
                          onChange={handleChange}
                          noValidate
                        />
                        {errors.role_description.length > 0 && (
                          <small
                            className="form-check-label text-red"
                            htmlFor="role_description"
                          >
                            {errors.role_description}
                          </small>
                        )}
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label form-label">Weight <label className="text-danger">*</label></label>
                        <input
                          type="text"
                          name="weight"
                          id="weight"
                          className="form-control"
                          value={role.weight}
                          onChange={handleChange}
                          noValidate
                        />
                        {errors.weight.length > 0 && (
                          <small
                            className="form-check-label text-red"
                            htmlFor="weight"
                          >
                            {errors.weight}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row pb-2">
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label form-label">Organization <label className="text-danger">*</label></label>
                        <div className="col-md-6">
                          <Select
                            name="organization_id"
                            options={OrganizationList}
                            isSearchable={true}
                            styles={styles}
                            value={OrganizationList.filter(
                              (organization) => organization.value === role.organization_id
                            )}
                            onChange={(val) => {
                              handleDropDownChange({
                                target: { name: "organization_id", value: val.value },
                              });
                            }}
                          ></Select>
                          {errors.organization_id.length > 0 && (
                            <small
                              className="form-check-label text-red"
                              htmlFor="organization_id"
                            >
                              {errors.organization_id}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label form-label">Active</label>
                        <div className="form-check form-switch ms-auto mb-0">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            disabled="disabled"
                            name="is_active"
                            id="is_active"
                            checked={role.is_active === "1" ? true : false}
                          />
                          <label className="form-check-label" htmlFor="is_active">
                            &nbsp;
                          </label>
                        </div>
                      </div>
                    </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
				<div className="col-xl-12">
						<Panel>
						<PanelHeader noButton={true}>
							<div className="d-flex align-items-center">
							<div>
								<ul className="nav nav-tabs nav-tabs-inverse">
								<li className="nav-item me-2" key="info">
									<Link
									to="/extra/profile"
									onClick={(e) => showTab(e, "info")}
									className={"nav-link " + (tabRolesinfo ? "active " : "")}
									>
									Role Information
									</Link>
								</li>
								<li className="nav-item" key="activityinfo">
									<Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
									className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Information</Link></li>
								</ul>
							</div>
              <ButtonGroup className="ms-auto my-n1">
                            <Button
                            type="submit"
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!isvaliddata}
                            size="md"
                            className="me-1"
                            >
                            <i className="fa fa-save"></i>&nbsp; Save
                            </Button>
                            <Button color="default" size="md" onClick={handleSweetAlert}>
                            Cancel
                            </Button>
						            </ButtonGroup>
							</div>
						</PanelHeader>
						<PanelBody>
								<ActivityHistory activityhistory={role!==undefined?JSON.stringify(role.activity_history):""}/>
						</PanelBody>
						</Panel>
					</div>

				
				
				</div>
      </div>
                <div>
                  {isSaved && (
                    <SweetAlert
                      success
                      style={{ color: "black" }}
                      title="Role is updated successfully.."
                      onConfirm={handleSweetAlert}
                    ></SweetAlert>
                  )}
                </div>
    </div>
  );
}

export default EditRole;