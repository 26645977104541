export const getAllCustomers = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getCustomers(loggedin_by, "");

    if (data !== undefined) {
      
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllCustomerForLookup = async (
  loggedinby,
  jackapp,
  organization_id
) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getCustomerForLookup(
      loggedin_by,
      "",
      organization_id
    );

    if (data !== undefined) {
      // console.log("Customer" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCustomerByID = async (customer_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getCustomerByID(
      customer_id,
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("Customers" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createCustomer = async (
  customer_account_number,
  customer_name,
  logo,
  organization_id,
  physical_id,
  physical_title,
  physical_street,
  physical_suite,
  physical_country,
  physical_states,
  physical_city,
  physical_post_code,
  physical_phone,
  physical_email,
  physical_fax,
  billing_id,
  billing_title,
  billing_street,
  billing_suite,
  billing_country,
  billing_states,
  billing_city,
  billing_post_code,
  billing_phone,
  billing_email,
  billing_fax,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  notes,
  is_same_address,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const address = [
      {
        id: physical_id,
        type: "physical",
        title: physical_title,
        street: physical_street,
        suite: physical_suite,
        country: physical_country,
        states: physical_states,
        city: physical_city,
        post_code: physical_post_code,
        phone: physical_phone,
        email: physical_email,
        fax: physical_fax,
      },
      {
        id: billing_id,
        type: "billing",
        title: billing_title,
        street: billing_street,
        suite: billing_suite,
        country: billing_country,
        states: billing_states,
        city: billing_city,
        post_code: billing_post_code,
        phone: billing_phone,
        email: billing_email,
        fax: billing_fax,
      },
    ];

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const customerdata = {
      customer_account_number: customer_account_number,
      customer_name: customer_name,
      logo: logo,
      address: address,
      social_media: social_media_handle,
      notes: notes,
      is_same_address: is_same_address,
      organization_id: organization_id,
      sort_order: "0",
      is_system_record: "0",
    };

    const data = await jackapp.functions.insertCustomer(
      JSON.stringify(customerdata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const updateCustomerByID = async (
  customer_id,
  customer_account_number,
  customer_name,
  logo,
  organization_id,
  physical_id,
  physical_title,
  physical_street,
  physical_suite,
  physical_country,
  physical_states,
  physical_city,
  physical_post_code,
  physical_phone,
  physical_email,
  physical_fax,
  billing_id,
  billing_title,
  billing_street,
  billing_suite,
  billing_country,
  billing_states,
  billing_city,
  billing_post_code,
  billing_phone,
  billing_email,
  billing_fax,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  notes,
  is_same_address,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const address = [
      {
        id: physical_id,
        type: "physical",
        title: physical_title,
        street: physical_street,
        suite: physical_suite,
        country: physical_country,
        states: physical_states,
        city: physical_city,
        post_code: physical_post_code,
        phone: physical_phone,
        email: physical_email,
        fax: physical_fax,
      },
      {
        id: billing_id,
        type: "billing",
        title: billing_title,
        street: billing_street,
        suite: billing_suite,
        country: billing_country,
        states: billing_states,
        city: billing_city,
        post_code: billing_post_code,
        phone: billing_phone,
        email: billing_email,
        fax: billing_fax,
      },
    ];

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };
    const customerdata = {
      customer_id: customer_id,
      customer_account_number: customer_account_number,
      customer_name: customer_name,
      logo: logo,
      address: address,
      social_media: social_media_handle,
      notes: notes,
      is_same_address: is_same_address,
      organization_id: organization_id,
      sort_order: "0",
      is_system_record: "0",
    };
    const data = await jackapp.functions.updateCustomerByID(
      JSON.stringify(customerdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteCustomerByID = async (customer_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteCustomerByID(
      customer_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
