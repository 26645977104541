import React, { useState, useMemo, useEffect } from "react";
import { ButtonGroup, Button } from "reactstrap";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as Realm from "realm-web";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import GlobalSimpleTable from "../../../components/globalTable/gSimpleTable";
import { AppSettings } from "../../../config/app-settings";

//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { isEmail } from "validator";
import {
  createCustomerUser,
  updateUserRealmUserID,
} from "../../../services/customerUsersService";
import SweetAlert from "react-bootstrap-sweetalert";
import FileBase from "react-file-base64";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllStateForLookup } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllCustomerForLookup } from "../../../services/customerService";
import { getAllGroupsForLookupbyCustomerID } from "../../../services/groupService";
import { getAllFacilityForLookup } from "../../../services/facilityService";
import styles from "../../../components/customstyles/react-select-styles";
const AddCustomerUser = (props) => {
  //state variavles
  const statuschecked = true;
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabUserinfo, setUserinfo] = useState(true);
  const [tabContactinfo, setContactinfo] = useState(false);
  const [grouplistmandatory, setGrouplistmandatory] = useState("");
  //const [serviceUser, setserviceUser] = React.useState();
  const [user, setUser] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    email: "",
    employee_number: "",
    organization_id: "",
    photo: "",
    password: "",
    confirm_password: "",
    title: "",
    department: "",
    is_active: "",
    user_facility: [
      {
        id: "0",
        organization_id: "",
        facility_id: "",
        group_id: "",
      },
    ],
    address: [
      {
        id: "0",
        type: "",
        title: "",
        street: "",
        suite: "",
        country: "",
        states: "",
        city: "",
        post_code: "",
        m_phone: "",
        o_phone: "",
        alternateemail: "",
        fax: "",
      },
    ],
    website: "",
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    remoteerror: "",
  });
  const [grouplistrow, setGroupListRow] = useState({
    id: "0",
    organization_id: "",
    organization_name: "",
    facility_id: "",
    customer_id: "",
    customer_name: "",
    group_id: "",
    group_name: "",
  });

  const [grouplisterrors, setGroupListErrors] = useState({
    organization_id: "",
    group_id: "",
    customer_id: "",
  });
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [customerMainList, setCustomerMainList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupMainList, setGroupMainList] = useState([]);

  const [usergroupList, setUserGroupList] = useState([]);
  //const [usergroupListdisplay, setUserGroupListDisplay] = useState([]);

  //context variables
  let navigate = useNavigate();
  const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [users, setusers] = useState([]);
  const [tabuserinfo, setuserinfo] = useState(true);
  //table functions
  const data = useMemo(() => [...usergroupList], [usergroupList]);

  const usergroupListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Organization",
        accessor: "organization_name",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Group",
        accessor: "group_name",
        sortable: false,
        width: "30%",
      },
    ],
    []
  );

  const hiddenusergroupListColumns = [
    "_id",
    "facility_id",
    "organization_id",
    "customer_id",
    "group_id",
  ];

  //save functions

  const handleGroupListDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    const newgroup = { ...grouplistrow };
    let pointer = newgroup;
    if (name === "organization_id") {
      pointer[name] = value;
      pointer["organization_name"] = OrganizationList.filter(
        (organization) => organization.value === value
      )[0].label;
      if (
        jackapp.currentUser !== null &&
        jackapp.currentUser !== undefined &&
        customerMainList.length === 0
      ) {
        loadcustomer(jackapp.currentUser, value);
      }
      let cusList = customerMainList.filter(
        (customer) => customer.organization_id === value
      );
      let options = cusList.map(function (customer) {
        return { value: customer._id, label: customer.customer_name };
      });
      setcustomerList(options);
    } else if (name === "customer_id") {
      pointer[name] = value;
      pointer["customer_name"] = customerList.filter(
        (customer) => customer.value === value
      )[0].label;
      if (
        jackapp.currentUser !== null &&
        jackapp.currentUser !== undefined
        //&&    groupMainList.length === 0
      ) {
        loadGroups(jackapp.currentUser, newgroup.organization_id, value);
      }
      let grpList = groupMainList.filter(
        (group) => group.organization_id === value
      );
      let options = grpList.map(function (group) {
        return { value: group._id, label: group.group_short_name };
      });
      setGroupList(options);
    } else if (name === "group_id") {
      pointer[name] = value;
      pointer["group_name"] = groupList.filter(
        (group) => group.value === value
      )[0].label;
    }
    setGroupListRow(newgroup);
    setisvaliddata(true);
    // if (name === "organization_id") {
    //   if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //     loadGroups(jackapp.currentUser, value);
    //   }
    // }
    //console.log("grouplistrow : ", grouplistrow);
  };

  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...user };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setUser(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };

  function handleAddressArrayObjectChange(e, itemId, property, islist) {
    const value = islist === true ? e.value : e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...user.address];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex((item) => item.id == itemId);
    // -1 check to see if we found that object in social_media
    if (index != -1) {
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value, //here property can be "link"
      };
    }
    setUser({ ...user, address: temparrayprops });
    //handleFieldValidation(e);
    //setisvaliddata(true);
  }

  function parseAuthenticationError(err) {
    const parts = err.message.split(":");
    const reason = parts[parts.length - 1].trimStart();
    if (!reason) return { status: "", message: "" };
    const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
    const match = reason.match(reasonRegex);
    const { status, message } = match?.groups ?? {};
    return { status, message };
  }

  const handleRegistrationAndLogin = async (userInfo) => {
    const isValidEmailAddress = isEmail(user.email);

    if (isValidEmailAddress) {
      try {
        setErrors({ ...errors, remoteerror: "" });
        // Register the user and, if successful, log them in
        //  if (jackapp.currentUser)
        //  {
        //   const newuser = jackapp.CreateUser(user.email,user.password);
        //  }
        if (jackapp.currentUser)
          await jackapp.emailPasswordAuth.registerUser(
            user.email,
            user.password
          );

        const newuser = await jackapp.NewUserlogIn(
          Realm.Credentials.emailPassword(user.email, user.password)
        );
        
        if (newuser !== undefined && newuser !== null) {
          var newuserrealm_user_id = newuser.id;
          updateUserRealmUserID(
            user.user_id,
            user.email,
            newuserrealm_user_id,
            jackapp.currentUser
          );
          await jackapp.logOutByUserID(newuserrealm_user_id);
          if (errors.remoteerror === "") {
            setisSaved(true);
          }
        }
      } catch (err) {
        
        handleAuthenticationError(err);
      }
    } else {
      setErrors({ ...errors, email: "Email is invalid." });
    }
  };

  const addGroupsList = async (e) => {
    e.preventDefault();
    if (validateGroupList()) {
      setUserGroupList((oldrecord) => [...oldrecord, grouplistrow]);

      //console.log(usergroupList);
      //  setisSaved(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await saveuser(jackapp.currentUser);
    }
  };
  async function saveuser(userInfo) {
    // console.log("called saveuser");
    createCustomerUser(
      user.first_name,
      user.last_name,
      user.email,
      user.employee_number,
      user.photo,
      user.title,
      user.department,
      user.address[0].id,
      user.address[0].title,
      user.address[0].street,
      user.address[0].suite,
      user.address[0].country,
      user.address[0].states,
      user.address[0].city,
      user.address[0].post_code,
      user.address[0].m_phone,
      user.address[0].o_phone,
      user.address[0].alternateemail,
      user.address[0].fax,
      usergroupList,
      user.website,
      user.linkedin,
      user.facebook,
      user.twitter,
      user.instagram,
      userInfo
    ).then((response) => {
      if (response !== undefined) {
        handleRegistrationAndLogin(userInfo);
        //  let data[] = response;
        //console.log(JSON.stringify(response));
        // setuser(JSON.parse(response));
        setisSaved(true);
      }
    });
  }

  function handleAuthenticationError(err) {
    const { status, message } = parseAuthenticationError(err);
    const errorType = message || status;
    switch (errorType) {
      case "invalid username":
        setErrors({
          ...errors,
          remoteerror: "invalid email address",
        });
        break;
      case "invalid username/password":
        setErrors({
          ...errors,
          remoteerror: "invalid username/password",
        });
        break;
      case "invalid password":
        setErrors({ ...errors, remoteerror: "invalid  password" });
        break;
      case "401":
        setErrors({
          ...errors,
          remoteerror: "invalid username/password",
        });
        break;
      case "name already in use":
        setErrors({
          ...errors,
          remoteerror: "Email address is already exist in the system",
        });
        break;
      case "409":
        setErrors({
          ...errors,
          remoteerror: "invalid username/password",
        });
        break;
      default:
        setErrors({
          ...errors,
          remoteerror: "invalid username/password",
        });
        break;
    }
  }

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const validateGroupList = () => {
    try {
      let valid = false;
      valid = handleFieldGroupListRequiredValidation();
      Object.values(grouplisterrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldGroupListRequiredValidation = () => {
    let iserror = "";

    setGroupListErrors({
      ...grouplisterrors,
      organization_id:
        grouplistrow.organization_id === ""
          ? (iserror = "Organization is required")
          : grouplisterrors.organization_id,
      // facility_id:
      //   grouplistrow.facility_id === ""
      //     ? (iserror = "Facility is required")
      //     : grouplisterrors.facility_id,
      group_id:
        grouplistrow.group_id === ""
          ? (iserror = "Group is required")
          : grouplisterrors.group_id,
    });

    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      first_name:
        user.first_name === ""
          ? (iserror = "First Name is required")
          : errors.first_name,
      last_name:
        user.last_name === ""
          ? (iserror = "Last Name is required")
          : errors.last_name,
      email: user.email === "" ? (iserror = "Email is required") : errors.email,
      password:
        user.password === ""
          ? (iserror = "Password is required")
          : errors.password,
      confirm_password:
        user.confirm_password === ""
          ? (iserror = "Confirm Password is required")
          : user.confirm_password !== user.password
          ? (iserror = "Password & Confirm Password are mismatched")
          : errors.confirm_password,
    });

    
    if (usergroupList.length === 0) {
      iserror = "Group info is required";
      setGrouplistmandatory(iserror);
    } else {
      setGrouplistmandatory("");
    }
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "first_name":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "last_name":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "password":
        localerror =
          value.length < 6 ? "password must be at least 6 characters" : "";
        break;
      case "confirm_password":
        localerror =
          value != user.password
            ? "Password & Confirm Password are mismatched"
            : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setUserinfo(true);
        setContactinfo(false);
        return;
      case "contactinfo":
        setUserinfo(false);
        setContactinfo(true);
        return;
    }
  };

  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/user/customerusers",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const handleOrganizationGroups = async (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadStates(jackapp.currentUser);
    }

    async function loadStates(userInfo) {
      // console.log("userInfo.id", userInfo.id);
      getAllStateForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let statedata = JSON.parse(response);
          let options = statedata.map(function (state) {
            return { value: state._id, label: state.name };
          });
          setStateList(options);
          //console.log("state1" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {
            return { value: country._id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization1" + response);
        }
      });
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadFacility(jackapp.currentUser);
    }

    async function loadFacility(userInfo) {
      getAllFacilityForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //console.log("Facility" + JSON.parse(response));
          let facilitydata = JSON.parse(response);
          let options = facilitydata.map(function (facility) {
            return { value: facility._id, label: facility.facility_name };
          });
          setFacilityList(options);
        }
      });
    }

    // if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //   loadcustomer(jackapp.currentUser);
    // }
  }, []);

  async function loadcustomer(userInfo, organization_id) {
    getAllCustomerForLookup(1, userInfo, organization_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let customerdata = JSON.parse(response);
        setCustomerMainList(customerdata);
        let cusList = customerdata.filter(
          (customer) => customer.organization_id === organization_id
        );
        let options = customerdata.map(function (customer) {
          return { value: customer._id, label: customer.customer_name };
        });
        setcustomerList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadGroups(userInfo, organization_id, customer_id) {
    getAllGroupsForLookupbyCustomerID(
      1,
      userInfo,
      organization_id,
      customer_id
    ).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let groupsdata = JSON.parse(response);
        setGroupMainList(groupsdata);
        let grpList = groupsdata.filter(
          (group) => group.organization_id === organization_id
        );
        let options = grpList.map(function (group) {
          return { value: group._id, label: group.group_short_name };
        });
        setGroupList(options);
        //console.log("group" + response);
      }
    });
  }

  // useEffect(() => {
  //   console.log("usergroupList : ", usergroupList);
  //   let _groupListData = usergroupList;
  //   _groupListData.forEach((row) => {
  //     // var filtered = OrganizationList.filter((a) => a.value == row.organization_id);
  //     // if (filtered.length !== 0) {
  //     //   row.organization_name = filtered[0].label;
  //     // }

  //     // var filtered1 = groupList.filter((a) => a.value == row.group_id);
  //     // if (filtered1.length !== 0) {
  //     //   row.group_name = filtered1[0].label;
  //     // }
  //     //setUserGroupListDisplay((oldrecord1) => [...oldrecord1, row]);
  //   });
  // }, [usergroupList]);

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/customerusers">Customer Users</Link>
          </li>
          <li className="breadcrumb-item active bold">Add user</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div className={"tab-pane fade " + (tabUserinfo ? "show active " : "")}>
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2 border-1">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-10  col ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Basic Info
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  First Name{" "}
                                  <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.first_name.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="first_name"
                                  >
                                    {errors.first_name}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Last Name <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.last_name.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="last_name"
                                  >
                                    {errors.last_name}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Email <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.email.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="email"
                                  >
                                    {errors.email}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Employee Number
                                </label>
                                <input
                                  type="text"
                                  name="employee_number"
                                  id="employee_number"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Password <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.password.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="password"
                                  >
                                    {errors.password}
                                  </small>
                                )}
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Confirm Password{" "}
                                  <label  className="text-danger">*</label>
                                </label>
                                <input
                                  type="password"
                                  name="confirm_password"
                                  id="confirm_password"
                                  className="form-control"
                                  value={user.confirm_password}
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.confirm_password.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="confirm_password"
                                  >
                                    {errors.confirm_password}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Title
                                </label>
                                <input
                                  type="title"
                                  name="title"
                                  id="title"
                                  className="form-control"
                                  value={user.title}
                                  onChange={handleChange}
                                  noValidate
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Department
                                </label>
                                <input
                                  type="department"
                                  name="department"
                                  id="department"
                                  className="form-control"
                                  value={user.department}
                                  onChange={handleChange}
                                  noValidate
                                />
                              </div>
                            </div>
                            <div className="row mb-1"></div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-2  col  ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Photo{" "}
                          </PanelHeader>
                          <PanelBody className="">
                            <div className="text-center">
                              <div className="col-sm-12 col-md-12 col-lg-12 m-2">
                                <FileBase
                                  type="file"
                                  multiple={false}
                                  onDone={({ base64 }) => {
                                    setUser({ ...user, ["photo"]: base64 });
                                    setisvaliddata(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="text-center">
                              <img
                                src={user.photo}
                                className="rounded-circle mx-auto d-block mw-100"
                                width="100"
                                height="100"
                              />
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className=" col-xl-12  col ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Group Info{" "}
                          </PanelHeader>
                          <div className="row p-1">
                            <div className=" col-xl-6  col ms-0">
                              <PanelBody className="p-0 m-0">
                                <div className="row p-0">
                                  <div className="col-sm-4 col-md-4 col-lg-4 mb-2">
                                    <label className="form-label form-label">
                                      Organization{" "}
                                      <label  className="text-danger">*</label>
                                    </label>
                                    <Select
                                      name="organization_id"
                                      options={OrganizationList}
                                      isSearchable={true}
                                      styles={styles}
                                      onChange={(val) => {
                                        handleGroupListDropDownChange({
                                          target: {
                                            name: "organization_id",
                                            value: val.value,
                                          },
                                        });
                                      }}
                                    ></Select>
                                    {grouplisterrors.organization_id.length > 0 && (
                                      <small
                                        className="form-check-label text-red"
                                        htmlFor="organization_id"
                                      >
                                        {grouplisterrors.organization_id}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-4 col-md-4 col-lg-4 ">
                                    <label className="form-label">
                                      Customer{" "}
                                      <label  className="text-danger">*</label>{" "}
                                    </label>
                                    <Select
                                      name="customer_id"
                                      id="customer_id"
                                      options={customerList}
                                      isSearchable={true}
                                      styles={styles}
                                      onChange={(val) => {
                                        handleGroupListDropDownChange({
                                          target: {
                                            name: "customer_id",
                                            value: val.value,
                                          },
                                        });
                                      }}
                                    ></Select>
                                    {grouplisterrors.customer_id.length > 0 && (
                                      <small
                                        className="form-check-label text-red"
                                        htmlFor="customer_id"
                                      >
                                        {grouplisterrors.customer_id}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-4 col-md-4 col-lg-4 mb-4">
                                    <label className="form-label form-label">
                                      Group <label  className="text-danger">*</label>
                                    </label>
                                    <Select
                                      name="group_id"
                                      options={groupList}
                                      isSearchable={true}
                                      styles={styles}
                                      onChange={(val) => {
                                        handleGroupListDropDownChange({
                                          target: {
                                            name: "group_id",
                                            value: val.value,
                                          },
                                        });
                                      }}
                                    ></Select>
                                    {grouplisterrors.group_id.length > 0 && (
                                      <small
                                        className="form-check-label text-red"
                                        htmlFor="group_id"
                                      >
                                        {grouplisterrors.group_id}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                <div className="row p-0">
                                  <div className="col-sm-12 col-md-12 col-lg-12 m-2">
                                    <ButtonGroup>
                                      <Button
                                        className="me-1"
                                        color="default"
                                        size="md"
                                        onClick={addGroupsList}
                                      >
                                        Add Group
                                      </Button>
                                      {/* <Button className="me-1"
                                      color="default"
                                      size="md"
                                      onClick={addGroupsList}
                                    >
                                      Update Group
                                    </Button> */}
                                      <Button
                                        color="default"
                                        size="md"
                                        onClick={addGroupsList}
                                      >
                                        Clear
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                  {grouplistmandatory.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="grouplistmandatory"
                                    >
                                      {grouplistmandatory}
                                    </small>
                                  )}
                                </div>
                              </PanelBody>
                            </div>
                            <div className=" col-xl-6   col ms-0">
                              <PanelBody className="p-0 m-0">
                                <div className="col-xl-12">
                                  <GlobalSimpleTable
                                    gcolumns={usergroupListColumns}
                                    gdata={
                                      data !== undefined
                                        ? JSON.stringify(data)
                                        : ""
                                    }
                                    ghiddencolumns={hiddenusergroupListColumns}
                                    gkey={"user_id"}
                                    glink="/user/detailuser"
                                    gfunction={handleOrganizationGroups}
                                    gplaceholder={""}
                                  />
                                </div>
                              </PanelBody>
                            </div>
                          </div>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabContactinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-8 colcol-xl-8 col ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Address Info{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Street</label>

                                <input
                                  type="text"
                                  name="address_0_street"
                                  id="address_0_street"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "street"
                                    )
                                  }
                                  value={user.address[0].street}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Suite</label>

                                <input
                                  type="text"
                                  name="suite"
                                  id="suite"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "suite"
                                    )
                                  }
                                  value={user.address[0].suite}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  Country{" "}
                                </label>
                                <Select
                                  name="country_id"
                                  options={countryList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={countryList.filter(
                                    (country) =>
                                      country.value === user.address[0].country
                                  )}
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "country",
                                      true
                                    )
                                  }
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  State{" "}
                                </label>
                                <Select
                                  name="state_id"
                                  options={stateList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={stateList.filter(
                                    (state) =>
                                      state.value === user.address[0].states
                                  )}
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "states",
                                      true
                                    )
                                  }
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">City</label>

                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "city"
                                    )
                                  }
                                  value={user.address[0].city}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Postal Code
                                </label>

                                <input
                                  type="text"
                                  name="post_code"
                                  id="post_code"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "post_code"
                                    )
                                  }
                                  value={user.address[0].post_code}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Mobile Phone
                                </label>

                                <input
                                  type="text"
                                  name="m_phone"
                                  id="m_phone"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "m_phone"
                                    )
                                  }
                                  value={user.address[0].m_phone}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Office Phone
                                </label>

                                <input
                                  type="text"
                                  name="o_phone"
                                  id="o_phone"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "o_phone"
                                    )
                                  }
                                  value={user.address[0].o_phone}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">Fax</label>

                                <input
                                  type="text"
                                  name="fax"
                                  id="fax"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "fax"
                                    )
                                  }
                                  value={user.address[0].fax}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">
                                  Alternate Email
                                </label>

                                <input
                                  type="text"
                                  name="alternateemail"
                                  id="alternateemail"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleAddressArrayObjectChange(
                                      e,
                                      "0",
                                      "alternateemail"
                                    )
                                  }
                                  value={user.address[0].alternateemail}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-4  col  ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Social Media handles{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1 ">
                              <div className="col-12">
                                <label className="form-label">Website</label>
                                <input
                                  type="text"
                                  name="website"
                                  id="website"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.website}
                                />
                              </div>
                            </div>
                            <div className="row p-1 ">
                              <div className="col-12">
                                <label className="form-label">LinkedIn</label>
                                <input
                                  type="text"
                                  name="linkedin"
                                  id="linkedin"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.linkedin}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-12">
                                <label className="form-label">Facebook</label>
                                <input
                                  type="text"
                                  name="facebook"
                                  id="facebook"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.facebook}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-6">
                                <label className="form-label">Twitter</label>

                                <input
                                  type="text"
                                  name="twitter"
                                  id="twitter"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.twitter}
                                />
                              </div>
                              <div className="col-6">
                                <label className="form-label">Instagram</label>

                                <input
                                  type="text"
                                  name="instagram"
                                  id="instagram"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.instagram}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="user is added successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default AddCustomerUser;
