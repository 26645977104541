// service for get All Leave Approval
export const getAllLeaveApproval = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveInfoApproval(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

// service for get leave approval by id
export const getLeaveApprovalByID = async (leaveapproval_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    // console.log("loggedin_by ",loggedin_by,"leaveapproval_id :",leaveapproval_id);
    const data = await LRAapp.functions.getLeaveInfoApprovalByID(
      leaveapproval_id,
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("users" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for update leave approval by id
export const updateLeaveApprovalByID = async (
  leaveapproval_id,
  leavetype_id,
  leave_description,
  total_days,
  from_date,
  to_date,
  requestby_id,
  leavestatus_id,
  leave_reason,
  jackapp
) => {
  //console.log("updateLeaveApprovalByID",updateLeaveApprovalByID);
  try {
    var loggedinby = jackapp.id;
    const Leavedata = {
      _id: leaveapproval_id,
      leavetype_id: leavetype_id,
      leave_description: leave_description,
      total_days: total_days,
      from_date: from_date,
      to_date: to_date,
      requestby_id: requestby_id,
      leavestatus_id: leavestatus_id,
      leave_reason: leave_reason,
    };
    const data = await jackapp.functions.updateLeaveInfoApprovalByID(
      JSON.stringify(Leavedata),
      loggedinby
    );
    //console.log("status: success",Holidaydata,loggedinby);
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};

//getPendingLeaveInfoByLeaveStatus

export const getPendingLeaveInfoByLeaveStatus = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getPendingLeaveInfoByLeaveStatus(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getLeaveTypeBySickLeave = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveTypeBySickLeave(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getLeaveTypeByCasualLeave = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveTypeByCasualLeave(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};


export const getAvailableSickLeaveByUserID = async (req_by, LRAapp) =>{
 // console.log("req_by",req_by);
 // console.log("LRAapp",LRAapp);
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAvailableSickLeaveByUserID(req_by,loggedin_by);
    //console.log("data" , data);
    if (data !== undefined) {
       
      return JSON.stringify(data);
    }
  } catch (error) {
    //console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};
export const getAvailableCasualLeaveByUserID = async  (req_by, LRAapp,) =>{
 // console.log("req_by",req_by);
//console.log("LRAapp",LRAapp);
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getAvailableCasualLeaveByUserID(req_by,loggedin_by);
   // console.log("data" , data);
    if (data !== undefined) {
     
      return JSON.stringify(data);
    }
  } catch (error) {
      //console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

