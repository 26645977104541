export const getCustomerUsers = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getCustomerUsers(loggedin_by, "");
    if (data !== undefined) {
      //   console.log("CustomerUsers" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCustomerUserByID = async (user_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getCustomerUserByID(
      user_id,
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("CustomerCustomerUsers" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createCustomerUser = async (
  first_name,
  last_name,
  email,
  employee_number,
  photo,
  title,
  department,
  physical_id,
  physical_title,
  physical_street,
  physical_suite,
  physical_country,
  physical_states,
  physical_city,
  physical_post_code,
  physical_m_phone,
  physical_o_phone,
  physical_email,
  physical_fax,
  grouplist,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    //console.log("grouplist ", grouplist);
    const address = [
      {
        id: physical_id,
        type: "physical",
        title: physical_title,
        street: physical_street,
        suite: physical_suite,
        country: physical_country,
        states: physical_states,
        city: physical_city,
        post_code: physical_post_code,
        m_phone: physical_m_phone,
        o_phone: physical_o_phone,
        alternateemail: physical_email,
        fax: physical_fax,
      },
    ];

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const customeruserdata = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      employee_number: employee_number,
      photo: photo,
      title: title,
      department: department,
      address: address,
      CustomerUser_group_roles: grouplist,
      social_media: social_media_handle,

      //  organization_id: organization_id,
    };

    const data = await jackapp.functions.InsertCustomerUser(
      JSON.stringify(customeruserdata),
      loggedinby
    );
    console.log("status: success");
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const updateCustomerUserByID = async (
  user_id,
  first_name,
  last_name,
  email,
  employee_number,
  photo,
  title,
  department,
  physical_id,
  physical_title,
  physical_street,
  physical_suite,
  physical_country,
  physical_states,
  physical_city,
  physical_post_code,
  physical_m_phone,
  physical_o_phone,
  physical_email,
  physical_fax,
  grouplist,
  website,
  linkedin,
  facebook,
  twitter,
  instagram,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const address = [
      {
        id: physical_id,
        type: "physical",
        title: physical_title,
        street: physical_street,
        suite: physical_suite,
        country: physical_country,
        states: physical_states,
        city: physical_city,
        post_code: physical_post_code,
        m_phone: physical_m_phone,
        o_phone: physical_o_phone,
        email: physical_email,
        fax: physical_fax,
      },
    ];

    const social_media_handle = {
      website: website,
      linkedin: linkedin,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
    };

    const customeruserdata = {
      user_id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      employee_number: employee_number,
      photo: photo,
      title: title,
      department: department,
      address: address,
      CustomerUser_group_roles: grouplist,
      social_media: social_media_handle,
    };

    const data = await jackapp.functions.updateCustomerUserByID(
      JSON.stringify(customeruserdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateUserRealmUserID = async (
  user_id,
  email,
  realm_user_id,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;

    const customeruserdata = {
      user_id: user_id,
      email: email,
      realm_user_id: realm_user_id,
    };

    const data = await jackapp.functions.updateUserRealmUserID(
      JSON.stringify(customeruserdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteCustomerUserByID = async (user_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteCustomerUserByID(
      user_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
