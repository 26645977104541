import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../providers/RealmApp";
import SweetAlert from "react-bootstrap-sweetalert";
import { checkUserByMailID } from "../../services/forgotpassword_service";
import userInfoReducer from "../../storestate/reducers/userInfoReducer";



const Forgot = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  console.log("jackapp",jackapp);

  //state variavles
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabLeaveRequestnfo, setLeaverRequestnfo] = useState(true);
  const [forgot, setForgot] = useState({
   email_id:""
 
  });
  const [errors, setErrors] = useState({
    email_id:""
  });
  const [tabForgotinfo, setForgotinfo] = useState(true); 
  const [validatemsg, SetValidatemsg] = useState("");

  //save functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setForgot({ ...forgot, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };

 

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setForgot({ ...forgot, [name]: value });
    handleFieldValidation(event);
    // console.log(forgot);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("email_id",forgot.email_id);
   GetEmailID(forgot.email_id)
    
   
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      email_id:
        forgot.email_id === ""
          ? (iserror = "Email ID is required")
          : errors.email_id,
     
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email_id":
        localerror =  "Email Id is missing" ;
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/authenticate/login",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setForgotinfo(true);
        return;
      default:
        break;
    }
  };
  useEffect(() => {

   
  }, []);

  //internal function


  async function GetEmailID(mail_id, q) {
    checkUserByMailID(mail_id,q).then(
       (response) => {
         if (response !== undefined) {
           let Emaildata = JSON.parse(response);         
           console.log("Emaildata",Emaildata);          
         }
       }
     );
   }





  return (
    <div className="row">
      

      <div className="col-md-4 offset-md-4">
        <Panel className="container">
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/lms/addLeaveRequest"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabForgotinfo ? "active " : "")
                      }
                    >
                     Forgot Password Info
                    </Link>
                  </li>
                </ul>
              </div>
            
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody  >
              <div className=" container ">
                
                <div className=" p-3">
                  <label className="form-label form-label">
                    Email <label className="text-danger">*</label>{" "}
                  </label>
                  <input
                    type="email_id"
                    name="email_id"
                    id="email_id"
                    className="form-control"
                    onChange={handleChange}
                   
                    value={forgot.email_id}
                  />
                 
                </div>
                <div className="p-3"><i class="fa fa-key"/>
                    <a href="/authenticate/login">I Remember the password</a>
                </div>
                <div  className="p-3">
                <button
                 onClick={handleSubmit}
                  className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                  disabled={!isvaliddata}>Send Mail</button></div>
                </div>
              {validatemsg.length > 0 && (
                <big className="form-check-label text-red" htmlFor="validation">
                  {validatemsg}
                </big>
              )}

            </PanelBody>
          </form>
        </Panel>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="LeaveRequest is added successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default Forgot;
