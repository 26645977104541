import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody} from "../../../components/panel/panel";
import Select from "react-select";
import { ButtonGroup,Button} from 'reactstrap';
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { getGroupByID, deleteGroupByID } from "../../../services/groupService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllCustomerForLookup } from "../../../services/customerService";
//import { getAllFacilityForLookup } from "../../../services/facilityService";
import styles from '../../../components/customstyles/react-select-styles';
import ActivityHistory from '../../../components/activityHistory/activityHistory';
const DetailGroup = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  //state variabley
  const [group, setGroup] = useState({
    _id:'',
    group_short_name: "",
    group_description: "",
    
    //facility_id: 0,
    customer_id: "",
    organization_id: "",
    is_active: "1",
    activity_history: {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      }
  });

  const jackapp = useRealmApp();
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  //const [facilityList, setFacilityList] = useState([]);
  //const data = useMemo(() => [...group], [group]);
  const keyid = useLocation().state.group_id;
  const [tabGroupinfo, setGroupinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //functions

  const handleDelete = async (groupid) => {
    console.log(groupid);
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteGroup(keyid, jackapp.currentUser);
    }
  };
  async function deleteGroup(groupid, userInfo) {
    deleteGroupByID(groupid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/user/groups",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setGroupinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setGroupinfo(false);
            setActivityinfo(true);
          return;
  }
  };
  
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          })
          setOrganizationList(options);
          //console.log("Organization1" + response);
        
        }
      });
    }

    // if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //   loadFacility(jackapp.currentUser);
    // }

    // async function loadFacility(userInfo) {
    //   getAllFacilityForLookup(1, userInfo).then((response) => {
    //     if (response !== undefined) {
    //       //let data[] = response;
    //       //console.log("Facility" + JSON.parse(response));
    //       let facilitydata = JSON.parse(response);
    //       let options = facilitydata.map(function (facility) {
    //         return { value: facility._id, label: facility.facility_name };
    //       })
    //       setFacilityList(options);
    //     }
    //   });
    // }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, []);
  useEffect(() => {
    //internal functions
    async function loadGroupByID(userInfo) {
      getGroupByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let groups = JSON.parse(response);
          groups.map((group) =>
            //console.log(group._id)
            setGroup({ ...group, _id: group._id })
          );
        
        }
      });
     // console.log();
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadGroupByID(jackapp.currentUser);
  }, [keyid, jackapp.currentUser]);
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/user/groups">Groups</Link>
        </li>
        <li className="breadcrumb-item active bold">Detail Group</li>
      </ol>
    </div>
  <div className="tab-content p-0">
      <div className={'tab-pane fade ' + (tabGroupinfo ? 'show active ': '')}>
        <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabGroupinfo ? "active " : "")}
                >
                Basic Info
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
            <Link
              to={{pathname: "/user/editgroup",}}
              state={{ "group_id": keyid }}
              className="btn btn-primary  me-2 "
              >
              <i className="fa fa-fw  fa-square"></i> Edit
              </Link>

              <Button
              onClick={() => handleDelete(keyid)}
              className="btn btn-danger me-2  "
              >
              <i className="fa fa-trash"></i>&nbsp; Delete
              </Button>
              <Button
              onClick={handleCancel}
              className="btn btn-default me-2 "
              >
              Cancel
              </Button>
            </ButtonGroup>
            </div>
          </PanelHeader>

          <PanelBody className="p-0 m-2">
          <div className="row">
         
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label className="form-label ">
                  Group Short Name{" "}
                </label>

                <input
                  type="text"
                  name="group_short_name"
                  id="group_short_name"
                  className="form-control"
                  disabled="disabled"
                  value={group.group_short_name}
                />
                <label className="form-check-label" htmlFor="group_short_name">
                  &nbsp;
                </label>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label className="form-label">Description</label>

                <input
                  type="text"
                  name="group_description"
                  id="group_description"
                  className="form-control"
                  disabled="disabled"
                  value={group.group_description}
                />
                <label className="form-check-label" htmlFor="group_description">
                  &nbsp;
                </label>
              </div>
              {/* <div className="col-sm-12 col-md-4 col-lg-4">
                <label className="form-label">Facility *</label>
                  <Select name="facility_id" 
                  value={facilityList.filter(facility => facility.value === group.facility_id)}
                  options={facilityList}
                  isSearchable={true}
                  styles={styles} 
                  isDisabled={true}
                   ></Select>
              </div> */}
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label className="form-label">Organization *</label>
                <Select name="organization_id" 
                
                  options={OrganizationList}
                  isSearchable={true}
                  styles={styles} 
                  value={OrganizationList.filter(organization => organization.value === group.organization_id)}
                  isDisabled={true}
                  ></Select>
              </div>
            </div>
            <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3">
                                        <label className="form-label">
                                          Customer *
                                        </label>
                                        <Select
                                          name="customer_id"
                                          options={customerList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={customerList.filter(
                                            (customer) =>
                                              customer.value === group.customer_id
                                          )}
                                          isDisabled={true}
                                        ></Select>
                                      </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label className="form-label">Active</label>
                <div className="form-check form-switch ms-auto mb-0">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled="disabled"
                    name="isactive"
                    id="isactive"
                    checked={group.is_active === "1" ? true : false}
                  />
                  <label className="form-check-label" htmlFor="isactive">
                    &nbsp;
                  </label>
                </div>
              </div>
            </div>
            </PanelBody>
        
          </Panel>
        </div>
      

      </div>
      <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
      <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabGroupinfo ? "active " : "")}
                >
                Group Info
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
            <Link
              to={{pathname: "/user/editgroup",}}
              state={{ "group_id": keyid }}
              className="btn btn-primary  me-2 "
              >
              <i className="fa fa-fw  fa-square"></i> Edit
              </Link>

              <Button
              onClick={() => handleDelete(keyid)}
              className="btn btn-danger me-2  "
              >
              <i className="fa fa-trash"></i>&nbsp; Delete
              </Button>
              <Button
              onClick={handleCancel}
              className="btn btn-default me-2 "
              >
              Cancel
              </Button>
            </ButtonGroup>
            </div>
          </PanelHeader>

          <PanelBody>
              <ActivityHistory activityhistory={group!==undefined?JSON.stringify(group.activity_history):""}/>
          </PanelBody>
          </Panel>
        </div>

      
      
      </div>
      
  </div>
  {isDeleted && (
            <SweetAlert
            style={{ color: "black" }}
            warning
            title=""
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnText="No"
            cancelBtnBsStyle="info"
            showCancel
            focusCancelBtn
            onConfirm={handleDeleteSweetAlert}
            onCancel={handleDeleteCancel}
            >
            <h5> Do you wish to delete group[{group.group_short_name}]?.</h5>
            </SweetAlert>
          )}
          {isDeletedConfirm && (
            <SweetAlert
            success
            style={{ color: "black" }}
            title="group is deleted successfully.."
            onConfirm={handleConfirmDeleteSweetAlert}
            ></SweetAlert>
          )}
</div>
  );
};

export default DetailGroup;
