import React,{ useEffect,  useState }  from 'react';
import { AppSettings } from './config/app-settings.js';
import { slideToggle } from './composables/slideToggle.js';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import ThemePanel from './components/theme-panel/theme-panel.jsx';
import { useRealmApp } from "./providers/RealmApp";
import { useSelector } from "react-redux"
import LoginScreen from "./pages/authenticate/login";
import Logout from "./pages/authenticate/logout";
import ChangePassword from "./pages/authenticate/changepassword";
import ForgotPassword from "./pages/authenticate/forgotpassword";
import Forgot from "./pages/authenticate/forgot";
import { Outlet,Routes,Route,useLocation } from 'react-router-dom';



const App = (props) => {
	//context variables
	const app = useRealmApp();
	const userInfo = useSelector((state) => state.userInfo);
	//constant variables

	//state variables
	const [appMomentDateFormat] = useState('DD MMM YYYY');	//MM/DD/YYYY

	
	const [appTheme,setsetappTheme] = useState('');
	const [appDarkMode,setappDarkMode] = useState(false);
	const [appGradientEnabled,setappGradientEnabled] = useState(false);
	const [appHeaderNone,setappHeaderNone] = useState(false);
	const [appHeaderFixed,setappHeaderFixed] = useState(true);
	const [appHeaderInverse,setappHeaderInverse] =useState(false);
	const [appHeaderMegaMenu,setappHeaderMegaMenu] = useState(false);
	const [appHeaderLanguageBar,setappHeaderLanguageBar] = useState(false);
	const [hasScroll,sethasScroll] = useState(false);		
	
	const [appSidebarNone,setappSidebarNone] = useState(false);
	const [appSidebarWide,setappSidebarWide] = useState(false);
	const [appSidebarLight,setappSidebarLight] = useState(false);
	const [appSidebarMinify,setappSidebarMinify] = useState(false);
	const [appSidebarMobileToggled,setappSidebarMobileToggled] = useState(false);
	const [appSidebarTransparent,setappSidebarTransparent] =useState(false);
	 const [appSidebarSearch,setappSidebarSearch] = useState(false);
	 const [appSidebarFixed,setappSidebarFixed] = useState(true);
	 const [appSidebarGrid,setappSidebarGrid] = useState(false);
			
	const [appContentNone,setappContentNone] = useState(false);
	const [appContentClass,setappContentClass] = useState('');
	const [appContentFullHeight,setappContentFullHeight] = useState(false);	
	
	const [appTopMenu,setappTopMenu] = useState(false);
	const [appTopMenuMobileToggled,setappTopMenuMobileToggled] = useState(false);		
		
			
	const [appSidebarTwo,setappSidebarTwo] = useState(false);
		
	
	const [appSidebarEnd,setappSidebarEnd] = useState(false);
	const [appSidebarEndToggled,setappSidebarEndToggled] = useState(false);
	const [appSidebarEndMobileToggled,setappSidebarEndMobileToggled] = useState(false);
			
	const [font,setfont] = useState({
				family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
				size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
				weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
			 });
	const [color,setcolor] = useState( {
				componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
				componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
				dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
				light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
				blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
				indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
				purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
				pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
				red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
				orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
				yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
				green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
				success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
				teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
				cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
				white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
				gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
				lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
				gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
				gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
				gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
				gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
				gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
				gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
				gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
				gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
				gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
				black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
				componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
				componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
				darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
				lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
				blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
				indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
				purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
				pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
				redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
				orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
				yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
				greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
				successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
				tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
				cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
				whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
				grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
				limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
				gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
				gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
				gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
				gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
				gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
				gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
				gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
				gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
				gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
				blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
			});




			const toggleAppSidebarMinify = (e) => {
				e.preventDefault();
				// if (appSidebarMinify) {
				// 	setappSidebarFloatSubMenuActive(false);
	
				// }
				setappSidebarMinify(!appSidebarMinify);
				if (localStorage) {
					localStorage.appSidebarMinify = !appSidebarMinify;
				}
			}
		const toggleAppSidebarMobile = (e) => {
				e.preventDefault();
					setappSidebarMobileToggled(!appSidebarMobileToggled);
			}
		const handleSetAppSidebarNone = (value) => {
			setappSidebarNone(value)
			
			}
		const handleSetAppSidebarMinified = (value) => {
			setappSidebarMinify(value)
			
			}
		const handleSetAppSidebarWide = (value) => {
			setappSidebarWide(value)
			
			}
		const handleSetAppSidebarLight = (value) => {
			setappSidebarLight(value)
			
			}
		const handleSetAppSidebarTransparent = (value) => {
			setappSidebarTransparent(value)
			
			}
		const handleSetAppSidebarSearch = (value) => {
			setappSidebarSearch(value)
			
			}
		const handleSetAppSidebarFixed = (value) => {
				if (value === true && !appHeaderFixed) {
					alert('Default Header with Fixed Sidebar option is not supported. Proceed with Fixed Header with Fixed Sidebar.');
				setappHeaderFixed(true);
				
					if (localStorage) {
						localStorage.appHeaderFixed = true;
					}
				}
			setappSidebarFixed(value)
			
				if (localStorage) {
					localStorage.appSidebarFixed = value;
				}
			}
		const handleSetAppSidebarGrid = (value) => {
			setappSidebarGrid(value)
			
				if (localStorage) {
					localStorage.appSidebarGrid = value;
				}
			}
			
			const toggleAppSidebarEnd = (e) => {
				e.preventDefault();
			setappSidebarEndToggled(!appSidebarEndToggled);
			
			}
			const toggleAppSidebarEndMobile = (e) => {
				e.preventDefault();
			setappSidebarEndMobileToggled(!appSidebarEndMobileToggled);
			
			}
		const handleSetAppSidebarEnd = (value) => {
			setappSidebarEnd(value)
			
			}
			
		const handleSetAppContentNone = (value) => {
			setappContentNone(value)
			
			}
		const handleSetAppContentClass = (value) => {
			setappContentClass(value)
			
			}
		const handleSetAppContentFullHeight = (value) => {
			setappContentFullHeight(value)
			
			}
			
		const handleSetAppHeaderNone = (value) => {
			setappHeaderNone(value)
			
			}
		const handleSetAppHeaderFixed = (value) => {
				if (value === false && appSidebarFixed) {
					alert('Default Header with Fixed Sidebar option is not supported. Proceed with Default Header with Default Sidebar.');
				setappSidebarFixed(false);
				
					if (localStorage) {
						localStorage.appSidebarFixed = false;
					}
				}
			setappHeaderFixed(value)
			
				if (localStorage) {
					localStorage.appHeaderFixed = value;
				}
			}
		const handleSetAppHeaderInverse = (value) => {
			setappHeaderInverse(value)
			
				if (localStorage) {
					localStorage.appHeaderInverse = value;
				}
			}
		const handleSetAppHeaderMegaMenu = (value) => {
			setappHeaderMegaMenu(value)
			
			}
		const handleSetAppHeaderLanguageBar = (value) => {
			setappHeaderLanguageBar(value)
			
			}
			
		const handleSetAppTopMenu = (value) => {
			setappTopMenu(value)
			
			}
			const toggleAppTopMenuMobile = (e) => {
				e.preventDefault();
				
				slideToggle(document.querySelector('.app-top-menu'));
			}
		const handleSetAppSidebarTwo = (value) => {
			setappSidebarTwo(value)
			
			setappSidebarEndToggled(value)
			
			}
		const handleSetAppBoxedLayout = (value) => {
				if (value === true) {
					document.body.classList.add('boxed-layout');
				} else {
					document.body.classList.remove('boxed-layout');
				}
			}
		const handleSetAppDarkMode = (value) => {
				if (value === true) {
					document.body.classList.add('dark-mode');
				} else {
					document.body.classList.remove('dark-mode');
				}
			setappDarkMode(value);
			 handleSetColor();
				if (localStorage) {
					localStorage.appDarkMode = value;
				}
			}
		const handleSetAppGradientEnabled = (value) => {
			setappGradientEnabled(value)
			
				if (localStorage) {
					localStorage.appGradientEnabled = value;
				}
			}
		const handleSetFont = () => {
			setfont({
						family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
						size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
						weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
					});
			
			}
		const handleSetColor = () => {
			setcolor({
						componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
						componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
						dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
						light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
						blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
						indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
						purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
						pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
						red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
						orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
						yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
						green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
						success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
						teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
						cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
						white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
						gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
						lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
						gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
						gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
						gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
						gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
						gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
						gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
						gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
						gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
						gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
						black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
						componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
						componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
						darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
						lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
						blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
						indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
						purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
						pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
						redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
						orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
						yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
						greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
						successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
						tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
						cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
						whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
						grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
						limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
						gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
						gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
						gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
						gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
						gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
						gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
						gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
						gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
						gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
						blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
					});
			
			}
		const handleSetAppTheme = (value) => {
				var newTheme = 'theme-' + value;
				for (var x = 0; x < document.body.classList.length; x++) {
					if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
						document.body.classList.remove(document.body.classList[x]);
					}
				}
				document.body.classList.add(newTheme);
				
				if (localStorage && value) {
					localStorage.appTheme = value;
				}
			}
			
		

	
			useEffect(() => {
		handleSetColor();
		handleSetFont();
		handleSetAppTheme(appTheme);
		if (appDarkMode) {
			handleSetAppDarkMode(true);
		}
    window.addEventListener('scroll', handleScroll);
    
		if (localStorage) {
			if (typeof localStorage.appTheme !== 'undefined') {
				document.body.classList.add('theme-'+ localStorage.appTheme);
			}
			if (typeof localStorage.appDarkMode !== 'undefined') {
				handleSetAppDarkMode((localStorage.appDarkMode === 'true') ? true : false);
			}
			if (typeof localStorage.appHeaderInverse !== 'undefined') {
				handleSetAppHeaderInverse((localStorage.appHeaderInverse === 'true') ? true : false);
			}
			if (typeof localStorage.appGradientEnabled !== 'undefined') {
				handleSetAppGradientEnabled((localStorage.appDarkMode === 'true') ? true : false);
			}
			if (typeof localStorage.appSidebarGrid !== 'undefined') {
				handleSetAppSidebarGrid((localStorage.appSidebarGrid === 'true') ? true : false);
			}
			if (typeof localStorage.appSidebarMinify !== 'undefined') {
				handleSetAppSidebarMinified((localStorage.appSidebarMinify === 'true') ? true : false);
			}
			if (typeof localStorage.appSidebarFixed !== 'undefined') {
				handleSetAppSidebarFixed((localStorage.appSidebarFixed === 'true') ? true : false);
			}
			if (typeof localStorage.appHeaderFixed !== 'undefined') {
				handleSetAppHeaderFixed((localStorage.appHeaderFixed === 'true') ? true : false);
			}
		}
	}, []);

	useEffect(() => {
		return () => {
    window.removeEventListener('scroll', handleScroll)
}
}, []);
  
  const handleScroll = () => {
  	if (window.scrollY > 0) {
  		sethasScroll(true);
  	} else {
		sethasScroll(false);
  	}
  	var elm = document.getElementsByClassName('nvtooltip');
  	for (var i = 0; i < elm.length; i++) {
  		elm[i].classList.add('d-none');
  	}
  }
  const location = useLocation();
  
  const navigateViews = () => {
//	console.log(location.pathname);
	if((location.pathname==='/authenticate/logout') || (app.currentUser === null || app.currentUser === undefined)) {
		return (
			<Routes>
			<Route path="/" element={<LoginScreen />} />
			<Route path="/authenticate/login" element={<LoginScreen />} />
			<Route path="/authenticate/logout" element={<Logout />} />
			<Route path="/authenticate/forgotpassword" element={<ForgotPassword />} />
			<Route path="/authenticate/forgot" element={<Forgot/>} />
			
		</Routes>
		)
	}
	else
	{
		return (

			<AppSettings.Provider value={{
				appMomentDateFormat,appDarkMode,appSidebarTransparent,appSidebarGrid,appHeaderLanguageBar, appHeaderMegaMenu, 
					appHeaderInverse, appSidebarTwo, appTopMenu, appSidebarNone,
					appContentClass,
					color,font,appTheme,
					toggleAppSidebarMinify,toggleAppSidebarMobile,
					toggleAppSidebarEndMobile,toggleAppTopMenuMobile,
					handleSetAppSidebarNone,handleSetAppHeaderNone,handleSetAppContentClass,
					handleSetAppDarkMode,
					setsetappTheme,setappDarkMode,setappGradientEnabled,setappHeaderFixed,setappHeaderInverse,
					setappHeaderMegaMenu,setappHeaderLanguageBar,setappSidebarWide,setappSidebarLight,
					setappSidebarTransparent,setappSidebarGrid,setappContentNone,setappTopMenu,setappSidebarTwo,
					setappSidebarEnd,setappSidebarEndToggled,setappSidebarFixed,setappContentFullHeight
				}}>
				<div className={
					'app ' +
					(appGradientEnabled ? 'app-gradient-enabled ' : '') + 
					(appHeaderNone ? 'app-without-header ' : '') + 
					(appHeaderFixed && !appHeaderNone ? 'app-header-fixed ' : '') + 
					(appSidebarFixed ? 'app-sidebar-fixed ' : '') +
					(appSidebarNone ? 'app-without-sidebar ' : '') + 
					(appSidebarEnd ? 'app-with-end-sidebar ' : '') +
					(appSidebarWide ? 'app-with-wide-sidebar ' : '') +
					(appSidebarLight ? 'app-with-light-sidebar ' : '') +
					(appSidebarMinify ? 'app-sidebar-minified ' : '') + 
					(appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') + 
					(appTopMenu ? 'app-with-top-menu ' : '') + 
					(appContentFullHeight ? 'app-content-full-height ' : '') + 
					(appSidebarTwo ? 'app-with-two-sidebar ' : '') + 
					(appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') + 
					(appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') + 
					(hasScroll ? 'has-scroll ' : '')
				}>
					{!appHeaderNone && (<Header />)}
					{!appSidebarNone && (<Sidebar />)}
					{appSidebarTwo && (<SidebarRight />)}
					{appTopMenu && (<TopMenu />)}
					{!appContentNone && (<Content />)}
					<ThemePanel />
				</div>
			</AppSettings.Provider>
		)
			}
			
	}
	return (
		<div>
			{navigateViews()}
		</div>
		
	)
}

export default App;