// Services for get leave request
export const getLeaveRequest = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveInfo(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

// Services for get leave request by id
export const getLeaveRequestByID = async (leaverequest_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    // console.log(      "loggedin_by :",      loggedin_by,      "leaverequest_id :",      leaverequest_id    );
    const data = await LRAapp.functions.getLeaveInfoByID(
      leaverequest_id,
      loggedin_by
    );

    if (data !== undefined) {
      // console.log("users" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for create leave request
export const createLeaveRequest = async (
  leavetype_id,
  requestto_id,
  from_date,
  to_date,
  total_days,
  leave_description,
  settings_id,
  leavestatus_id,
  LRAapp
) => {
  try {
    /*    const user = JSON.parse(userpayload);*/
    //temp

    var loggedinby = LRAapp.id;
    const userdata = {
      leavetype_id: leavetype_id,
      requestto_id: requestto_id,
      from_date: from_date,
      to_date: to_date,
      total_days: total_days,
      leave_description: leave_description,
      settings_id,
      leavestatus_id,
      requestby_id: loggedinby,
    };
    //console.log("userdata",userdata);
    const data = await LRAapp.functions.insertLeaveInfo(
      JSON.stringify(userdata),
      loggedinby
    );

    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("our error" + error);
    return error;
  }
};

export const updateLeaveRequestByID = async (
  leaveapproval_id,
  leavetype_id,
  leave_description,
  total_days,
  from_date,
  to_date,
  requestto_id,
  requestby_id,
  leavestatus_id,

  jackapp
) => {
  //console.log("updateLeaveApprovalByID",updateLeaveApprovalByID);
  try {
    var loggedinby = jackapp.id;
    const Leavedata = {
      _id: leaveapproval_id,
      leavetype_id: leavetype_id,
      leave_description: leave_description,
      total_days: total_days,
      from_date: from_date,
      to_date: to_date,
      requestto_id: requestto_id,
      requestby_id: requestby_id,
      leavestatus_id: leavestatus_id,
    };
    const data = await jackapp.functions.updateLeaveInfoByID(
      JSON.stringify(Leavedata),
      loggedinby
    );
    //console.log("status: success",Holidaydata,loggedinby);
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for delete leave request by id
export const deleteLeaveRequestByID = async (keyid, LRAapp) => {
  try {
    var loggedinby = LRAapp.id;
    const data = await LRAapp.functions.deleteLeaveInfoByID(keyid, loggedinby);
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    console.log("error:" + error);
    return error;
  }
};


// Services for get leave request
export const getLeaveInfoByHome = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveInfoByHome(loggedin_by);
    if (data !== undefined) {
      //console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};