import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";

import { ButtonGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";

import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getAllRoleGroups } from "../../../services/rolegroupService";
import GlobalListTable from '../../../components/globalTable/gListTable';
//import SweetAlert from 'react-bootstrap-sweetalert';

const RoleGroups = (props) => {
  //context variables
  const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [rolegroups, setRoleGroups] = useState([]);
  const [tabRoleGroupsInfo, setRoleGroupsInfo] = useState(true);
  //table functions
  const data = useMemo(() => [...rolegroups], [rolegroups]);
  const rolesColumns = useMemo(
    () => [
      {
        Header: "ID",accessor: '_id',sortable:true,width: '20%'
       },

          {
           Header: "Organization Name",accessor: 'organization_name',sortable:true,width: '20%'
          },
      //     {
      //       Header: "Customer Name",accessor: 'customer_name',sortable:true,width: '30%'
      //  },
		  {
           Header: "Group Name",accessor: 'group_short_name',sortable:true,width: '30%'
          },
		
		  {
            Header: "Created On",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          },
          {
            Header: "Active",
							  accessor: 'is_active',
							  sortable: true,width:  '10%',
                Cell: ({ value }) => {
                  return (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                      {value === "1" ? "Yes" : "No"}
                    </span>
                  );
                },
          }
    ],
    []
  ); 
  
 
   const hiddenRoleGroupsColumns = [
    "_id",
    "activity_history",
    "organization_id",
    "group_id",
  ];
    //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setRoleGroupsInfo(true);
        return;
    }
  };

  useEffect(() => {
    //internal functions
    async function loadRoleGroups(userInfo) {
      
      if (rolegroups.length === 0)
        getAllRoleGroups(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;            
            setRoleGroups(JSON.parse(response));
          }
        });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadRoleGroups(jackapp.currentUser);
  }, [jackapp.currentUser]);

  return (
    <div>
      <div className="row">
        <div className="fs-3 mb-1">
          <ol className="breadcrumb float-xl-start">
            <li className="breadcrumb-item">
              <Link to="/user/userpolicies">User Policies</Link>
            </li>
            <li className="breadcrumb-item active bold">List User Policies</li>
          </ol>
        </div>

        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
                <div>
                  <ul className="nav nav-tabs nav-tabs-inverse">
                    <li className="nav-item" key="info">
                      <Link
                        to="/user/userpolicies"
                        onClick={(e) => showTab(e, "info")}
                        className={
                          "nav-link " + (tabRoleGroupsInfo ? "active " : "")
                        }
                      >
                        All
                      </Link>
                    </li>
                  </ul>
                </div>
                <ButtonGroup className="ms-auto my-n1">
                  <Link
                    to="/user/adduserpolicy"
                    href="#"
                    className="btn btn-success btn-rounded px-4 rounded-pill"
                  >
                    <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
                    Add RoleGroup
                  </Link>
                </ButtonGroup>
              </div>
            </PanelHeader>
            <PanelBody>
            <GlobalListTable gcolumns={rolesColumns}  gdata={data!==undefined?JSON.stringify(data):""} 
        ghiddencolumns={hiddenRoleGroupsColumns}
        gkey={"rolegroup_id"} glink="/user/detailuserpolicy"
        gplaceholder={"Search User Policies.."}
        />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default RoleGroups;
