import React,{useState,useEffect} from 'react';
//import {useContext} from 'react';
import { Link} from 'react-router-dom';
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../components/panel/panel';
import { ButtonGroup,Button} from 'reactstrap';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {createRole} from "../../../services/roleService";
import SweetAlert from 'react-bootstrap-sweetalert';
import {isNumeric} from 'validator';
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from '../../../components/customstyles/react-select-styles';
const AddRole = (props) => {
//context variables
let navigate = useNavigate();
//const context = useContext(AppSettings);
const jackapp = useRealmApp();
//state variavles
const statuschecked =true;
const [isvaliddata, setisvaliddata] = useState(false);
const [isSaved, setisSaved] = useState(false);
const [tabRolesnfo, setRolesnfo] = useState(true);
const [roles, setRoles] = useState({
    role_short_name: '',
    role_description: '',
    weight:0,
    organization_id: '',
    activity_history: {
      activity_type: "",
      activity_by_id: "",
      activity_by_name: "",
      activity_by_email: "",
      activity_by_clientee: "",
      activity_on: "",
    },
  });
  const [errors, setErrors] = useState({
    role_short_name: '',
    role_description: '',
    weight:'',
    organization_id: '',
  });
  const [OrganizationList, setOrganizationList] = useState([]);
  
//save functions
const handleDropDownChange = selectedOption => {
    const { name, value } = selectedOption.target;
    setRoles({ ...roles, [name]: value });
    setErrors({ ...errors, [name]: ""});
    setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
 
    setRoles({ ...roles, [name]: value });
    handleFieldValidation(event);
   // console.log(roles);
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(validateForm()){
        if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
        saveRole(jackapp.currentUser);
    }
    async function saveRole(userInfo) 
        {
	
            createRole(roles.role_short_name,roles.role_description,roles.weight,roles.organization_id,userInfo)
            .then((response)=>{
                if(response!==undefined)
                {	//let data[] = response;
                    //	console.log("12" +response.map());
                    //setRoles(JSON.parse(response));
                    setisSaved(true);
                }

            });
		
		
	     }
	 
  }
  
//error handling functions
const validateForm = () => {
    try
    {
    let valid = false;
    valid= handleFieldRequiredValidation();
    Object.values(errors).forEach((val) => {
        if(val.length > 0) valid = false;
      })
   // console.log(valid);
    return valid;
}catch(error)
{return false;}
};
const handleFieldRequiredValidation=() =>
    {
        let iserror = '';
       
        setErrors({ ...errors, 
            weight:  (roles.weight === 0? iserror='Weight is required': errors.weight) ,
            role_short_name:  (roles.role_short_name === ''? iserror='Role Short Name is required': errors.role_short_name) ,
            organization_id:roles.organization_id === '' || roles.organization_id === ""? (iserror = "Organization is required"): errors.organization_id
                 });
           // console.log(iserror);
            return (iserror.length===0)
}
const handleFieldValidation=(event) =>
    {
        event.preventDefault();
        const { name, value } = event.target;
        let localerror ='';  
        switch (name) {
          case 'role_short_name': 
          localerror = (value.length < 3? 'Role Short Name must be at least 3 characters': '');
            break;
            case 'role_description': 
            localerror = (value.length > 255? 'Role Description must be less than 255 characters': '');
            break;
          case 'weight': 
          localerror = (value.length <= 0? 'Weight information is missing.': '');
          localerror = (!isNumeric(value)? 'Weight must contain only numbers.': '');
            break;
           case "organization_id":
           localerror = value.length === "" ? "Organization is missing." : "";
                break;
          default:
            break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
}
//Message functions
  const handleSweetAlert =  () => {
    setisSaved(false);

    navigate({
        pathname:  '/user/roles',
        // state: {
        //   response: messageFromServer 
        // } 
     });
    
}
const showTab = (e, tab) => {
	e.preventDefault();
	switch(tab){
		case 'info':
	setRolesnfo(true);
			return; 
}
};
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadOrganization(jackapp.currentUser);
      }
  
      async function loadOrganization(userInfo) {
        getAllOrganizationForLookup(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;
            let Organizationdata = JSON.parse(response);
            let options = Organizationdata.map(function (organization) {
              return { value: organization._id, label: organization.organization_name };
            })
            setOrganizationList(options);
            //console.log("Organization1" + response);
          }
        });
      }
	}, []);
    
	return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/Roles">Roles</Link>
          </li>
          <li className="breadcrumb-item active bold">Add Role</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/user/addrole"
                      onClick={(e) => showTab(e, "info")}
                      className={"nav-link " + (tabRolesnfo ? "active " : "")}
                    >
                      Role Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody>
              <fieldset>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <label className="form-label form-label ">
                      Role Short Name <label  className="text-danger">*</label>
                    </label>

                    <input
                      type="text"
                      name="role_short_name"
                      id="role_short_name"
                      className="form-control"
                      onChange={handleChange}
                      noValidate
                    />
                    {errors.role_short_name.length > 0 && (
                      <small
                        className="text-red"
                        htmlFor="role_short_name"
                      >
                        {errors.role_short_name}
                      </small>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <label className="form-label form-label">Description</label>

                    <input
                      type="text"
                      name="role_description"
                      id="role_description"
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.role_description.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="role_description"
                      >
                        {errors.role_description}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <label className="form-label form-label">Weight <label  className="text-danger">*</label></label>
                    <input
                      type="text"
                      name="weight"
                      id="weight"
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.weight.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="weight"
                      >
                        {errors.weight}
                      </small>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <label className="form-label form-label">Organization <label  className="text-danger">*</label></label>
                    <Select
                      name="organization_id"
                      options={OrganizationList}
                      isSearchable={true}
                      styles={styles}
                      onChange={(val) => {
                        handleDropDownChange({
                          target: { name: "organization_id", value: val.value },
                        });
                      }}
                    ></Select>
                    {errors.organization_id.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="organization_id"
                      >
                        {errors.organization_id}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <label className="form-label form-label">Active</label>
                    <div className="form-check form-switch ms-auto mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled="disabled"
                        name="isactive"
                        checked={statuschecked}
                        id="isactive"
                      />
                      <label className="form-check-label" htmlFor="isactive">
                        &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div>
                {isSaved && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="Role is added successfully.."
                    onConfirm={handleSweetAlert}
                  ></SweetAlert>
                )}
              </div>
            </PanelBody>
          </form>
        </Panel>
      </div>
    </div>
  );
}

export default AddRole;