import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getRoleGroupByID,
  deleteRoleGroupByID,
} from "../../../services/rolegroupService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllCustomerForLookup } from "../../../services/customerService";
import { getGroupsForLookup } from "../../../services/groupService";
import { getRolesForLookupByOrganization } from "../../../services/roleService";
import styles from "../../../components/customstyles/react-select-styles";
import ActivityHistory from "../../../components/activityHistory/activityHistory";

const DetailRolegroup = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  //state variabley

  const [rolegroups, setRoleGroups] = useState({
    _id: "",
    organization_id: "",
    customer_id: "",
    group_id: "",
    role_id: [],
    is_active: 1,
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });

  const jackapp = useRealmApp();
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [checked, setChecked] = useState([]);
  //const data = useMemo(() => [...role], [role]);
  const keyid = useLocation().state.rolegroup_id;
  const [tabRolegroupsinfo, setRolegroupsinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //functions

  const handleDelete = async (roleid) => {
    //console.log(roleid);
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteRolegroup(keyid, jackapp.currentUser);
    }
  };
  async function deleteRolegroup(roleid, userInfo) {
    deleteRoleGroupByID(roleid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/user/userpolicies",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setRolegroupsinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setRolegroupsinfo(false);
        setActivityinfo(true);
        return;
    }
  };

  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadRoleGroupByID(jackapp.currentUser);
    }

    async function loadRoleGroupByID(userInfo) {
      getRoleGroupByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          // console.log("response", JSON.parse(response));
          let rolegroups1 = JSON.parse(response);
          if (jackapp.currentUser !== null &&jackapp.currentUser !== undefined) {
            if (rolegroups1[0].organization_id !== "" || rolegroups1[0].organization_id !== "undefined")
            {
              loadGroups(jackapp.currentUser, rolegroups1[0].organization_id);
              loadRoles(jackapp.currentUser,rolegroups1[0].organization_id );
            }
          }
         
          rolegroups1.map((role1) => {
            setRoleGroups({ ...rolegroups, ...role1 }, () => {
              
            });
            setChecked(role1.role_id);
          });
        }
      });
    }

  

    async function loadRoles(userInfo,organization_id) {
      getRolesForLookupByOrganization(1, organization_id,userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let rolesdata = JSON.parse(response);
          let options = rolesdata.map(function (role) {
            return { value: role._id, label: role.role_short_name };
          });
          setRolesList(options);
          //console.log("role" + response);
        }
      });
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, [keyid, jackapp.currentUser]);
  // Return classes based on whether item is checked
  var isChecked = (item) => (checked.includes(item) ? true : false);

  async function loadGroups(userInfo, organization_id) {
    getGroupsForLookup(1, userInfo, organization_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let groupsdata = JSON.parse(response);
        let options = groupsdata.map(function (group) {
          return { value: group._id, label: group.group_short_name };
        });
        setGroupsList(options);
        //console.log("group" + response);
      }
    });
  }

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/userpolicies">User Policies</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail User Policy</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={
            "tab-pane fade " + (tabRolegroupsinfo ? "show active " : "")
          }
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabRolegroupsinfo ? "active " : "")
                          }
                        >
                          Rolegroup Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
              to={{pathname: "/user/edituserpolicy",}}
              state={{ "rolegroup_id": keyid }}
              className="btn btn-primary  me-2 "
              >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <fieldset>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <label className="form-label form-label">
                        Organization <label className="text-danger">*</label>
                      </label>
                      <Select
                        name="organization_id"
                        options={OrganizationList}
                        isSearchable={true}
                        styles={styles}
                        value={OrganizationList.filter(
                          (organization) =>
                            organization.value === rolegroups.organization_id
                        )}
                        isDisabled={true}
                      ></Select>
                    </div>
                    {/* <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <label className="form-label">
                        Customer <label className="text-danger">*</label>
                      </label>
                      <Select
                        name="customer_id"
                        id="customer_id"
                        options={customerList}
                        isSearchable={true}
                        styles={styles}
                        isDisabled={true}
                        value={customerList.filter(
                          (customer) =>
                            customer.value === rolegroups.customer_id
                        )}
                      ></Select>
                    </div> */}
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <label className="form-label form-label">
                        Group <label className="text-danger">*</label>
                      </label>
                      <Select
                        name="group_id"
                        options={groupsList}
                        isSearchable={true}
                        styles={styles}
                        value={groupsList.filter(
                          (group) => group.value === rolegroups.group_id
                        )}
                        isDisabled={false}
                      ></Select>
                    </div>
                  </div>
                  <div className="row">
                    <Panel>
                      <PanelHeader noButton={true} minButton={true}>
                        Roles List{" "}
                      </PanelHeader>
                      <PanelBody className="p-2 m-2">
                        <div className="row ">
                          <div className="col-sm-12 col-md-12 col-lg-12 ">
                            {rolesList.map((role, i) => (
                              <div
                                key={i}
                                className="form-check form-check-inline p-3 "
                              >
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value={role.value}
                                  checked={isChecked(role.value)}
                                ></input>
                                <label
                                  className="form-check-label align-middle"
                                  htmlFor="inlineCheckbox1"
                                >
                                  <span>{role.label}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </PanelBody>
                    </Panel>
                  </div>
                  {/* <div>
        {`Roles Selected are: ${checkedItems}`}
      </div>
 */}
                </fieldset>
              </PanelBody>
              {isDeleted && (
                <SweetAlert
                  style={{ color: "black" }}
                  warning
                  title=""
                  confirmBtnText="Yes"
                  confirmBtnBsStyle="danger"
                  cancelBtnText="No"
                  cancelBtnBsStyle="info"
                  showCancel
                  focusCancelBtn
                  onConfirm={handleDeleteSweetAlert}
                  onCancel={handleDeleteCancel}
                >
                  <h5> Do you wish to delete rolegroup?.</h5>
                </SweetAlert>
              )}
              {isDeletedConfirm && (
                <SweetAlert
                  success
                  style={{ color: "black" }}
                  title="Rolegroup is deleted successfully.."
                  onConfirm={handleConfirmDeleteSweetAlert}
                ></SweetAlert>
              )}
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabRolegroupsinfo ? "active " : "")
                          }
                        >
                          Rolegroup Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
              to={{pathname: "/user/edituserpolicy",}}
              state={{ "rolegroup_id": keyid }}
              className="btn btn-primary  me-2 "
              >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    rolegroups !== undefined
                      ? JSON.stringify(rolegroups.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRolegroup;
