// gTable.jsx

import React from "react";
import { useGlobalFilter,useTable, useSortBy, usePagination,useColumnOrder } from 'react-table'
import {GlobalSearch} from "../globalsearch/gsearch"
import { useNavigate } from "react-router-dom";
const GlobalListPrintTable = React.forwardRef(({ gcolumns, gdata ,ghiddencolumns,gkey,glink,gplaceholder},ref) =>{
    let navigate = useNavigate();
  // Use the useTable Hook to send the columns and data to build the table
  const getData = () => {
    if(gdata!==undefined)
    {
      var arr = [];
      var obj =JSON.parse(gdata);
      for(var i in obj)
      arr.push(obj[i]);
      return arr;}
return []

  }
  const data = React.useMemo(() => getData(), [gdata]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, 
    
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
	preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize ,globalFilter },
  } = useTable({ columns:gcolumns, data, 
	initialState: {hiddenColumns:ghiddencolumns, pageIndex: 0 } }, 
	useGlobalFilter,useSortBy, usePagination,useColumnOrder)
  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive mb-0" ref={ref}>
    <table className="table table-hover table-panel align-middle mb-3" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th  {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className="d-flex align-items-center" >
                  <span>{column.render('Header')}</span>
                </div>
              </th>
            ))}
          </tr>
        ))}
     </thead>
     <tbody {...getTableBodyProps()}>
        {page.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr className="highlight" style={{cursor: 'pointer'}} {...row.getRowProps({
                onClick: (e) => {
                  navigate({
                    pathname:  glink,
                    state: { [gkey]: row.allCells[0].value }
                   });
                } 
              })}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )}
        )}
     </tbody>
   </table>
 </div>
  );
})


export default  GlobalListPrintTable;