import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
import { Link} from 'react-router-dom';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {updateLeaveApprovalByID} from "../../../services/leave_approvalService";
import {getLeaveApprovalByID} from "../../../services/leave_approvalService"
import SweetAlert from "react-bootstrap-sweetalert";
import ActivityHistory from '../../../components/activityHistory/activityHistory';
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";
import { getLeaveStatusLookup } from "../../../services/leave_statusService";//import {useContext} from 'react';
import { getLeaveTypeLookup } from "../../../services/leavetypesService";
import { getAllUsersForLookup } from "../../../services/usersService";
import { getAvailableCasualLeaveByUserID, getAvailableSickLeaveByUserID } from "../../../services/leave_approvalService";
import { getLeaveTypeByCasualLeave, getLeaveTypeBySickLeave } from "../../../services/leave_approvalService";

const DetailApproval = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variable
  const [tabLeaveApprovalinfo, setLeaveApprovalinfo] = useState(true);
const [tabActivityinfo, setActivityinfo] = useState(false);
  const [leaveapproval, setLeaveApproval] = useState({
    holiday_id: '',
    realm_user_id:'',
    leavetype_id: "",
    leave_description: "",
    total_days: "",
    from_date:"",
    to_date:"",
    requestby_id:"",
    requestto_id:"",
    leavestatus_id:"",
    leave_reason:"",
    is_active: "",
      activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      }],
      
  });
  const [errors, setErrors] = useState({
    holiday_id: '',
    realm_user_id:'',
    leavetype_id: "",
    leave_description: "",
    total_days: "",
    from_date:"",
    to_date:"",
    requestby_id:"",
    requestto_id:"",
    leavestatus_id:"",
    leave_reason:"",

  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [leavestatusList, setleavestatusList] = useState([]);
  const [leavetypeList, setleavetypeList] = useState([]);
  const [requestbyList, setrequestbyList] = useState([]);
  const [requesttoList, setrequesttoList] = useState([]);

  const [casualleaveList, setcasualleaveList] = useState();
  const [sickleaveList, setsickleaveList] = useState();

 
  const keyid = useLocation().state.holiday_id;

  //savefunctions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setLeaveApproval({ ...leaveapproval, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };

  const handleChange = (event) => {
    let target = event.target;
    setLeaveApproval({ ...leaveapproval, [target.name]: target.value });
    handleFieldValidation(event);
    // console.log(leaveapproval);
  };
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        updateHoliday(jackapp.currentUser);
    }
    async function updateHoliday(userInfo) {
      updateLeaveApprovalByID(
        keyid,
        leaveapproval.leavetype_id,
        leaveapproval.leave_description,
        leaveapproval.total_days,
        leaveapproval.from_date,
        leaveapproval.to_date,
        leaveapproval.requestby_id,
        leaveapproval.leavestatus_id,
        leaveapproval.requestto_id,
        leaveapproval.leave_reason,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setHolidays(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
   // console.log("updateHoliday",updateHoliday);
  };
  
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      leavetype_id:
        leaveapproval.leavetype_id === ""
          ? (iserror = "Leave Approval Name is required")
          : errors.leavetype_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "leavetype_id":
        localerror =
          value.length < 3 ? "Leave Approval Name must be at least 3 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/listapproval",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const handleCancel = () => {
    navigate({
      pathname: "/lms/listapproval",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setLeaveApprovalinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setLeaveApprovalinfo(false);
            setActivityinfo(true);
          return;
          default:
            break;
  }
  };
  useEffect(() => {
  
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadLeaveApprovalByID(jackapp.currentUser);
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavestatus(jackapp.currentUser);
    }
   
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavetype(jackapp.currentUser);
    }    

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadrequestto(jackapp.currentUser);
      } 
     

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadrequestby(jackapp.currentUser);
      }

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      GetLeaveTypeBySickLeave(jackapp.currentUser);

      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        GetLeaveTypeByCasualLeave(jackapp.currentUser);

  
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        loadcasualleave(String(leaveapproval.requestby_id));}


        if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
          loadsickleave(String(leaveapproval.requestby_id));}
     
  }, []);
  loadsickleave(leaveapproval.requestby_id);
  loadcasualleave(leaveapproval.requestby_id);
  //internal functions
  async function loadLeaveApprovalByID(userInfo) {
    getLeaveApprovalByID(keyid, userInfo).then((response) => {
      if (response !== undefined) {
        //console.log(JSON.parse(response));
        let LeaveApproval = JSON.parse(response);
        LeaveApproval.map((leaveapproval) =>
          //console.log(leaveapproval._id)
          setLeaveApproval({ ...leaveapproval, _id: leaveapproval.leave_status })
        );
      }
    });
  }

  async function loadrequestby(userInfo) {
    getAllUsersForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let requestbydata = JSON.parse(response);
        let options = requestbydata.map(function (requestby) {
          return { value: requestby.realm_user_id, label: requestby.fullname };
        });
        setrequestbyList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadrequestto(userInfo) {
    getAllUsersForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let requesttodata = JSON.parse(response);
        let options = requesttodata.map(function (requestto) {
          return { value: requestto.realm_user_id, label: requestto.fullname };
        });
        setrequesttoList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadleavetype(userInfo) {
    getLeaveTypeLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavetypedata = JSON.parse(response);
        let options = leavetypedata.map(function (leavetype) {
          return { value: leavetype._id, label: leavetype.leave_name };
        });
        setleavetypeList(options);
        //console.log("Organization1" + response);
      }
    });
  }  

  async function loadleavestatus(userInfo) {
    getLeaveStatusLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavestatusdata = JSON.parse(response);
        let options = leavestatusdata.map(function (leavestatus) {
          return { value: leavestatus._id, label: leavestatus.status_name };
        });
        setleavestatusList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function GetLeaveTypeBySickLeave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getLeaveTypeBySickLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let sickleavedata = JSON.parse(response);
       // console.log(sickleavedata[0].no_of_days)
        setsickleaveList(sickleavedata[0].no_of_days);
        
      }
    });
  }

  async function GetLeaveTypeByCasualLeave(userInfo) {
    // console.log("userInfo : ", userInfo);
    getLeaveTypeByCasualLeave(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        let casualleavedata = JSON.parse(response);
       // console.log(casualleavedata[0].no_of_days)
        setcasualleaveList(casualleavedata[0].no_of_days);
        
      }
    });
  }

  
 
  async function loadsickleave(requestby_id) {
     //console.log("requestby_id : ", requestby_id);
    // console.log("requestby_id : ", requestby_id);
     await getAvailableSickLeaveByUserID(requestby_id,jackapp.currentUser).then((response) => {
      if (response !== undefined) {       
        let sickleavedata = JSON.parse(response);   
        setsickleaveList(sickleavedata[0].count);
       //console.log("sickleavedata[0].count", sickleavedata[0].count);
      }
    });
  }


  async function loadcasualleave(requestby_id,userInfo) {
   // console.log("requestby_id : ", requestby_id);
//console.log("requestby_id : ", requestby_id);
    await getAvailableCasualLeaveByUserID(requestby_id,jackapp.currentUser).then((response) => {
      if (response !== undefined) {     
        let casualleavedata = JSON.parse(response);
       // console.log("casualleavedata",casualleavedata)
               setcasualleaveList(casualleavedata[0].count);     
                
    
      }
    });
  }

  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/lms/listapproval">Leave Approval</Link>
        </li>
        <li className="breadcrumb-item active bold">Detail Leave Approval
        </li>
      </ol>
    </div>
    <div className="tab-content p-0">
      <div className={'tab-pane fade ' + (tabLeaveApprovalinfo ? 'show active ': '')}>
        <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
<div className="d-flex align-items-center">
<div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveApprovalinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>

<ButtonGroup className="ms-auto my-n1">
                    <Link
                      to={{ pathname: "/lms/editapproval" }}
                      state={{ holiday_id: keyid }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
</div>
</PanelHeader>
<form noValidate>
<PanelBody>
<div className="row">
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label ">
Leave Type
</label>
<Select
                                          name="leavetype_id"
                                          options={leavetypeList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={leavetypeList.filter(
                                            (customer) =>
                                              customer.value === leaveapproval.leavetype_id
                                          )}
                                          isDisabled={true}
                                        ></Select>


{errors.leavetype_id.length > 0 && (
<small
className="form-check-label text-red"
htmlFor="leavetype_id"
>
{errors.leavetype_id}
</small>
)}
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">From Date</label>
<input
type="text"
name="from_date"
id="from_date"
className="form-control"
value={leaveapproval.from_date}
onChange={handleChange}
noValidate
disabled
/>
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">To Date</label>
<input
type="text"
name="to_date"
id="to_date"
className="form-control"
value={leaveapproval.to_date}
onChange={handleChange}
noValidate
disabled
/>
</div>
<div className="col-sm-4 col-md-4 col-lg-8">
<label className="form-label form-label">Leave Description</label>

<input
type="text"
name="leave_description"
id="leave_description"
className="form-control"
value={leaveapproval.leave_description}
onChange={handleChange}
noValidate
disabled
/>
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">Total Days </label>
<input
type="text"
name="total_days"
id="total_days"
className="form-control"
value={leaveapproval.total_days}
onChange={handleChange}
noValidate
disabled
/>
</div>

<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">Request By</label>
 <Select
                                          name="requestby_id"
                                          options={requestbyList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={requestbyList.filter(
                                            (customer) =>
                                              customer.value === leaveapproval.requestby_id
                                          )}
                                          isDisabled={true}
                                        ></Select> 

<label className="form-check-label" htmlFor="requestby_id">
&nbsp;
</label>
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">Request To</label>
<Select
                                          name="requestto_id"
                                          options={requesttoList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={requesttoList.filter(
                                            (customer) =>
                                              customer.value === leaveapproval.requestto_id
                                          )}
                                          isDisabled={true}
                                        ></Select>

<label className="form-check-label" htmlFor="requestto_id">
&nbsp;
</label>
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">Leave Status</label>

                                        <Select
                                          name="leavestatus_id"
                                          options={leavestatusList}
                                          isSearchable={true}
                                          styles={styles}
                                          value={leavestatusList.filter(
                                            (customer) =>
                                              customer.value === leaveapproval.leavestatus_id
                                          )}
                                          isDisabled={true}
                                        ></Select>
{errors.leavestatus_id.length > 0 && (
<small
className="form-check-label text-red"
htmlFor="leavestatus_id"
>
{errors.leavestatus_id}
</small>
)}
</div>
<div className="col-sm-4 col-md-4 col-lg-12">
<label className="form-label form-label">Leave Status Comments</label>
<input
type="text"
name="leave_reason"
id="leave_reason"
className="form-control"
value={leaveapproval.leave_reason}
onChange={handleChange}
noValidate
disabled
/>
</div>

<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">User Available Sick Leave</label>
<input
type="text"
name="sick_leave"
id="sick_leave"
className="form-control"
value={sickleaveList}
disabled
/>
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">User Available Casual Leave</label>
<input
type="text"
name="casual_leave"
id="casual_leave"
className="form-control"
value={casualleaveList}
disabled
/>
</div>
</div>
<div className="row pb-2">
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">Active</label>
<div className="form-check form-switch ms-auto mb-0">
<input
type="checkbox"
className="form-check-input"
disabled="disabled"
name="is_active"
id="is_active"
checked={leaveapproval.is_active === "1" ? true : false}
/>
<label className="form-check-label" htmlFor="is_active">
&nbsp;
</label>
</div>
</div>
</div>
</PanelBody>
</form>
</Panel>
</div>
</div>
<div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
<div className="col-xl-12">
<Panel>
<PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveApprovalinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
                      to={{ pathname: "/lms/editapproval" }}
                      state={{ holiday_id: keyid }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>
                    

                   
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

          <PanelBody>
              <ActivityHistory activityhistory={leaveapproval!==undefined?JSON.stringify(leaveapproval.activity_history):""}/>
          </PanelBody>
          </Panel>
        </div>

      
      
      </div>
    </div>
              <div>
                {isSaved && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="LeaveApproval is updated successfully.."
                    onConfirm={handleSweetAlert}
                  ></SweetAlert>
                )}
              </div>
  </div>
  );
};

export default DetailApproval;
