/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";

import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup} from 'reactstrap';
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getAllGroups } from "../../../services/groupService";
import GlobalListTable from '../../../components/globalTable/gListTable';
//import SweetAlert from 'react-bootstrap-sweetalert';

const Groups = (props) => {
  //context variables
  const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [groups, setGroups] = useState([]);
  const [tabGroupInfo, setGroupinfo] = useState(true);
  //table functions
  const data = useMemo(() => [...groups], [groups]);
  const groupsColumns = useMemo(
    () => [
      {
        Header: "ID",accessor: '_id',sortable:true,width: '20%'
       },

          {
           Header: "Group ShortName",accessor: 'group_short_name',sortable:true,width: '20%'
          },
		  {
           Header: "Description",accessor: 'group_description',sortable:true,width: '30%'
          },
          {
            Header: "Organization Name",accessor: 'organization_name',sortable:true,width: '30%'
       },
       {
        Header: "Customer Name",accessor: 'customer_name',sortable:true,width: '30%'
   },
		  // {
      //      Header: "Facility",accessor: 'facility_name',sortable:true,width: '30%'
      // },
		  {
            Header: "Created On",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          },
          {
            Header: "Active",
							  accessor: 'is_active',
							  sortable: true,width:  '10%',
                Cell: ({ value }) => {
                  return (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                      {value === "1" ? "Yes" : "No"}
                    </span>
                  );
                },
          }
    ],
    []
  ); 
    
  const hiddenGroupsColumns = [
    "_id",
    "deleted_on",
    "organization_id",
    "deleted_by",
    "created_by",
    "last_modified_on",
    "last_modified_by",
    "facility_id",
    "modified_by",
    "modified_on",
  ];
  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   page,

  //   canPreviousPage,
  //   canNextPage,
  //   pageOptions,
  //   pageCount,
  //   gotoPage,
  //   nextPage,
  //   previousPage,
  //   setPageSize,
  //   preGlobalFilteredRows,
  //   setGlobalFilter,
  //   setColumnOrder,
  //   state: { pageIndex, pageSize, globalFilter },
  // } = useTable(
  //   {
  //     columns: groupsColumns,
  //     data,
  //     initialState: { hiddenColumns: hiddenGroupsColumns, pageIndex: 0 },
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination,
  //   useColumnOrder
  // );
  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
    setGroupinfo(true);
        return; 
  }
  };
  useEffect(() => {
    //internal functions
    async function loadGroups(userInfo) {
      if (groups.length === 0)
        getAllGroups(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;
            //	console.log("12" +response.map());
            setGroups(JSON.parse(response));
          }
        });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadGroups(jackapp.currentUser);
  }, [jackapp.currentUser]);

  return (
    <div>
    <div className="row">
      <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item"><Link to="/user/groups">Groups</Link></li>
        <li className="breadcrumb-item active bold">List Group</li>
      </ol>
      </div>
      
        <div className="col-xl-12">
    <Panel>
      <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
              <div >
              <ul className="nav nav-tabs nav-tabs-inverse">
                <li className="nav-item" key="info">
                <Link to="/settings/roles" onClick={(e) => showTab(e, 'info')} 
                className={'nav-link ' + (tabGroupInfo ? 'active ': '')}>All</Link></li>
              
              </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
              <Link to="/user/addgroup" href="#" className="btn btn-success btn-rounded px-4 rounded-pill">
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Group</Link>
                </ButtonGroup>
              </div>
      </PanelHeader>
      <PanelBody>
        <GlobalListTable gcolumns={groupsColumns}  gdata={data!==undefined?JSON.stringify(data):""} 
          ghiddencolumns={hiddenGroupsColumns}
          gkey={"group_id"} glink="/user/detailgroup"
          gplaceholder={"Search Groups.."}
          />
      </PanelBody>
          </Panel>
          </div>
  </div>
  </div>
  );
};

export default Groups;
