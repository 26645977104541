import React, { useContext, useState } from "react";
import { AppSettings } from './../../config/app-settings.js';
const ThemePanel = (props) => {

	const context = useContext(AppSettings);
	const [expand,setexpand] = useState(false);
	//const [darkMode,setdarkMode] = useState(false);
	const [theme, settheme] = useState(['red','pink','orange','yellow','lime','green','teal','cyan','blue','purple','indigo','dark']);

	// constructor(props) {
	// 	super(props);
		
	// 	this.state = {
	// 		expand: false,
	// 		theme: (localStorage && typeof localStorage.appTheme !== 'undefined') ?  localStorage.appTheme : 'teal',
	// 		darkMode: false
	// 	}
	//{ ['red','pink','orange','yellow','lime','green','teal','cyan','blue','purple','indigo','dark']}
	// }
	
	const handleDarkMode = (e) => {
		if (e.target.checked) {
			context.handleSetAppDarkMode(true);
		} else {
			context.handleSetAppDarkMode(false);
		}
	}
	
	const handleHeaderFixed = (e) => {
		if (e.target.checked) {
			context.handleSetAppHeaderFixed(true);
		} else {
			context.handleSetAppHeaderFixed(false);
		}
	}
	
	const handleSidebarFixed = (e) => {
		if (e.target.checked) {
			context.handleSetAppSidebarFixed(true);
		} else {
			context.handleSetAppSidebarFixed(false);
		}
	}
	
	const handleHeaderInverse = (e) => {
		if (e.target.checked) {
			context.handleSetAppHeaderInverse(true);
		} else {
			context.handleSetAppHeaderInverse(false);
		}
	}
	
	const handleSidebarGrid = (e) => {
		if (e.target.checked) {
			context.handleSetAppSidebarGrid(true);
		} else {
			context.handleSetAppSidebarGrid(false);
		}
	}
	
	const handleGradientEnabled = (e) => {
		if (e.target.checked) {
			context.handleSetAppGradientEnabled(true);
		} else {
			context.handleSetAppGradientEnabled(false);
		}
	}
	
	const toggleExpand = (e) => {
		e.preventDefault();
		setexpand(!expand);
	}
	
	const toggleTheme = (e, theme) => {
		e.preventDefault();
		context.handleSetAppTheme(theme);
		settheme(theme);
	
	}

		return (
			<AppSettings.Consumer>
				{({appDarkMode, appHeaderFixed, appHeaderInverse, appSidebarFixed, appSidebarGrid, appGradientEnabled}) => (
					<div className={'theme-panel ' + (expand ? 'active' : '')}>
					 <a href="#0" onClick={ e => toggleExpand(e) } className="theme-collapse-btn"><i className="fa fa-cog"></i></a>
						<div className="theme-panel-content" data-scrollbar="true" data-height="100%">
							<h5>App Settings</h5>
				
				
							<div className="theme-panel-divider"></div>
				
							<div className="row mt-10px">
								<div className="col-8 control-label text-dark fw-bold">
									<div>Dark Mode <span className="badge bg-primary ms-1 py-2px position-relative" style={{top: '-1px'}}>NEW</span></div>
									<div className="lh-14">
										<small className="text-dark opacity-50">
											Adjust the appearance to reduce glare and give your eyes a break.
										</small>
									</div>
								</div>
								<div className="col-4 d-flex">
									<div className="form-check form-switch ms-auto mb-0">
										<input type="checkbox" className="form-check-input" name="app-theme-dark-mode" onChange={handleDarkMode} id="appThemeDarkMode" checked={appDarkMode} value="1" />
										<label className="form-check-label" htmlFor="appThemeDarkMode">&nbsp;</label>
									</div>
								</div>
							</div>
				
							</div> 
					</div>
				)}
			</AppSettings.Consumer>
		);

};

export default ThemePanel;
