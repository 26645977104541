import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "reactstrap";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";

//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { createFacility } from "../../../services/facilityService";
import SweetAlert from "react-bootstrap-sweetalert";
//import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllStateForLookupByCountry } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllCustomerForLookup } from "../../../services/customerService";
import styles from "../../../components/customstyles/react-select-styles";

const AddFacility = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const statuschecked = true;
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [tabFacilitynfo, setFacilitynfo] = useState(true);
  const [facility, setFacility] = useState({
    facility_code: "",
    facility_name: "",
    type: "",
    title: "",
    street: "",
    suite: "",
    country_id: "",
    state_id: "",
    city: "",
    post_code: "",
    phone: "",
    email: "",
    fax: "",
    notes: "",
    customer_id: "",
    organization_id: "",
    is_active: "",
    website: "",
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    activity_history: {
      activity_type: "",
      activity_by_id: "",
      activity_by_name: "",
      activity_by_email: "",
      activity_by_clientee: "",
      activity_on: "",
    },
  });
  const [errors, setErrors] = useState({
    facility_code: "",
    facility_name: "",
    type: "",
    title: "",
    street: "",
    suite: "",
    country_id: "",
    state_id: "",
    city: "",
    post_code: "",
    phone: "",
    email: "",
    fax: "",
    notes: "",
    customer_id: "",
    organization_id: "",
    is_active: "",
    website: "",
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
  });
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  //const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
 
  //save functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setFacility({ ...facility, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    if(name==="country_id")
    loadStates("0",value);
    setisvaliddata(true);
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFacility({ ...facility, [name]: value });
    handleFieldValidation(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        saveFacility(jackapp.currentUser);
    }
    async function saveFacility(userInfo) {
      console.log("called saveFacility");
      createFacility(
        facility.facility_code,
        facility.facility_name,
        facility.notes,
        facility.customer_id,
        facility.organization_id,
        facility.street,
        facility.suite,
        facility.country_id,
        facility.state_id,
        facility.city,
        facility.post_code,
        facility.phone,
        facility.email,
        facility.fax,
        facility.website,
        facility.linkedin,
        facility.facebook,
        facility.twitter,
        facility.instagram,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //  let data[] = response;
          //console.log(JSON.stringify(response));
          // setFacility(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      facility_code:
        facility.facility_code === ""
          ? (iserror = "Facility code is required")
          : errors.facility_code,
      facility_name:
        facility.facility_name === ""
          ? (iserror = "Facility name is required")
          : errors.facility_name,
      // organization_id:
      //   facility.organization_id === "" || facility.organization_id === ""
      //     ? (iserror = "Organization is required")
      //     : errors.organization_id,
      customer_id:
        facility.customer_id === "" || facility.customer_id === ""
          ? (iserror = "customer is required")
          : errors.customer_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "facility_name":
        localerror =
          value.length < 3 ? "Facility Name must be at least 3 characters" : "";
        break;
      // case "organization_id":
      //   localerror = value.length === "" ? "Organization is missing." : "";
      //   break;
      case "customer_id":
        localerror = value.length === "" ? "customer is missing." : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setFacilitynfo(true);
        return;
        default:
          return;

    }
  };

  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/settings/facility",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  async function loadStates(itemid,country_id) {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      
      getAllStateForLookupByCountry(1, jackapp.currentUser,country_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let statedata = JSON.parse(response);
        let options = statedata.map(function (state) {
          return { value: state.id, label: state.name };
        });
        setStateList(options);
        //console.log("state1" + response);
      }
    });
  
    }
  }
  useEffect(() => {
  
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {
            return { value: country.id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }
    // if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //   loadOrganization(jackapp.currentUser);
    // }

    // async function loadOrganization(userInfo) {
    //   getAllOrganizationForLookup(1, userInfo).then((response) => {
    //     if (response !== undefined) {
    //       //let data[] = response;
    //       let Organizationdata = JSON.parse(response);
    //       let options = Organizationdata.map(function (organization) {
    //         return { value: organization._id, label: organization.organization_name };
    //       });
    //       setOrganizationList(options);
    //       //console.log("Organization1" + response);
    //     }
    //   });
    // }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, [jackapp.currentUser]);

  return (
      <div className="row">
        <div className="fs-3 mb-1">
          <ol className="breadcrumb float-xl-start">
            <li className="breadcrumb-item">
              <Link to="/settings/facility">Facilities</Link>
            </li>
            <li className="breadcrumb-item active bold">Add Facility</li>
          </ol>
        </div>

        <div className="col-xl-12">
          <Panel>
           
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/settings/AddFacility"
                      onClick={(e) => showTab(e, "info")}
                      className={"nav-link " + (tabFacilitynfo ? "active " : "")}
                    >
                      Facility Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
              <form  noValidate>
              <PanelBody className="p-1 m-1">
                <div className="tab-content p-0">
                  <div
                    className={
                      "tab-pane fade " + (tabFacilitynfo ? "show active " : "")
                    }
                  >
                    <div className="row">
                      <div className="col-xl-12 col ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Basic Info
                          </PanelHeader>
                          <PanelBody>
                            <div className="row mb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label ">
                                  Facility Code <label className="text-danger">*</label>
                                </label>

                                <input
                                  type="text"
                                  name="facility_code"
                                  id="facility_code"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.facility_code.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="facility_code"
                                  >
                                    {errors.facility_code}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Facility Name <label className="text-danger">*</label>
                                </label>

                                <input
                                  type="text"
                                  name="facility_name"
                                  id="facility_name"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                                {errors.facility_name.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="facility_name"
                                  >
                                    {errors.facility_name}
                                  </small>
                                )}
                              </div>
                              {/* <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Organization *</label>
                                <Select
                                  name="organization_id"
                                  options={OrganizationList}
                                  isSearchable={true}
                                  styles={styles}
                                  onChange={(val) => {
                                    handleDropDownChange({
                                      target: {
                                        name: "organization_id",
                                        value: val.value,
                                      },
                                    });
                                  }}
                                ></Select>
                                {errors.organization_id.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="organization_id"
                                  >
                                    {errors.organization_id}
                                  </small>
                                )}
                              </div> */}
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Customer <label className="text-danger">*</label>
                                </label>
                                <Select
                                  name="customer_id"
                                  id="customer_id"
                                  options={customerList}
                                  isSearchable={true}
                                  styles={styles}
                                  onChange={(val) => {
                                    handleDropDownChange({
                                      target: {
                                        name: "customer_id",
                                        value: val.value,
                                      },
                                    });
                                  }}
                                ></Select>
                                {errors.customer_id.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="customer_id"
                                  >
                                    {errors.customer_id}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 ">
                                  <label className="form-label">Website</label>
                                  <input
                                    type="text"
                                    name="website"
                                    id="website"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.website.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="website"
                                    >
                                      {errors.website}
                                    </small>
                                  )}
                                </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-sm-12 col-md-9 col-lg-9 ">
                                <label className="form-label">Notes</label>

                                <input
                                  type="text"
                                  name="notes"
                                  id="notes"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                                {errors.notes.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="notes"
                                  >
                                    {errors.notes}
                                  </small>
                                )}
                              </div>
                             
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label">Active</label>
                                  <div className="form-check form-switch ms-auto mb-0">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      disabled="disabled"
                                      name="isactive"
                                      checked={statuschecked}
                                      id="isactive"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isactive"
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="row p-0 m-0">
                        <div className=" col-xl-8 col ms-0">
                          <Panel>
                            <PanelHeader noButton={true} minButton={true}>
                              Contact Info{" "}
                            </PanelHeader>
                            <PanelBody className="p-0 m-0">
                              <div className="row p-1">
                                <div className="col-sm-12 col-md-6 col-lg-6 ">
                                  <label className="form-label">Street</label>

                                  <input
                                    type="text"
                                    name="street"
                                    id="street"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.street.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="street"
                                    >
                                      {errors.street}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 ">
                                  <label className="form-label">Suite</label>

                                  <input
                                    type="text"
                                    name="suite"
                                    id="suite"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.suite.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="suite"
                                    >
                                      {errors.suite}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="row pb-1">
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label form-label">
                                    Country{" "}
                                  </label>
                                  <Select
                                    name="country_id"
                                    options={countryList}
                                    isSearchable={true}
                                    styles={styles}
                                    onChange={(val) => {
                                      handleDropDownChange({
                                        target: {
                                          name: "country_id",
                                          value: val.value,
                                        },
                                      });
                                    }}
                                  ></Select>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label form-label">
                                    State{" "}
                                  </label>
                                  <Select
                                    name="state_id"
                                    options={stateList}
                                    isSearchable={true}
                                    styles={styles}
                                    onChange={(val) => {
                                      handleDropDownChange({
                                        target: {
                                          name: "state_id",
                                          value: val.value,
                                        },
                                      });
                                    }}
                                  ></Select>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label">City</label>

                                  <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.city.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="city"
                                    >
                                      {errors.city}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label">
                                    Postal Code
                                  </label>

                                  <input
                                    type="text"
                                    name="post_code"
                                    id="post_code"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.post_code.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="post_code"
                                    >
                                      {errors.post_code}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="row pb-1">
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label">Phone</label>

                                  <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.phone.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="phone"
                                    >
                                      {errors.phone}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                  <label className="form-label">Fax</label>

                                  <input
                                    type="text"
                                    name="fax"
                                    id="fax"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.fax.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="fax"
                                    >
                                      {errors.fax}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                  <label className="form-label">Email</label>

                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.email.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="facility_name"
                                    >
                                      {errors.email}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="row pb-1">
                              
                                
                              </div>
                            </PanelBody>
                          </Panel>
                        </div>
                        <div className="col-xl-4  col  ms-0">
                          <Panel>
                            <PanelHeader noButton={true} minButton={true}>
                              Social Media handles{" "}
                            </PanelHeader>
                            <PanelBody className="p-0 m-0">
                              <div className="row p-1 ">
                                <div className="col-12">
                                  <label className="form-label">LinkedIn</label>
                                  <input
                                    type="text"
                                    name="linkedin"
                                    id="linkedin"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.linkedin.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="linkedin"
                                    >
                                      {errors.linkedin}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="row pb-1">
                                <div className="col-12">
                                  <label className="form-label">Facebook</label>
                                  <input
                                    type="text"
                                    name="facebook"
                                    id="facebook"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.facebook.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="facebook"
                                    >
                                      {errors.facebook}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="row pb-1">
                                <div className="col-6">
                                  <label className="form-label">Twitter</label>

                                  <input
                                    type="text"
                                    name="twitter"
                                    id="twitter"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.twitter.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="twitter"
                                    >
                                      {errors.twitter}
                                    </small>
                                  )}
                                </div>
                                <div className="col-6">
                                  <label className="form-label">
                                    Instagram
                                  </label>

                                  <input
                                    type="text"
                                    name="instagram"
                                    id="instagram"
                                    className="form-control"
                                    onChange={handleChange}
                                  />
                                  {errors.instagram.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="instagram"
                                    >
                                      {errors.instagram}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </PanelBody>
                          </Panel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {isSaved && (
                    <SweetAlert
                      success
                      style={{ color: "black" }}
                      title="Facility is added successfully.."
                      onConfirm={handleSweetAlert}
                    ></SweetAlert>
                  )}
                </div>
              </PanelBody>
            </form>
          </Panel>
        </div>
      </div>
  );
};

export default AddFacility;
