import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";
import { ButtonGroup, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
//const context = useContext(AppSettings);
import GlobalSimpleTable from "../../../components/globalTable/gSimpleTable";
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getCustomerUserByID,
  deleteCustomerUserByID,
} from "../../../services/customerUsersService";
import SweetAlert from "react-bootstrap-sweetalert";

import { getAllStateForLookup } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from "../../../components/customstyles/react-select-styles";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
import { GlobalSearch } from "../../../components/globalsearch/gsearch";
const DetailCustomerUser = (props) => {
  //context variables
  let navigate = useNavigate();
  const statuschecked = true;
  const context = useContext(AppSettings);
  const [usergroupList, setUserGroupList] = useState([]);
  //state variabley
  const [user, setUser] = useState({
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
    photo: "",
    password: "",
    confirm_password: "",
    title: "",
    department: "",
    organization_id: "",
    is_active: "",
    address: [
      {
        id: "0",
        type: "",
        title: "",
        street: "",
        suite: "",
        country: "",
        states: "",
        city: "",
        post_code: "",
        m_phone: "",
        o_phone: "",
        alternateemail: "",
        fax: "",
      },
    ],
    social_media: [
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
    ],
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
    user_group_roles: [
      {
        id: "",
        organization_id: "",
        facility_id: "",
        customer_id: "",
        group_id: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    user_id: "",
    organization_id: "",
    is_active: "",
  });
  const jackapp = useRealmApp();
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [isstatuschecked, setisstatuschecked] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [tabUserinfo, setUserinfo] = useState(true);
  const [tabContactinfo, setContactinfo] = useState(false);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //const data = useMemo(() => [...user], [user]);
  const keyid = useLocation().state.user_id;

  //table functions
  const data = useMemo(
    () => [...user.user_group_roles],
    [user.user_group_roles]
  );
  const usergroupListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Organization",
        accessor: "organization_name",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Group",
        accessor: "group_name",
        sortable: false,
        width: "30%",
      },
    ],
    []
  );

  const hiddenusergroupListColumns = [
    "_id",
    "facility_id",
    "organization_id",
    "customer_id",
    "group_id",
  ];

  //functions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    // setUser({ ...user, [name]: value });
    const path = name.split(".");
    const finalProp = path.pop();
    const newuser = { ...user };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] = value;
    setUser(newuser);
    // setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    // let target = event.target;
    // setUser({ ...user, [target.name]: target.value });
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...user };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setUser(newuser);
    // handleFieldValidation(event);
    // setisvaliddata(true);
    // console.log(user);
  };
  function handleAddressArrayObjectChange(e, itemId, property) {
    const value = e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...user.address];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex((item) => item.id == itemId);
    // -1 check to see if we found that object in social_media
    if (index != -1) {
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value, //here property can be "link"
      };
    }
    setUser({ ...user, address: temparrayprops });
    //handleFieldValidation(e);
    //setisvaliddata(true);
  }
  const handleIsStatusChanged = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (event.target.checked) {
      setisstatuschecked(true);
    } else {
      setisstatuschecked(false);
    }
    setUser({ ...user, [name]: event.target.checked });
  };
  const handleDelete = async (userid) => {
    
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteuser(keyid, jackapp.currentUser);
    }
  };
  async function deleteuser(userid, userInfo) {
    deleteCustomerUserByID(userid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/user/customerusers",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setUserinfo(true);
        setContactinfo(false);
        setActivityinfo(false);
        return;
      case "contactinfo":
        setUserinfo(false);
        setContactinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setUserinfo(false);
        setContactinfo(false);
        setActivityinfo(true);
        return;
    }
  };
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadStates(jackapp.currentUser);
    }

    async function loadStates(userInfo) {
      getAllStateForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let statedata = JSON.parse(response);
          let options = statedata.map(function (state) {
            return { value: state._id, label: state.name };
          });
          setStateList(options);
          //console.log("state1" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {
            return { value: country._id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, []);
  const handleOrganizationGroups = async (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    //internal functions
    async function loaduserByID(userInfo) {
      getCustomerUserByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let users = JSON.parse(response);
          users.map((user) => {
            //console.log("user._id", user._id);
            setUser({ ...user, _id: user._id });
          });
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loaduserByID(jackapp.currentUser);
      
    }
  }, [keyid, jackapp.currentUser]);

  useEffect(() => {
    if (user.first_name != "") setUserGroupList(user.usergroupList);
  }, [keyid, jackapp.currentUser]);

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/customerusers">Customer Users</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail user</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div className={"tab-pane fade " + (tabUserinfo ? "show active " : "")}>
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
                    to={{pathname: "/user/editcustomeruser",}}
                    state={{ "user_id": keyid }}                     
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2 border-1">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-10  col ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Basic Info
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  First Name*
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  className="form-control"
                                  value={user.first_name}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Last Name*
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  className="form-control"
                                  value={user.last_name}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Email *
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  value={user.email}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Employee Number
                                </label>
                                <input
                                  type="text"
                                  name="employee_number"
                                  id="employee_number"
                                  className="form-control"
                                  value={user.employee_number}
                                />
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Title
                                </label>
                                <input
                                  type="title"
                                  name="title"
                                  id="title"
                                  className="form-control"
                                  value={user.title}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Department
                                </label>
                                <input
                                  type="department"
                                  name="department"
                                  id="department"
                                  className="form-control"
                                  value={user.department}
                                />
                              </div>
                            </div>
                            <div className="row mb-1"></div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-2  col  ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Photo{" "}
                          </PanelHeader>
                          <PanelBody className="">
                            <div className="col-sm-10 col-md-10 col-lg-10 m-2 text-center">
                              <img
                                src={user.photo}
                                alt="No Image"
                                className="rounded-circle mx-auto d-block mw-100"
                                width="100"
                                height="100"
                              />
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className=" col-xl-12  col ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Group Info{" "}
                          </PanelHeader>
                          <PanelBody>
                            <div className="col-xl-12">
                              <GlobalSimpleTable
                                gcolumns={usergroupListColumns}
                                gdata={
                                  data !== undefined ? JSON.stringify(data) : ""
                                }
                                ghiddencolumns={hiddenusergroupListColumns}
                                gkey={"user_id"}
                                glink="/user/detailcustomeruser"
                                gfunction={handleOrganizationGroups}
                                gplaceholder={""}
                              />
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabContactinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
                    to={{pathname: "/user/editcustomeruser",}}
                    state={{ "user_id": keyid }}                     
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-8 colcol-xl-8 col ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Address Info{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Street</label>

                                <input
                                  type="text"
                                  name="address_0_street"
                                  id="address_0_street"
                                  className="form-control"
                                  value={user.address[0].street}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Suite</label>

                                <input
                                  type="text"
                                  name="suite"
                                  id="suite"
                                  className="form-control"
                                  value={user.address[0].suite}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  Country{" "}
                                </label>
                                <Select
                                  name="country_id"
                                  options={countryList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={countryList.filter(
                                    (country) =>
                                      country.value === user.address[0].country
                                  )}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label form-label">
                                  State{" "}
                                </label>
                                <Select
                                  name="state_id"
                                  options={stateList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={stateList.filter(
                                    (state) =>
                                      state.value === user.address[0].states
                                  )}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">City</label>

                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="form-control"
                                  value={user.address[0].city}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Postal Code
                                </label>

                                <input
                                  type="text"
                                  name="post_code"
                                  id="post_code"
                                  className="form-control"
                                  value={user.address[0].post_code}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Mobile Phone
                                </label>

                                <input
                                  type="text"
                                  name="m_phone"
                                  id="m_phone"
                                  className="form-control"
                                  value={user.address[0].m_phone}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">
                                  Office Phone
                                </label>

                                <input
                                  type="text"
                                  name="o_phone"
                                  id="o_phone"
                                  className="form-control"
                                  value={user.address[0].o_phone}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">Fax</label>

                                <input
                                  type="text"
                                  name="fax"
                                  id="fax"
                                  className="form-control"
                                  value={user.address[0].fax}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">
                                  Alternate Email
                                </label>

                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  value={user.address[0].alternateemail}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-4  col  ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Social Media handles{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1 ">
                              <div className="col-12">
                                <label className="form-label">Website</label>
                                <input
                                  type="text"
                                  name="website"
                                  id="website"
                                  className="form-control"
                                  value={user.social_media[0].link}
                                />
                              </div>
                            </div>
                            <div className="row p-1 ">
                              <div className="col-12">
                                <label className="form-label">LinkedIn</label>
                                <input
                                  type="text"
                                  name="linkedin"
                                  id="linkedin"
                                  className="form-control"
                                  value={user.social_media[1].link}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-12">
                                <label className="form-label">Facebook</label>
                                <input
                                  type="text"
                                  name="facebook"
                                  id="facebook"
                                  className="form-control"
                                  value={user.social_media[2].link}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-6">
                                <label className="form-label">Twitter</label>

                                <input
                                  type="text"
                                  name="twitter"
                                  id="twitter"
                                  className="form-control"
                                  value={user.social_media[3].link}
                                />
                              </div>
                              <div className="col-6">
                                <label className="form-label">Instagram</label>

                                <input
                                  type="text"
                                  name="instagram"
                                  id="instagram"
                                  className="form-control"
                                  value={user.social_media[4].link}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Link
                      to={{
                        pathname: "/user/edituser",
                        state: { user_id: keyid },
                      }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    user !== undefined
                      ? JSON.stringify(user.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isDeleted && (
          <SweetAlert
            style={{ color: "black" }}
            warning
            title=""
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnText="No"
            cancelBtnBsStyle="info"
            showCancel
            focusCancelBtn
            onConfirm={handleDeleteSweetAlert}
            onCancel={handleDeleteCancel}
          >
            <h5>
              {" "}
              Do you wish to delete User[
              {user.first_name + " " + user.last_name}]?.
            </h5>
          </SweetAlert>
        )}
        {isDeletedConfirm && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="User is deleted successfully.."
            onConfirm={handleConfirmDeleteSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default DetailCustomerUser;
