import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { GlobalSearch } from "../../../components/globalsearch/gsearch";
import { ButtonGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getUserByID, updateUserByID } from "../../../services/usersService";
import FileBase from "react-file-base64";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";
import { getAllStateForLookupByCountry } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getGroupsForLookup } from "../../../services/groupService";
import { getAllFacilityForLookup } from "../../../services/facilityService";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
import GlobalSimpleTable from "../../../components/globalTable/gSimpleTable";
import { getAllGenderForLookup } from "../../../services/usersService";
import { getEmployeeRoleLookup } from "../../../services/usersService";
import { getRolePermissionByUserID } from "../../../services/employeeroleService";
//import {useContext} from 'react';
const EditUser = (props) => {
  //context variables
  let navigate = useNavigate();
  const context = useContext(AppSettings);
    var defaultpassword = "!@#$%^&*"
  const jackapp = useRealmApp();
  const [genderlist, setGenderList] = useState([]);
  const [employeerolelist, setemployeeroleList] = useState([]);
  //state variable
  const [user, setUser] = useState({
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
password:defaultpassword,
confirm_password: defaultpassword,
    gender_id: "",
    dob: "",
    doj: "",
    employee_number: "",
    emergency_contact: "",
    employeerole_id: "",
    pan_number: "",
    aadhar_number: "",
    photo: "",
    designation: "",
    department: "",
    is_active: "",
    address: [
      {
        id: "0",
        type: "",
        title: "",
        street: "",
        suite: "",
        country: "",
        states: "",
        city: "",
        post_code: "",
        m_phone: "",
        o_phone: "",
        alternateemail: "",
        fax: "",
      },
    ],
    social_media: [
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
      { id: "", type: "", title: "", link: "" },
    ],
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
    user_group_roles: [
      {
        id: "",
        organization_id: "",
        facility_id: "",
        group_id: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password:"",
    confirm_password:"",
    employeerole_id: "",
  });

  const [grouplistrow, setGroupListRow] = useState({
    id: "0",
    organization_id: "",
    facility_id: "",
    group_id: "",
  });
  const [grouplisterrors, setGroupListErrors] = useState({
    organization_id: "",
    facility_id: "",
    group_id: "",
  });

  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [isstatuschecked, setisstatuschecked] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupMainList, setGroupMainList] = useState([]);
  const [usergroupList, setUserGroupList] = useState([]);
  const [tabUserinfo, setUserinfo] = useState(true);
  const [tabContactinfo, setContactinfo] = useState(false);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  const keyid = useLocation().state.user_id;
  const statuschecked = true;
  const [grouplistmandatory, setGrouplistmandatory] = useState("");
  const [emproleid, setEmpRoleID] = useState();

  //table functions
  const data = useMemo(() => [...usergroupList], [usergroupList]);
  const usergroupListColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Organization",
        accessor: "organization_name",
        sortable: false,
        width: "20%",
      },
      {
        Header: "Group",
        accessor: "group_name",
        sortable: false,
        width: "30%",
      },
    ],
    []
  );

  const hiddenusergroupListColumns = [
    "_id",
    "facility_id",
    "organization_id",
    "group_id",
  ];

  //savefunctions

  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...user };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setUser(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
    // console.log(user);
  };

  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;

    // setFacility({ ...facility, [name]: value });
    const path = name.split(".");
    const finalProp = path.pop();
    const newuser = { ...user };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] = value;
    setUser(newuser);
    setisvaliddata(true);
  };

  function handleArrayObjectChange(e, itemId, property) {
    const value = e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...user.social_media];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex((item) => item.id == itemId);
    // -1 check to see if we found that object in social_media
    if (index != -1) {
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value, //here property can be "link"
      };
    }
    setUser({ ...user, social_media: temparrayprops });
    handleFieldValidation(e);
    setisvaliddata(true);
  }
  function handleAddressArrayObjectChange(e, itemId, property, islist) {
    const value = islist === true ? e.value : e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...user.address];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex((item) => item.id == itemId);
    // -1 check to see if we found that object in social_media
    if (index != -1) {
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value, //here property can be "link"
      };
    }
    setUser({ ...user, address: temparrayprops });
    if (property === "country") loadStates(itemId, value);
    //handleFieldValidation(e);
    setisvaliddata(true);
  }

  const addGroupsList = async (e) => {
    e.preventDefault();
    if (validateGroupList()) {
      //console.log();
      setUserGroupList((oldrecord) => [grouplistrow]);
      //console.log("usergroupList : ", usergroupList);
      //console.log(usergroupList);
      //  setisSaved(false);
    }
    // alert("addGroupsList");
  };
  const deleteGroupsList = async (e) => {
    e.preventDefault();
    if (validateGroupList()) {
      //console.log();
      setUserGroupList((oldrecord) => []);
      //console.log("usergroupList : ", usergroupList);
      //console.log(usergroupList);
      //  setisSaved(false);
    }
    // alert("addGroupsList");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        updateuser(jackapp.currentUser);
    }
    async function updateuser(userInfo) {
      updateUserByID(
        user._id,
        user.first_name,
        user.last_name,
        user.email,
        user.gender_id,
        user.dob,
        user.doj,
        user.employee_number,
        user.emergency_contact,
        user.employeerole_id,
        user.pan_number,
        user.aadhar_number,
        user.photo,
        user.designation,
        user.department,
        user.address[0].id,
        user.address[0].title,
        user.address[0].street,
        user.address[0].suite,
        user.address[0].country,
        user.address[0].states,
        user.address[0].city,
        user.address[0].post_code,
        user.address[0].m_phone,
        user.address[0].o_phone,
        user.address[0].alternateemail,
        user.address[0].fax,
        usergroupList,
        user.social_media[0].link,
        user.social_media[1].link,
        user.social_media[2].link,
        user.social_media[3].link,
        user.social_media[4].link,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
if(user.password !== defaultpassword){
  updatepassword();
}
       
          
          setisSaved(true);
        }
      });
    }
  };
  //validation functions
  //error handling functions
  async function updatepassword (){
    await jackapp.ChangePassword(
      user.email,
      user.password
    );
  }
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const validateGroupList = () => {
    try {
      let valid = false;
      valid = handleFieldGroupListRequiredValidation();
      Object.values(grouplisterrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldGroupListRequiredValidation = () => {
    let iserror = "";

    setGroupListErrors({
      ...grouplisterrors,
      organization_id:
        grouplistrow.organization_id === ""
          ? (iserror = "Organization is required")
          : grouplisterrors.organization_id,

      group_id:
        grouplistrow.group_id === ""
          ? (iserror = "Group is required")
          : grouplisterrors.group_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      first_name:
        user.first_name === ""
          ? (iserror = "First Name is required")
          : errors.first_name,
      last_name:
        user.last_name === ""
          ? (iserror = "Last Name is required")
          : errors.last_name,
      email: user.email === "" ? (iserror = "Email is required") : errors.email,
      employeerole_id:
        user.employeerole_id === ""
          ? (iserror = "Employee Role is required")
          : errors.employeerole_id,
          password:
          user.password === ""
            ? (iserror = "Password is required")
            : errors.password,
        confirm_password:
          user.confirm_password === ""
            ? (iserror = "Confirm Password is required")
            : user.confirm_password !== user.password
            ? (iserror = "Password & Confirm Password are mismatched")
            : errors.confirm_password,
    });
    if (usergroupList.length === 0) {
      iserror = "Group info is required";
      setGrouplistmandatory(iserror);
    } else {
      setGrouplistmandatory("");
    }
    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleGroupListDropDownChange = (selectedOption) => {
    
    const { name, value } = selectedOption.target;
    const newgroup = { ...grouplistrow };
    let pointer = newgroup;
    if (name === "organization_id") {
      pointer[name] = value;
      pointer["organization_name"] = OrganizationList.filter(
        (organization) => organization.value === value
      )[0].label;
      if (
        jackapp.currentUser !== null &&
        jackapp.currentUser !== undefined &&
        groupMainList.length === 0
      ) {
        loadGroups(jackapp.currentUser, value);
      }
      let grpList = groupMainList.filter(
        (group) => group.organization_id === value
      );
      let options = grpList.map(function (group) {
        return { value: group._id, label: group.group_short_name };
      });
      setGroupList(options);
    } else {
      pointer[name] = value;
      pointer["group_name"] = groupList.filter(
        (group) => group.value === value
      )[0].label;
    }
    setGroupListRow(newgroup);
    setisvaliddata(true);
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "first_name":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "last_name":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    if (emproleid == true) {
      navigate({
        pathname: "/",
        // state: {
        //   response: messageFromServer
        // }
      });
    } else {
      navigate({
        pathname: "/user/Organizationusers",
        // state: {
        //   response: messageFromServer
        // }
      });
    }
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setUserinfo(true);
        setContactinfo(false);
        setActivityinfo(false);
        return;
      case "contactinfo":
        setUserinfo(false);
        setContactinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setUserinfo(false);
        setContactinfo(false);
        setActivityinfo(true);
        return;
      default:
        return;
    }
  };
  async function loadStates(itemid, country_id) {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      getAllStateForLookupByCountry(1, jackapp.currentUser, country_id).then(
        (response) => {
          if (response !== undefined) {
            //let data[] = response;
            let statedata = JSON.parse(response);
            let options = statedata.map(function (state) {
              return { value: state.id, label: state.name };
            });
            if (itemid === "0") setStateList(options);

            //console.log("state1" + response);
          }
        }
      );
    }
  }
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loademployeerole(jackapp.currentUser);
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadFacility(jackapp.currentUser);
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadGender(jackapp.currentUser);
    }

    var emprole_id = jackapp.currentUser.customData.employeerole_id;
    GetRolePermissionByUserID(emprole_id);
  }, []);

  async function loadOrganization(userInfo) {
    getAllOrganizationForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let Organizationdata = JSON.parse(response);
        let options = Organizationdata.map(function (organization) {
          return {
            value: organization._id,
            label: organization.organization_name,
          };
        });
        setOrganizationList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadFacility(userInfo) {
    getAllFacilityForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //console.log("Facility" + JSON.parse(response));
        let facilitydata = JSON.parse(response);
        let options = facilitydata.map(function (facility) {
          return { value: facility._id, label: facility.facility_name };
        });
        setFacilityList(options);
      }
    });
  }

  async function loadGender(userInfo) {
    getAllGenderForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let genderdata = JSON.parse(response);
        let options = genderdata.map(function (gender) {
          return { value: gender._id, label: gender.gender };
        });
        setGenderList(options);
      }
    });
  }

  // console.log("emprole_id" + emprole_id);
  // console.log("emproleid :" + emproleid);
  async function GetRolePermissionByUserID(emprole_id) {
    //  console.log("keyid : ", keyid);
    getRolePermissionByUserID(emprole_id, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          console.log(JSON.parse(response));
          let EmpRoleData = JSON.parse(response);

          setEmpRoleID(EmpRoleData);
        }
      }
    );
  }

  async function loadCountry(userInfo) {
    getCountryForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let countrydata = JSON.parse(response);
        let options = countrydata.map(function (country) {
          return { value: country.id, label: country.name };
        });
        setCountryList(options);
        //console.log("state1" + response);
      }
    });
  }

  async function loademployeerole(userInfo) {
    getEmployeeRoleLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavetypedata = JSON.parse(response);
        let options = leavetypedata.map(function (employeerole) {
          return {
            value: employeerole._id,
            label: employeerole.employee_role,
          };
        });
        setemployeeroleList(options);
      }
    });
  }

  useEffect(() => {
    if (user.user_group_roles.length > 0) {
      setUserGroupList(user.user_group_roles);
    }
  }, [user]);

  useEffect(() => {
    //internal functions
    async function loaduserByID(userInfo) {
      getUserByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let users = JSON.parse(response);
          users.map((user) => {
            //console.log(user._id)
            if (
              user.address[0].country !== null &&
              user.address[0].country !== ""
            ) {
              loadStates("0", user.address[0].country);
            }
            setUser({ ...user, _id: user._id });
          });
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loaduserByID(jackapp.currentUser);
    }
  }, [keyid, jackapp.currentUser]);
  const handleOrganizationGroups = async (e) => {
    e.preventDefault();
  };
  async function loadGroups(userInfo, organization_id) {
    getGroupsForLookup(1, userInfo, organization_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let groupsdata = JSON.parse(response);
        setGroupMainList(groupsdata);
        let grpList = groupsdata.filter(
          (group) => group.organization_id === organization_id
        );
        let options = grpList.map(function (group) {
          return { value: group._id, label: group.group_short_name };
        });
        setGroupList(options);
        //console.log("group" + response);
      }
    });
  }

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start"
        hidden= {emproleid == true}
        >
          <li className="breadcrumb-item">
            <Link to="/user/organizationusers">Organization Users</Link>
          </li>
          <li className="breadcrumb-item active bold">Edit user</li>
        </ol> 
      </div>
      <div className="tab-content p-0">
        <div className={"tab-pane fade " + (tabUserinfo ? "show active " : "")}>
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2 border-1">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-10  col ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Basic Info
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  First Name{" "}
                                  <label className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.first_name}
                                  noValidate
                                />
                                {errors.first_name.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="first_name"
                                  >
                                    {errors.first_name}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Last Name{" "}
                                  <label className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.last_name}
                                  noValidate
                                />
                                {errors.last_name.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="last_name"
                                  >
                                    {errors.last_name}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Email <label className="text-danger">*</label>
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.email}
                                />
                                {errors.email.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="email"
                                  >
                                    {errors.email}
                                  </small>
                                )}
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label>Gender</label>
                                <Select
                                  name="gender_id"
                                  id="gender_id"
                                  options={genderlist}
                                  isSearchable={true}
                                  styles={styles}
                                  value={genderlist.filter(
                                    (gender) => gender.value === user.gender_id
                                  )}
                                  onChange={(val) => {
                                    handleDropDownChange({
                                      target: {
                                        name: "gender_id",
                                        value: val.value,
                                      },
                                    });
                                  }}
                                ></Select>
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Date Of Birth
                                </label>
                                <input
                                  type="date"
                                  name="dob"
                                  id="dob"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.dob}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Date Of Join{" "}
                                </label>
                                <input
                                  type="date"
                                  name="doj"
                                  id="doj"
                                  disabled={emproleid == true ? true : false}
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.doj}
                                  noValidate
                                />
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Employee Number
                                </label>
                                <input
                                  type="text"
                                  name="employee_number"
                                  id="employee_number"
                                  disabled={emproleid == true ? true : false}
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.employee_number}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Emergency contact
                                </label>
                                <input
                                  type="text"
                                  name="emergency_contact"
                                  id="emergency_contact"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.emergency_contact}
                                />
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Pan Number
                                </label>
                                <input
                                  type="text"
                                  name="pan_number"
                                  id="pan_number"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.pan_number}
                                  noValidate
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Aadhar Number
                                </label>
                                <input
                                  type="text"
                                  name="aadhar_number"
                                  id="aadhar_number"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.aadhar_number}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  name="designation"
                                  id="designation"
                                  disabled={emproleid == true ? true : false}
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.designation}
                                  noValidate
                                />
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Department
                                </label>
                                <input
                                  type="department"
                                  name="department"
                                  id="department"
                                  disabled={emproleid == true ? true : false}
                                  className="form-control"
                                  onChange={handleChange}
                                  value={user.department}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label>
                                  Employee Role{" "}
                                  <label className="text-danger">*</label>
                                </label>
                                <Select
                                  name="employeerole_id"
                                  options={employeerolelist}
                                  isSearchable={true}
                                  isDisabled={emproleid == true ? true : false}
                                  styles={styles}
                                  value={employeerolelist.filter(
                                    (employeerole) =>
                                      employeerole.value ===
                                      user.employeerole_id
                                  )}
                                  onChange={(val) => {
                                    handleDropDownChange({
                                      target: {
                                        name: "employeerole_id",
                                        value: val.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Password{" "}
                                  <label className="text-danger">*</label>
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"        
                                  value={user.password}             
                                  className="form-control"
                                  onChange={handleChange}                            
                                  noValidate
                                />
                                {errors.password.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="password"      >
                                    {errors.password}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Confirm Password{" "}
                                  <label className="text-danger">*</label>
                                </label>
                                <input
                                  type="password"
                                  name="confirm_password"
                                  id="confirm_password"
                                  className="form-control"
                                  value={user.confirm_password}
                                  onChange={handleChange}
                                  noValidate                        />
                                {errors.confirm_password.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="confirm_password"
                                  >
                                    {errors.confirm_password}
                                  </small>
                                )}
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-2  col  ms-0 ">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Photo{" "}
                          </PanelHeader>
                          <PanelBody className="">
                            <div className="col-sm-10 col-md-10 col-lg-10 m-2">
                              <div className="">
                                <FileBase
                                  type="file"
                                  multiple={false}
                                  onDone={({ base64 }) => {
                                    setUser({ ...user, ["photo"]: base64 });
                                    setisvaliddata(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="text-center">
                              <img
                                src={user.photo}
                                alt="No Image"
                                className="rounded-circle mx-auto d-block mw-100"
                                width="100"
                                height="100"
                              />
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className=" col-xl-12  col ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Group Info{" "}
                          </PanelHeader>
                          <div className="row p-1">
                            <div className=" col-xl-4  col ms-0">
                              <PanelBody className="p-0 m-0">
                                <div className="row p-1">
                                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                                    <label className="form-label form-label">
                                      Organization{" "}
                                      <label className="text-danger">*</label>
                                    </label>
                                    <Select
                                      name="organization_id"
                                      options={OrganizationList}
                                      isSearchable={true}
                                      styles={styles}
                                      isDisabled={
                                        emproleid == true ? true : false
                                      }
                                      onChange={(val) => {
                                        handleGroupListDropDownChange({
                                          target: {
                                            name: "organization_id",
                                            value: val.value,
                                          },
                                          
                                        });grouplisterrors.organization_id="";
                                      }}
                                    ></Select>
                                    {grouplisterrors.organization_id.length >
                                      0 && (
                                      <small
                                        className="form-check-label text-red"
                                        htmlFor="organization_id"
                                      >
                                        {grouplisterrors.organization_id}
                                      </small>
                                    )}
                                  </div>

                                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                                    <label className="form-label form-label">
                                      Group{" "}
                                      <label className="text-danger">*</label>
                                    </label>
                                    <Select
                                      name="group_id"
                                      options={groupList}
                                      isSearchable={true}
                                      isDisabled={
                                        emproleid == true ? true : false
                                      }
                                      styles={styles}
                                      onChange={(val) => {
                                        handleGroupListDropDownChange({
                                          target: {
                                            name: "group_id",
                                            value: val.value,
                                          },
                                        });grouplisterrors.group_id="";
                                      }}
                                    ></Select>
                                    {grouplisterrors.group_id.length > 0 && (
                                      <small
                                        className="form-check-label text-red"
                                        htmlFor="group_id"
                                      >
                                        {grouplisterrors.group_id}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                <div className="row p-0">
                                  <div className="col-sm-12 col-md-12 col-lg-12 m-2">
                                    <ButtonGroup>
                                      <Button
                                        className="me-1"
                                        color="default"
                                        size="md"
                                        disabled={
                                          emproleid == true ? true : false
                                        }
                                        onClick={addGroupsList}
                                      >
                                        Add Group
                                      </Button>
                                      {/* <Button className="me-1"
                                      color="default"
                                      size="md"
                                      onClick={addGroupsList}
                                    >
                                      Update Group
                                    </Button> */}
                                      <Button
                                        color="default"
                                        size="md"
                                        onClick={deleteGroupsList}
                                        disabled={
                                          emproleid == true ? true : false
                                        }
                                      >
                                        Clear
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                  {grouplistmandatory.length > 0 && (
                                    <small
                                      className="form-check-label text-red"
                                      htmlFor="grouplistmandatory"
                                    >
                                      {grouplistmandatory}
                                    </small>
                                  )}
                                </div>
                              </PanelBody>
                            </div>
                            <div className=" col-xl-8  col ms-0">
                              <div className="col-xl-12">
                                <Panel>
                                  <PanelBody>
                                    <div className="col-xl-12">
                                      <GlobalSimpleTable
                                        gcolumns={usergroupListColumns}
                                        gdata={
                                          data !== undefined
                                            ? JSON.stringify(data)
                                            : ""
                                        }
                                        ghiddencolumns={
                                          hiddenusergroupListColumns
                                        }
                                        gkey={"user_id"}
                                        glink="/user/detailuser"
                                        gfunction={handleOrganizationGroups}
                                        gplaceholder={""}
                                      />
                                    </div>
                                  </PanelBody>
                                </Panel>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabContactinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody className="p-0 m-2">
                <div className="row">
                  <div className="row p-0 m-0">
                    <div className="col-xl-8 colcol-xl-8 col ms-0 ">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                          Address Info{" "}
                        </PanelHeader>
                        <PanelBody className="p-0 m-0">
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                              <label className="form-label">Street</label>

                              <input
                                type="text"
                                name="street"
                                id="street"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "street"
                                  )
                                }
                                value={user.address[0].street}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                              <label className="form-label">Suite</label>

                              <input
                                type="text"
                                name="suite"
                                id="suite"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "suite"
                                  )
                                }
                                value={user.address[0].suite}
                              />
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label form-label">
                                Country{" "}
                              </label>
                              <Select
                                name="country_id"
                                options={countryList}
                                isSearchable={true}
                                styles={styles}
                                value={countryList.filter(
                                  (country) =>
                                    country.value === user.address[0].country
                                )}
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "country",
                                    true
                                  )
                                }
                              ></Select>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label form-label">
                                State{" "}
                              </label>
                              <Select
                                name="state_id"
                                options={stateList}
                                isSearchable={true}
                                styles={styles}
                                value={stateList.filter(
                                  (state) =>
                                    state.value === user.address[0].states
                                )}
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "states",
                                    true
                                  )
                                }
                              ></Select>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">City</label>

                              <input
                                type="text"
                                name="city"
                                id="city"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(e, "0", "city")
                                }
                                value={user.address[0].city}
                              />
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Postal Code</label>

                              <input
                                type="text"
                                name="post_code"
                                id="post_code"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "post_code"
                                  )
                                }
                                value={user.address[0].post_code}
                              />
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Mobile Phone</label>

                              <input
                                type="text"
                                name="m_phone"
                                id="m_phone"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "m_phone"
                                  )
                                }
                                value={user.address[0].m_phone}
                              />
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Office Phone</label>

                              <input
                                type="text"
                                name="o_phone"
                                id="o_phone"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "o_phone"
                                  )
                                }
                                value={user.address[0].o_phone}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label">Fax</label>

                              <input
                                type="text"
                                name="fax"
                                id="fax"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(e, "0", "fax")
                                }
                                value={user.address[0].fax}
                              />
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label">
                                Alternate Email
                              </label>

                              <input
                                type="text"
                                name="alternateemail"
                                id="alternateemail"
                                className="form-control"
                                onChange={(e) =>
                                  handleAddressArrayObjectChange(
                                    e,
                                    "0",
                                    "alternateemail"
                                  )
                                }
                                value={user.address[0].alternateemail}
                              />
                            </div>
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                    <div className="col-xl-4  col  ms-0">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                          Social Media handles{" "}
                        </PanelHeader>
                        <PanelBody className="p-0 m-0">
                          <div className="row p-1 ">
                            <div className="col-12">
                              <label className="form-label">Website</label>
                              <input
                                type="text"
                                name="website"
                                id="website"
                                className="form-control"
                                onChange={(e) =>
                                  handleArrayObjectChange(e, "1", "link")
                                }
                                value={user.social_media[0].link}
                              />
                            </div>
                          </div>
                          <div className="row p-1 ">
                            <div className="col-12">
                              <label className="form-label">LinkedIn</label>
                              <input
                                type="text"
                                name="linkedin"
                                id="linkedin"
                                className="form-control"
                                onChange={(e) =>
                                  handleArrayObjectChange(e, "2", "link")
                                }
                                value={user.social_media[1].link}
                              />
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-12">
                              <label className="form-label">Facebook</label>
                              <input
                                type="text"
                                name="facebook"
                                id="facebook"
                                className="form-control"
                                onChange={(e) =>
                                  handleArrayObjectChange(e, "3", "link")
                                }
                                value={user.social_media[2].link}
                              />
                            </div>
                          </div>
                          <div className="row pb-1">
                            <div className="col-6">
                              <label className="form-label">Twitter</label>

                              <input
                                type="text"
                                name="twitter"
                                id="twitter"
                                className="form-control"
                                onChange={(e) =>
                                  handleArrayObjectChange(e, "4", "link")
                                }
                                value={user.social_media[3].link}
                              />
                            </div>
                            <div className="col-6">
                              <label className="form-label">Instagram</label>

                              <input
                                type="text"
                                name="instagram"
                                id="instagram"
                                className="form-control"
                                onChange={(e) =>
                                  handleArrayObjectChange(e, "5", "link")
                                }
                                value={user.social_media[4].link}
                              />
                            </div>
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabUserinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="contactinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "contactinfo")}
                          className={
                            "nav-link " + (tabContactinfo ? "active " : "")
                          }
                        >
                          Contact Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          {" "}
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    user !== undefined
                      ? JSON.stringify(user.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="user is updated successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default EditUser;
