import React, { useState, useEffect } from "react";
//import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getHolidayByID,
  deleteHolidayID,
} from "../../../services/company_holidaysService";
import {
  getLeaveRequestByID,
} from "../../../services/leaverequestService";
//import { AppSettings } from "../../../config/app-settings";
import SweetAlert from "react-bootstrap-sweetalert";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
const DetailHoliday = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  //state variabley
  const [holiday, setHoliday] = useState({
    _id: "",
    holiday_names: "",
    holiday_description: "",
    holiday_date: "",
    settings_year_id: "",
    is_active: "1",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
//console.log('holiday',holiday);
  const jackapp = useRealmApp();
  //const context = useContext(AppSettings);
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);

  //const data = useMemo(() => [...Holiday], [Holiday]);
  const keyid = useLocation().state.holiday_id;
  const [tabHolidayinfo, setHolidayinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //functions

  const handleDelete = async (Holidayid) => {
    //  console.log(Holidayid);
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteHoliday(keyid, jackapp.currentUser);
    }
  };
  async function deleteHoliday(Holidayid, userInfo) {
    deleteHolidayID(Holidayid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/lms/listholiday",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setHolidayinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setHolidayinfo(false);
        setActivityinfo(true);
        return;
      default:
        break;
    }
  };
 


  useEffect(() => {
  
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
    loadHolidayByID(jackapp.currentUser);

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadLeaveRequestByID(jackapp.currentUser);
  }, []);

    //internal functions
    async function loadLeaveRequestByID(userInfo) {
      // console.log("keyid : ", keyid);
       getLeaveRequestByID(keyid, userInfo).then((response) => {
         if (response !== undefined) {
           //console.log(JSON.parse(response));
           let LeaveRequest = JSON.parse(response);
           LeaveRequest.map((leaverequest) =>
             //console.log(leaverequest._id)
             setHoliday({ ...leaverequest, _id: leaverequest.leaverequest })
           );
         }
       });
     }

  async function loadHolidayByID(userInfo) {
    getHolidayByID(keyid, userInfo).then((response) => {
      if (response !== undefined) {
       // console.log(JSON.parse(response));
        let holidays = JSON.parse(response);
        holidays.map((holiday) =>
          //console.log(group._id)
          setHoliday({ ...holiday, _id: holiday._id })
        );
      }
    });
    // console.log();
  }
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listholiday">Holiday</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail Holiday</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabHolidayinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabHolidayinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Link
                      to={{ pathname: "/lms/editholiday" }}
                      state={{ holiday_id: keyid }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">
                      Holiday Date{" "}
                    </label>
                    <input
                      type="text"
                      name="holiday_date"
                      id="holiday_date"
                      className="form-control"
                      disabled="disabled"
                      value={holiday.holiday_date}
                    />
                    <label className="form-check-label" htmlFor="holiday_date">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label ">
                      Holiday Name{" "}
                    </label>

                    <input
                      type="text"
                      name="holiday_names"
                      id="holiday_names"
                      className="form-control"
                      disabled="disabled"
                      value={holiday.holiday_names}
                    />
                    <label className="form-check-label" htmlFor="holiday_names">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">
                      Holiday Description
                    </label>

                    <input
                      type="text"
                      name="holiday_description"
                      id="holiday_description"
                      className="form-control"
                      disabled="disabled"
                      value={holiday.holiday_description}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="holiday_description"
                    >
                      &nbsp;
                    </label>
                  </div>
                  
                </div>
                <div className="row pb-2">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">Active</label>
                    <div className="form-check form-switch ms-auto mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled="disabled"
                        name="is_active"
                        id="is_active"
                        checked={holiday.is_active === "1" ? true : false}
                      />
                      <label className="form-check-label" htmlFor="is_active">
                        &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabHolidayinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                  <Link
                      to={{ pathname: "/lms/editholiday" }}
                      state={{ holiday_id: keyid }}
                      className="btn btn-primary  me-2 "
                    >
                      <i className="fa fa-fw  fa-square"></i> Edit
                    </Link>

                    <Button
                      onClick={() => handleDelete(keyid)}
                      className="btn btn-danger me-2  "
                    >
                      <i className="fa fa-trash"></i>&nbsp; Delete
                    </Button>
                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    holiday !== undefined
                      ? JSON.stringify(holiday.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      {isDeleted && (
        <SweetAlert
          style={{ color: "black" }}
          warning
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnText="No"
          cancelBtnBsStyle="info"
          showCancel
          focusCancelBtn
          onConfirm={handleDeleteSweetAlert}
          onCancel={handleDeleteCancel}
        >
          <h5> Do you wish to delete Holiday[{holiday.holiday_names}]?.</h5>
        </SweetAlert>
      )}
      {isDeletedConfirm && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title="Holiday is deleted successfully.."
          onConfirm={handleConfirmDeleteSweetAlert}
        ></SweetAlert>
      )}
    </div>
  );
};

export default DetailHoliday;
