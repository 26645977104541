import React, { useState, useEffect } from "react";
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";
//import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
//import Moment from 'moment';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getLeaveRequestByID,
  deleteLeaveRequestByID,
} from "../../../services/leaverequestService";
import {
  getLeaveStatusLookup,
  getLeaveStatusPermission,
} from "../../../services/leave_statusService"; //import {useContext} from 'react';
//import { AppSettings } from "../../../config/app-settings";
import SweetAlert from "react-bootstrap-sweetalert";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
import { getLeaveTypeLookup } from "../../../services/leavetypesService";
import { getAllUsersForLookup } from "../../../services/usersService";

const DetailLeaveRequest = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  //state variabley
  const [leaverequest, setLeaveRequest] = useState({
    _id: "",
    leavetype_id: "",
    requestto_id: "",
    from_date: "",
    to_date: "",
    total_days: "",
    status_name: "",
    leave_description: "",
    //settings_year_id: "",
    leavestatus_id: "",
    //employee_id:"",
    is_active: "1",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
  const keyid = useLocation().state.user_id;
  const jackapp = useRealmApp();
  //const context = useContext(AppSettings);
  const [isDeleted, setisDeleted] = useState(false);
  const [isDeletedConfirm, setisDeletedConfirm] = useState(false);
  const [leavetypeList, setleavetypeList] = useState([]);
  const [requesttoList, setrequesttoList] = useState([]);
  const [leavestatusList, setleavestatusList] = useState([]);
  const [buttonpermission, setButtonPermission] = useState(true);
  const [leave_request_id, SetLeave_request_id] = useState("");
  //const data = useMemo(() => [...LeaveRequest], [LeaveRequest]);

  const [tabLeaveRequestinfo, setLeaveRequestinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  //functions

  const handleDelete = async (LeaveRequestid) => {
    //  console.log(LeaveRequestid);
    setisDeleted(true);
  };
  const handleDeleteSweetAlert = () => {
    setisDeleted(false);
    if (keyid != null) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        deleteLeaveRequest(keyid, jackapp.currentUser);
    }
  };
  async function deleteLeaveRequest(LeaveRequestid, userInfo) {
    deleteLeaveRequestByID(LeaveRequestid, userInfo).then((response) => {
      if (response !== undefined) {
        setisDeleted(false);
        setisDeletedConfirm(true);
      }
    });
  }
  const handleConfirmDeleteSweetAlert = () => {
    setisDeletedConfirm(false);
    handleCancel();
  };
  const handleDeleteCancel = () => {
    setisDeleted(false);
    setisDeletedConfirm(false);
    return true;
  };
  const handleCancel = () => {
    navigate({
      pathname: "/lms/listleaverequest",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setLeaveRequestinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setLeaveRequestinfo(false);
        setActivityinfo(true);
        return;
      default:
        break;
    }
  };

  useEffect(() => {
    //internal functions
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavetype(jackapp.currentUser);
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadLeaveRequestByID(jackapp.currentUser);
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadrequestto(jackapp.currentUser);
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavestatus(jackapp.currentUser);
    }
  }, []);

  useEffect(() => {
    loadButtonPermission(leave_request_id);
  }, [leave_request_id]);

  async function loadLeaveRequestByID(userInfo) {
    //  console.log("keyid : ", keyid);
    getLeaveRequestByID(keyid, userInfo).then((response) => {
      if (response !== undefined) {
        //console.log(JSON.parse(response));
        let LeaveRequest = JSON.parse(response);
        LeaveRequest.map((leaverequest) => {
          //console.log(leaverequest._id)
          setLeaveRequest({ ...leaverequest, _id: leaverequest.leaverequest });
          SetLeave_request_id(leaverequest.leavestatus_id);
        });

        //
      }
    });
  }

  async function loadleavetype(userInfo) {
    getLeaveTypeLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavetypedata = JSON.parse(response);
        let options = leavetypedata.map(function (leavetype) {
          return { value: leavetype._id, label: leavetype.leave_name };
        });
        setleavetypeList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadrequestto(userInfo) {
    getAllUsersForLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let requesttodata = JSON.parse(response);
        let options = requesttodata.map(function (requestto) {
          return { value: requestto.realm_user_id, label: requestto.fullname };
        });
        setrequesttoList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadleavestatus(userInfo) {
    getLeaveStatusLookup(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavestatusdata = JSON.parse(response);
        let options = leavestatusdata.map(function (leavestatus) {
          return { value: leavestatus._id, label: leavestatus.status_name };
        });
        setleavestatusList(options);
        //console.log("Organization1" + response);
      }
    });
  }

  async function loadButtonPermission(leavestatusid) {
    getLeaveStatusPermission(leavestatusid, jackapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let leavestatusdata = response;
          // let button = leavestatusdata.map(function (leavestatus) {
          //   return { leavestatus};
          // });
          setButtonPermission(leavestatusdata);
          //console.log("Organization1" + response);
        }
      }
    );
  }
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listleaverequest">Leave Request</Link>
          </li>
          <li className="breadcrumb-item active bold">Detail Leave Request</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={
            "tab-pane fade " + (tabLeaveRequestinfo ? "show active " : "")
          }
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveRequestinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <div>
                      <Button
                        className="btn btn-success  me-2 "
                        disabled={buttonpermission == "false" ? true : false}
                      >
                        <i className="fa fa-fw  fa-square"></i>
                        <Link
                          style={{ color: "white", textDecoration: "none" }}
                          to={{ pathname: "/lms/editleaverequest" }}
                          state={{ user_id: keyid }}
                        >
                          Edit
                        </Link>
                      </Button>
                      <Button
                        onClick={() => handleDelete(keyid)}
                        className="btn btn-danger me-2"
                        disabled={buttonpermission == "false" ? true : false}
                      >
                        <i className="fa fa-trash"></i>&nbsp; Delete
                      </Button>{" "}
                    </div>

                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label ">LeaveType </label>
                    <Select
                      name="leavetype_id"
                      options={leavetypeList}
                      isSearchable={true}
                      styles={styles}
                      value={leavetypeList.filter(
                        (customer) =>
                          customer.value === leaverequest.leavetype_id
                      )}
                      isDisabled={true}
                    ></Select>

                    
                    <label className="form-check-label" htmlFor="leavetype_id">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">Request To</label>
                    <Select
                      name="requestby_id"
                      options={requesttoList}
                      isSearchable={true}
                      styles={styles}
                      value={requesttoList.filter(
                        (customer) =>
                          customer.value === leaverequest.requestto_id
                      )}
                      isDisabled={true}
                    ></Select>
                    {/* <input
type="text"
name="requestto_id"
id="requestto_id"
className="form-control"
disabled="disabled"
value={leaverequest.requestto_id}
/> */}
                    <label className="form-check-label" htmlFor="requestto_id">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">From Date</label>
                    <input
                      type="text"
                      name="from_date"
                      id="from_date"
                      className="form-control"
                      disabled="disabled"
                      value={leaverequest.from_date}
                    />
                    <label className="form-check-label" htmlFor="from_date">
                      &nbsp;
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label ">To Date </label>

                    <input
                      type="text"
                      name="to_date"
                      id="to_date"
                      className="form-control"
                      disabled="disabled"
                      value={leaverequest.to_date}
                    />
                    <label className="form-check-label" htmlFor="to_date">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">Total Days</label>

                    <input
                      type="text"
                      name="total_days"
                      id="total_days"
                      className="form-control"
                      disabled="disabled"
                      value={leaverequest.total_days}
                    />
                    <label className="form-check-label" htmlFor="total_days">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label form-label">
                      Leave Description
                    </label>
                    <input
                      type="text"
                      name="leave_description"
                      id="leave_description"
                      className="form-control"
                      disabled="disabled"
                      value={leaverequest.leave_description}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="leave_description"
                    >
                      &nbsp;
                    </label>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Leave Status</label>

                  <Select
                    name="leavestatus_id"
                    options={leavestatusList}
                    isSearchable={true}
                    styles={styles}
                    value={leavestatusList.filter(
                      (customer) =>
                        customer.value === leaverequest.leavestatus_id
                    )}
                    isDisabled={true}
                  ></Select>
                </div>
                {/* <div className="row">
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label ">Settings Year ID</label>

<input
type="text"
name="settings_year_id"
id="settings_year_id"
className="form-control"
disabled="disabled"
value={leaverequest.settings_year_id}
/>
<label className="form-check-label" htmlFor="settings_year_id">
&nbsp;
</label>
</div>
<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label ">Employee ID</label>

<input
type="text"
name="employee_id"
id="employee_id"
className="form-control"
disabled="disabled"
value={leaverequest.employee_id}
/>
<label className="form-check-label" htmlFor="employee_id">
&nbsp;
</label>
</div>

<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label ">Leave Status</label>

<input
type="text"
name="leavestatus_id"
id="leavestatus_id"
className="form-control"
disabled="disabled"
value={leaverequest.leavestatus_id}
/>
<label className="form-check-label" htmlFor="leavestatus_id">
&nbsp;
</label>
</div>

<div className="col-sm-4 col-md-4 col-lg-4">
<label className="form-label form-label">Active</label>
<div className="form-check form-switch ms-auto mb-0">
<input
type="checkbox"
className="form-check-input"
disabled="disabled"
name="is_active"
id="is_active"
checked={leaverequest.is_active === "1" ? true : false}
/>
<label className="form-check-label" htmlFor="is_active">
&nbsp;
</label>
</div>
</div>

</div> */}
              </PanelBody>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabLeaveRequestinfo ? "active " : "")
                          }
                        >
                          Basic Info
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <div>
                      <Button
                        className="btn btn-success  me-2 "
                        disabled={buttonpermission == "false" ? true : false}
                      >
                        <i className="fa fa-fw  fa-square"></i>
                        <Link
                          style={{ color: "white", textDecoration: "none" }}
                          to={{ pathname: "/lms/editleaverequest" }}
                          state={{ user_id: keyid }}
                        >
                          Edit
                        </Link>
                      </Button>
                      <Button
                        onClick={() => handleDelete(keyid)}
                        className="btn btn-danger me-2"
                        disabled={buttonpermission == "false" ? true : false}
                      >
                        <i className="fa fa-trash"></i>&nbsp; Delete
                      </Button>{" "}
                    </div>

                    <Button
                      onClick={handleCancel}
                      className="btn btn-default me-2 "
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    leaverequest !== undefined
                      ? JSON.stringify(leaverequest.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      {isDeleted && (
        <SweetAlert
          style={{ color: "black" }}
          warning
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnText="No"
          cancelBtnBsStyle="info"
          showCancel
          focusCancelBtn
          onConfirm={handleDeleteSweetAlert}
          onCancel={handleDeleteCancel}
        >
          <h5>
            {" "}
            Do you wish to delete LeaveRequest[{leaverequest.leave_name}
            ]?.
          </h5>
        </SweetAlert>
      )}
      {isDeletedConfirm && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title="LeaveRequest is deleted successfully.."
          onConfirm={handleConfirmDeleteSweetAlert}
        ></SweetAlert>
      )}
    </div>
  );
};

export default DetailLeaveRequest;
