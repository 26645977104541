import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getRoleGroupByID,
  updateRoleGroupByID,
} from "../../../services/rolegroupService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllCustomerForLookup } from "../../../services/customerService";
import { getGroupsForLookup } from "../../../services/groupService";
import { getRolesForLookupByOrganization } from "../../../services/roleService";
import styles from "../../../components/customstyles/react-select-styles";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
//import {useContext} from 'react';
const EditRoleGroup = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variable
  const [tabRoleGroupsinfo, setRoleGroupsinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  const [roleGroup, setRoleGroup] = useState({
    _id: "",
    organization_id: "",
    customer_id: "",
    group_id: "",
    role_id: [],
    is_active: 1,
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    _id: "",
    organization_id: "",
    customer_id: "",
    group_id: "",
    role_id: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);

  const keyid = useLocation().state.rolegroup_id;
  const [OrganizationList, setOrganizationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [checked, setChecked] = useState([]);

  //savefunctions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setRoleGroup({ ...roleGroup, [name]: value });
    setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    let target = event.target;
    setRoleGroup({ ...roleGroup, [target.name]: target.value });
    handleFieldValidation(event);
    // console.log(roleGroup);
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    setisvaliddata(true);
  };
  // Return classes based on whether item is checked
  var isChecked = (item) => (checked.includes(item) ? true : false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        updateRoleGroup(jackapp.currentUser);
    }
    async function updateRoleGroup(userInfo) {
      updateRoleGroupByID(
        roleGroup._id,
        roleGroup.organization_id,
        roleGroup.customer_id,
        roleGroup.group_id,
        checked,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setRoleGroups(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      if (checked.length > 0) valid = true;
      // console.log("valid", checkedItems.length);
      //  console.log("valid", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      organization_id:
        roleGroup.organization_id === "" || roleGroup.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
      group_id:
        roleGroup.group_id === "" || roleGroup.group_id === ""
          ? (iserror = "Group is required")
          : errors.group_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "group_id":
        localerror = value.length === "" ? " is missing." : "";
        break;
      case "organization_id":
        localerror = value.length === "" ? "Organization is missing." : "";
        break;
      case "inlineCheckbox1":
        localerror = value.length === "" ? "Roles is missing." : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/user/userpolicies",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setRoleGroupsinfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setRoleGroupsinfo(false);
        setActivityinfo(true);
        return;
    }
  };

  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadRoleGroupByID(jackapp.currentUser);
    }

    async function loadRoleGroupByID(userInfo) {
      getRoleGroupByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          // console.log("response", JSON.parse(response));
          let rolegroups2 = JSON.parse(response);
          if (jackapp.currentUser !== null &&jackapp.currentUser !== undefined) {
            if (rolegroups2[0].organization_id !== "" || rolegroups2[0].organization_id !== "undefined")
            {
              loadGroups(jackapp.currentUser, rolegroups2[0].organization_id);
              loadRoles(jackapp.currentUser,rolegroups2[0].organization_id );
            }
          }
          rolegroups2.map((role2) => {
            setRoleGroup({ ...roleGroup, ...role2 });
            setChecked(role2.role_id);
          });
        }
      });
    }

    async function loadRoles(userInfo,organization_id) {
      getRolesForLookupByOrganization(1, organization_id,userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let rolesdata = JSON.parse(response);
          let options = rolesdata.map(function (role) {
            return { value: role._id, label: role.role_short_name };
          });
          setRolesList(options);
        }
      });
    }

    async function loadGroups(userInfo, organization_id) {
      getGroupsForLookup(1, userInfo, organization_id).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let groupsdata = JSON.parse(response);
          let options = groupsdata.map(function (group) {
            return { value: group._id, label: group.group_short_name };
          });
          setGroupsList(options);
          //console.log("group" + response);
        }
      });
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          });
          setOrganizationList(options);
          //console.log("Organization" + response);
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, [keyid, jackapp.currentUser]);
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/user/userpolicies">User Policies</Link>
          </li>
          <li className="breadcrumb-item active bold">Edi User Policy</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={
            "tab-pane fade " + (tabRoleGroupsinfo ? "show active " : "")
          }
        >
          <div className="col-xl-12">
            <Panel>
              <form noValidate>
                <PanelHeader noButton={true}>
                  <div className="d-flex align-items-center">
                    <div>
                      <ul className="nav nav-tabs nav-tabs-inverse">
                        <li className="nav-item me-2" key="info">
                          <Link
                            to="/extra/profile"
                            onClick={(e) => showTab(e, "info")}
                            className={
                              "nav-link " + (tabRoleGroupsinfo ? "active " : "")
                            }
                          >
                            RoleGroup Information
                          </Link>
                        </li>
                        <li className="nav-item" key="activityinfo">
                          <Link
                            to="/extra/profile"
                            onClick={(e) => showTab(e, "activityinfo")}
                            className={
                              "nav-link " + (tabActivityinfo ? "active " : "")
                            }
                          >
                            Activity Information
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <ButtonGroup className="ms-auto my-n1">
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!isvaliddata}
                        size="md"
                        className="me-1"
                      >
                        <i className="fa fa-save"></i>&nbsp; Save
                      </Button>
                      <Button
                        color="default"
                        size="md"
                        onClick={handleSweetAlert}
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </div>
                </PanelHeader>

                <PanelBody>
                  <fieldset>
                    <div className="row mb-4">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Organization <label className="text-danger">*</label>
                        </label>
                        <Select
                          name="organization_id"
                          options={OrganizationList}
                          isSearchable={true}
                          styles={styles}
                          value={OrganizationList.filter(
                            (organization) =>
                              organization.value === roleGroup.organization_id
                          )}
                          onChange={(val) => {
                            handleDropDownChange({
                              target: { name: "organization_id", value: val.value },
                            });
                          }}
                        ></Select>
                        {errors.organization_id.length > 0 && (
                          <small
                            className="form-check-label text-red"
                            htmlFor="organization_id"
                          >
                            {errors.organization_id}
                          </small>
                        )}
                      </div>
                      {/* <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <label className="form-label">
                          Customer <label className="text-danger">*</label>
                        </label>
                        <Select
                          name="customer_id"
                          id="customer_id"
                          options={customerList}
                          isSearchable={true}
                          styles={styles}
                          isDisabled={true}
                          value={customerList.filter(
                            (customer) =>
                              customer.value === roleGroup.customer_id
                          )}
                        ></Select>
                        {errors.customer_id.length > 0 && (
                          <small
                            className="form-check-label text-red"
                            htmlFor="customer_id"
                          >
                            {errors.customer_id}
                          </small>
                        )}
                      </div> */}
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Group <label className="text-danger">*</label>
                        </label>
                        <Select
                          name="group_id"
                          options={groupsList}
                          isSearchable={true}
                          styles={styles}
                          value={groupsList.filter(
                            (group) => group.value === roleGroup.group_id
                          )}
                          onChange={(val) => {
                            handleDropDownChange({
                              target: { name: "group_id", value: val.value },
                            });
                          }}
                        ></Select>
                        {errors.group_id.length > 0 && (
                          <small
                            className="form-check-label text-red"
                            htmlFor="group_id"
                          >
                            {errors.group_id}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                          Roles List{" "}
                        </PanelHeader>
                        <PanelBody className="p-2 m-2">
                          <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12 ">
                              {rolesList.map((role) => (
                                <div
                                  key={role.value}
                                   className="form-check form-check-inline p-3 "
                                >
                                  <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value={role.value}
                                    onChange={handleCheck}
                                    checked={isChecked(role.value)}
                                  ></input>
                                  <label
                                     className="form-check-label align-middle"
                                    for="inlineCheckbox1"
                                  >
                                    <span>{role.label}</span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </PanelBody>
                      </Panel>
                      {/* <div>
        {`Roles Selected are: ${checkedItems}`}
      </div>
 */}
                    </div>
                  </fieldset>
                  <div>
                    {isSaved && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title="RoleGroup is added successfully.."
                        onConfirm={handleSweetAlert}
                      ></SweetAlert>
                    )}
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabRoleGroupsinfo ? "active " : "")
                          }
                        >
                          RoleGroup Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>
              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    roleGroup !== undefined
                      ? JSON.stringify(roleGroup.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="RoleGroup is updated successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default EditRoleGroup;
