import React from "react";
import * as Realm from "realm-web";
//import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../storestate/index"
export const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  const [app, setApp] = React.useState(new Realm.App(appId));
  //const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const { setUserInfo } = bindActionCreators(actionCreators, dispatch);
  //console.log(userInfo)
  React.useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  // Wrap the Realm.App object's user state with React state
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  async function logIn(credentials) {
    await app.logIn(credentials);
    // If successful, app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
    setUserInfo(app.currentUser);
  }
  async function NewUserlogIn(credentials) {
   const newuser= await app.logIn(credentials);
   return newuser;
    // If successful, app.currentUser is the user that just logged in
   // setCurrentUser(app.currentUser);
   // setUserInfo(app.currentUser);
  }
  async function logOut() {
    // remove the currently active user
    const user = app.currentUser;
    await app.removeUser(user);
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    //setCurrentUser(app.currentUser);
  }
  async function logOutAllUsers() {
    // Log out the currently active user
    for (const userId in app.allUsers) {
      const user = app.allUsers[userId];
      console.log(
        `User with id ${user.id} is ${
          user.isLoggedIn ? "logged in" : "logged out"
        }`
      );
      await app.removeUser(user);
      
    }
  }
  async function ChangePassword(email,password) {
    if (app.currentUser)
    {
      try
      {
         await app.emailPasswordAuth.callResetPasswordFunction(
            email,
            password
          )
          return true;
       }
      catch(err)
      {throw err;}
    }
       
  }
  async function logOutByUserID(user_id) {
    // Log out the currently active user
    for (const userId in app.allUsers) {
      const user = app.allUsers[userId];
      if(user.id=user_id)
      {
        await app.removeUser(user);
        break;
      }
      
    }
  }
  const wrapped = { ...app, currentUser, logIn, logOut,NewUserlogIn,logOutAllUsers,logOutByUserID,ChangePassword };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};