import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {
  getSettingsByID,
  updateSettingsByID,
} from "../../../services/settingsService";
import SweetAlert from "react-bootstrap-sweetalert";
import FileBase from "react-file-base64";
import Select from "react-select";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import styles from "../../../components/customstyles/react-select-styles";
import { getOrganizationListByOrgID } from "../../../services/settingsService";
import ActivityHistory from "../../../components/activityHistory/activityHistory";
import { getSettingsStatusByOrgID } from "../../../services/settingsService";
//import {useContext} from 'react';
const EditSetting = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variable
  const [tabSettinginfo, setSettinginfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  const [setting, setSetting] = useState({
    _id: "",
    organization_id: "",
    starting_year: "",
    ending_year: "",
    is_active: "",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    _id: "",
    organization_id: "",
    starting_year: "",
    ending_year: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [OrganizationList, setOrganizationList] = useState([]);
  const keyid = useLocation().state.setting_id;
  const [validatemsg, SetValidatemsg] = useState("");
  const [settingrecordvalidation, SetSettingrecordvalidation] = useState("");

  //savefunctions

  const handleChange = (event) => {
    let target = event.target;
    setSetting({ ...setting, [target.name]: target.value });
    handleFieldValidation(event);
    SetValidatemsg("");
    // console.log(setting);
  };
  const handleSubmit = async (e) => {
    console.log("called handlesubmit");
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        if (settingrecordvalidation !== "0") {
          console.log("handleFieldRequiredValidation called!");
          SetValidatemsg(
            "This organization setting record is already active, please inactive the record"
          );
        } else {
          updateSetting(jackapp.currentUser);
        }
    }
    async function updateSetting(userInfo) {
      updateSettingsByID(
        setting._id,
        setting.organization_id,
        setting.starting_year,
        setting.ending_year,
        setting.is_active,

        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setSettings(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      organization_id:
        setting.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
      starting_year:
        setting.starting_year === ""
          ? (iserror = "Starting Year is required")
          : errors.starting_year,
      ending_year:
        setting.ending_year === ""
          ? (iserror = "Ending year is required")
          : errors.ending_year,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "organization_id":
        localerror = value.length < 3 ? "Organization is required" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/setting",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setSettinginfo(true);
        setActivityinfo(false);
        return;
      case "activityinfo":
        setSettinginfo(false);
        setActivityinfo(true);
        return;
      default:
        break;
    }
  };
  useEffect(() => {
    //internal functions
    async function loadSettingByID(userInfo) {
      getSettingsByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let Setting = JSON.parse(response);
          Setting.map((setting) =>
            //console.log(setting._id)
            setSetting({ ...setting, _id: setting._id })
          );
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadSettingByID(jackapp.currentUser);
    }
  }, [keyid, jackapp.currentUser]);
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setSetting({ ...setting, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };

  async function GetSettingsStatusByOrgID(org_id, statusid) {
    await getSettingsStatusByOrgID(org_id, statusid, jackapp.currentUser).then(
      (result) => {
        console.log("result : ", result);
        SetSettingrecordvalidation(result);
      }
    );
  }
  useEffect(() => {
    console.log("setting.organization_id : ", setting.organization_id);
    console.log("setting.is_active : ", setting.is_active);
    if (setting.organization_id !== "" && setting.is_active !== "") {
      if (setting.is_active === "1") {
        GetSettingsStatusByOrgID(setting.organization_id, setting.is_active);
        console.log("settingrecordvalidation1 : ", settingrecordvalidation);
      } else {
        SetSettingrecordvalidation("0");
      }
    }
    SetValidatemsg("");
  }, [setting.organization_id, setting.is_active]);

  useEffect(() => {
    // console.log(location);

    var org_id =
      jackapp.currentUser.customData.user_group_roles[0].organization_id;
    GetOrganizationListByOrgID(org_id);

    async function GetOrganizationListByOrgID(org_id) {
      getOrganizationListByOrgID(org_id, jackapp.currentUser).then(
        (response) => {
          if (response !== undefined) {
            //let data[] = response;
            let Organizationdata = JSON.parse(response);
            let options = Organizationdata.map(function (organization) {
              return {
                value: organization._id,
                label: organization.organization_name,
              };
            });
            setOrganizationList(options);
            //console.log("Organization1" + response);
          }
        }
      );
    }
  }, []);
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/setting">Setting</Link>
          </li>
          <li className="breadcrumb-item active bold">Edit Setting</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div
          className={"tab-pane fade " + (tabSettinginfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabSettinginfo ? "active " : "")
                          }
                        >
                          Setting Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>
              <form noValidate>
                <PanelBody>
                  <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <label className="form-label">
                        Organization <label className="text-danger">*</label>
                      </label>
                      <Select
                        name="organization_id"
                        options={OrganizationList}
                        isSearchable={true}
                        styles={styles}
                        value={OrganizationList.filter(
                          (organization) =>
                            organization.value === setting.organization_id
                        )}
                        onChange={(val) => {
                          handleDropDownChange({
                            target: {
                              name: "organization_id",
                              value: val.value,
                            },
                          });
                        }}
                      ></Select>
                      {errors.organization_id.length > 0 && (
                        <small
                          className="form-check-label text-red"
                          htmlFor="organization_id"
                        >
                          {errors.organization_id}
                        </small>
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <label className="form-label form-label">
                        Starting Year{""}<label className="text-danger">*</label>
                      </label>

                      <input
                        type="date"
                        name="starting_year"
                        id="starting_year"
                        className="form-control"
                        value={setting.starting_year}
                        onChange={handleChange}
                        noValidate
                      />
                      {errors.starting_year.length > 0 && (
                        <small
                          className="form-check-label text-red"
                          htmlFor="starting_year"
                        >
                          {errors.starting_year}
                        </small>
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <label className="form-label form-label">
                        Ending Year{" "}<label className="text-danger">*</label>
                      </label>
                      <input
                        type="date"
                        name="ending_year"
                        id="ending_year"
                        className="form-control"
                        value={setting.ending_year}
                        onChange={handleChange}
                        noValidate
                      />{" "}
                      {errors.ending_year.length > 0 && (
                        <small
                          className="form-check-label text-red"
                          htmlFor="ending_year"
                        >
                          {errors.ending_year}
                        </small>
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <label className="form-label form-label">Active</label>
                      <div className="form-check form-switch ms-auto mb-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="is_active"
                          id="is_active"
                          checked={setting.is_active === "1" ? true : false}
                          onChange={(e) => {
                            setisvaliddata(true);
                            setSetting({
                              ...setting,
                              [e.target.name]: e.target.checked ? "1" : "0",
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="is_active">
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </div>
                  {validatemsg.length > 0 && (
                    <big
                      className="form-check-label text-red"
                      htmlFor="validation"
                    >
                      {validatemsg}
                    </big>
                  )}
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className={"tab-pane fade " + (tabActivityinfo ? "show active " : "")}
        >
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabSettinginfo ? "active " : "")
                          }
                        >
                          Setting Information
                        </Link>
                      </li>
                      <li className="nav-item" key="activityinfo">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "activityinfo")}
                          className={
                            "nav-link " + (tabActivityinfo ? "active " : "")
                          }
                        >
                          Activity Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Save
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>
              <PanelBody>
                <ActivityHistory
                  activityhistory={
                    setting !== undefined
                      ? JSON.stringify(setting.activity_history)
                      : ""
                  }
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Setting is updated successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default EditSetting;
