import React from "react";
import { Outlet } from "react-router-dom";
import App from "./../app.jsx";
import Home from "../pages/home/home.jsx";
import ManagerHome from "../pages/home/managerhome";
import EmployeeHome from "../pages/home/employeehome";
//Auth Menu
import Login from "./../pages/authenticate/login";
import Logout from "./../pages/authenticate/logout";
import ChangePassword from "./../pages/authenticate/changepassword";
import ForgotPassword from "./../pages/authenticate/forgotpassword";
import Forgot from "../pages/authenticate/forgot.jsx";
//Add Reference Data Menu
//import Countries from './../pages/references/countries.js';

//Add Settings Menu
/**roles */
import Roles from "./../pages/settings/roles/listroles";
import AddRole from "./../pages/settings/roles/addroles";
import EditRole from "./../pages/settings/roles/editroles";
import DetailRole from "./../pages/settings/roles/detailroles";
/**groups */
import Groups from "./../pages/settings/groups/listgroups";
import AddGroup from "./../pages/settings/groups/addgroups";
import EditGroup from "./../pages/settings/groups/editgroups";
import DetailGroup from "./../pages/settings/groups/detailgroups";
/**organization */
import Organization from "./../pages/settings/organization/listorganization";
import AddOrganization from "./../pages/settings/organization/addorganization";
import EditOrganization from "./../pages/settings/organization/editorganization";
import DetailOrganization from "./../pages/settings/organization/detailorganization";
/**Customers */
import Customers from "./../pages/settings/customers/listcustomers";
import AddCustomer from "./../pages/settings/customers/addcustomer";
import EditCustomer from "./../pages/settings/customers/editcustomer";
import DetailCustomer from "./../pages/settings/customers/detailcustomer";
/**facility */
import Facility from "./../pages/settings/facility/listfacility";
import AddFacility from "./../pages/settings/facility/addfacility";
import EditFacility from "./../pages/settings/facility/editfacility";
import DetailFacility from "./../pages/settings/facility/detailfacility";
/**rolegroup */
import RoleGroup from "./../pages/settings/rolegroup/listrolegroup";
import AddRoleGroup from "./../pages/settings/rolegroup/addrolegroup";
import EditRoleGroup from "./../pages/settings/rolegroup/editrolegroup";
import DetailRoleGroup from "./../pages/settings/rolegroup/detailrolegroup";

//Add Usermgmt Menu
/**edit profile users */

/**organization Users */
import Users from "./../pages/usermgmt/organizationusers/listusers";
import AddUser from "./../pages/usermgmt/organizationusers/adduser";
import EditUser from "./../pages/usermgmt/organizationusers/edituser";
import DetailUser from "./../pages/usermgmt/organizationusers/detailuser";
/**Customer Users */
import CustomerUsers from "./../pages/usermgmt/customerusers/listcustomerusers";
import AddCustomerUser from "./../pages/usermgmt/customerusers/addcustomeruser";
import EditCustomerUser from "./../pages/usermgmt/customerusers/editcustomeruser";
import DetailCustomerUser from "./../pages/usermgmt/customerusers/detailcustomeruser";

/**Leave Management menu */
/**Manager Login */
/**Company Holiday */
import ListHoliday from "./../pages/lms/companyholidays/listholiday";
import DetailHoliday from "../pages/lms/companyholidays/detailholiday.jsx";
import AddHoliday from "./../pages/lms/companyholidays/addholiday";
import EditHoliday from "./../pages/lms/companyholidays/editholiday";
/**Settings */
import Setting from "./../pages/lms/settings/listsetting";
import AddSetting from "./../pages/lms/settings/addsetting";
import EditSetting from "./../pages/lms/settings/editsetting";
import DetailSetting from "./../pages/lms/settings/detailsetting";
/**Leave Request Approval */
import ListApproval from "../pages/lms/leaverequestapproval/listapproval.jsx";
import DetailApproval from "../pages/lms/leaverequestapproval/detailapproval.jsx";
import EditApproval from "../pages/lms/leaverequestapproval/editapproval.jsx";

/**Leave Types */
import ListLeaveType from "../pages/lms/leavetype/listleavetype.jsx";
import DetailLeaveType from "../pages/lms/leavetype/detailleavetype";
import EditLeaveType from "../pages/lms/leavetype/editleavetype";
import AddLeaveType from "../pages/lms/leavetype/addleavetype";

/**Employee */
/**Company Holidays */
import UserListHoliday from "../pages/lms/usercompanyholidays/userlistholiday.jsx";
import UserDetailHoliday from "../pages/lms/usercompanyholidays/userdetailholiday.jsx";
/**Leave Request */
import ListLeaveRequest from "./../pages/lms/leaverequest/listleaverequest";
import AddLeaveRequest from "./../pages/lms/leaverequest/addleaverequest";
import DetailLeaveRequest from "./../pages/lms/leaverequest/detailleaverequest";
import EditLeaveRequest from "./../pages/lms/leaverequest/editleaverequest";

/**Leave Transaction */
import ListLeaveTransaction from "../pages/lms/leavetransaction/listtransaction.jsx";
/**Report */
import Report from "./../pages/lms/reports/reports";

/**About */
import About from "./../pages/lms/about/about";

const AppRoute = [
  {
    path: "*",
    element: <App />,
    children: [
      // { path: "home", element: <EmployeeHome /> },
      { path: "", element: <Home /> },
      { path: "home/managerhome", element: <ManagerHome /> },
      { path: "home/employeehome", element: <EmployeeHome /> },

      { path: "authenticate/login", element: <Login /> },
      { path: "authenticate/changepassword", element: <ChangePassword /> },
      { path: "authenticate/forgotpassword", element: <ForgotPassword /> },
      { path: "authenticate/forgot", element: <Forgot/> },
      {
        path: "settings/*",
        element: <Outlet />,
        children: [
          {
            index: 16,
            path: "organization",
            title: "Organization",
            element: <Organization />,
          },
          {
            index: 17,
            path: "addorganization",
            title: "Add Organization",
            element: <AddOrganization />,
          },
          {
            index: 18,
            path: "editorganization",
            title: "Edit Organization",
            element: <EditOrganization />,
          },
          {
            index: 19,
            path: "detailorganization",
            title: "Detail Organization",
            element: <DetailOrganization />,
          },
          {
            index: 20,
            path: "customers",
            title: "Customers",
            element: <Customers />,
          },
          {
            index: 21,
            path: "addcustomer",
            title: "Add Customer",
            element: <AddCustomer />,
          },
          {
            index: 22,
            path: "editcustomer",
            title: "Edit Customer",
            element: <EditCustomer />,
          },
          {
            index: 23,
            path: "detailcustomer",
            title: "Detail Customer",
            element: <DetailCustomer />,
          },
          {
            index: 24,
            path: "facility",
            title: "Facilities",
            element: <Facility />,
          },
          {
            index: 25,
            path: "addfacility",
            title: "Add facility",
            element: <AddFacility />,
          },
          {
            index: 26,
            path: "editfacility",
            title: "Edit Facility",
            element: <EditFacility />,
          },
          {
            index: 27,
            path: "detailfacility",
            title: "Detail Facility",
            element: <DetailFacility />,
          },
        ],
      },
      {
        path: "user/*",
        element: <Outlet />,
        children: [
          { index: 8, path: "roles", title: "Roles", element: <Roles /> },
          {
            index: 9,
            path: "addrole",
            title: "Add Role",
            element: <AddRole />,
          },
          {
            index: 10,
            path: "editrole",
            title: "Edit Role",
            element: <EditRole />,
          },
          {
            index: 11,
            path: "detailrole",
            title: "Detail Role",
            element: <DetailRole />,
          },
          { index: 12, path: "groups", title: "Groups", element: <Groups /> },
          {
            index: 13,
            path: "addgroup",
            title: "Add Group",
            element: <AddGroup />,
          },
          {
            index: 14,
            path: "editgroup",
            title: "Edit Group",
            element: <EditGroup />,
          },
          {
            index: 15,
            path: "detailgroup",
            title: "Detail Group",
            element: <DetailGroup />,
          },

          {
            index: 28,
            path: "userpolicies",
            title: "RoleGroup",
            element: <RoleGroup />,
          },
          {
            index: 29,
            path: "adduserpolicy",
            title: "Add RoleGroup",
            element: <AddRoleGroup />,
          },
          {
            index: 30,
            path: "edituserpolicy",
            title: "Edit RoleGroup",
            element: <EditRoleGroup />,
          },
          {
            index: 31,
            path: "detailuserpolicy",
            title: "Detail RoleGroup",
            element: <DetailRoleGroup />,
          },

          {
            index: 32,
            path: "organizationusers",
            title: "Organization Users",
            element: <Users />,
          },
          {
            index: 33,
            path: "adduser",
            title: "Add User",
            element: <AddUser />,
          },
          {
            index: 34,
            path: "edituser",
            title: "Edit User",
            element: <EditUser />,
          },

          {
            index: 35,
            path: "detailuser",
            title: "Detail User",
            element: <DetailUser />,
          },

          {
            index: 36,
            path: "customerusers",
            title: "Customer Users",
            element: <CustomerUsers />,
          },
          {
            index: 37,
            path: "addcustomeruser",
            title: "Add Customer User",
            element: <AddCustomerUser />,
          },
          {
            index: 38,
            path: "editcustomeruser",
            title: "Edit Customer User",
            element: <EditCustomerUser />,
          },
          {
            index: 39,
            path: "detailcustomeruser",
            title: "Detail Customer User",
            element: <DetailCustomerUser />,
          },
        ],
      },
      {
        path: "lms/*",
        element: <Outlet />,
        children: [
          {
            index: 8,
            path: "userlistholiday",
            title: "List Holiday",
            element: <UserListHoliday />,
          },
          {
            index: 8,
            path: "userdetailholiday",
            title: "Detail Holiday",
            element: <UserDetailHoliday />,
          },
          {
            index: 8,
            path: "listholiday",
            title: "List Holiday",
            element: <ListHoliday />,
          },
          {
            index: 9,
            path: "addholiday",
            title: "Add Holiday",
            element: <AddHoliday />,
          },
          {
            index: 9,
            path: "editholiday",
            title: "Edit Holiday",
            element: <EditHoliday />,
          },
          {
            index: 9,
            path: "detailholiday",
            title: "Detail Holiday",
            element: <DetailHoliday />,
          },
          {
            index: 10,
            path: "setting",
            title: " Setting",
            element: <Setting />,
          },
          {
            index: 41,
            path: "addsetting",
            title: "Add Setting",
            element: <AddSetting />,
          },
          {
            index: 42,
            path: "editsetting",
            title: "Edit Setting",
            element: <EditSetting />,
          },
          {
            index: 43,
            path: "detailsetting",
            title: "Detail Setting",
            element: <DetailSetting />,
          },
          {
            index: 44,
            path: "listapproval",
            title: "List Approval",
            element: <ListApproval />,
          },
          {
            index: 45,
            path: "detailapproval",
            title: "Detail Approval",
            element: <DetailApproval />,
          },
          {
            index: 45,
            path: "Editapproval",
            title: "Edit Approval",
            element: <EditApproval />,
          },

          {
            index: 43,
            path: "detailsetting",
            title: "Detail Setting",
            element: <DetailSetting />,
          },
          {
            index: 10,
            path: "listleaverequest",
            title: " ListLeaveRequest",
            element: <ListLeaveRequest />,
          },
          {
            index: 41,
            path: "addleaverequest",
            title: "Add LeaveRequest",
            element: <AddLeaveRequest />,
          },
          {
            index: 41,
            path: "detailleaverequest",
            title: "Detail LeaveRequest",
            element: <DetailLeaveRequest />,
          },
          {
            index: 41,
            path: "editleaverequest",
            title: "Edit LeaveRequest",
            element: <EditLeaveRequest />,
          },
          {
            index: 41,
            path: "listtransaction",
            title: "List Leave Transaction",
            element: <ListLeaveTransaction />,
          },

          {
            index: 10,
            path: "listleavetype",
            title: " List Leave Type",
            element: <ListLeaveType />,
          },
          {
            index: 41,
            path: "addleavetype",
            title: "Add Leave Type",
            element: <AddLeaveType />,
          },
          {
            index: 42,
            path: "editleavetype",
            title: "Edit Leave Type",
            element: <EditLeaveType />,
          },
          {
            index: 43,
            path: "detailleavetype",
            title: "Detail Leave Type",
            element: <DetailLeaveType />,
          },
          {
            index: 43,
            path: "report",
            title: "Report",
            element: <Report />,
          },

          {
            index: 43,
            path: "about",
            title: "About",
            element: <About />,
          },
        ],
      },
    ],
  },
];

export default AppRoute;
