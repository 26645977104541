import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes } from "react-router-dom";
import AppRoute from './config/app-route.jsx';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import {  RealmAppProvider } from "./providers/RealmApp";

import { Provider } from 'react-redux';
import store from './storestate/store';

export const APP_ID = process.env.REACT_APP_REALM_APP_ID;

const container = document.getElementById('root');
const root = createRoot(container);
function App() {
	let element = useRoutes(AppRoute);
	
	return element;
}

root.render(
  <BrowserRouter>
  	<Provider store={store}>
  	<RealmAppProvider appId={APP_ID}>
    <App />
    </RealmAppProvider>
    </Provider>
  </BrowserRouter>
);
