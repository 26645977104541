/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useMemo, useEffect, useRef } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getLeaveRequest } from "../../../services/leaverequestService";
import { getAllLeaveApproval } from "../../../services/leave_approvalService";
import GlobalListTable from "../../../components/globalTable/gListTable";
import GlobalListPrintTable from "../../../components/globalTable/gListPrintTable";
import { useReactToPrint } from "react-to-print";
//import SweetAlert from 'react-bootstrap-sweetalert';

const ListApproval = (props) => {
  //context variables


  const context = useContext(AppSettings);
  const jackapp = useRealmApp();

  //state variables
  const [leaveapproval, setleaveapproval] = useState([]);
  const [tabLeaveApprovalinfo, setLeaveApprovalinfo] = useState(true);
  //print variables & functions
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //table functions
  const data = useMemo(() => [...leaveapproval], [leaveapproval]);
  const LeaveApprovalColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Leave Type",
        accessor: "leave_name",
        sortable: true,
        width: "5%",
      },
      {
        Header: "Leave Description",
        accessor: "leave_description",
        sortable: true,
        width: "20",
      },

      {
        Header: "From Date",
        accessor: "from_date",
        sortable: true,
        width: "10%",
      },
      {
        Header: "To Date",
        accessor: "to_date",
        sortable: true,
        width: "10%",
      },
      {
        Header: "Total  Days",
        accessor: "total_days",
        sortable: true,
        width: "10%",
      },
      {
        Header: "Request By",
        accessor: "fullname",
        sortable: true,
        width: "20%",
      },

      {
        Header: "Status",
        accessor: "status_name",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Leave Status Comments",
        accessor: "leave_reason",
        sortable: true,
        width: "20%",
      },
      // {
      //   Header: "Created On",
      //   accessor: "activity_on",
      //   sortable: true,
      //   width: "10%",
      //   Cell: ({ value }) => Moment(value).format(context.appMomentDateFormat),
      // },
      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    [context.appMomentDateFormat]
  );

  const hiddenLeaveApprovalColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
    "leave_description",
    "is_active",
    "leave_reason",


  ];
  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setLeaveApprovalinfo(true);
        return;
      default:
        break;
    }
  };

  useEffect(() => {


    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadLeaveApproval(jackapp.currentUser);
  }, []);

  //internal functions
  async function loadLeaveApproval(userInfo) {
    if (leaveapproval.length === 0)
      getAllLeaveApproval(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          setleaveapproval(JSON.parse(response));
          // console.log("Response : ", response);
        }
      });
  }

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/listapproval">Leave Approval</Link>
          </li>
          <li className="breadcrumb-item active bold">List Leave Approval</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/lms/leaverequest"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabLeaveApprovalinfo ? "active " : "")
                      }
                    >
                      All
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">

                {/* <Button
                  onClick={handlePrint}
                  className="btn btn-success btn-rounded px-4 rounded-pill"
                >
                  <i className="fa fa-print fa-lg me-2 ms-n2 text-success-900"></i>{" "}
                  Print
                </Button> */}
              </ButtonGroup>
            </div>
          </PanelHeader>
          <PanelBody>
            <GlobalListTable
              gcolumns={LeaveApprovalColumns}
              gdata={data !== undefined ? JSON.stringify(data) : ""}
              ghiddencolumns={hiddenLeaveApprovalColumns}
              gkey={"holiday_id"}
              glink="/lms/detailapproval"
              gplaceholder={"Search leaveapproval.."}
            />
          </PanelBody>
          {/* <PanelBody className="invisible">
            <GlobalListPrintTable
              gcolumns={LeaveApprovalColumns}
              gdata={data !== undefined ? JSON.stringify(data) : ""}
              ghiddencolumns={hiddenLeaveApprovalColumns}
              gkey={"holiday_id"}
              glink="/lms/detailapproval"
              gplaceholder={"Search leave approval.."}
              ref={componentRef}
            />
          </PanelBody> */}
        </Panel>
      </div>
    </div>
  );
};

export default ListApproval;
