import React, { useState, useMemo, useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup } from "reactstrap";
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getSetting } from "../../../services/settingsService";
import GlobalListTable from "../../../components/globalTable/gListTable";
//import SweetAlert from 'react-bootstrap-sweetalert';

const Setting = (props) => {
  //context variables
  let navigate = useNavigate();
  const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variables
  const [setting, setsetting] = useState([]);
  const [tabuserinfo, setuserinfo] = useState(true);
  //table functions
  const data = useMemo(() => [...setting], [setting]);
  const settingColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Organization Name",
        accessor: "organization_name",
        sortable: true,
        width: "30%",
      },

      {
        Header: "Starting Year",
        accessor: "starting_year",
        sortable: true,
        width: "30%",
      },
      {
        Header: "Ending Year",
        accessor: "ending_year",
        sortable: true,
        width: "30%",
      },
      //      {
      //       Header: "Customer Name",accessor: 'customer_name',sortable:true,width: '30%'
      //  },
      // {
      //      Header: "Facility",accessor: 'facility_name',sortable:true,width: '30%'
      // },

      {
        Header: "Active",
        accessor: "is_active",
        sortable: true,
        width: "10%",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddensettingColumns = ["_id", "company_logo"];

  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setuserinfo(true);
        return;
    }
  };
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadsetting(jackapp.currentUser);
  }, []);

  //internal functions
  async function loadsetting(userInfo) {
    //console.log("userInfo : ", userInfo);

    getSetting(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        //	console.log("12" +response.map());
        setsetting(JSON.parse(response));
      }
    });
  }

  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/setting"> Setting</Link>
          </li>
          <li className="breadcrumb-item active bold">List setting</li>
        </ol>
      </div>
      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/setting"
                      onClick={(e) => showTab(e, "info")}
                      className={"nav-link " + (tabuserinfo ? "active " : "")}
                    >
                      All
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Link
                  to="/lms/addsetting"
                  href="#"
                  className="btn btn-success btn-rounded px-4 rounded-pill"
                >
                  <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
                  Add Setting
                </Link>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <PanelBody>
            <GlobalListTable
              gcolumns={settingColumns}
              gdata={data !== undefined ? JSON.stringify(data) : ""}
              ghiddencolumns={hiddensettingColumns}
              gkey={"setting_id"}
              glink="/lms/detailsetting"
              gplaceholder={"Search  Setting.."}
            />
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default Setting;
