import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody} from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { createOrganization } from "../../../services/organizationService";
import SweetAlert from "react-bootstrap-sweetalert";
const AddOrganization = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [organization, setOrganization] = useState({
    organization_name: "",
    root_url: "",
    login_url: "",
  });
  const [errors, setErrors] = useState({
    organization_name: "",
    root_url: "",
    login_url: "",
  });
  const [tabOrganizationinfo, setOrganizationsnfo] = useState(true);
  //save functions

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setOrganization({ ...organization, [name]: value });
    handleFieldValidation(event);
    // console.log(organization);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        saveOrganization(jackapp.currentUser);
    }
    async function saveOrganization(userInfo) {
      createOrganization(
        organization.organization_name,
        organization.root_url,
        organization.login_url,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setOrganization(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      organization_name:
        organization.organization_name === ""
          ? (iserror = "Organization Name is required")
          : errors.organization_name,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "organization_name":
        localerror =
          value.length < 3 ? "Organization Name must be at least 3 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/settings/organization",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
    setOrganizationsnfo(true);
        return; 
        default:
          break;
  }
  };
  useEffect(() => {}, []);
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/settings/organization">Organization</Link>
        </li>
        <li className="breadcrumb-item active bold">Add Organization</li>
      </ol>
    </div>

    <div className="col-xl-12">
      <Panel>
        <PanelHeader noButton={true}>
          <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
                <li className="nav-item" key="info">
                  <Link
                    to="/settings/addOrganization"
                    onClick={(e) => showTab(e, "info")}
                    className={"nav-link " + (tabOrganizationinfo ? "active " : "")}
                  >
                    Organization Information
                  </Link>
                </li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
              <Button
                onClick={handleSubmit}
                type="submit"
                color="primary"
                disabled={!isvaliddata}
                size="md"
                className="me-1"
              >
                <i className="fa fa-save"></i>&nbsp; Save
              </Button>
              <Button color="default" size="md" onClick={handleSweetAlert}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </PanelHeader>
        <form noValidate>
          <PanelBody>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <label className="form-label form-label ">
                    Organization Name <label  className="text-danger">*</label>
                  </label>

                  <input
                    type="text"
                    name="organization_name"
                    id="organization_name"
                    className="form-control"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.organization_name.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="organization_name"
                    >
                      {errors.organization_name}
                    </small>
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <label className="form-label form-label">Root Url</label>

                  <input
                    type="text"
                    name="root_url"
                    id="root_url"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {errors.root_url.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="root_url"
                    >
                      {errors.root_url}
                    </small>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <label className="form-label form-label">Login Url </label>
                  <input
                    type="text"
                    name="login_url"
                    id="login_url"
                    className="form-control"
                    onChange={handleChange}
                    value={organization.login_url}
                  />
                  {errors.login_url.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="login_url"
                    >
                      {errors.login_url}
                    </small>
                  )}
                </div>
              </div>
          

           
          </PanelBody>
        </form>
      </Panel>
    </div>
            <div>
                      {isSaved && (
                        <SweetAlert
                          success
                          style={{ color: "black" }}
                          title="Organization is added successfully.."
                          onConfirm={handleSweetAlert}
                        ></SweetAlert>
                      )}
           </div>
  </div>
  );
};

export default AddOrganization;
