// gTable.jsx

import React from "react";
import { useGlobalFilter,useTable, useSortBy, usePagination,useColumnOrder } from 'react-table'
import { useNavigate } from "react-router-dom";
export default function GlobalSimpleTable({ gcolumns, gdata ,ghiddencolumns,gkey,gfunction,gplaceholder}) {
    let navigate = useNavigate();
  // Use the useTable Hook to send the columns and data to build the table
  const getData = () => {
    if(gdata!==undefined)
    {
      var arr = [];
      var obj =JSON.parse(gdata);
      for(var i in obj)
      arr.push(obj[i]);
      return arr;}
return []

  }
  const data = React.useMemo(() => getData(), [gdata]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, 
    
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
	preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize ,globalFilter },
  } = useTable({ columns:gcolumns, data, 
	initialState: {hiddenColumns:ghiddencolumns, pageIndex: 0 } }, 
	useGlobalFilter,useSortBy, usePagination,useColumnOrder)
  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive mb-0">
    <table className="table table-hover table-panel align-middle mb-3" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th  {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className="d-flex align-items-center" >
                  <span>{column.render('Header')}</span>
                  <span className="ms-auto">
                    {column.sortable ?
                      column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                          : <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                        : <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                      : ''}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
     </thead>
     <tbody {...getTableBodyProps()}>
        {page.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr className="highlight" style={{cursor: 'pointer'}} {...row.getRowProps({
                onClick: (e) => {gfunction(gkey)} 
              })}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )}
        )}
     </tbody>
   </table>
 </div>
  );
}