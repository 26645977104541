import React, { useContext, useState, useEffect } from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/RealmApp";
import { Link } from "react-router-dom";
import { AppSettings } from "../../config/app-settings.js";
import { isEmail } from "validator";

export default function Login() {
  //context variables
  const app = useRealmApp();
  const context = useContext(AppSettings);

  //state variables
  const [values, setValues] = React.useState({
    email: "",
    password: "",
  });
  const [errorvalues, setErrorValues] = React.useState({
    email: "",
    password: "",
    remoteerror: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  useEffect(() => {
    if (context != null) {
      context.handleSetAppSidebarNone(true);
      context.handleSetAppHeaderNone(true);
      context.handleSetAppContentClass("p-0");
    }
  }, [context]);

  //save functions
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (errorvalues.remoteerror !== "")
      setErrorValues({ ...errorvalues, remoteerror: "" });
    setValues({ ...values, [name]: value });
    setisvaliddata(true);
    // console.log(roles);
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errorvalues).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let isemailerror = "";
    let ispassowrderror = "";
    setErrorValues({
      ...errorvalues,
      email: !isEmail(values.email)
        ? (isemailerror = "email is invalid")
        : values.email === ""
        ? "email is required"
        : "",
      password:
        values.password === ""
          ? (ispassowrderror = "password is required")
          : "",
    });
    // console.log(iserror);
    return isemailerror.length === 0 && ispassowrderror.length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // if (app.currentUser === null ||app.currentUser === undefined)
      handleLogin();
    }
  };

  const handleLogin = async () => {
    try {
      await app.logIn(
        Realm.Credentials.emailPassword(values.email, values.password)
      );
      //console.log(app);
      window.location.href = "/";
      //    navigate({
      //     pathname:  '/',
      //     // state: {
      //     //   response: messageFromServer
      //     // }
      //  });
    } catch (err) {
      console.log("err : " + err);
      handleAuthenticationError(err);
    }
  };

  function handleAuthenticationError(err) {
    const { status, message } = parseAuthenticationError(err);
    const errorType = message || status;
    switch (errorType) {
      case "invalid username":
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid email address",
        });
        break;
      case "invalid username/password":
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid username/password",
        });
        break;
      case "invalid password":
        setErrorValues({ ...errorvalues, remoteerror: "invalid  password" });
        break;
      case "401":
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid username/password",
        });
        break;
      case "name already in use":
      case "409":
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid username/password",
        });
        break;
      default:
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid username/password",
        });
        break;
    }
  }

  function parseAuthenticationError(err) {
    const parts = err.message.split(":");
    const reason = parts[parts.length - 1].trimStart();
    if (!reason) return { status: "", message: "" };
    const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
    const match = reason.match(reasonRegex);
    const { status, message } = match?.groups ?? {};
    return { status, message };
  }

  return (
    <div className="login login-with-news-feed">
      <div className="news-feed">
        <div
          className="news-image"
          style={{
            backgroundImage: "url(/assets/img/login-bg/login-bg-8.jpg)",
          }}
        ></div>
        <div className="news-caption">
          <h4 className="caption-title">
            <b>DS IT Consultancy</b> LMS
          </h4>
        </div>
      </div>
      <div className="login-container">
        <div className="login-header mb-30px">
          <div className="brand">
            <div className="d-flex align-items-center">
              <b>Login</b>
            </div>
          </div>
          <div className="icon">
            <i className="fa fa-sign-in-alt"></i>
          </div>
        </div>
        <div className="login-content">
          <div className="form-floating mb-15px">
            <input
              type="text"
              className="form-control h-45px fs-13px"
              placeholder="Email Address"
              id="email"
              name="email"
              value={values.email}
              onChange={(e) => {
                handleChange(e);
              }}
            />

            <label
              htmlFor="emailAddress"
              className="d-flex align-items-center fs-13px text-gray-600"
            >
              Email Address
            </label>
            <div>
              {errorvalues.email.length > 0 && (
                <label
                  className="form-check-label text-red"
                  htmlFor="emailAddress"
                >
                  {errorvalues.email}
                </label>
              )}
            </div>
          </div>

          <div className="form-floating mb-15px">
            <input
              type="password"
              className="form-control h-45px fs-13px"
              placeholder="Password"
              id="password"
              name="password"
              value={values.password}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label
              htmlFor="password"
              className="d-flex align-items-center fs-13px text-gray-600"
            >
              Password
            </label>
            <div>
              {errorvalues.password.length > 0 && (
                <label
                  className="form-check-label text-red"
                  htmlFor="emailAddress"
                >
                  {errorvalues.password}
                </label>
              )}
            </div>
          </div>

          <div className="mb-15px">
            <button
              onClick={handleSubmit}
              className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
              disabled={!isvaliddata}
            >
              Sign me in
            </button>
          </div>
          <div ><center>

            <a href="/authenticate/forgot">Forgot Password</a></center>
          </div>
          <div>
            <br className="bg-gray-600 opacity-2" />
            {errorvalues.remoteerror && (
              <h4 className="form-check-label text-red" htmlFor="remoteerror">
                {errorvalues.remoteerror}
              </h4>
            )}
          </div>

          <br className="bg-gray-600 opacity-2" />
          <br className="bg-gray-600 opacity-2" />
          <hr className="bg-gray-600 opacity-2" />
          <div className="text-gray-600 text-center text-gray-500-darker mb-0">
            &copy; DS IT Consultancy @ 2022
          </div>
        </div>
      </div>
    </div>
  );
}
