export const getAllStateForLookup = async (loggedinby, jackapp) => {
  try {
    const data = await jackapp.functions.getStateForLookup(loggedinby,"",'');
    if (data !== undefined) {
      //console.log("States" + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllStateForLookupByCountry = async (loggedinby, jackapp,countryid) => {
  try {
    const data = await jackapp.functions.getStateForLookup(loggedinby,countryid,'');
    if (data !== undefined) {
      //console.log("States" + data);
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllStatesByID = async (StateId, jackapp) => {
  try {
    const { data } = null;
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createState = async (
  alpha_code,
  code,
  State_name,
  description,
  flag_path,
  internet_code,
  numeric_code,
  official_state_name,
  sovereignty,
  jackapp
) => {
  try {
    const { data } = null;
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateStateByID = async (
  id,
  alpha_code,
  code,
  State_name,
  description,
  flag_path,
  internet_code,
  numeric_code,
  official_state_name,
  sovereignty,
  loggedinby,
  jackapp
) => {
  try {
    const { data } = null;
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteStateByID = async (id, loggedinby, jackapp) => {
  try {
    const { data } = null;
    return { status: "ok", data };
  } catch (error) {
    return error.response.data.message;
  }
};
