import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import {getFacilityByID,updateFacilityByID,} from "../../../services/facilityService";

import SweetAlert from "react-bootstrap-sweetalert";
//import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllStateForLookupByCountry } from "../../../services/stateService.jsx";
import { getCountryForLookup } from "../../../services/countryService.jsx";
import { getAllCustomerForLookup } from "../../../services/customerService";
import styles from "../../../components/customstyles/react-select-styles";
import ActivityHistory from '../../../components/activityHistory/activityHistory';
//import {useContext} from 'react';
const EditFacility = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const [tabFacilityinfo, setFacilityinfo] = useState(true);
  const [tabActivityinfo, setActivityinfo] = useState(false);
  const jackapp = useRealmApp();
  //state variable
  const statuschecked = true;
  const [facility, setFacility] = useState({
    _id: "",
    facility_code: "",
    facility_name: "",
    address: {
      _id: "",
      type: "",
      title: "",
      street: "",
      suite: "",
      country: "",
      states: "",
      city: "",
      post_code: "",
      phone: "",
      email: "",
      fax: "",
    },
    social_media: [
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
      {
        id: "",
        type: "",
        title: "",
        link: "",
      },
    ],
    notes: "",
    customer_id: "",
    organization_id: "",
    is_active: "",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    _id: "",
    facility_code: "",
    facility_name: "",
    customer_id: "",
    organization_id: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);

  const keyid = useLocation().state.facility_id;
  //const [OrganizationList, setOrganizationList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [customerList, setcustomerList] = useState([]);

  //savefunctions
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    // setFacility({ ...facility, [name]: value });
    const path = name.split('.');
    const finalProp = path.pop();
    const newfaciity = { ...facility };
    let pointer = newfaciity;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =value;
    if(name==="address.country")
    loadStates("0",value);
      setFacility(newfaciity);
     
    setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    // let target = event.target;
    // setFacility({ ...facility, [target.name]: target.value });
    const path = event.target.name.split('.');
    const finalProp = path.pop();
    const newfaciity = { ...facility };
    let pointer = newfaciity;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      setFacility(newfaciity);
    handleFieldValidation(event);
    // console.log(facility);
  };
  function handleArrayObjectChange(e, itemId, property) {
    const value = e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...facility.social_media];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex(item => item.id === itemId);
    // -1 check to see if we found that object in social_media
    if(index !== -1){
      temparrayprops[index] = {
         ...temparrayprops[index], //keeping existing values in object
         [property]: value  //here property can be "link" 
       }
    }
    setFacility({ ...facility, social_media: temparrayprops })
    handleFieldValidation(e);
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        
        updateFacility(jackapp.currentUser);
      }
    }
    async function updateFacility(userInfo) {
      updateFacilityByID(
        facility._id,
        facility.facility_code,
        facility.facility_name,
        facility.notes,
        facility.customer_id,
        facility.organization_id,
        facility.address.street,
        facility.address.suite,
        facility.address.country,
        facility.address.states,
        facility.address.city,
        facility.address.post_code,
        facility.address.phone,
        facility.address.email,
        facility.address.fax,
        facility.social_media[0].link,
        facility.social_media[1].link,
        facility.social_media[2].link,
        facility.social_media[3].link,
        facility.social_media[4].link,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          // setFacility(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });

      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      facility_code:
        facility.facility_code === 0
          ? (iserror = "Facility code is required")
          : errors.facility_code,
      facility_name:
        facility.facility_name === 0
          ? (iserror = "Facility name is required")
          : errors.facility_name,
      // organization_id:
      //   facility.organization_id === "" || facility.organization_id === ""
      //     ? (iserror = "Organization is required")
      //     : errors.organization_id,
      customer_id:
        facility.customer_id === "" || facility.customer_id === ""
          ? (iserror = "customer is required")
          : errors.customer_id,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "facility_name":
        localerror =
          value.length < 3 ? "Facility Name must be at least 3 characters" : "";
        break;
      // case "organization_id":
      //   localerror = value.length === "" ? "Organization is missing." : "";
      //   break;
      case "customer_id":
        localerror = value.length === "" ? "customer is missing." : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/settings/facility",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setFacilityinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setFacilityinfo(false);
            setActivityinfo(true);
          return;
          default:
            return;
  }
  };
  async function loadStates(itemid,country_id) {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      
      getAllStateForLookupByCountry(1, jackapp.currentUser,country_id).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let statedata = JSON.parse(response);
        let options = statedata.map(function (state) {
          return { value: state.id, label: state.name };
        });
        setStateList(options);
        //console.log("state1" + response);
      }
    });
  
    }
  }
  useEffect(() => {
    //internal functions
    async function loadFacilityByID(userInfo) {
      getFacilityByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let facilitys = JSON.parse(response);
          facilitys.map((facility) => {
            //console.log("facility._id", facility._id);
            if (facility.address.country !== null && facility.address.country !== "") {
              loadStates("0",facility.address.country);
            }
            setFacility({ ...facility, _id: facility._id });
          });
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadFacilityByID(jackapp.currentUser);
    //  console.log("facility.facility_name ", facility.facilityele_name);
    }
  }, [keyid, jackapp.currentUser]);

  useEffect(() => {
    //internal functions
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadCountry(jackapp.currentUser);
    }
    async function loadCountry(userInfo) {
      getCountryForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let countrydata = JSON.parse(response);
          let options = countrydata.map(function (country) {
            return { value: country.id, label: country.name };
          });
          setCountryList(options);
          //console.log("state1" + response);
        }
      });
    }
    // if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
    //   loadOrganization(jackapp.currentUser);
    // }

    // async function loadOrganization(userInfo) {
    //   getAllOrganizationForLookup(1, userInfo).then((response) => {
    //     if (response !== undefined) {
    //       //let data[] = response;
    //       let Organizationdata = JSON.parse(response);
    //       let options = Organizationdata.map(function (organization) {
    //         return { value: organization._id, label: organization.organization_name };
    //       });
    //       setOrganizationList(options);
    //       //console.log("Organization1" + response);
    //     }
    //   });
    // }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, [jackapp.currentUser]);
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/settings/facility">Facility</Link>
          </li>
          <li className="breadcrumb-item active bold">Edit Facility</li>
        </ol>
      </div>
      <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabFacilityinfo ? 'show active ': '')}>
               <div className="col-xl-12">
        <Panel>
      		 <PanelHeader noButton={true}>
                        <div className="d-flex align-items-center">
                        <div>
                          <ul className="nav nav-tabs nav-tabs-inverse">
                          <li className="nav-item me-2" key="info">
                            <Link
                            to="/extra/profile"
                            onClick={(e) => showTab(e, "info")}
                            className={"nav-link " + (tabFacilityinfo ? "active " : "")}
                            >
                            Basic Info
                            </Link>
                          </li>
                          <li className="nav-item" key="activityinfo">
                            <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                            className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
                          </ul>
                        </div>
                         <ButtonGroup className="ms-auto my-n1">
						  <Button
							type="submit"
							onClick={handleSubmit}
							color="primary"
							disabled={!isvaliddata}
							size="md"
							className="me-1"
						  >
							<i className="fa fa-save"></i>&nbsp; Save
						  </Button>
						  <Button color="default" size="md" onClick={handleSweetAlert}>
							Cancel
						  </Button>
						</ButtonGroup>
                        </div>
                    </PanelHeader>

            <form noValidate>
            <PanelBody className="p-1 m-1">
              <div className="tab-content p-0">
                <div
                  className={
                    "tab-pane fade " + (tabFacilityinfo ? "show active " : "")
                  }
                >
                  <div className="row">
                    <div className="col-xl-12 col">
                      <Panel>
                        <PanelHeader noButton={true} minButton={true}>
                          Basic Info
                        </PanelHeader>
                        <PanelBody className="p-0 m-1">
                          <div className="row mb-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label ">
                                Facility Code <label className="text-danger">*</label>
                              </label>

                              <input
                                type="text"
                                name="facility_code"
                                id="facility_code"
                                className="form-control"
                                onChange={handleChange}
                                value={facility.facility_code}
                                noValidate
                              />
                              {errors.facility_code.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="facility_code"
                                >
                                  {errors.facility_code}
                                </small>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">
                                Facility Name <label className="text-danger">*</label>
                              </label>

                              <input
                                type="text"
                                name="facility_name"
                                id="facility_name"
                                className="form-control"
                                value={facility.facility_name}
                                onChange={handleChange}
                              />
                              {errors.facility_name.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="facility_name"
                                >
                                  {errors.facility_name}
                                </small>
                              )}
                            </div>
                            {/* <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Organization *</label>
                              <Select
                                name="organization_id"
                                options={OrganizationList}
                                isSearchable={true}
                                styles={styles}
                                value={OrganizationList.filter(
                                  (organization) =>
                                    organization.value === facility.organization_id
                                )}
                                onChange={(val) => {
                                  handleDropDownChange({
                                    target: {
                                      name: "organization_id",
                                      value: val.value,
                                    },
                                  });
                                }}
                              ></Select>
                              {errors.organization_id.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="organization_id"
                                >
                                  {errors.organization_id}
                                </small>
                              )}
                            </div> */}
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Customer <label className="text-danger">*</label></label>
                              <Select
                                name="customer_id"
                                id="customer_id"
                                options={customerList}
                                isSearchable={true}
                                styles={styles}
                                value={customerList.filter(
                                  (client) =>
                                    client.value === facility.customer_id
                                )}
                                onChange={(val) => {
                                  handleDropDownChange({
                                    target: {
                                      name: "customer_id",
                                      value: val.value,
                                    },
                                  });
                                }}
                              ></Select>
                              {errors.customer_id.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="customer_id"
                                >
                                  {errors.customer_id}
                                </small>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Website</label>
                                <input
                                  type="text"
                                  name="website"
                                  id="website"
                                  className="form-control"
                                  onChange={(e) => handleArrayObjectChange(e, "1", "link")}
                                  value={facility.social_media[0].link}
                                />
                              </div>
                          </div>
                          <div className="row  mb-1">
                            <div className="col-sm-12 col-md-9 col-lg-9 ">
                              <label className="form-label">Notes</label>

                              <input
                                type="text"
                                name="notes"
                                id="notes"
                                value={facility.notes}
                                className="form-control"
                                onChange={handleChange}
                              />
                            </div>
                          
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Active</label>
                                <div className="form-check form-switch ms-auto mb-0">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    disabled="disabled"
                                    name="isactive"
                                    checked={statuschecked}
                                    id="isactive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="isactive"
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </div>
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                    <div className="row p-0 m-0">
                    <div className=" col-xl-8 col ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Contact Info{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-1">
                          <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Street</label>
                                <input
                                  type="text"
                                  name="address.street"
                                  id="address.street"
                                  value={facility.address.street}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <label className="form-label">Suite</label>

                                <input
                                  type="text"
                                  name="address.suite"
                                  id="address.suite"
                                  value={facility.address.suite}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3 ">
                                <label className="form-label form-label">
                                  Country{" "}
                                </label>
                                <Select
                                  name="country"
                                  options={countryList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={countryList.filter(
                                    (country) =>
                                      country.value === facility.address.country
                                  )}
                                  onChange={(val) => {
                                    handleDropDownChange({
                                      target: {
                                        name: "address.country",
                                        value: val.value,
                                      },
                                    });
                                  }}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 ">
                                <label className="form-label form-label">
                                  State{" "}
                                </label>
                                <Select
                                  name="states"
                                  options={stateList}
                                  isSearchable={true}
                                  styles={styles}
                                  value={stateList.filter(
                                    (state) =>
                                      state.value === facility.address.states
                                  )}
                                  onChange={(val) => {
                                    handleDropDownChange({
                                      target: {
                                        name: "address.states",
                                        value: val.value,
                                      },
                                    });
                                  }}
                                ></Select>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 ">
                                <label className="form-label">City</label>

                                <input
                                  type="text"
                                  name="address.city"
                                  id="address.city"
                                  value={facility.address.city}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 ">
                                <label className="form-label">
                                  Postal Code
                                </label>

                                <input
                                  type="text"
                                  name="address.post_code"
                                  id="address.post_code"
                                  value={facility.address.post_code}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Phone</label>

                                <input
                                  type="text"
                                  name="address.phone"
                                  id="address.phone"
                                  value={facility.address.phone}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3">
                                <label className="form-label">Fax</label>

                                <input
                                  type="text"
                                  name="address.fax"
                                  id="address.fax"
                                  value={facility.address.fax}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label">Email</label>

                                <input
                                  type="text"
                                  name="address.email"
                                  id="address.email"
                                  value={facility.address.email}
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className="col-xl-4  col  ms-0">
                        <Panel>
                          <PanelHeader noButton={true} minButton={true}>
                            Social Media handles{" "}
                          </PanelHeader>
                          <PanelBody className="p-0 m-0">
                            <div className="row  p-1">
                              <div className="col-12">
                                <label className="form-label">LinkedIn</label>
                                <input
                                  type="text"
                                  name="social_media"
                                  id="social_media"
                                  className="form-control"
                                  onChange={(e) => handleArrayObjectChange(e, "2", "link")}
                                  value={facility.social_media[1].link}
                                />
                              </div>
                            </div>
                            <div className="row  pb-1">
                              <div className="col-12">
                                <label className="form-label">Facebook</label>
                                <input
                                  type="text"
                                  name="facebook"
                                  id="facebook"
                                  className="form-control"
                                  onChange={(e) => handleArrayObjectChange(e, "3", "link")}
                                  value={facility.social_media[2].link}
                                />
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-6">
                                <label className="form-label">Twitter</label>

                                <input
                                  type="text"
                                  name="twitter"
                                  id="twitter"
                                  className="form-control"
                                  onChange={(e) => handleArrayObjectChange(e, "4", "link")}
                                  value={facility.social_media[3].link}
                                />
                              </div>
                          
                              <div className="col-6">
                                <label className="form-label">Instagram</label>

                                <input
                                  type="text"
                                  name="instagram"
                                  id="instagram"
                                  className="form-control"
                                  onChange={(e) => handleArrayObjectChange(e, "5", "link")}
                                  value={facility.social_media[4].link}
                                />
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {isSaved && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="Facility is updated successfully.."
                    onConfirm={handleSweetAlert}
                  ></SweetAlert>
                )}
              </div>
            </PanelBody>
            
          </form>
        </Panel>
              </div>
          </div>
          <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
                <div className="col-xl-12">
                    <Panel>
                    
				
				 <PanelHeader noButton={true}>
                        <div className="d-flex align-items-center">
                        <div>
                          <ul className="nav nav-tabs nav-tabs-inverse">
                          <li className="nav-item me-2" key="info">
                            <Link
                            to="/extra/profile"
                            onClick={(e) => showTab(e, "info")}
                            className={"nav-link " + (tabFacilityinfo ? "active " : "")}
                            >
                            Basic Info
                            </Link>
                          </li>
                          <li className="nav-item" key="activityinfo">
                            <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                            className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
                          </ul>
                        </div>
                         <ButtonGroup className="ms-auto my-n1">
                            <Button
                            type="submit"
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!isvaliddata}
                            size="md"
                            className="me-1"
                            >
                            <i className="fa fa-save"></i>&nbsp; Save
                            </Button>
                            <Button color="default" size="md" onClick={handleSweetAlert}>
                            Cancel
                            </Button>
						            </ButtonGroup>
                        </div>
                    </PanelHeader>


                    <PanelBody>
                        <ActivityHistory activityhistory={facility!==undefined?JSON.stringify(facility.activity_history):""}/>
                    </PanelBody>
                    </Panel>
                  </div>
				    </div>
      </div>
               <div>
              {isSaved && (
                <SweetAlert
                  success
                  style={{ color: "black" }}
                  title="Facility is updated successfully.."
                  onConfirm={handleSweetAlert}
                ></SweetAlert>
              )}
            </div>
    </div>
  );
};

export default EditFacility;
