import React, { useState, useMemo, useEffect } from "react";
import { ButtonGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRealmApp } from "../../providers/RealmApp";
import { isEmail } from "validator";
import SweetAlert from "react-bootstrap-sweetalert";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
const ForgetPassword = (props) => {
  //state variavles
 
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isForgetPassword, setisForgetPassword] = useState(false);
  const [passwordinfo, setpasswordinfo] = useState({
    user_id: "",
    realm_user_id: "",
    email: "",
    password: "",
    confirm_password: "",
   
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirm_password: "",
    remoteerror: "",
  });
  //context variables
  let navigate = useNavigate();
  const jackapp = useRealmApp();
  //state variables
  const [tabpasswordinfo, settabpasswordinfo] = useState(true);
  //table functions


  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newpassword = { ...passwordinfo };
    let pointer = newpassword;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setpasswordinfo(newpassword);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if(jackapp.currentUser)
      {
        try
        {
        await jackapp.ForgetPassword(passwordinfo.email,passwordinfo.password);
        setisForgetPassword(true);
       }
       catch(err)
       {
        setErrors({...errors,remoteerror:err});
       }
     
      }
    }
  };

 

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

 
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      email: passwordinfo.email === "" ? (iserror = "Email is required") : errors.email,
      password:
        passwordinfo.password === ""
          ? (iserror = "Password is required")
          : errors.email,

      password:
        passwordinfo.password === ""
          ? (iserror = "Password is required")
          : errors.password,
      confirm_password:
        passwordinfo.confirm_password === ""
          ? (iserror = "Confirm Password is required")
          : passwordinfo.confirm_password !== passwordinfo.password
          ? (iserror = "Password & Confirm Password are mismatched")
          : errors.confirm_password,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email":
        localerror =
          !isEmail(value) ? "Invalid Email" : "";
        break;
      case "password":
        localerror =
          value.length < 6 ? "password must be at least 6 characters" : "";
        break;
      case "confirm_password":
        localerror =
          value != passwordinfo.password
            ? "Password & Confirm Password are mismatched"
            : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        settabpasswordinfo(true);
        return;
      default:
        return;
    }
  };

  const handleSweetAlert = async () => {
    setisForgetPassword(false);

    await jackapp.logOutAllUsers();
      window.location.href = "/user/login";
  };

  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) 
		{
      setpasswordinfo({ ...passwordinfo, email: jackapp.currentUser._profile.data.email });

    }
	}, [jackapp.currentUser])
 
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/reference/passwords">Users</Link>
          </li>
          <li className="breadcrumb-item active bold">Change Password</li>
        </ol>
      </div>
      <div className="tab-content p-0">
        <div className={"tab-pane fade " + (tabpasswordinfo ? "show active " : "")}>
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex align-items-center">
                  <div>
                    <ul className="nav nav-tabs nav-tabs-inverse">
                      <li className="nav-item me-2" key="info">
                        <Link
                          to="/extra/profile"
                          onClick={(e) => showTab(e, "info")}
                          className={
                            "nav-link " + (tabpasswordinfo ? "active " : "")
                          }
                        >
                          Password Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ButtonGroup className="ms-auto my-n1">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={!isvaliddata}
                      size="md"
                      className="me-1"
                    >
                      <i className="fa fa-save"></i>&nbsp; Change Password
                    </Button>
                    <Button
                      color="default"
                      size="md"
                      onClick={handleSweetAlert}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              </PanelHeader>

              <form noValidate>
                <PanelBody className="p-0 m-2 border-1">
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-xl-10  col ms-0 ">
                        <Panel>
                       
                          <PanelBody className="p-0 m-0">
                            <div className="row p-1">
                             
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label">
                                  Email *
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                 value={passwordinfo.email}
                                  noValidate
                                  disabled={true}
                                />
                                {errors.email.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="email"
                                  >
                                    {errors.email}
                                  </small>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Password *
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="form-control"
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.password.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="password"
                                  >
                                    {errors.password}
                                  </small>
                                )}
                              </div>

                              <div className="col-sm-12 col-md-4 col-lg-3">
                                <label className="form-label form-label ">
                                  Confirm Password *
                                </label>
                                <input
                                  type="password"
                                  name="confirm_password"
                                  id="confirm_password"
                                  className="form-control"
                                  value={passwordinfo.confirm_password}
                                  onChange={handleChange}
                                  noValidate
                                />
                                {errors.confirm_password.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="confirm_password"
                                  >
                                    {errors.confirm_password}
                                  </small>
                                )}
                              </div>
                            </div>
                          </PanelBody>
                        </Panel>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </form>
            </Panel>
          </div>
        </div>
      </div>
      <div>
        {isForgetPassword && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Password is changed successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
        {errors.remoteerror.length > 0 && (
                                  <small
                                    className="form-check-label text-red"
                                    htmlFor="remoteerror"
                                  >
                                    {errors.remoteerror}
                                  </small>
                                )}
      </div>
    </div>
  );
};

export default ForgetPassword;
