/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Panel,PanelHeader,PanelBody,} from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
import Select from "react-select";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import { getGroupByID, updateGroupByID } from "../../../services/groupService";
import SweetAlert from "react-bootstrap-sweetalert";
//import { isNumeric } from "validator";
import { getAllOrganizationForLookup } from "../../../services/organizationService";
import { getAllCustomerForLookup } from "../../../services/customerService";
//import { getAllFacilityForLookup } from "../../../services/facilityService";
import styles from '../../../components/customstyles/react-select-styles';
import ActivityHistory from '../../../components/activityHistory/activityHistory';
//import {useContext} from 'react';
const EditGroup = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variable
  const [tabGroupsinfo, setGroupsinfo] = useState(true);
const [tabActivityinfo, setActivityinfo] = useState(false);
  const [group, setGroup] = useState({
    _id: "",
    group_short_name: "",
    group_description: "",
   // facility_id: 0,
   customer_id: "",
    organization_id: "",
    is_active: "1",
    activity_history: [
      {
        activity_type: "",
        activity_by_id: "",
        activity_by_name: "",
        activity_by_email: "",
        activity_by_clientee: "",
        activity_on: "",
      }],
  });
  const [errors, setErrors] = useState({
    _id: "",
    group_short_name: "",
    group_description: "",
    //facility_id: 0,
    customer_id: "",
    organization_id: "",
    is_active: "",
    created_on: "",
    deleted_on: "",
    deleted_by: "",
    created_by: "",
    last_modified_on: "",
    last_modified_by: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);

  const keyid = useLocation().state.group_id;
  const [OrganizationList, setOrganizationList] = useState([]);
  //const [facilityList, setFacilityList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  //savefunctions
  const handleDropDownChange = selectedOption => {
    const { name, value } = selectedOption.target;
    setGroup({ ...group, [name]: value });
    setisvaliddata(true);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    let target = event.target;
    setGroup({ ...group, [target.name]: target.value });
    handleFieldValidation(event);
    // console.log(group);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
        updateGroup(jackapp.currentUser);
    }
    async function updateGroup(userInfo) {
      updateGroupByID(
        group._id,
        group.group_short_name,
        group.group_description,
       // group.facility_id,
       group.customer_id,
        group.organization_id,
        userInfo
      ).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          //	console.log("12" +response.map());
          //setGroups(JSON.parse(response));
          setisSaved(true);
        }
      });
    }
  };
  //validation functions
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      // facility_id:
      //   group.facility_id === "0" || group.facility_id === ""
      //     ? (iserror = "Facility is required")
      //     : errors.facility_id,
      organization_id:
        group.organization_id === "0" || group.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
          customer_id:
          group.customer_id === "0" || group.customer_id === ""
            ? (iserror = "Customer is required")
            : errors.customer_id,
      group_short_name:
        group.group_short_name === ""
          ? (iserror = "Group Short Name is required")
          : errors.group_short_name,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "group_short_name":
        localerror =
          value.length < 3
            ? "Group Short Name must be at least 3 characters"
            : "";
        break;
      case "group_description":
        localerror =
          value.length > 255
            ? "Group Description must be less than 255 characters"
            : "";
        break;
      // case "facility_id":
      //   localerror =
      //     value.length <= 0 ? "facility_id information is missing." : "";
      //   localerror = !isNumeric(value)
      //     ? "facility_id must contain only numbers."
      //     : "";
      //   break;
      case "organization_id":
        localerror = value.length <= 0 ? "Organization is missing." : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/user/groups",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
        setGroupsinfo(true);
        setActivityinfo(false);
        return; 
      case 'activityinfo':
            setGroupsinfo(false);
            setActivityinfo(true);
          return;
  }
  };
  
  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadOrganization(jackapp.currentUser);
    }

    async function loadOrganization(userInfo) {
      getAllOrganizationForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;

          let Organizationdata = JSON.parse(response);
          let options = Organizationdata.map(function (organization) {
            return { value: organization._id, label: organization.organization_name };
          })
          setOrganizationList(options);
          //console.log("Organization1" + response);
        }
      });
    }

    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadcustomer(jackapp.currentUser);
    }

    async function loadcustomer(userInfo) {
      getAllCustomerForLookup(1, userInfo).then((response) => {
        if (response !== undefined) {
          //let data[] = response;
          let customerdata = JSON.parse(response);
          let options = customerdata.map(function (customer) {
            return { value: customer._id, label: customer.customer_name };
          });
          setcustomerList(options);
          //console.log("Organization1" + response);
        }
      });
    }
  }, []);

  useEffect(() => {
    //internal functions
    async function loadGroupByID(userInfo) {
      getGroupByID(keyid, userInfo).then((response) => {
        if (response !== undefined) {
          //console.log(JSON.parse(response));
          let groups = JSON.parse(response);
          groups.map((group) =>
            //console.log(group._id)
            setGroup({ ...group, _id: group._id })
          );
        }
      });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadGroupByID(jackapp.currentUser);
    }
  }, [keyid, jackapp.currentUser]);
  return (
    <div className="row">
    <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item">
          <Link to="/user/groups">Groups</Link>
        </li>
        <li className="breadcrumb-item active bold">Edit Group</li>
      </ol>
    </div>
    <div className="tab-content p-0">
      <div className={'tab-pane fade ' + (tabGroupsinfo ? 'show active ': '')}>
        <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabGroupsinfo ? "active " : "")}
                >
                Basic Info
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
                          <Button
                          type="submit"
                          onClick={handleSubmit}
                          color="primary"
                          disabled={!isvaliddata}
                          size="md"
                          className="me-1"
                          >
                          <i className="fa fa-save"></i>&nbsp; Save
                          </Button>
                          <Button color="default" size="md" onClick={handleSweetAlert}>
                          Cancel
                          </Button>
                      </ButtonGroup>
            </div>
          </PanelHeader>
            <form noValidate>
              <PanelBody>
              <div className="row pb-2">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label ">
                    Group Short Name{" "}<label  className="text-danger">*</label>
                  </label>

                  <input
                    type="text"
                    name="group_short_name"
                    id="group_short_name"
                    className="form-control"
                    value={group.group_short_name}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.group_short_name.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="group_short_name"
                    >
                      {errors.group_short_name}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Description</label>

                  <input
                    type="text"
                    name="group_description"
                    id="group_description"
                    className="form-control"
                    value={group.group_description}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.group_description.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="group_description"
                    >
                      {errors.group_description}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label">Organization <label  className="text-danger">*</label></label>
                  <div className="col-md-6">
                  <Select name="organization_id" 
                
                options={OrganizationList}
                isSearchable={true}
                styles={styles} 
                value={OrganizationList.filter((organization) => organization.value === group.organization_id)}
                onChange={(val)=> {handleDropDownChange({target: { name:'organization_id', value: val.value }})}} 
                ></Select>
                    {errors.organization_id.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="organization_id"
                      >
                        {errors.organization_id}
                      </small>
                    )}
                  </div>
                </div>
                {/* <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Facility *</label>
                  <div className="col-md-6">
                  <Select name="facility_id" 
                  value={facilityList.filter(facility => facility.value === group.facility_id)}
                  options={facilityList}
                  isSearchable={true}
                  styles={styles} 
                  onChange={(val)=> {handleDropDownChange({target: { name:'facility_id', value: val.value }})}} 

                   ></Select>
                
                    {errors.facility_id.length > 0 && (
                      <small
                        className="form-check-label text-red"
                        htmlFor="facility_id"
                      >
                        {errors.facility_id}
                      </small>
                    )}
                  </div>
                </div> */}
              </div>
              <div className="row pb-2">
              <div className="col-sm-12 col-md-3 col-lg-3">
                              <label className="form-label">Customer <label  className="text-danger">*</label></label>
                              <Select
                                name="customer_id"
                                id="customer_id"
                                options={customerList}
                                isSearchable={true}
                                styles={styles}
                                value={customerList.filter(
                                  (client) =>
                                    client.value === group.customer_id
                                )}
                                onChange={(val) => {
                                  handleDropDownChange({
                                    target: {
                                      name: "customer_id",
                                      value: val.value,
                                    },
                                  });
                                }}
                              ></Select>
                              {errors.customer_id.length > 0 && (
                                <small
                                  className="form-check-label text-red"
                                  htmlFor="customer_id"
                                >
                                  {errors.customer_id}
                                </small>
                              )}
                            </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Active</label>
                  <div className="form-check form-switch ms-auto mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      disabled="disabled"
                      name="is_active"
                      id="is_active"
                      checked={group.is_active === "1" ? true : false}
                    />
                    <label className="form-check-label" htmlFor="is_active">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>
              </PanelBody>
            </form>
          </Panel>
        </div>
      </div>
      <div className={'tab-pane fade ' + (tabActivityinfo ? 'show active ': '')}>
      <div className="col-xl-12">
          <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
            <div>
              <ul className="nav nav-tabs nav-tabs-inverse">
              <li className="nav-item me-2" key="info">
                <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, "info")}
                className={"nav-link " + (tabGroupsinfo ? "active " : "")}
                >
                Basic Info
                </Link>
              </li>
              <li className="nav-item" key="activityinfo">
                <Link to="/extra/profile" onClick={(e) => showTab(e, 'activityinfo')} 
                className={'nav-link ' + (tabActivityinfo ? 'active ': '')}>Activity Info</Link></li>
              </ul>
            </div>
            <ButtonGroup className="ms-auto my-n1">
                          <Button
                          type="submit"
                          onClick={handleSubmit}
                          color="primary"
                          disabled={!isvaliddata}
                          size="md"
                          className="me-1"
                          >
                          <i className="fa fa-save"></i>&nbsp; Save
                          </Button>
                          <Button color="default" size="md" onClick={handleSweetAlert}>
                          Cancel
                          </Button>
                      </ButtonGroup>
            </div>
          </PanelHeader>
          <PanelBody>
              <ActivityHistory activityhistory={group!==undefined?JSON.stringify(group.activity_history):""}/>
          </PanelBody>
          </Panel>
        </div>

      
      
      </div>
    </div>
              <div>
                {isSaved && (
                  <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="group is updated successfully.."
                    onConfirm={handleSweetAlert}
                  ></SweetAlert>
                )}
              </div>
  </div>
  );
};

export default EditGroup;
