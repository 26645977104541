export const setUserInfo= (userinfo) => {
    
    return (dispatch) => {
        dispatch({
            type: "setuserinfo",
            payload: userinfo
        });
    }
}
export const setAppInfo= (appinfo) => {
    
    return (dispatch) => {
        dispatch({
            type: "setappinfo",
            payload: appinfo
        });
    }
}