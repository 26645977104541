export const getYearlyReports = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getYearlyReports(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};
export const getDailyReports = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getDailyReports(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};
export const getWeeklyReports = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getWeeklyReports(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};
export const getMonthlyReports = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getMonthlyReports(loggedin_by);

    if (data !== undefined) {
      // console.log("LeaveInfo" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    //  console.log("LeaveInfo error" + error);
    return error.response.data.message;
  }
};

export const getDateWiseReport = async (from_date, to_date, userby, LRAapp) => {
 // console.log("from_date", from_date);
 // console.log("to_date", to_date);
 // console.log("userby", userby);
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getDateWiseReports(
      from_date,
      to_date,
      userby,
      loggedin_by
    );
    //console.log("data", data);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return "error:" + error;
  }
};
