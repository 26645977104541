import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { ButtonGroup } from "reactstrap";
import Select from "react-select";
import styles from "../../../components/customstyles/react-select-styles";

import { getLeaveTypeByAbout } from "../../../services/leavetypesService";
import { useRealmApp } from "../../../providers/RealmApp";
function About() {
  const jackapp = useRealmApp();

  const [leavetypeList, setleavetypeList] = useState([]);
  const [tabAboutinfo, setAboutinfo] = useState(true);

  useEffect(() => {
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
      loadleavetype(jackapp.currentUser);
    }
  }, []);

  async function loadleavetype(userInfo) {
    getLeaveTypeByAbout(1, userInfo).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let leavetypedata = JSON.parse(response);

        setleavetypeList(leavetypedata);
        //console.log("Organization1" + response);
      }
    });
  }

  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setAboutinfo(true);
        return;
    }
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse  ">
                  <li className="nav-item" key="info">
                  <Link
                      to=""
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabAboutinfo ? "active " : "")
                      }
                    >
                   About
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            
          </PanelHeader>

          <PanelBody>
            <h4>Learn about the types of leave:</h4>
            <div className="App">
              <style>
                {`
          .title {
            border-style: groove;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
          }
          .title,
          .description {
            border: 1px solid black;
            padding: 5px;
          }
        `}
              </style>
              {leavetypeList.map((c, i) => {
                return (
                  <div className="d-grid gap-2" key={c.leave_name}>
                    <div
                      className="btn btn-gray-600 title"
                      onClick={() => {
                        const c = [...leavetypeList];
                        c[i].expanded = !c[i].expanded;
                        setleavetypeList(c);
                      }}
                    >
                      <div>
                        <b>
                          <h4 className="text-white">{c.leave_name}</h4>
                        </b>
                      </div>
                      <div>
                        {c.expanded ? (
                          <span>&#x2191;</span>
                        ) : (
                          <span>&#x2193;</span>
                        )}
                      </div>
                    </div>
                    {c.expanded && (
                      <div className="text-area">
                        <p>{c.leavetype_description}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
}
export default About;
