import React, { useState, useEffect } from "react";
//import {useContext} from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup, Button } from "reactstrap";
import styles from "../../../components/customstyles/react-select-styles";
//import { AppSettings } from '../../../config/app-settings';
import { useRealmApp } from "../../../providers/RealmApp";
import FileBase from "react-file-base64";
import { createSettings } from "../../../services/settingsService";
import SweetAlert from "react-bootstrap-sweetalert";
import { getOrganizationListByOrgID } from "../../../services/settingsService";
import { getSettingsStatusByOrgID } from "../../../services/settingsService";

const AddSetting = (props) => {
  //context variables
  let navigate = useNavigate();
  //const context = useContext(AppSettings);
  const jackapp = useRealmApp();
  //state variavles
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [validatemsg, SetValidatemsg] = useState("");
  const [setting, setSetting] = useState({
    organization_id: "",
    starting_year: "",
    ending_year: "",
    is_active: "",
  });
  const [errors, setErrors] = useState({
    organization_id: "",
    starting_year: "",
    ending_year: "",
    is_active: "",
  });
  const [OrganizationList, setOrganizationList] = useState([]);
  const [settingrecordvalidation, SetSettingrecordvalidation] = useState("");
  const [tabSettinginfo, setSettingsnfo] = useState(true);

  //save functions

  useEffect(() => {
    var org_id =
      jackapp.currentUser.customData.user_group_roles[0].organization_id;
    GetOrganizationListByOrgID(org_id);
    setSetting({ ...setting, is_active: "1" });
  }, [jackapp.currentUser]);

  useEffect(() => {
    //console.log("setting.organization_id : ", setting.organization_id);
    //console.log("setting.is_active : ", setting.is_active);
    if (setting.organization_id !== "" && setting.is_active !== "") {
      if (setting.is_active === "1") {
        GetSettingsStatusByOrgID(setting.organization_id, setting.is_active);
      //  console.log("settingrecordvalidation1 : ", settingrecordvalidation);
      } else {
        SetSettingrecordvalidation("0");
      }
    }
    SetValidatemsg("");
  }, [setting.organization_id, setting.is_active]);

  async function GetOrganizationListByOrgID(org_id) {
    getOrganizationListByOrgID(org_id, jackapp.currentUser).then((response) => {
      if (response !== undefined) {
        //let data[] = response;
        let SettingsData = JSON.parse(response);
        let options = SettingsData.map(function (setting) {
          return { value: setting._id, label: setting.organization_name };
        });
        setOrganizationList(options);
      }
    });
  }

  async function GetSettingsStatusByOrgID(org_id, statusid) {
    await getSettingsStatusByOrgID(org_id, statusid, jackapp.currentUser).then(
      (result) => {
        console.log("result : ", result);
        SetSettingrecordvalidation(result);
      }
    );
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setSetting({ ...setting, [name]: value });
    handleFieldValidation(event);
    // console.log(setting);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (jackapp.currentUser !== null && jackapp.currentUser !== undefined) {
        console.log("settingrecordvalidation : ", settingrecordvalidation);
        if (settingrecordvalidation !== "0") {
          console.log("handleFieldRequiredValidation called!");
          SetValidatemsg(
            "This organization setting record is already active, please inactive the record"
          );
        } else {
          saveSetting(jackapp.currentUser);
        }
      }
    }
  };
  async function saveSetting(userInfo) {
    createSettings(
      setting.organization_id,
      setting.starting_year,
      setting.ending_year,
      setting.is_active,
      userInfo
    ).then((response) => {
      if (response !== undefined) {
        setisSaved(true);
      }
    });
  }

  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setSetting({ ...setting, [name]: value });
    setErrors({ ...errors, [name]: "" });
    //console.log(`Option selected:`, selectedOption);
    setisvaliddata(true);
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      starting_year:
        setting.starting_year === ""
          ? (iserror = "Starting year is required")
          : errors.starting_year,
      ending_year:
        setting.ending_year === ""
          ? (iserror = "Ending year is required")
          : errors.ending_year,
      organization_id:
        setting.organization_id === ""
          ? (iserror = "Organization is required")
          : errors.organization_id,
    });
    return iserror.length === 0;
  };

  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "starting_year":
        localerror = value.length < 3 ? "Starting year is required" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  //Message functions
  const handleSweetAlert = () => {
    setisSaved(false);

    navigate({
      pathname: "/lms/setting",
      // state: {
      //   response: messageFromServer
      // }
    });
  };
  const showTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "info":
        setSettingsnfo(true);
        return;
      default:
        break;
    }
  };
  return (
    <div className="row">
      <div className="fs-3 mb-1">
        <ol className="breadcrumb float-xl-start">
          <li className="breadcrumb-item">
            <Link to="/lms/setting">Setting</Link>
          </li>
          <li className="breadcrumb-item active bold">Add Setting</li>
        </ol>
      </div>

      <div className="col-xl-12">
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex align-items-center">
              <div>
                <ul className="nav nav-tabs nav-tabs-inverse">
                  <li className="nav-item" key="info">
                    <Link
                      to="/settings/addSetting"
                      onClick={(e) => showTab(e, "info")}
                      className={
                        "nav-link " + (tabSettinginfo ? "active " : "")
                      }
                    >
                      Setting Information
                    </Link>
                  </li>
                </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="primary"
                  disabled={!isvaliddata}
                  size="md"
                  className="me-1"
                >
                  <i className="fa fa-save"></i>&nbsp; Save
                </Button>
                <Button color="default" size="md" onClick={handleSweetAlert}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </PanelHeader>
          <form noValidate>
            <PanelBody>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label">
                    Organization <label className="text-danger">*</label>
                  </label>
                  <Select
                    name="organization_id"
                    options={OrganizationList}
                    isSearchable={true}
                    styles={styles}
                    onChange={(val) => {
                      handleDropDownChange({
                        target: {
                          name: "organization_id",
                          value: val.value,
                        },
                      });
                    }}
                  ></Select>
                  {errors.organization_id.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="organization_id"
                    >
                      {errors.organization_id}
                    </small>
                  )}
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    Starting Year<label className="text-danger">*</label>
                  </label>

                  <input
                    type="date"
                    name="starting_year"
                    id="starting_year"
                    className="form-control"
                    value={setting.starting_year}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.starting_year.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="starting_year"
                    >
                      {errors.starting_year}
                    </small>
                  )}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">
                    Ending Year<label className="text-danger">*</label>{" "}
                  </label>
                  <input
                    type="date"
                    name="ending_year"
                    id="ending_year"
                    className="form-control"
                    value={setting.ending_year}
                    onChange={handleChange}
                    noValidate
                  />
                  {errors.ending_year.length > 0 && (
                    <small
                      className="form-check-label text-red"
                      htmlFor="ending_year"
                    >
                      {errors.ending_year}
                    </small>
                  )}
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4">
                  <label className="form-label form-label">Active</label>
                  <div className="form-check form-switch ms-auto mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="is_active"
                      id="is_active"
                      defaultChecked={true}
                      onChange={(e) => {
                        setisvaliddata(true);
                        setSetting({
                          ...setting,
                          [e.target.name]: e.target.checked ? "1" : "0",
                        });
                      }}
                    />

                    <label className="form-check-label" htmlFor="is_active">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>
              {validatemsg.length > 0 && (
                <big className="form-check-label text-red" htmlFor="validation">
                  {validatemsg}
                </big>
              )}
            </PanelBody>
          </form>
        </Panel>
      </div>
      <div>
        {isSaved && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Setting is added successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default AddSetting;
