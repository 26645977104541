import React, { useState, useMemo, useEffect,useRef } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel";
import { ButtonGroup,Button} from 'reactstrap';
import Moment from "moment";
import { AppSettings } from "../../../config/app-settings";
import { useRealmApp } from "../../../providers/RealmApp";
import { getAllOrganization } from "../../../services/organizationService";
import GlobalListTable from '../../../components/globalTable/gListTable';
import GlobalListPrintTable from '../../../components/globalTable/gListPrintTable';
import { useReactToPrint } from 'react-to-print';
//import SweetAlert from 'react-bootstrap-sweetalert';

const Organization = (props) => {
  //context variables

  const context = useContext(AppSettings);
  const jackapp = useRealmApp();

  //state variables
  const [organization, setorganization] = useState([]);
  const [tabOrganizationinfo, setOrganizationinfo] = useState(true);
   //print variables & functions
 const componentRef = useRef();
 const handlePrint = useReactToPrint({
   content: () => componentRef.current,
 });

  //table functions
  const data = useMemo(() => [...organization], [organization]);
  const OrganizationColumns = useMemo(
    () => [
       {
        Header: "ID",accessor: '_id',sortable:true,width: '20%'
       },

          {
           Header: "Organization Name",accessor: 'organization_name',sortable:true,width: '20%'
          },
		  {
           Header: "Root Url",accessor: 'root_url',sortable:true,width: '30%'
          },
		  {
           Header: "Login Url",accessor: 'login_url',sortable:true,width: '30%'
      },
		  {
            Header: "Created On",
							  accessor: 'activity_on',
							  sortable: true,width:  '10%',
							  Cell: ({ value }) =>
								Moment(value).format(context.appMomentDateFormat),
          },
          {
            Header: "Active",
							  accessor: 'is_active',
							  sortable: true,width:  '10%',
                Cell: ({ value }) => {
                  return (
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                      {value === "1" ? "Yes" : "No"}
                    </span>
                  );
                },
          }
    ],
    [context.appMomentDateFormat]
  ); 
  
  const hiddenOrganizationColumns = [
    "_id",
    "sort_order",
    "deleted_by_id",
    "deleted_by_name",
    "deleted_on",
    "modified_history",
    "created_by_name",
    "created_by_id",
  ];
  //Ops Functions
  const showTab = (e, tab) => {
    e.preventDefault();
    switch(tab){
      case 'info':
    setOrganizationinfo(true);
        return; 
        default:
          break;
  }
  };
  
  useEffect(() => {
    //internal functions
    async function loadOrganization(userInfo) {
      if (organization.length === 0)
        getAllOrganization(1, userInfo).then((response) => {
          if (response !== undefined) {
            //let data[] = response;
            //	console.log("12" +response.map());
            setorganization(JSON.parse(response));
           // console.log("Response : ", response);
          }
        });
    }
    if (jackapp.currentUser !== null && jackapp.currentUser !== undefined)
      loadOrganization(jackapp.currentUser);
  }, [jackapp.currentUser]);

  return (
 
    <div className="row">
      <div className="fs-3 mb-1">
      <ol className="breadcrumb float-xl-start">
        <li className="breadcrumb-item"><Link to="/settings/organization">Organization</Link></li>
        <li className="breadcrumb-item active bold">List Organization</li>
      </ol>
      </div>
      
        <div className="col-xl-12">
    <Panel>
      <PanelHeader noButton={true}>
              <div className="d-flex align-items-center">
              <div >
              <ul className="nav nav-tabs nav-tabs-inverse">
                <li className="nav-item" key="info">
                <Link to="/settings/organization" onClick={(e) => showTab(e, 'info')} 
                className={'nav-link ' + (tabOrganizationinfo ? 'active ': '')}>All</Link></li>
              
              </ul>
              </div>
              <ButtonGroup className="ms-auto my-n1">
              <Link to="/settings/addorganization" href="#" className="btn btn-success btn-rounded px-4 rounded-pill">
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Organization</Link>
            
             
                   <Button onClick={handlePrint} className="btn btn-success btn-rounded px-4 rounded-pill">
                      <i className="fa fa-print fa-lg me-2 ms-n2 text-success-900"></i> Print</Button>
                </ButtonGroup>
              </div>
      </PanelHeader>
      <PanelBody>
        <GlobalListTable gcolumns={OrganizationColumns}  gdata={data!==undefined?JSON.stringify(data):"" } 
        ghiddencolumns={hiddenOrganizationColumns}
        gkey={"organization_id"} glink="/settings/detailorganization"
        gplaceholder={"Search organization.."}
        />
      </PanelBody>
      <PanelBody className="invisible">
      <GlobalListPrintTable  gcolumns={OrganizationColumns}  gdata={data!==undefined?JSON.stringify(data):"" } 
        ghiddencolumns={hiddenOrganizationColumns}
        gkey={"organization_id"} glink="/settings/detailorganization"
        gplaceholder={"Search organization.."}
        ref={componentRef} 
        />
  
      </PanelBody>
          </Panel>
          </div>
  </div>
  );
};

export default Organization;
