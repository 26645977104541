import { useEffect } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { useNavigate } from "react-router-dom";
function LogOut() {
  const app = useRealmApp();
  let navigate = useNavigate();
  useEffect(() => {
    async function logout() {
      //console.log("logout");
      await app.logOutAllUsers();
     // window.location.href = "/authenticate/login";
     navigate("/authenticate/login");
    }

    logout();
  }, [app]);

  return (
    <div>
      <p>Successfully Logout</p>
    </div>
  );
}

export default LogOut;
