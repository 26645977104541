export const getRolesForLookup = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getAllRolesForLookup(loggedin_by,'','');

    if (data !== undefined) {
      // console.log("Organization" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getRolesForLookupByOrganization = async (loggedinby,Organizationid, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getAllRolesForLookup(loggedin_by,Organizationid,'');

    if (data !== undefined) {
      // console.log("Organization" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllRoles = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getRoles(loggedin_by,'');

    if (data !== undefined) {
      // console.log("Roles" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRoleByID = async (role_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getRoleByID(role_id, loggedin_by);
    if (data !== undefined) {
      //console.log("Roles" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createRole = async (
  role_short_name,
  role_description,
  weight,
  organization_id,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    //console.log("role_short_name : ", role_short_name);
    const roledata = {
      role_short_name: role_short_name,
      role_description: role_description,
      role_key:"",
      weight: weight,
      organization_id: organization_id,
      sort_order:"0",
      is_system_record:"0"
    };
    const data = await jackapp.functions.insertRole(
      JSON.stringify(roledata),
      loggedinby
    );
  
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
export const updateRoleByID = async (
  role_id,
  role_short_name,
  role_description,
  weight,
  organization_id,
  jackapp
) => {
  try {
    var loggedinby = jackapp.id;
    const roledata = {
      role_id: role_id,
      role_short_name: role_short_name,
      role_description: role_description,
      role_key:"",
      weight: weight,
      organization_id: organization_id,
      sort_order:"0",
      is_system_record:"0"
    };

    const data = await jackapp.functions.updateRoleByID(
      JSON.stringify(roledata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteRoleByID = async (role_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteRoleByID(role_id, loggedinby);
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
