// Services for getLeaveTypes

export const getLeaveTypes = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveTypes(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveTypes error" + error);
    return error.response.data.message;
  }
};
// Services for getLeaveTypesByID

export const getLeaveTypesByID = async (leavetype_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveTypesByID(leavetype_id, loggedin_by);
    if (data !== undefined) {
      //console.log("Business" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return "error:" + error;
  }
};

// Services for createLeaveTypes
export const createLeaveTypes = async (
  setting_year_id,
  leave_name,
  leavetype_description,  
  no_of_days,  
  is_active, 
  LRAapp
) => {
  try {
    /*    const user = JSON.parse(userpayload);*/
    //temp

    var loggedinby = LRAapp.id;
    const leavetypesdata = {
      setting_year_id:setting_year_id,
      leave_name: leave_name,
      leavetype_description: leavetype_description,
      no_of_days: no_of_days,           
      is_active: is_active,
   
    };
    const data = await LRAapp.functions.insertLeaveTypes(
      JSON.stringify(leavetypesdata),
      loggedinby
    );

    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("our error" + error);
    return error;
  }
};

// Services for updateLeaveTypesByID
export const updateLeaveTypesByID = async (
  leavetype_id,
  leave_name,
  leavetype_description,
  no_of_days,
  setting_year_id,
  is_active, 
  LRAapp
) => {
  try {
    var loggedinby = LRAapp.id;
    const userdata = {
      _id: leavetype_id,
      leave_name: leave_name,
      setting_year_id:setting_year_id,
      leavetype_description: leavetype_description,     
      no_of_days: no_of_days,        
      is_active: is_active
    };
    console.log(loggedinby);
    const data = await LRAapp.functions.updateLeaveTypesByID(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");

    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};

// Services for deleteLeaveTypesByID
export const deleteLeaveTypesByID = async (leavetype_id, LRAapp) => {
  try {
    var loggedinby = LRAapp.id;
    const data = await LRAapp.functions.deleteLeaveTypesByID(
      leavetype_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    console.log("error:" + error);
    return error;
  }
};

// Services for getLeaveTypeLookup
export const getLeaveTypeLookup = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveTypeLookup(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};


//getLeaveTypeByAbout

export const getLeaveTypeByAbout = async (loggedinby, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getLeaveTypeByAbout(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("LeaveTypes error" + error);
    return error.response.data.message;
  }
};