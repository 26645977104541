// Services for getemployeeroleByID

export const getemployeerolesByID = async (emprole_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getEmployee_roleByID(
      emprole_id,
      loggedin_by
    );
    //console.log("data", data);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return "error:" + error;
  }
};

export const getRolePermissionByUserID = async (emprole_id, LRAapp) => {
  try {
    const loggedin_by = LRAapp.id;
    const data = await LRAapp.functions.getRolePermissionByUserID(
      emprole_id,
      loggedin_by
    );
   //console.log("data", data);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return "error:" + error;
  }
};
