export const getAllOrganization = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getOrganization(loggedin_by);

    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("Organization error" + error);
    return error.response.data.message;
  }
};

export const getAllOrganizationForLookup = async (loggedinby, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getOrganizationForLookup(loggedin_by);

    if (data !== undefined) {
      // console.log("Organization" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getOrganizationByID = async (organization_id, jackapp) => {
  try {
    const loggedin_by = jackapp.id;
    const data = await jackapp.functions.getOrganizationByID(
      organization_id,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("Organization" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const createOrganization = async (
  organization_name,
  root_url,
  login_url,
  jackapp
) => {
  try {
    // var loggedinby = jackapp.id;
    // console.log("organization_name : ", organization_name);
    // const data = await jackapp.functions.insertOrganization(
    //   organization_name,
    //   root_url,
    //   login_url,
    //   loggedinby
    // );
    var loggedinby = jackapp.id;
    const Organizationdata = {
      organization_name: organization_name,
      organization_description: "",
      organization_key: "",
      sort_order: "0",
      root_url: root_url,
      login_url: login_url,
    };
    const data = await jackapp.functions.insertOrganization(
      JSON.stringify(Organizationdata),
      loggedinby
    );

    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const updateOrganizationByID = async (
  organization_id,
  organization_name,
  root_url,
  login_url,
  jackapp
) => {
  try {
    // var loggedinby = jackapp.id;
    // console.log("organization_name : ", organization_name);
    // const data = await jackapp.functions.updateOrganizationByID(
    //   organization_id,
    //   organization_name,
    //   root_url,
    //   login_url,
    //   loggedinby
    // );
    var loggedinby = jackapp.id;
    const Organizationdata = {
      organization_id: organization_id,
      organization_name: organization_name,
      organization_description: "",
      organization_key: "",
      sort_order: "0",
      root_url: root_url,
      login_url: login_url,
    };

    const data = await jackapp.functions.updateOrganizationByID(
      JSON.stringify(Organizationdata),
      loggedinby
    );
    console.log("status: success");
    return { status: "success", data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteOrganizationByID = async (organization_id, jackapp) => {
  try {
    var loggedinby = jackapp.id;
    const data = await jackapp.functions.deleteOrganizationByID(
      organization_id,
      loggedinby
    );
    if (data !== undefined) {
      return { status: "success", data };
    }
  } catch (error) {
    return error.response.data.message;
  }
};
