/* eslint-disable no-whitespace-before-property */
/* eslint-disable no-lone-blocks */
import React from "react";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { useRealmApp } from "../../providers/RealmApp";
import { AppSettings } from './../../config/app-settings.js';
import { slideToggle } from './../../composables/slideToggle.js';


const SidebarProfile = (props) => {
	const [userfullname, setUserFullName] = useState();
	const [useremail, setuseremail] = useState();
	const [userdesignation, setUserDesignation] = useState();
	const [userphoto, setuserphoto] = useState();
	const [isdefaultuserphoto, setisdefaultuserphoto] = useState(true);
	const app = useRealmApp();
	//console.log("app : ",app.users[0].customData.first_name);
	const handleProfileExpand = (e) => {
		e.preventDefault();
		
		var targetSidebar = document.querySelector('.app-sidebar:not(.app-sidebar-end)');
		var targetMenu = e.target.closest('.menu-profile');
		var targetProfile = document.querySelector('#appSidebarProfileMenu');
		var expandTime = (targetSidebar && targetSidebar.getAttribute('data-disable-slide-animation')) ? 0 : 250;
	
		if (targetProfile) {
			if (targetProfile.style.display === 'block') {
				targetMenu.classList.remove('active');
			} else {
				targetMenu.classList.add('active');
			}
			slideToggle(targetProfile, expandTime);
			targetProfile.classList.toggle('expand');
		}
	}

	
	useEffect(() => {
		
		
		  if (app.currentUser !== null && app.currentUser !== undefined) 
		{
			setuseremail(app.currentUser._profile.data.email);
			if (app.currentUser.customData !== null && app.currentUser .customData!== undefined) 
			{
				setuserphoto(app.currentUser.customData.photo);
				if(app.currentUser.customData.photo!=="")
				setisdefaultuserphoto(false);
			}
			{
				setUserDesignation(app.currentUser.customData.designation);
			}
			{
				setUserFullName(app.currentUser.customData.first_name+" "+app.currentUser.customData.last_name);
			}
		}

	
	}, [app.currentUser])


	

	  
		return (
			<AppSettings.Consumer>
				{({appSidebarMinify}) => (
					<div className="menu">
						<div className="menu-profile">
							<Link to=""  className="menu-profile-link">
								<div className="menu-profile-cover with-shadow"></div>
								<div  className="menu-profile-image menu-profile-image-icon bg-gray-900 text-gray-600">
								<img src={isdefaultuserphoto?"../assets/img/user/user-13.jpg":userphoto }alt="" /> 
								</div>
								
								<div className="menu-profile-info">
									<div className="d-flex align-items-center">
										<div className="flex-grow-1">
									{userfullname}
										</div>
										
									</div>	
									<small>{userdesignation}</small>					
								</div>
									
							</Link>
						</div>
						<div id="appSidebarProfileMenu" className="collapse">
							<div className="menu-item pt-5px">
								<Link to="/" className="menu-link">
									<div className="menu-icon"><i className="fa fa-cog"></i></div>
									<div className="menu-text">Settings</div>
								</Link>
							</div>						
							
							<div className="menu-divider m-0"></div>
						</div>
					</div>
				)}
			</AppSettings.Consumer>
		)

}

export default SidebarProfile;